import { DateInput, SelectInput, TextInput, Filter } from "react-admin";
import { useTranslation } from 'react-i18next';


const ManufacturerFilter = (props) => {
  const { t : itranslate } = useTranslation();
  return (
    <Filter {...props}>

      <TextInput
        source="q"
        label={itranslate('Search manufacturer code / manufacturer name / scope')}
        alwaysOn
        sx={{ width: "500px" }}
      />

      <TextInput
        label={itranslate('Manufacturer Code')}
        source="manufacturer_code"
        defaultValue="M"
      />

      <TextInput 
        label={itranslate('Manufacturer Name')}
        source="provided_name" 
      />

      <TextInput
        label={itranslate('Manufacturer Address')}
        source="iso_manufacturer_address"
      />

      <SelectInput
        label={itranslate('Scope')}
        source="type"
        choices={[
          {
            id: "SafetyComponent",
            name: itranslate('Manufacturer Code'),
          },
          {
            id: "Model",
            name: itranslate('Lift/Escalator System Model'),
          },
          {
            id: "Both",
            name: itranslate('Both Lift/Escalator System Model and Safety Components'),
          },
        ]}
      />

      <DateInput 
        label={itranslate('All Approval Date')}
        source="approval_date" 
      />
    </Filter>
  );
};

export default ManufacturerFilter;
