import "./reports.css";
import { useState, useEffect } from "react";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import { MenuItem, Select, InputLabel, Button, CircularProgress, Box } from "@mui/material";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";
import { SelectDates, handleDateChange } from "./SelectDates";
import { Confirm, useNotify, useRefresh } from "react-admin";
import CustomNotification from "./CustomNotification";
import { useTranslation } from 'react-i18next';


const labelProps = {
  sx: {
    fontWeight: "bold",
  },
};

const dateStyles = {
  padding: "10px",
  boxShadow: "2px 4px 10px 1px rgba(201,201,201,0.47)",
  borderRadius: "10px",
  minWidth: "200px",
  fontSize: "16px",
}

const Divider = () => (
  <hr
    style={{
      margin: "20px 0",
      height: "1px",
      border: "none",
      background: "#ccc",
    }}
  />
);

const Spinner = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '30px'}}>
    <CircularProgress />
  </Box>
)

// Date for Models Search
const DateRangeComponent = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    onDateChange(newStartDate, endDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    onDateChange(startDate, newEndDate);
  };

  return (
    <div>
      <input
        type="date"
        value={startDate || ""}
        onChange={handleStartDateChange}
        style={dateStyles}
      />
      <input
        type="date"
        value={endDate || ""}
        onChange={handleEndDateChange}
        style={dateStyles}
      />
    </div>
  );
};

// Date for Components Search
const DateRangeComponent2 = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    onDateChange(newStartDate, endDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    onDateChange(startDate, newEndDate);
  };

  return (
    <div>
      <input
        type="date"
        value={startDate || ""}
        onChange={handleStartDateChange}
        style={dateStyles}
      />

      <input
        type="date"
        value={endDate || ""}
        onChange={handleEndDateChange}
        style={dateStyles}
      />
    </div>
  );
};


// Date for Applications Search
const DateRangeComponent3= ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    onDateChange(newStartDate, endDate);
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
    onDateChange(startDate, newEndDate);
  };

  return (
    <div>
      <input
        type="date"
        value={startDate || ""}
        onChange={handleStartDateChange}
        style={dateStyles}
      />

      <input
        type="date"
        value={endDate || ""}
        onChange={handleEndDateChange}
        style={dateStyles}
      />
    </div>
  );
};



const Reports = () => {
  const { t : itranslate } = useTranslation();
  const notify = useNotify()
  const [customMessage, setCustomMessage] = useState("");

  // models search
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modelreport, setModelreport] = useState([]);

  // components search
  const [startDate2, setStartDate2] = useState(null);
  const [endDate2, setEndDate2] = useState(null);
  const [componentreport, setComponentreport] = useState([]);

  // applications search
  const [startDate3, setStartDate3] = useState(null);
  const [endDate3, setEndDate3] = useState(null);
  const [applicationsreport, setApplicationsreport] = useState([])

  // models
  const fileName = "models-detail";
  const [loading, setLoading] = useState(false);
  const headers = [
    { label: "APP_TYPE", key: "application_type" },
    // { label: "update_type", key: "update_type" },
    { label: "RC_MODEL_CODE", key: "model_code" },
    { label: "MANUF_CODE", key: "manu_code" },
    { label: "REF_APP_ID", key: "application_number" },
    { label: "RC_TYPE_CODE", key: "rc_type_code" },
    { label: "RC_NO", key: "rc_no" },
    { label: "LE_TYPE", key: "LE_type" },
    { label: "CREATED_DATE", key: "createdAt" },
    { label: "APPROVAL_DATE", key: "approval_date" },
  ];

  // components
  const componentsFileName = "components-detail";
  const [loading2, setLoading2] = useState(false);
  const headers2 = [
    { label: "APP_TYPE", key: "application_type" },
    // { label: "update_type", key: "update_type" },
    { label: "RC_COMP_CODE", key: "component_code" },
    { label: "MANUF_CODE", key: "manu_code" },
    { label: "REF_APP_ID", key: "application_number" },
    { label: "COMP_TYPE", key: "comp_type" },
    { label: "LE_TYPE", key: "LE_type" },
    { label: "RC_TYPE_CODE", key: "rc_type_code" },
    { label: "RC_NO", key: "rc_no" },
    { label: "CREATED_DATE", key: "createdAt" },
    { label: "APPROVAL_DATE", key: "approval_date" },
  ];

  // applications
  const applicationsFileName = "applications-detail";
  const [loading3, setLoading3] = useState(false);
  const headers3 = [
    { label: "APP_TYPE", key: "application_type" },
    // { label: "update_type", key: "update_type" },
    { label: "REF_APP_ID", key: "application_number" },
    { label: "LE_TYPE", key: "LE_type" },
    { label: "RC_TYPE_CODE", key: "rc_type_code" },
    { label: "RC_NO", key: "rc_no" },
    { label: "CREATED_DATE", key: "createdAt" },
    { label: "APPROVAL_DATE", key: "approval_date" },
  ];


  // models  
  const handleModelsDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleModelsSearch = () => {
    setModelreport([])
    setLoading(true);
    const body = {};
    if (startDate !== null) {
      body.startDate = startDate;
    }
    if (endDate !== null) {
      body.endDate = endDate;
    }

    async function fetchData() {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/models/report`,
        {body},
        {headers: { authorization: getToken() }}
      );
      // console.log(data);
      let convertedData = data.map(data => {
        return {
          ...data,
          approval_date: moment(data.approval_date).tz('Asia/Hong_Kong').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }
        }) 
        setModelreport(convertedData);
        setLoading(false);
    }
    fetchData();
  };


  // components
  const handleComponentsDateChange = (startDate2, endDate2) => {
    setStartDate2(startDate2);
    setEndDate2(endDate2);
  };

  const handleComponentsSearch = () => {
    if (!startDate2 || !endDate2) {
      setCustomMessage("Please select both starting and ending dates");
      return;
    };

    if (new Date(startDate2) > new Date(endDate2)) {
      setCustomMessage("Please select a valid date range");
      return;
    };

    setComponentreport([])
    setLoading2(true);
    const body = {};
    if (startDate2 !== null) {
      body.startDate = startDate2;
    }
    if (endDate2 !== null) {
      body.endDate = endDate2;
    }

    async function fetchData() {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/components/report`,
        {body},
        {headers: { authorization: getToken() }}
      );
      // console.log(data);
      let convertedData = data.map(data => {
        return {
          ...data,
          approval_date: moment(data.approval_date).tz('Asia/Hong_Kong').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }
        }) 
        setComponentreport(convertedData);
        setLoading2(false);
    }
    fetchData();
  };


  // applications
  const handleApplicationsDateChange = (startDate3, endDate3) => {
    setStartDate3(startDate3);
    setEndDate3(endDate3);
  };

  const handleApplicationsSearch = () => {
    setApplicationsreport([])
    setLoading3(true);
    const body = {};
    if (startDate3 !== null) {
      body.startDate = startDate3;
    }
    if (endDate3 !== null) {
      body.endDate = endDate3;
    }

    async function fetchData() {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps/report`,
        {body},
        {headers: { authorization: getToken() }}
      );
      // console.log(data);
      let convertedData = data.map(data => {
        return {
          ...data,
          approval_date: moment(data.approval_date).tz('Asia/Hong_Kong').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        }
        }) 
        setApplicationsreport(convertedData);
        setLoading3(false);
    }
    fetchData();
  };


  return (
   <div className="reports__outercontainer">

      <div className="reports__container">
        <div className="reports__date">
          <InputLabel {...labelProps}>
          {itranslate("Models Search")}
          </InputLabel>

          <DateRangeComponent onDateChange={handleModelsDateChange} />

          <div className="reports__button">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleModelsSearch()}
            >
              {itranslate("Search")}
            </Button>
          </div>

          {modelreport.length !== 0 ? (
            <div className="reports__export">
              <>
                <div className="reports__message">
                  {itranslate("Click to Download")}
                </div>
                <Button variant="contained" color="primary">
                  <CSVLink
                    headers={headers}
                    data={modelreport}
                    filename={fileName}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {itranslate("Export Data")}
                  </CSVLink>
                </Button>
              </>
            </div>
          ) : (
            <div className="reports__export">
              {loading ?  <Spinner /> : itranslate("No Data")}
            </div>
          )}
        </div>
      </div>

      <Divider />

      <div className="reports__container">
        <div className="reports__date">
          <InputLabel {...labelProps}>
          {itranslate("Components Search")}
          </InputLabel>

          <DateRangeComponent2 onDateChange={handleComponentsDateChange} />

          <div className="reports__button">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleComponentsSearch()}
            >
              {itranslate("Search")}
            </Button>
          </div>
          

          {componentreport.length !== 0 ? (
            <div className="reports__export">
              <>
                <div className="reports__message">
                  {itranslate("Click to Download")}
                </div>
                <Button variant="contained" color="primary">
                  <CSVLink
                    headers={headers2}
                    data={componentreport}
                    filename={componentsFileName}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {itranslate("Export Data")}
                  </CSVLink>
                </Button>
              </>
            </div>
          ) : (
            <div className="reports__export">
              {loading2 ?  <Spinner /> : itranslate("No Data")}

              <CustomNotification
                message={customMessage}
                onClose={() => setCustomMessage("")}
              />
            </div>
          )}
        </div>
      </div>

      <Divider />

      <div className="reports__container">
        <div className="reports__date">
          <InputLabel {...labelProps}>
          {itranslate("Applications Search")}
          </InputLabel>

          <DateRangeComponent3 onDateChange={handleApplicationsDateChange} />

          <div className="reports__button">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleApplicationsSearch()}
            >
              {itranslate("Search")}
            </Button>
          </div>

          {applicationsreport.length !== 0 ? (
            <div className="reports__export">
              <>
                <div className="reports__message">
                  {itranslate("Click to Download")}
                </div>
                <Button variant="contained" color="primary">
                  <CSVLink
                    headers={headers3}
                    data={applicationsreport}
                    filename={applicationsFileName}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {itranslate("Export Data")}
                  </CSVLink>
                </Button>
              </>
            </div>
          ) : (
            <div className="reports__export">
              {loading3 ?  <Spinner /> : itranslate("No Data")}
            </div>
          )}
   
      </div>
   </div>
   </div>

  )
}


export default Reports