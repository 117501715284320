import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Grid, Select, MenuItem, InputLabel } from "@mui/material";
import FileUploadZoneAndShowFileImage from "../../utils/fileUploadZone";
import getToken from "../../utils/getAuthToken";
import mandatoryFields from "../update/mandatoryFields.json";
import { useLocation } from "react-router";
import { useTranslation } from 'react-i18next';


export const ModelFields = (props) => {
  const location = useLocation();
  const {
    template,
    fileStackObject,
    handleChange,
    modelIndex,
    fileStackIndex,
    add,
    isValidated,
    edit,
    show,
    showOne,
    upload,
    uploadingFile,
    filenames,
  } = props;

  const { t : itranslate } = useTranslation();

  const [certChoices, setCertChoices] = useState(
    fileStackObject.full_quality_certificate_id?.length > 0
      ? "full_quality_certificate_id"
      : "type_test_certificate_id"
  ); //RC can choose either upload TEC or Full Quality Certificate.

  const fileDownload = async (id) => {
    window.open(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${id}?token=${getToken()}`);
  };

  const dropzoneId = "dropzone";

  useEffect(() => {
    const dragEventHandler = (e) => {
      if (e.target.className !== dropzoneId) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      }
    };

    ["dragenter", "dragover", "drop"].forEach((ev) =>
      window.addEventListener(ev, dragEventHandler, false)
    );

    return () => {
      ["dragenter", "dragover", "drop"].forEach((ev) =>
        window.removeEventListener(ev, dragEventHandler, false)
      );
    };
  }, []);

  const displayedField = (mode) => {
    switch (mode) {
      case "a": {
        // If it is manufacturer name change, hide all manufacturers except the one user picked in updateModeSelector.js
        const query = new URLSearchParams(location.search);
        const manufacturerQuery = query.get("manufacturer");
        if (!manufacturerQuery) return ["type_test_certificate_id"]; //it should not happen but just in case
        if (manufacturerQuery === fileStackObject.manufacturer_id)
          return ["type_test_certificate_id"];
        return [];
      }
      case "b":
        return ["type_test_certificate_id"];
      case "c":
        return upload?.model[modelIndex]?.LE_type === "L"
          ? [
              "compatibility_cert_id",
              "type_test_certificate_id",
              "calculation",
              "compliance_confirmation",
            ]
          : ["type_test_certificate_id", "calculation"];
      case "f":
        return [
          "compliance_confirmation",
          "compatibility_cert_id",
          "type_test_certificate_id",
        ];
      default:
        return [
          "compatibility_cert_id",
          "type_test_certificate_id",
          "full_quality_certificate_id",
          "calculation",
          "compliance_confirmation",
          "install_operation_maint",
          "maintenance_schedule",
          "arr_training_tech_support",
          "EMC_compliance",
          "other_man_model_documents",
        ].filter((field) => {
          const filtered =
            certChoices === "type_test_certificate_id"
              ? "full_quality_certificate_id"
              : "type_test_certificate_id";
          if (show && upload.update_upon) {
            const changedFields =
              upload.changes?.model?.[upload.model[modelIndex]?._id]?.mmf[
                fileStackIndex
              ];
            return changedFields?.includes(field);
          } else if (upload?.model[modelIndex]?.type === "Others") {
            return field !== "compatibility_cert_id" && field !== filtered;
          } else if (add || ["new", "record"].includes(edit.clarifying)) {
            return field !== filtered;
          } else {
            return field;
          }
        });
    }
  };

  return (
    <Grid container spacing={4} sx={{ width: "90%" }}>
      {add && (
        <Grid item xs={6}>
          <InputLabel id="certChoices">
            {/* Choose Certificate */}
            {itranslate("Choose Certificate ")}
          </InputLabel>
          <Select
            labelId="certChoices"
            value={certChoices}
            onChange={(e) => setCertChoices(e.target.value)}
          >
            <MenuItem value="type_test_certificate_id">
              {/* Type Examination Certificate and Test Report */}
              {itranslate("Type Examination Certificate and Test Report")}
            </MenuItem>
            <MenuItem value="full_quality_certificate_id">
              {/* Full Quality Assurance Certificate */}
              {itranslate("Full Quality Assurance Certificate")}
            </MenuItem>
          </Select>
        </Grid>
      )}
      {Object.keys(template)?.filter((fieldname) => displayedField(edit.mode || edit.clarifying).includes(fieldname))
        .map((fieldname, i) => {

          //For edit mode f (new SC), only type test cert is mandatory
          //upload?.mandatoryFileSet is for range extension, check RangeExtensionCheckbox.js
          const markMandatory = fieldname !== "other_man_model_documents" &&
            (add ||
              edit?.clarifying === "new" ||
              mandatoryFields[upload?.mandatoryFileSet || edit?.mode || edit?.clarifying]?.models?.includes(fieldname)) && (
              <Typography
                component="span"
                sx={{
                  color: "red",
                  fontStyle: "italic",
                  fontSize: "0.75rem",
                  verticalAlign: "top",
                }}
              >
                {/* *Required */}
                {itranslate("*Required")}
              </Typography>
            );
            
          if (fileStackObject[fieldname]?.length > 0 || !show)
            return (
              <Grid
                item
                xs={6}
                key={`model${modelIndex}-mmf${fileStackIndex}-${fieldname}`}
              >
                <FileUploadZoneAndShowFileImage
                  AIfeedback={upload?.aiFeedBack}
                  edit={edit}
                  add={add}
                  isValidated={isValidated}
                  show={show}
                  showOne={showOne}
                  data={fileStackObject}
                  fieldname={fieldname}
                  filenames={filenames}
                  markMandatory={markMandatory}
                  uploadingFile={uploadingFile}
                  deleteFile={(additionalFileIndex) => {
                    handleChange(
                      "deleteFile",
                      modelIndex,
                      fieldname,
                      "",
                      fileStackIndex,
                      additionalFileIndex
                    );
                  }}
                  downloadFile={fileDownload}
                  acceptFile={(acceptedFiles) =>
                    handleChange(
                      "addFile",
                      modelIndex,
                      fieldname,
                      acceptedFiles,
                      fileStackIndex
                    )
                  }
                />
              </Grid>
            );
        })}
    </Grid>
  );
};
