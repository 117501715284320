import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { IconButton, InputLabel, TextField, Grid } from "@mui/material";
import { Add, Close, Delete } from "@mui/icons-material";
import keyToField from "../../utils/keyToField";
import { PatternFormat } from "react-number-format";
import autoSave from "../../utils/applications/autoSave";
import { useTranslation } from 'react-i18next';


function ModelForm(props) {
  const {
    upload,
    setUpload,
    update,
    setUpdate,
    add,
    edit,
    modelIndex,
    fields,
    isForEMSD,
    models,
    setModels,
  } = props;

  const { t : itranslate } = useTranslation();

  const modelState = isForEMSD ? models : upload.model;

  const handleChange = async (
    action,
    index,
    key,
    file,
    low_index,
    extra_key,
    extra_value
  ) => {
    if (action === "editModelValues") {
      const regex = /^\d+(\.\d{0,2})?$/;
      //file is e.target.value, only allows number, excluding rope_factor as it is already sanitized by PatternFormat
      if (!(file === "" || regex.test(file) || key === "rope_factor")) return;
      let change_obj = modelState;
      let change_app_type = isForEMSD ? null : { ...upload.app_type };

      if (key === "rope_factor") {
        !change_obj[index].rope_factor
          ? (change_obj[index].rope_factor = [file])
          : (change_obj[index].rope_factor[extra_key] = file);
      } else if (key === "balancing_factor") {
        if (change_obj[index].balancing_factor) {
          const balancingFactor = change_obj[index].balancing_factor.split("-");
          const from = balancingFactor[0];
          const to = balancingFactor[1];
          extra_key === "from"
            ? (change_obj[index].balancing_factor = file + "-" + to)
            : (change_obj[index].balancing_factor = from + "-" + file);
        } else {
          extra_key === "from"
            ? (change_obj[index].balancing_factor = file + "-")
            : (change_obj[index].balancing_factor = "-" + file);
        }
      } else if (key === "rated_speed") {
        //In case user increase the value of rated speed after deleting buffer, add back buffer if speed > 1
        if (
          !isForEMSD &&
          (add || ["new", "record"].includes(edit.clarifying)) &&
          [
            "ElectricTractionLiftMachineRoom",
            "ElectricTractionLiftRoomless",
          ].includes(change_app_type?.type) &&
          file > 1 &&
          !change_app_type.components["Buffer"]
        )
          change_app_type.components["Buffer"] = [];
        change_obj[index][key] = file;
      } else {
        change_obj[index][key] = file;
      }

      if (isForEMSD) {
        setModels([...change_obj]);
      } else {
        const newUpload = {
          ...upload,
          model: [...change_obj],
          app_type: change_app_type,
        };
        setUpload(newUpload);
        const newUpdate = {
          ...update,
          model: [...change_obj],
          app_type: change_app_type,
        };
        edit && setUpdate(newUpdate);
        autoSave(newUpload, edit ? newUpdate : update, edit?.mode);
      }
    }

    if (action === "addOrDeleteRopeFactor") {
      let change_obj = modelState;
      if (extra_key === "add") {
        if (!change_obj[index].rope_factor)
          change_obj[index].rope_factor = ["", ""];
        else
          change_obj[index].rope_factor = [
            ...change_obj[index].rope_factor,
            "",
          ];
      } else if (extra_key === "delete") {
        change_obj[index].rope_factor = change_obj[index].rope_factor.filter(
          (rf, index) => index !== extra_value
        );
      }
      if (isForEMSD) {
        setModels([...change_obj]);
      } else {
        const newUpload = { ...upload, model: [...change_obj] };
        setUpload(newUpload);
        const newUpdate = { ...update, model: [...change_obj] };
        edit && setUpdate(newUpdate);
        autoSave(newUpload, edit ? newUpdate : update, edit?.mode);
      }
    }
  };

  return (
    <Grid container spacing={4} sx={{ width: "70%" }}>
      {fields.map((fieldname) => {
        const model = modelState[modelIndex].provided_model_name;
        const fieldFailed =
          add &&
          upload?.aiFeedBack?.length > 0 &&
          upload?.aiFeedBack?.some(
            (item) => item.name === model && item.field === fieldname
          );
          
        // if model type is stairlift and balancing factor is not mandatory, and other documents are not mandatory  
        const markMandatory = add && 
          fieldname !== "other_man_documents" && 
          (upload.app_type.type === "StairLift" && (fieldname !== "balancing_factor" && fieldname !== "rope_factor" )) 
          && (
          <Typography
            component="span"
            sx={{
              color: "red",
              fontStyle: "italic",
              fontSize: "0.75rem",
              verticalAlign: "top",
            }}
          >
            {itranslate("Required")}
          </Typography>
        );

        const placeholderGenerator = (field) => {
          switch (field) {
            case "rated_load":
              return "2500";
            case "rated_speed":
              return "3";
            case "maximum_travel_of_height":
              return "150";
            case "rope_factor":
              return "2:1";
            case "balancing_factor":
              return "40";
            case "maximum_rise":
              return "25";
            case "angle_of_inclination":
              return "30";
            default:
              return;
          }
        };
        const placeholder = placeholderGenerator(fieldname);
        const textFieldProps = {
          fullWidth: true,
          sx: { ...(fieldFailed && { backgroundColor: "pink" }) },
          InputLabelProps: { shrink: true },
          inputProps: { maxLength: 10 },
          placeholder: placeholder,
          labelid: fieldname,
          variant: "outlined",
        };
        const ropeFactorProps = {
          fullWidth: true,
          sx: { ...(fieldFailed && { backgroundColor: "pink" }) },
          InputLabelProps: { shrink: true },
          inputProps: { maxLength: 10 },
          labelid: fieldname,
          variant: "outlined",
          format: "#:#",
          allowEmptyFormatting: true,
          mask: "_",
          customInput: TextField,
        };
        return (
          <Grid item xs={6} key={`model${modelIndex}-${fieldname}`}>
            {fieldname === "model_code" || fieldname === "type" ? (
              <Typography>
                {itranslate(keyToField[fieldname]) ?? fieldname}:{" "}

                {fieldname === "type"
                  ? itranslate(keyToField[modelState[modelIndex][fieldname]])
                  : modelState[modelIndex][fieldname]
                  ? itranslate(modelState[modelIndex][fieldname])
                  : "Not assigned"}

              </Typography>
            ) : (
              <div>
                <InputLabel
                  id={fieldname}
                  sx={{ color: "black", fontWeight: "bold" }}
                >
                  {itranslate(keyToField[fieldname]) ?? itranslate(fieldname)} {markMandatory}
                </InputLabel>
                
                {fieldname === "rope_factor" ? (
                  <Grid container spacing={1}>
                    {modelState[modelIndex].rope_factor ? (
                      modelState[modelIndex].rope_factor.map(
                        (ropeFactor, ropeFactorIndex) => {
                          return (
                            <Grid item md={6} xl={3} key={ropeFactorIndex}>
                              <PatternFormat
                                value={ modelState[modelIndex].rope_factor[ropeFactorIndex] }
                                autoFocus={true}
                                onChange={(e) =>
                                  handleChange(
                                    "editModelValues",
                                    modelIndex,
                                    fieldname,
                                    e.target.value,
                                    undefined,
                                    ropeFactorIndex
                                  )
                                }
                                //Note that inputProps and InputProps are different
                                InputProps={{
                                  ...(ropeFactorIndex !== 0 && {
                                    endAdornment: (
                                      <IconButton
                                        sx={{
                                          width: "20px",
                                          color: "red",
                                        }}
                                        onClick={() =>
                                          handleChange(
                                            "addOrDeleteRopeFactor",
                                            modelIndex,
                                            fieldname,
                                            undefined,
                                            undefined,
                                            "delete",
                                            ropeFactorIndex
                                          )
                                        }
                                      >
                                        <Close />
                                      </IconButton>
                                    ),
                                  }),
                                }}
                                {...ropeFactorProps}
                              />
                            </Grid>
                          );
                        }
                      )
                    ) : (
                      //rope factor is undefined before user input
                      <Grid item md={6} xl={3}>
                        <PatternFormat
                          onChange={(e) =>
                            handleChange(
                              "editModelValues",
                              modelIndex,
                              fieldname,
                              e.target.value,
                              undefined,
                              0
                            )
                          }
                          {...ropeFactorProps}
                        />
                      </Grid>
                    )}
                    <IconButton
                      onClick={() =>
                        handleChange(
                          "addOrDeleteRopeFactor",
                          modelIndex,
                          fieldname,
                          undefined,
                          undefined,
                          "add"
                        )
                      }
                    >
                      <Add />
                      <Typography>
                        {/* Add new */}
                        {itranslate("Add new")} 
                      </Typography>
                    </IconButton>
                  </Grid>
                ) : fieldname === "balancing_factor" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography>
                      {/* From */}
                      {itranslate("From")}
                    </Typography>
                    <TextField
                      {...textFieldProps}
                      value={
                        modelState[modelIndex][fieldname]
                          ? modelState[modelIndex][fieldname].split("-")[0]
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(
                          "editModelValues",
                          modelIndex,
                          fieldname,
                          e.target.value,
                          undefined,
                          "from"
                        )
                      }
                    />
                    <Typography>
                      {/* To */}
                      {itranslate("To")}
                    </Typography>
                    <TextField
                      {...textFieldProps}
                      value={
                        modelState[modelIndex][fieldname]
                          ? modelState[modelIndex][fieldname].split("-")[1]
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(
                          "editModelValues",
                          modelIndex,
                          fieldname,
                          e.target.value,
                          undefined,
                          "to"
                        )
                      }
                    />
                  </div>
                ) : (
                  <TextField
                    {...textFieldProps}
                    onChange={(e) =>
                      handleChange(
                        "editModelValues",
                        modelIndex,
                        fieldname,
                        e.target.value
                      )
                    }
                    value={modelState[modelIndex][fieldname] ?? ""}
                  />
                )}
              </div>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ModelForm;
