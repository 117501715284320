// import polyglotI18nProvider from "ra-i18n-polyglot";
// import englishMessages from "./english";
// import chineseMessages from "./chinese";

// const i18nProvider = polyglotI18nProvider(
//   (locale) => (locale === "ch" ? chineseMessages : englishMessages),
//   "en",
//   {
//     allowMissing: true,
//   }
// );

// export default i18nProvider;



import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./english";
import chineseMessages from "./chinese";

// Get the selected language from localStorage
const defaultLanguage = localStorage.getItem("selectedLanguage") || "en";

const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === "ch" ? chineseMessages : englishMessages),
  defaultLanguage,
  {
    allowMissing: true,
  }
);

export default i18nProvider;