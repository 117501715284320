import { createSlice } from "@reduxjs/toolkit";

const componentsSlice = createSlice({
  name: "components",
  initialState: {
    value: [],
  },
  reducers: {
    setComponents: (state, action) => {
      state.value = action.payload;
    },
    clearComponents: (state) => {
      state.value = [];
    },
  },
});

export const { setComponents, clearComponents } = componentsSlice.actions;
export default componentsSlice.reducer;
