import {
  Box,
  TextField,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Typography,
} from "@mui/material";
import keyToField from "../../utils/keyToField";
import DescriptionInput from "./DescriptionInput";
import { useTranslation } from 'react-i18next';


function CertificateInput(props) {
  const { upload, setUpload } = props;
  const { t : itranslate } = useTranslation();

  const componentCerts = Object.values(upload?.app_type?.components || {})
    .flat()
    .flatMap((comp) => {
      return comp.type_test_certificate_id
        .filter((cert) => cert.id)
        .map((cert) => ({
          ...cert,
          reference: `${comp.provided_comp_name} / ${
            upload?.manufacturer?.find(
              (m) =>
                m._id === comp.manufacturer_id ||
                m.provided_name === comp.manufacturer_id
            )?.provided_name
          }`, // Include provided_comp_name in the resulting object
          referenceType: keyToField[comp.type],
        }));
    });

  const modelCerts = upload?.model?.flatMap((m) => {
    return m.manufacturer_files.flatMap((f) => {
      return f.type_test_certificate_id
        .filter((cert) => cert.id)
        .map((cert) => {
          return {
            ...cert,
            reference: m.provided_model_name,
            referenceType: "Model",
          };
        });
    });
  });

  const manufacturerCerts =
    upload?.manufacturer
      ?.filter((m) => m.iso_certificate_id?.id)
      .map((m) => {
        return {
          ...m.iso_certificate_id,
          reference: m.provided_name,
          referenceType: "Manufacturer",
        };
      }) || [];

  const certs = componentCerts
    .concat(modelCerts)
    .concat(manufacturerCerts)
    .map((cert) => {
      return {
        id: cert.id,
        reference: cert.reference,
        referenceType: cert.referenceType,
        filename: cert.filename,
        cert_type_metadata: {},
        type: componentCerts.includes(cert)
          ? "ComponentTypeCert"
          : modelCerts?.includes(cert)
          ? "ModelTypeCert"
          : "ISO",
      };
    });

  const handleChange = (e, field, id) => {
    const previous = certs.map((cert) => {
      const cert_with_user_input = upload.certs?.find((c) => c.id === cert.id);
      return cert_with_user_input || cert;
    });

    setUpload((prev) => ({
      ...prev,
      certs: previous.map((cert) => {
        if (cert.id === id) {
          return {
            ...cert,
            cert_type_metadata: {
              ...cert.cert_type_metadata,
              [field]: e.target.value,
            },
          };
        }
        return cert;
      }),
    }));
  };

  const handleText = (e) => {
    setUpload({ ...upload, custom_application_number: e.target.value });
  };

  const handleDate = (e) => {
    setUpload({ ...upload, approval_date: e.target.value });
  };

  return (
    <Box>
      <label for="approval_date"> {itranslate("Approval date:")} </label>
      <input
        id="approval_date"
        onChange={handleDate}
        type="date"
        value={upload?.approval_date || ""}
      />
      <TextField
        value={upload?.custom_application_number}
        onChange={handleText}
        variant="standard"
        label={itranslate("Application number")}
        fullWidth
      />
      <DescriptionInput upload={upload} setUpload={setUpload} />

      <TableContainer>
        <Typography variant="caption">
           {itranslate("* Uploading type test certificates / ISO in previous page will add a row in this table")}
        </Typography>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell> {itranslate("Type")} </TableCell>
              <TableCell> {itranslate("Model / Manufacturer")}  </TableCell>
              <TableCell> {itranslate("Filename")}  </TableCell>
              <TableCell> {itranslate("Certificate Number")}  </TableCell>
              <TableCell> {itranslate("Issue Date")}  </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {certs.map((cert, i) => {
              const cert_with_user_input = upload.certs?.find((c) => c.id === cert.id);

              const cert_number =
                cert.type === "ISO"
                  ? "iso_cert_number"
                  : "tec_certificate_number";

              const issue_date = cert.type === "ISO" ? "iso_issue_date" : "tec_issue_date";

              return (
                <TableRow key={i}>
                  <TableCell>{cert.referenceType}</TableCell>
                  <TableCell sx={{ width: "20%" }}>{cert.reference}</TableCell>
                  <TableCell>{cert.filename}</TableCell>
                  <TableCell sx={{ width: "35%" }}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label={itranslate("Certificate Number")}
                      value={cert_with_user_input?.cert_type_metadata?.[cert_number] || ""}
                      onChange={(e) => handleChange(e, cert_number, cert.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="standard"
                      label={itranslate("Issue Date")}
                      value={cert_with_user_input?.cert_type_metadata?.[issue_date] || ""}
                      onChange={(e) => handleChange(e, issue_date, cert.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CertificateInput;
