import { useState } from "react";
import { Confirm, useNotify, useRefresh } from "react-admin";
import axios from "axios";
import getToken from "../../../utils/getAuthToken";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import palette from "../../../styles/palette";
import { useTranslation } from 'react-i18next';


export const RevocationButton = ({
  id,
  type,
  teams,
  // applicationRole,
  previousStatus,
  userInfo,
  record,
  userRoles
}) => {
  const { t : itranslate } = useTranslation();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const [isApproving, setIsApproving] = useState(false);
  const handleClick = () => setOpen(true);
  const [selectedRole, setSelectedRole] = useState({ _id: "", role_name: "" });
  // console.log("🚀 ~ selectedRole:", selectedRole)

  // const [remark, setRemark] = useState("");
  const handleDialogClose = () => setOpen(false);


  const setRole = (e) => {
    let selectedEngRole;

    if (type === "Pending") {
      selectedEngRole = engRoles.find(
        (engRole) => engRole._id === e.target.value
      );
    } else if (type === "Pending E") {
      selectedEngRole = seRoles.find((seRole) => seRole._id === e.target.value);
    }

    if (selectedEngRole) {
      setSelectedRole({
        _id: selectedEngRole._id,
        role_name: selectedEngRole.role_name,
      });
    }
  };


  const Spinner = () => (
    <Backdrop
      sx={{ color: "#fff", zIndex: 9999, top: "-350px" }}
      open={isApproving}
    >
      <CircularProgress color="inherit" size={40} />
    </Backdrop>
  );


  const handleConfirm = async () => {
    const data = {
      id,
      type,
      previousStatus,
      selectedRole: selectedRole,
    };
    // console.log("🚀 ~ handleConfirm ~ data:", data)

    try {
      if (selectedRole._id === "" || selectedRole.role_name === "") {
        // notify(`Please select a valid role`);
        notify(itranslate(`Please select a valid role`));
        return;
      };
      
      setIsApproving(true);
      
      const response = await axios.put(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke/status/${id}`,
        { data },
        {
          headers: {
            authorization: getToken(),
          },
        }
      );
      // console.log("🚀 ~ handleConfirm ~ response:", response);
      // if (response.data) notify("Success")
      if (response.data) notify(itranslate("Success"))
      setSelectedRole({ _id: "", role_name: "" })
      refresh();
    } catch (error) {
      console.error("Error updating status:", error);
      // notify(`Error approving application, please try again later`);
      notify(itranslate(`Error approving application, please try again later`));
    } finally {
      setIsApproving(false);
      setOpen(false);
    }
  };


  const handleApprove = async () => {
     const data = {
      id,
      type,
      previousStatus,
    };
    // console.log("🚀 ~ handleConfirm ~ data:", data)

    try {
      setIsApproving(true);
      const response = await axios.put(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke/${id}`,
        { data },
        {
          headers: {
            authorization: getToken(),
          },
        }
      );
      // console.log("🚀 ~ handleApprove ~ response:", response)
      // if (response.data) notify("Success")
      if (response.data) notify(itranslate("Success"))
      setSelectedRole({ _id: "", role_name: "" })
      refresh();
    } catch (error) {
      console.error("Error updating status:", error);
      // notify(`Error approving application, please try again later`);
      notify(itranslate(`Error approving application, please try again later`));
    } finally {
      setIsApproving(false);
      setOpen(false);
    }
  };


  let engRoles = [];
  let seRoles = [];
  teams &&
    teams.map((team) => {
      engRoles.push(...team.engineers);
      seRoles.push(...team.seniorEngineers);

      return null;
    });

    
  const handleText = () => {
    switch (type) {
      case "Pending": {
        return {
          label: itranslate("Pass to Engineer"),
          title: itranslate("Confirm Pass to Engineer"),
          content:
          itranslate("Are you sure you want to set the status of this application to Pending E? The Engineer will receive notice"),
        };
      }
      case "Pending E": {
        return {
          label: itranslate("Pass to SE"),
          title: itranslate("Confirm Pass to SE"),
          content:
            itranslate("Are you sure you want to set the status of this application to Pending SE? The SE will receive notice"),
        };
      }
      case "Approved": {
        return {
          label: itranslate("Approve Application"),
          title: itranslate("Confirm Set Approved"),
          content:
            itranslate("Are you sure you want to set the status of this application to Approved?"),
        };
      }
      // case "Rejected": {
      //   return {
      //     label: "Deny",
      //     title: "Confirm Set Rejected",
      //     content:
      //       "Are you sure you want to set the status of this application to Rejected? Rejected applications means no changes will be allowed afterwards",
      //   };
      // }
      default: {
        return {
          label: itranslate("Default Label"),
          title: itranslate("Default Title"),
          content: itranslate("Default Content"),
        };
      }
    }
  };

  // const handleRemark = (e) => {
  //   setRemark(e.target.value);
  // };

  // useEffect(() => {
  //   applicationRole && setSelectedRole(applicationRole._id || "");
  // }, [applicationRole]);

 

  // when status = "Pending E and Pending SE, //Check roleId
  // when status = "Pending" allow GR and Engineer
  // inspector, Admin, CE not allowed at all 3 status
  const isButtonDisabled =
  ((record?.status ==="Pending E" || record?.status === "Pending SE") &&
  !userRoles.includes(record?.current_role_id)) ||
  ((record?.status === "Pending" && !(userInfo?.role === "EMSDGR" || userInfo?.role === "EMSDEngineer"))) ||
  userInfo?.role === "Inspector" ||
  userInfo?.role === "TapasAdmin" || 
  userInfo?.role === "EMSDCE" 


  return (
    <Box>
      {!isButtonDisabled && (
        <>
          <FormControl
            fullWidth
            sx={{
              width: "150px",
              height: "100%",
            }}
          >
            {type !== "Approved" && (
              <>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    width: "150px",
                    fontSize: ".8em",
                  }}
                >
                  {handleText().label}
                </InputLabel>
    
                <Select
                  sx={{ mb: 1, fontSize: ".9rem" }}
                  value={selectedRole._id}
                  onChange={setRole}
                  disabled={isButtonDisabled}
                >
                  {type === "Pending" &&
                    engRoles &&
                    engRoles.map((engRole, index) => (
                      <MenuItem 
                        value={engRole._id} 
                        key={index}
                        sx={{ fontSize: "1rem" }}
                      >
                        {engRole.role_name}
                      </MenuItem>
                    ))}
                  {type === "Pending E" &&
                    seRoles &&
                    seRoles.map((seRole, index) => (
                      <MenuItem 
                        value={seRole._id} 
                        key={index}
                        sx={{ fontSize: "1rem" }}
                      >
                        {seRole.role_name}
                      </MenuItem>
                    ))}
                </Select>
              </>
            )}
    
            
            <Button
              disabled={isButtonDisabled}
              sx={{
                backgroundColor: palette.shadow,
                color: "black",
                height: "100%",
                border: "1px solid black",
                width: "150px",
                fontSize: ".8em",
              }}
              onClick={handleClick}
            >
              {handleText().label}
            </Button>

          </FormControl>


          <Confirm
            isOpen={open}
            loading={isApproving}
            title={handleText().title}
            content={
              <Box>
                <Typography>{handleText().content}</Typography>
                {/* SE approval no need this remarks. Just confirm button is ok for CE approval. Still need when convert back to SE */}
                {/* {!isCE && ( */}
                {/* {(
                  <TextField
                    value={remark}
                    label="remarks"
                    variant="standard"
                    onChange={handleRemark}
                    inputProps={{ maxLength: 500 }}
                    fullWidth
                  />
                )} */}
              </Box>
            }
            onConfirm={type === "Approved" ? handleApprove : handleConfirm}
            onClose={handleDialogClose}
            confirmLabel={itranslate('confirm')}
            cancelLabel={itranslate('cancel')}
          />
        </>
      )}

      <Spinner />
    </Box>
  );
};
