import emsdFontLight from "../../styles/font/FRUTL___.TTF";
import emsdFontBold from "../../styles/font/FRUTUBL_.TTF";
import emsdFontmedium from "../../styles/font/frutigerLB.ttf";
import emsdFontItalic from "../../styles/font/Frutiger-BoldItalic.otf";
import deJaVu from "../../styles/font/DejaVuSans.ttf";
import deJaVuBoldOblique from "../../styles/font/DejaVuSans-BoldOblique.ttf";
import mYuenLight from "../../styles/font/MYuenHK-Light.otf";
import mHeiBold from "../../styles/font/MHeiHK-Bold.otf";
import futura from "../../styles/font/FuturaCondensedLightRegular.otf";
import futuraBold from "../../styles/font/FuturaCondensedBold.otf";
import NotoSansSC_Bold from "../../styles/font/Noto_Sans_SC/static/NotoSansSC-Bold.ttf";
import NotoSansSC_Medium from "../../styles/font/Noto_Sans_SC/static/NotoSansSC-Medium.ttf";
import NotoSansSC_Regular from "../../styles/font/Noto_Sans_SC/static/NotoSansSC-Regular.ttf";
import NotoSansSC_Light from "../../styles/font/Noto_Sans_SC/static/NotoSansSC-Light.ttf";

import MPLUSRounded1c_Regular from "../../styles/font/M_PLUS_Rounded_1c/MPLUSRounded1c-Regular.ttf";

import { Font } from "@react-pdf/renderer";

function fontRegister() {
  Font.register({ family: "Frutiger_light", src: emsdFontLight });
  Font.register({ family: "Frutiger_Ultra_bold", src: emsdFontBold });
  Font.register({ family: "Frutiger_bold", src: emsdFontmedium });
  Font.register({ family: "Frutiger_italic", src: emsdFontItalic });
  Font.register({ family: "Futura", src: futura });
  Font.register({ family: "Futura_bold", src: futuraBold });
  Font.register({ family: "DejaVu Sans", src: deJaVu });
  Font.register({ family: "DejaVu Sans Bold Oblique", src: deJaVuBoldOblique });
  Font.register({ family: "mYuenLight", src: mYuenLight });
  Font.register({ family: "mHeiBold", src: mHeiBold });
  Font.register({
    // works fine this way
    family: "NotoSansSC",
    fonts: [
      { src: NotoSansSC_Regular },
      { src: NotoSansSC_Bold, fontWeight: "bold" },
      { src: NotoSansSC_Bold, fontWeight: "700" },
      { src: NotoSansSC_Bold, fontWeight: "400", fontStyle: "italic" },
      { src: NotoSansSC_Medium, fontWeight: "medium" },
      { src: NotoSansSC_Medium, fontWeight: "400", fontStyle: "italic" },
      { src: NotoSansSC_Light, fontWeight: "light" },
      { src: NotoSansSC_Light, fontWeight: "400", fontStyle: "italic" },
    ],
    format: "truetype",
  });
  // Font.register({ family: "NotoSansSC_Bold", src: NotoSansSC_Bold, fontWeight: "bold" });
  // Font.register({ family: "NotoSansSC_Medium", src: NotoSansSC_Medium });
  // Font.register({ family: "NotoSansSC_Regular", fonts: [{ src: NotoSansSC_Regular }] });
  // Font.register({ family: "NotoSansSC_Light", src: NotoSansSC_Light });
  Font.register({
    family: "MPLUSRounded1c_Regular",
    src: MPLUSRounded1c_Regular,
  });
}

export default fontRegister;
