import {
  Box,
  Typography,
  Popper,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useTranslation } from 'react-i18next';


const CertValuesPopper = (props) => {
  const { anchorEl, certificates, isLoadingCerts } = props;
  const { t : itranslate } = useTranslation();
  const openPopper = Boolean(anchorEl);
  const id = openPopper ? "simple-popper" : undefined;

  return (
    <Popper
      placement={"bottom-end"}
      id={id}
      open={openPopper}
      anchorEl={anchorEl}
      sx={{
        position: "relative",
        height: "100vh",
        overflow: "scroll",
        zIndex: 999,
      }}
      disablePortal
    >
      <Box sx={{ border: 1, p: 1, bgColor: "background.paper" }}>
        <TableContainer component={Paper}>
          <Table fullWidth>
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: "none" }}>
                  {/* Code */}
                  {itranslate("Code")}
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} align="center">
                  {/* Value */}
                  {itranslate("Value")}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!isLoadingCerts &&
                certificates.length > 0 &&
                certificates?.map((certificate, i) => {
                  if (certificate.cert_type_metadata)
                    return (
                      <div
                        className="contents mb-4"
                        key={`${certificate.id}`}
                      >
                        <TableRow>
                          <Typography
                            sx={{ ml: 2, mt: 5, color: "red" }}
                          >{`Type: ${certificate.type} ID: ${certificate.id}`}</Typography>
                        </TableRow>
                        {Object.keys(certificate.cert_type_metadata).map(
                          (field) => {
                            return (
                              <TableRow key={`${certificate.id}-${field}`}>
                                <TableCell component="th" scope="row">
                                  {`$CERT${i}_${field}$`}
                                </TableCell>
                                <TableCell align="center">
                                  {certificate.cert_corrections &&
                                    certificate.cert_corrections[field]
                                    ? certificate.cert_corrections[field]
                                    : certificate.cert_type_metadata?.[field]}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </div>
                    );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Popper>
  );
};

export default CertValuesPopper;
