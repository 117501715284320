import React, { useEffect, useState } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  CreateBase,
  ResourceContextProvider,
  useCreate,
  useNotify,
  Title,
  useGetList,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  StepButton,
  Stepper,
  Step,
  StepLabel,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ManufacturerDisplay } from "../../components/manufacturer/ManufacturerDisplay";
import { ModelDisplay } from "../../components/model/ModelDisplay";
import { DocumentStack } from "../../components/document/DocumentStack";
import { ComponentDisplay } from "../../components/component/ComponentDisplay";
import { CompletionCheck } from "../../components/create/CompletionCheck";
import * as static_fields from "../../utils/static_fields.json";
import variable_fields from "../../utils/variable_fields.json";
import { SelectRC, SelectMachine } from "../../components/create/SelectInputs";
import { useTranslation } from 'react-i18next';


const RecordCreate = (props) => {
  const navigate = useNavigate();
  const notify = useNotify();
  const { t : itranslate } = useTranslation();

  const initialState = {
    file_code: uuidv4(),
    manufacturer: [],
    model: [],
    app_type: {
      type: "",
      components: {},
    },
    other_documents: [],
    dialog: [],
  };

  const [action, setActionType] = useState("le");
  const [system_type, setSystemType] = useState("lift");
  const [upload, setUpload] = useState(initialState);
  const [radio, setRadio] = useState(0);
  const [disableRadio, setDisableRadio] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [create, { isLoading: isCreating }] = useCreate();
  const [options, setOptions] = useState([]);
  const [optional, setOptional] = useState({});

  // PerPage: 0 result in .limit(0) in backend, which returns all data instead of the default range (0-25)
  const { data: manufacturersLoad } = useGetList(
    "manufacturers",
    {
      filter: {
        rc_id: upload.rc_id,
      },
      pagination: { page: null, perPage: null },
    },
    { enabled: Boolean(upload.rc_id) }
  );

  const { data: componentsLoad } = useGetList(
    "components",
    {
      filter: {
        rc_id: upload.rc_id,
      },
      pagination: { page: null, perPage: null },
    },
    { enabled: Boolean(upload.rc_id) }
  );

  const steps_short = [
    "Select operation",
    "Add Manufacturer",
    "Add Safety Components",
    "Upload Additional Documents",
    "Complete Process",
  ];
  
  const steps = [
    "Select operation",
    "Add Manufacturer",
    "Add Models",
    "Add Safety Components",
    "Upload Additional Documents",
    "Complete Process",
  ];

  useEffect(() => {
    setDisableRadio(
      upload?.model?.length > 0 ||
        upload?.manufacturer?.length > 0 ||
        Object.values(upload?.app_type?.components)?.some((x) => x.length > 0)
    );
  }, [radio, JSON.stringify(upload)]);

  useEffect(() => {
    if (radio === "9") setOptions([]);
    //Only allow user add brand new SC if mode is add standalone safety component
    else if (componentsLoad) {
      setOptions(
        componentsLoad?.map((object) => {
          return {
            category: "Old",
            ...object,
          };
        })
      );
    }
  }, [componentsLoad, radio]);

  useEffect(() => {
    handleRadio("0");
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.addEventListener("popstate", unloadCallback);
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  const isStepOptional = (step) => {
    if (radio === "9") {
      if (step === 3) return true;
    } else {
      if (step === 4) return true;
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSend = () => {
    if (!upload?.description) return notify(itranslate("Please fill in the remark"));
    create(
      "records",
      {
        data: upload,
      },
      {
        onSuccess: () => (
          notify(itranslate("Sucessfully created application")), navigate(`/applications`)
        ),
        onError: (e) => notify(e.message),
      }
    );
  };

  const handleRadio = (value) => {
    var obj;
    switch (value) {
      case "0": {
        obj = initialState;
        obj.app_type.type = "ElectricTractionLiftMachineRoom";
        obj.app_type.components = {
          ...variable_fields.app_type.ElectricTractionLiftMachineRoom,
        };
        break;
      }
      case "1": {
        obj = initialState;
        obj.app_type.type = "HydraulicLift";
        obj.app_type.components = { ...variable_fields.app_type.HydraulicLift };
        break;
      }
      case "2": {
        obj = initialState;
        obj.app_type.type = "StairLift";
        obj.app_type.components = { ...variable_fields.app_type.StairLift };
        break;
      }
      case "3": {
        obj = initialState;
        obj.app_type.type = "ServiceLift";
        obj.app_type.components = { ...variable_fields.app_type.ServiceLift };
        break;
      }
      case "4": {
        obj = initialState;
        obj.app_type.type = "Escalator";
        obj.app_type.components = { ...variable_fields.app_type.Escalator };
        break;
      }
      case "5": {
        obj = initialState;
        obj.app_type.type = "PassengerConveyor";
        obj.app_type.components = {
          ...variable_fields.app_type.PassengerConveyor,
        };
        break;
      }
      case "6": {
        obj = initialState;
        obj.app_type.type = "MVPS";
        obj.app_type.components = { ...variable_fields.app_type.MVPS };
        break;
      }
      case "7": {
        obj = initialState;
        obj.app_type.type = "ElectricalVerticalLiftingPlatform";
        obj.app_type.components = {
          ...variable_fields.app_type.ElectricalVerticalLiftingPlatform,
        };
        break;
      }
      case "8": {
        obj = initialState;
        obj.app_type.type = "InclinedLift";
        obj.app_type.components = { ...variable_fields.app_type.InclinedLift };
        break;
      }
      case "9": {
        obj = initialState;
        obj.app_type.type = "SafetyComponent";
        obj.app_type.components = {
          ...variable_fields.app_type.SafetyComponent,
        };
        break;
      }
      case "10": {
        obj = initialState;
        obj.app_type.type = "ElectricTractionLiftRoomless";
        obj.app_type.components = {
          ...variable_fields.app_type.ElectricTractionLiftRoomless,
        };
        break;
      }
      case "11": {
        obj = initialState;
        obj.app_type.type = "Others";
        obj.app_type.components = {
          ...variable_fields.app_type.Others,
        };
        break;
      }
      default: {
        obj = initialState;
        obj.app_type.type = "ElectricTractionLiftMachineRoom";
        obj.app_type.components = {
          ...variable_fields.app_type.ElectricTractionLiftMachineRoom,
        };
        break;
      }
    }
    setUpload((prev) => {
      return {
        ...obj,
        rc_id: prev.rc_id,
      };
    });
    setRadio(value);
  };

  const handleNavManufacturer = () => {
    setActiveStep(1);
  };

  const handleAction = (e) => {
    setActionType(e.target.value);
    if (e.target.value === "le") handleRadio("0");
    else handleRadio("9");
  };

  const selectProps = {
    sx: { mt: 2 },
    fullWidth: true,
    disabled: disableRadio,
  };

  return (
    <ResourceContextProvider {...props} value="applications">
      {/* <Title title="- New Record" /> */}
      <Title title={itranslate("- New Record")}/>

      <CreateBase {...props}>
        <Box sx={{ width: "100%", mt: 1 }}>
          <Stepper nonLinear activeStep={activeStep} sx={{ mt: 2, mb: 3 }}>
            {radio === "9"
              ? steps_short.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">
                        {itranslate('Optional')}
                      </Typography>
                    );
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton
                        {...labelProps}
                        color="inherit"
                        onClick={() => setActiveStep(index)}
                      >
                        <StepLabel>{itranslate(label)}</StepLabel>
                      </StepButton>
                    </Step>
                  );
                })
              : steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">
                        {itranslate('Optional')}
                      </Typography>
                    );
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton
                        {...labelProps}
                        color="inherit"
                        onClick={() => setActiveStep(index)}
                      >
                        <StepLabel>{itranslate(label)}</StepLabel>
                      </StepButton>
                    </Step>
                  );
                })}
          </Stepper>
          <>
            <Typography sx={{ mt: 2, mb: 1 }}> {itranslate("Step ")}  {activeStep + 1}</Typography>

            {/* place to put flex accordion */}
            {activeStep === 0 && (
              <Card sx={{}}>
                <CardHeader title={itranslate("New Record")} />
                <CardContent>
                  <>
                    <InputLabel sx={{ mt: 4 }} id="action">
                      {itranslate('Choose type of action')}
                    </InputLabel>
                    <Select
                      {...selectProps}
                      labelId="action"
                      value={action}
                      onChange={handleAction}
                    >
                      <MenuItem value={"le"}>
                        {itranslate('Add lift/escalator model')}
                      </MenuItem>
                      <MenuItem value={"sc"}>
                        {itranslate('Add standalone safety component')}
                      </MenuItem>
                    </Select>
                  </>

                  <SelectRC upload={upload} setUpload={setUpload} />
                  
                  {action === "le" && (
                    <React.Fragment>
                      <>
                        <InputLabel sx={{ mt: 4 }} id="model_type">
                          {itranslate('Choose type of model')}
                        </InputLabel>
                        <Select
                          {...selectProps}
                          labelId="model_type"
                          value={system_type}
                          onChange={(e) => setSystemType(e.target.value)}
                        >
                          <MenuItem value={"lift"}>
                            {itranslate("Lift")}
                          </MenuItem>
                          <MenuItem value={"escalator"}>
                            {itranslate("Escalator")}
                          </MenuItem>
                        </Select>
                      </>

                      <SelectMachine
                        system_type={system_type}
                        handleRadio={handleRadio}
                        disableRadio={disableRadio}
                        radio={radio}
                      />

                    </React.Fragment>
                  )}
                </CardContent>
              </Card>
            )}

            {/* filter for unique code */}
            {activeStep === 1 && (
              <ManufacturerDisplay
                show={false}
                edit={false}
                add={true}
                title={"Add Manufacturer(s)"}
                existing={manufacturersLoad || []}
                upload={upload}
                setUpload={setUpload}
                template={static_fields}
                variable={variable_fields}
              />
            )}

            {activeStep === 2 && radio !== "9" && (
              <ModelDisplay
                show={false}
                edit={false}
                add={true}
                title={"Add Model(s)"}
                existing={[]}
                upload={upload}
                setUpload={setUpload}
                template={static_fields}
                variable={variable_fields}
                nav={handleNavManufacturer}
              />
            )}

            {((activeStep === 3 && radio !== "9") ||
              (activeStep === 2 && radio === "9")) && (
              <ComponentDisplay
                applicationFlow={{ system_type }}
                show={false}
                edit={false}
                add={true}
                title={"Add Safety Component(s)"}
                options={options || []}
                setOptions={setOptions}
                optional={optional}
                setOptional={setOptional}
                upload={upload}
                setUpload={setUpload}
                template={static_fields}
                variable={variable_fields}
                nav={handleNavManufacturer}
                isRecordManagementStandalone={radio === "9"}
              />
            )}

            {((activeStep === 4 && radio !== "9") ||
              (activeStep === 3 && radio === "9")) && (
              <DocumentStack
                show={false}
                edit={false}
                add={true}
                title={"Upload Additional Documents"}
                upload={upload}
                setUpload={setUpload}
                static={static_fields}
                variable={variable_fields}
              />
            )}

            {((activeStep === 5 && radio !== "9") ||
              (activeStep === 4 && radio === "9")) && (
              <CompletionCheck
                recordManagement={true}
                upload={upload}
                setUpload={setUpload}
                isCreating={isCreating}
                handleSend={handleSend}
                radio={radio}
              />
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                alignItems: "center",
                mb: 2,
              }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="contained"
                color="primary"
              >
                {itranslate("Back")}
              </Button>

              <Box sx={{ flex: "1 1 auto" }} />
              {((radio !== "9" && activeStep !== steps.length - 1) ||
                (radio === "9" && activeStep !== steps_short.length - 1)) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  {itranslate("Next")}
                </Button>
              )}
            </Box>

          </>
        </Box>
      </CreateBase>
    </ResourceContextProvider>
  );
};

export default RecordCreate;
