import useSWR from 'swr'
import axios from 'axios'

import fetchWithToken from '../services/fetchWithToken'
import getFormData from '../services/getFormData'
import getToken from "../utils/getAuthToken";

var token

export default function useDeAppendix(appId = undefined) {
    token = getToken()
    const { data, error, isLoading, mutate } = useSWR(
        [`${process.env.REACT_APP_ENV_ENDPOINT}/api2/derived_appendices/application_id/${appId}`, token], ([url, token]) => fetchWithToken(url, token), {
        shouldRetryOnError: false
    })

    const saveDeAppendix = (appendix, app_id, app_no, app_rc_id, app_type, originalAppendix, qrcode_url, qrcode_encoded) => (new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/derived_appendices`,
            data: { appendix, app_id, app_no, app_rc_id, app_type, originalAppendix, qrcode_url, qrcode_encoded},
            headers:{authorization: token}
        }).then(res => {
            mutate([...data, res?.data?.result])
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    }))

    const deleteDeAppendix = (deAppendixId) => (new Promise((resolve, reject) => {
        axios({
            method: "DELETE",
            url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/derived_appendices/${deAppendixId}`,
            headers:{authorization: token}
        }).then(res => {
            mutate(data.filter((v => v._id !== deAppendixId)))
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    }))

    const updateDeAppendixPDF = (deAppendixId, da_no, pdfFile) => (new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/derived_appendices/pdf/${deAppendixId}`,
            data: {da_no, pdfFile},
            headers:{authorization: token}
        }).then(res => {
            mutate([...data, res?.data?.result])
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    }))
    

    return {
        data,
        error,
        isLoading,
        deleteDeAppendix,
        saveDeAppendix,
        updateDeAppendixPDF
    }
}

// serve to route /createRestrictedUrl  // get da list by application number
export const getDeAppendicesByBMNumber = (BM_number) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/derived_appendices/application_number/${BM_number}`,
        headers:{authorization: token}
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))

export const getOneAppendix = (BM_number) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/derived_appendices/application_number/${BM_number}`,
        headers:{authorization: token}
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))

export const getDaForQrPage = (encryptedDaNo) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/derived_appendices/${encryptedDaNo}`,
        // url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/derived_appendices/test/${encryptedDaNo}`,
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))

// export const getPDF = (fileId) => (new Promise((resolve, reject) => {
//     axios({
//         method: "GET",
//         url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/restrictedUrls/getFile/${fileId}`,
//     }).then(res => {
//         resolve(res.data)
//     }).catch(err => {
//         reject(err)
//     })
// }))