const palette = {
  primary: "#2a598f",
  secondary: "#5c5c5c",
  shadow: "#efeeee",
  border: "#e0e0e0",
  menuHeader: "#64748b",
  selected: "#ecf9ff",
  delete: "#FE6464",
};

export default palette;
