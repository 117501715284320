import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import keyToField from "../../../utils/keyToField";
import renderChinese from "../../../utils/pdf/renderChinese";
import { fixInvalidDates } from "../../../utils/misc/fixInvalidDates";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "5px 0px",
    marginRight: "-15px",
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "none",
    fontFamily: "Frutiger_bold",
    fontSize: "11px",
    marginRight: "-45px",
    marginBottom: "15px",
  },
});

const DerivedPartCCompTable = ({
  appendix,
  application,
  component_type,
  romanize,
  index,
  manufacturers,
  liftModels,
  components,
  certificates,
  simpleDisplay,
}) => {
  if (!certificates || !appendix || !application) return null;

  const checkLiftStandard = [
    "HydraulicLift",
    "ElectricTractionLiftMachineRoom",
    "ElectricTractionLiftRoomless",
  ].includes(application.app_type?.type);

  const checkEscalatorStandard = ["Escalator", "PassengerConveyor"].includes(application.app_type?.type);

  return (
    <View style={styles.tableContainer}>
      <Text
        style={{
          textDecoration: "underline",
          fontFamily: "Frutiger_bold",
          fontSize: "11px",
        }}
      >{`${romanize(liftModels.length + index + 1)}. Safety Components - ${
        keyToField[component_type]
      }`}</Text>
      <View style={styles.tableHead}>
        <Text style={{ width: "33.3333%", paddingLeft: "6px" }}>Model</Text>
        <Text style={{ width: "33.3333%" }}>Type Exam. Certificate</Text>
        <Text style={{ width: "33.3333%" }}>Issue Date</Text>
      </View>


      {manufacturers.map((manu, i) => {
        const componentsOfManu = components[component_type].filter((compModel) => compModel.manufacturer_id === manu._id);

        function getCertList (component) {
          const certs = certificates.filter(
            (tec) =>
              tec?.reference === component.component_code &&
              tec?.type === "ComponentTypeCert" &&
              !appendix.hideCerts?.includes(tec?.id) && 
              !appendix.hideComponentCodes2?.some(
                (item) =>
                    item.component_code === tec?.reference &&
                    item.component_type === component_type
              ) 
              // !appendix.hideIndividualCerts2?.some(
              //   (item) => 
              //       item.cert_id === tec.id &&
              //       item.component_type === component_type
              // )
          );
          return certs
        }

        const filteredComp = componentsOfManu.filter((component)=> {
          const certs = getCertList(component)
          return certs.length > 0
        })
        // if manufacturer have components
        if (filteredComp.length > 0) {

          return (
            <View
              key={i}
              style={{ paddingBottom: "10px", marginRight: "-45px" }}
            >
              <Text
                style={ 
                  {
                    width: "40%",
                    fontFamily: "Frutiger_bold",
                    fontSize: "11px",
                    paddingLeft: "3px",
                    ...renderChinese(manu.provided_name),
                  } }
                  hyphenationCallback={(word) => [word]} // Prevents hyphenation
              >
                {manu?.short_name || manu?.provided_name}
              </Text>
              {filteredComp.map((component) => {
                const certs = getCertList(component)
                
                return certs.map((cert, i) => (
                  <View
                    key={i}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ width: "33.3333%", paddingLeft: "6px" }}>
                      {/* {component.provided_comp_name} {component.component_code} */}
                      {component.provided_comp_name}
                    </Text>
                    <Text style={{ width: "33.3333%" }}>
                      {simpleDisplay(cert.id, "tec_type_exam") || simpleDisplay(cert.id, "tec_certificate_number") } 
                    </Text>
                    <Text style={{ width: "33.3333%" }}>  
                      {simpleDisplay(cert.id, "tec_issue_date") && fixInvalidDates(((simpleDisplay(cert.id, "tec_issue_date")) || "").replace(/–/g, "-")) }
                      {checkLiftStandard && cert.comply_standard?.lift && (
                        <Text style={{ fontFamily: "DejaVu Sans" }}>
                          {"[Ƨ]"}
                        </Text>
                      )}
                      {checkEscalatorStandard &&
                        cert.comply_standard?.escalator &&
                        `[#]`}
                    </Text>
                  </View>
                ));
              })}
            </View>
          );
        }
      })}
    </View>
  );
};

export default DerivedPartCCompTable;
