import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  EditBase,
  useEditController,
  Title,
  useGetOne,
  Loading,
  useNotify,
} from "react-admin";
import {
  TableRow,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { ComponentDisplay } from "../../components/component/ComponentDisplay";
import * as static_fields from "../../utils/static_fields.json";
import variable_fields from "../../utils/variable_fields.json";
import isGetOneData from "../../utils/applications/isGetOneData";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useUpdate } from "react-admin";
import keyToField from "../../utils/keyToField";
import DescriptionInput from "./DescriptionInput";
import { useTranslation } from 'react-i18next';


const ScRecordEdit = (props) => {
  const { t : itranslate } = useTranslation();

  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useEditController({ resource: "applications" });

  const { id } = useParams();
  const notify = useNotify();
  const navigate = useNavigate();
  const [updateAPI, { isLoading: isUpdating }] = useUpdate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const standaloneQuery = query.get("component");

  const tableFields = {
    ACOP: [
      "tec_car_load",
      "tec_balancing_factor",
      "tec_rated_load",
      "tec_rated_speed",
      "tec_tripping_speed",
      "tec_rope_factor",
      "tec_certificate_number",
      "Approval Reference",
    ],
    Buffer: [
      "tec_total_mass",
      "tec_impact_speed",
      "tec_certificate_number",
      "Approval Reference",
    ],
    CarDoorLockingDevice: [
      "tec_door_type",
      "tec_contact_rating",
      "tec_certificate_number",
      "Approval Reference",
    ],
    LandingDoorLockingDevice: [
      "tec_door_type",
      "tec_contact_rating",
      "tec_certificate_number",
      "Approval Reference",
    ],
    OverspeedGovernor: [
      "tec_rated_speed",
      "tec_tripping_speed",
      "tec_certificate_number",
      "Approval Reference",
    ],
    Pallet: [
      "tec_rated_load",
      "tec_rated_speed",
      "tec_certificate_number",
      "Approval Reference",
    ],
    SafetyCircuitElectricalComponent: [
      "tec_circuit_description",
      "tec_certificate_number",
      "Approval Reference",
    ],
    SafetyGear: [
      "tec_total_mass",
      "tec_rated_speed",
      "tec_tripping_speed",
      "tec_certificate_number",
      "Approval Reference",
    ],
    Step: [
      "tec_rated_load",
      "tec_rated_speed",
      "tec_certificate_number",
      "Approval Reference",
    ],
    UCMP: [
      "tec_car_load",
      "tec_balancing_factor",
      "tec_rated_load",
      "tec_rated_speed",
      "tec_tripping_speed",
      "tec_rope_factor",
      "tec_certificate_number",
      "Approval Reference",
    ],
    TractionMachine: [
      "tec_rated_load",
      "tec_rated_speed",
      "tec_certificate_number",
      "Approval Reference",
    ],
    Controller: [
      "tec_rated_load",
      "tec_rated_speed",
      "tec_certificate_number",
      "Approval Reference",
    ],
  };

  const [certificates, setCertificates] = useState(null);

  const { data: certificatesRes } = useGetOne("records", {
    id: standaloneQuery,
  });

  const { data: filenames } = useGetOne("files/filenames", { id: id });
  const [optional, setOptional] = useState({});
  const [upload, setUpload] = useState(null);

  const [update, setUpdate] = useState({
    file_code: uuidv4(),
    manufacturer: [],
    model: [],
    app_type: {
      type: "",
      components: {},
    },
    other_documents: [],
    dialog: [],
  });

  const componentCerts = Object.values(upload?.app_type?.components || {})
    .flat()
    .flatMap((comp) => comp.type_test_certificate_id)
    .filter((c) => c.id) // is user uploaded
    .filter((c) => !certificates.find((cert) => cert.id === c.id)); //is not already in the list

  const combinedCertificates = [...(certificates || []), ...componentCerts];

  const handleChange = (value, certificateId, field) => {
    if (!certificates) return;
    setCertificates(
      combinedCertificates.map((c) => {
        if (c._id === certificateId) {
          return {
            ...c,
            cert_corrections: { ...c.cert_corrections, [field]: value },
          };
        } else {
          return c;
        }
      })
    );
  };

  const handleUpdate = () => {
    if (!upload?.description) return notify(itranslate("Please fill in the remark"));
    updateAPI(
      "records/sc",
      {
        id: id,
        data: upload,
        meta: {
          update: update,
          certificates: certificates,
        },
      },
      {
        onSuccess: () => (
          navigate(`/applications`), notify(itranslate("Record successfully edited"))
        ),
        onError: (e) => notify(e.response.data),
      }
    );
  };

  const simpleDisplay = (cert_id, field) => {
    const matchCert = combinedCertificates.find((cert) => cert._id === cert_id);
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field]
    );
  };


  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.addEventListener("popstate", unloadCallback);
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  useEffect(() => {
    if (record && isGetOneData(record, "applications")) {
      //filter the application to only show the component that is being edited
      const updatedComponents = Object.entries(
        record.app_type.components
      ).reduce((acc, [key, value]) => {
        const filteredValues = value.filter((item) =>
          standaloneQuery ? item._id === standaloneQuery : item
        );
        if (filteredValues.length > 0) {
          acc[key] = filteredValues;
        }
        return acc;
      }, {});

      const updatedData = {
        ...record,
        app_type: {
          ...record.app_type,
          components: updatedComponents,
        },
      };

      setUpload(_.cloneDeep(updatedData));
    }
  }, [record]);

  useEffect(() => {
    if (certificatesRes) {
      setCertificates(certificatesRes.data);
    }
  }, [certificatesRes]);

  if (!upload) return <Loading />;

  return (
    <EditBase {...props}>
      <Title title={itranslate("- Edit Record")} />
      <ComponentDisplay
        show={false}
        edit={{ clarifying: "record_comp" }}
        add={false}
        title={"Edit Safety Component(s)"}
        options={[]}
        upload={upload}
        setUpload={setUpload}
        update={update}
        setUpdate={setUpdate}
        optional={optional}
        setOptional={setOptional}
        template={static_fields}
        variable={variable_fields}
        filenames={filenames}
      />
      {combinedCertificates && certificatesRes && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {itranslate("Certificate")}
                </TableCell>

                {tableFields[certificatesRes.type].map((field) => (
                  <TableCell>{itranslate(keyToField[field])}</TableCell>
                ))}

              </TableRow>
            </TableHead>

            <TableBody>
              {combinedCertificates.map((cert) => {
                return (
                  <TableRow key={cert._id}>
                    <TableCell>
                      {simpleDisplay(cert._id, "tec_certificate_number")}
                    </TableCell>

                    {tableFields[certificatesRes.type].map((field) => (
                      <TableCell key={field}>
                        <TextField
                          value={simpleDisplay(cert._id, field) || ""}
                          variant="standard"
                          onChange={(e) => handleChange(e.target.value, cert._id, field)}
                        />
                      </TableCell>
                    ))}

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <DescriptionInput upload={upload} setUpload={setUpload} />

      <Box>
        <Button
          disabled={isUpdating}
          sx={{ mt: 2 }}
          color="primary"
          variant="outlined"
          onClick={handleUpdate}
        >
          {itranslate("Send")}
        </Button>
      </Box>
    </EditBase>
  );
};

export default ScRecordEdit;
