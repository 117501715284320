import { createSlice } from "@reduxjs/toolkit";

export const saveDaHookSlice = createSlice({
  name: "das",
  initialState: {
    value: null,
  },
  reducers: {
    saveDaHook: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveDaHook } = saveDaHookSlice.actions;

export default saveDaHookSlice.reducer;
