import { Page, View, Document, Text } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import emsdLogo from "../../styles/logo/emsd_coverLetter.jpeg";
import fontRegister from "../../utils/pdf/fontRegister";
import ReactDOMServer from "react-dom/server";
import Html from "react-pdf-html";
import parseAppendixJson from "../../utils/pdf/appendix_format_parser";
import "../../styles/appendix/ApprovalLetter.css";
import formatDate from "../../utils/misc/formatDate";
import getToken from "../../utils/getAuthToken";
import { useGetList } from "react-admin";

function ApprovalLetter(props) {
  const { signatureResponse, application, appendix, format, width, height } =
    props;
  fontRegister();
  const stylesheet = {
    "*": {
      fontFamily: "Frutiger_light",
      fontSize: "11px",
    },
    p: {
      margin: 0,
      fontFamily: "Frutiger_light",
    },
    ".al-column": {
      display: "flex",
      flexDirection: "column",
    },
    ".al-row": {
      display: "flex",
      flexDirection: "row",
    },
    ".al-bold, .al-bold *": {
      fontFamily: "Frutiger_bold",
    },
    ".al-logo": {
      position: "relative",
      width: "210px",
      left: "45%",
      marginTop: "30px",
      marginBottom: "20px",
    },
    ".al-address": {
      marginRight: "19px",
      marginBottom: "3px",
      alignSelf: "flex-end",
    },
    ".al-address *": {
      lineHeight: 1.1,
      fontSize: "10px",
      fontFamily: "mYuenLight",
    },
    ".al-address_eng": {
      marginRight: "2px",
      alignSelf: "flex-end",
      transform: "scale(.5, 1)",
      transformOrigin: "right",
      lineHeight: 1.1,
    },
    ".al-letterHeader": {
      justifyContent: "space-between",
      padding: "0 52px 0px 26px",
    },
    ".al-letterHeaderRight": {
      flexBasis: "43%",
      paddingTop: "10px",
    },
    ".al-reference": {
      alignItems: "flex-start",
      marginBottom: "5px",
    },
    ".al-thinFont": {
      transform: "scale(.5, 1)",
      transformOrigin: "right",
      marginRight: "8px",
    },
    ".al-chinese": {
      fontFamily: "mYuenLight",
      fontSize: "10px",
      paddingTop: "2px",
      lineHeight: 1.3,
    },
    ".al-ml": {
      marginLeft: "-4px",
    },
    ".al-sendAddress": {
      marginTop: "7px",
      marginBottom: "15px",
      lineHeight: 1.6,
    },
    ".al-phoneNumber p": {
      fontSize: "12px",
    },
    ".al-phoneNumber p:first-child": {
      marginTop: "0.5px",
    },
    ".al-phoneNumber p:nth-child(2)": {
      marginTop: "6px",
    },
    ".al-facsimile": {
      marginLeft: "6px",
      marginBottom: "0px",
    },
    ".al-padding": {
      padding: "0 105px",
    },
    ".al-content *": {
      lineHeight: 1.6,
    },
    ".al-title": {
      fontFamily: "Frutiger_Ultra_bold",
      padding: "15px 0px",
      borderBottom: "1px solid black",
    },
    ".al-title *": {
      fontFamily: "Frutiger_Ultra_bold",
    },
    ".al-main": {
      paddingTop: "15px",
    },
    ".al-main p": {
      marginBottom: "15px",
    },
    ".al-sign": {
      marginTop: "150px",
      marginLeft: "105px",
    },
    ".al-signature": {
      width: "215px",
      padding: "0px 0px 10px 0px",
      borderBottom: "1px solid black",
      marginBottom: "5px",
    },
  };

  const address = application?.rc_id?.address;
  const approvalLetter = (
    <div className="al-column">
      <img src={emsdLogo} className="al-logo" alt="emsdLogo" />
      <div className="al-address">
        <p>香港特別行政區政府 機電工程署</p>
        <p>香港九龍啟成街3號</p>
      </div>
      <div className="al-address_eng al-bold">
        <p>Electrical and Mechanical Services Department</p>
        <p>Government of the Hong Kong Special Administrative Region</p>
        <p>3 Kai Shing Street, Kowloon, Hong Kong</p>
        <p>www.emsd.gov.hk</p>
      </div>

      <div className="al-letterHeader al-row">
        <div>
          <div className="al-reference al-row">
            <p className="al-thinFont al-bold">Our reference</p>
            <div>
              <p className="al-chinese">本署檔案 :</p>
              <p>(2) in {application.application_number}</p>
            </div>
          </div>
          <div className="al-reference al-ml al-row">
            <p className="al-thinFont al-bold">Your reference</p>
            <div>
              <p className="al-chinese">來函檔案 :</p>
              <p>{appendix.approvalLetterMisc?.your_reference}</p>
            </div>
          </div>
        </div>

        <div className="al-letterHeaderRight al-row">
          <div>
            <div className="al-reference al-row">
              <p className="al-thinFont al-bold">Telephone</p>
              <p className="al-chinese">電話號碼 :</p>
            </div>
            <div className="al-reference al-facsimile al-row">
              <p className="al-thinFont al-bold">Facsimile</p>
              <p className="al-chinese">圖文傳真 :</p>
            </div>
          </div>

          <div className="al-phoneNumber">
            <p> 28083861</p>
            <p> 25045970</p>
          </div>
        </div>
      </div>

      <div className="al-padding">
        <div className="al-sendAddress">
          <p className="al-sendAddress">{formatDate(application.updatedAt)}</p>
          {address && (
            <div>
              <p>{address.company}</p>
              <p>
                {address.floor && `${address.floor}/F, `}
                {address.flat && `${address.flat}, `}
                {address.block && `Block ${address.block}, `}
                {address.building && `${address?.building}, `}
                {address.estate && `${address?.estate}, `}
              </p>
              <p>
                {address.street_start_no && address.street_end_no
                  ? `${address.street_start_no} - ${address.street_end_no} `
                  : `${address.street_start_no} `}
                {address.street},
              </p>
              <p>{address.district_name}</p>
            </div>
          )}
        </div>

        <p>Dear Sir/Madam,</p>
        <div className="al-content">
          {appendix?.approvalLetterSubject?.map((section) => {
            return section.map((text, index) => {
              return (
                <p key={index} className="al-title">
                  {parseAppendixJson(text, application.model, "plainHtml")}
                </p>
              );
            });
          })}
          <div className="al-main">
            {appendix?.approvalLetterBody?.map((section) => {
              return section.map((text, index) => {
                return (
                  <p key={index}>
                    {parseAppendixJson(text, application.model, "plainHtml")}
                  </p>
                );
              });
            })}
          </div>
        </div>
      </div>
    </div>
  );

  // 2nd page
  const approvalLetterSign = (
    <div className="al-sign al-content">
      <p>Yours faithfully,</p>
      <img
        crossOrigin="anonymous"
        src={`${
          process.env.REACT_APP_ENV_ENDPOINT
        }/lift/options/signature_image?token=${getToken()}`}
        className="al-signature max-w-56"
        alt="signature"
      />
      <p>( {signatureResponse?.[0]?.signature} )</p>
      <p>for Director of Electrical and Mechanical Services</p>
    </div>
  );

  const approvalLetterHtml = ReactDOMServer.renderToStaticMarkup(
    <html>
      <body className="al">{approvalLetter}</body>
    </html>
  );

  const approvalLetterSignHtml = ReactDOMServer.renderToStaticMarkup(
    <html>
      <body className="al">{approvalLetterSign}</body>
    </html>
  );

  return format === "pdf" ? (
    <Document>
      <Page wrap>
        <Html stylesheet={stylesheet}>{approvalLetterHtml}</Html>
        <Text
          style={{
            fontFamily: "Frutiger_light",
            position: "absolute",
            fontSize: 11,
            bottom: 35,
            right: 60,
          }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
        <Text
          style={{
            fontFamily: "mYuenLight",
            position: "absolute",
            fontSize: 9,
            bottom: 10,
            left: 30,
            right: 0,
          }}
          render={({ pageNumber, totalPages }) =>
            pageNumber === totalPages
              ? ""
              : "凡寄來之文件請註明為機電工程署所收，而非個別職員者。"
          }
          fixed
        />

        <Text
          style={{
            transform: "scale(0.5, 1)",
            transformOrigin: "right",
            fontFamily: "Frutiger_bold",
            position: "absolute",
            fontSize: 9,
            bottom: 17,
            right: 50,
          }}
          render={({ pageNumber, totalPages }) =>
            pageNumber === totalPages
              ? ""
              : "Please address your correspondence to the Electrical and MechanicalServices Department and not to individual officiers by name."
          }
          fixed
        />

        {/* 2nd page */}
        <View break>
          <Html stylesheet={stylesheet}>{approvalLetterSignHtml}</Html>
        </View>
      </Page>
    </Document>
  ) : (
    <div className="al appendix">
      {approvalLetter}
      {approvalLetterSign}
    </div>
  );
}

export default ApprovalLetter;
