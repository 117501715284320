import { visuallyHidden } from '@mui/utils';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import * as React from 'react'
// import { useTranslate } from 'react-admin';
import { useTranslation } from 'react-i18next';


function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount, tableHeadCells,
      modelSec,
      numCertsSelectedPerRow, certsCountPerRow, onSelectAllCertsClick
    } =
    props;

    // const translate = useTranslate();
    const { t : itranslate } = useTranslation();
      
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(e) => onSelectAllClick(e, modelSec._id)}
              inputProps={{
                'aria-label': 'select all rows',
              }}
              aria-checked={numSelected > 0 && numSelected === rowCount ? true : numSelected > 0 && numSelected < rowCount ? 'mixed' : false}
            />
          </TableCell>
          
          {
            tableHeadCells.map((headCell) => (
              <React.Fragment key={headCell.id}>
                {
                  headCell.label === "Documents" && (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      role="columnheader"
                      scope='col'
                    >
                      <Checkbox
                        color="primary"
                        indeterminate={numCertsSelectedPerRow > 0 && numCertsSelectedPerRow < certsCountPerRow}
                        checked={numCertsSelectedPerRow > 0 && numCertsSelectedPerRow === certsCountPerRow}
                        onChange={(e) => onSelectAllCertsClick(e, modelSec._id)}
                        inputProps={{
                          'aria-label': 'select all rows',
                        }}
                        aria-checked={numCertsSelectedPerRow > 0 && numCertsSelectedPerRow === certsCountPerRow ? true : numCertsSelectedPerRow > 0 && numCertsSelectedPerRow < certsCountPerRow ? 'mixed' : false}
                      />
                      <TableSortLabel
                        hideSortIcon={true}
                      >
                        {/* {translate(`phase2.ru.enhancedTableHead.${headCell.label}`)} */}
                        {itranslate(`${headCell.label}`)}

                        {/* {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null} */}
                      </TableSortLabel>
                    </TableCell>
                  )
                }

                {
                  headCell.label !== "Documents" && (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      role="columnheader"
                      scope='col'
                    >
                      <TableSortLabel
                        hideSortIcon={true}
                      >
                        {/* {translate("phase2.ru.enhancedTableHead." + headCell.label)} */}
                        {itranslate(`${headCell.label}`)}
                      </TableSortLabel>
                    </TableCell>
                  )
                }
              </React.Fragment>
            ))
          }
      
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
    onSelectAllCertsClick: PropTypes.func.isRequired,
    numCertsSelectedPerRow: PropTypes.number.isRequired,
    certsCountPerRow: PropTypes.number.isRequired,
  };

export default EnhancedTableHead