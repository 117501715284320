import {
    Backdrop,
    CircularProgress,
  } from "@mui/material";

const CircularProgressHoc = ({isLoading = true}) => (
    <Backdrop
      sx={{ color: "#fff", zIndex: 9999, top: "-350px" }}
      open={isLoading}
    >
      <CircularProgress color="inherit" size={40} />
    </Backdrop>
  );

export default CircularProgressHoc