import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import PartBTableRow from "./PartBTableRow";

const styles = StyleSheet.create({
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "no-wrap",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
  },
});

const PartBTable = (props) => {
  const {
    application,
    appendix,
    model,
    manu_code,
    certificates,
    simpleDisplay,
  } = props;

  if (!application) return null;
  const fieldShownForLift = [
    "provided_model_name",
    "type",
    "rated_load",
    "rated_speed",
    "maximum_travel_of_height",
    "rope_factor",
    "balancing_factor",
    "manufacturer_files",
  ].filter((field) => {
    const typeOfMachine = application.app_type?.type;
    switch (typeOfMachine) {
      case "HydraulicLift":
        return field !== "rope_factor" && field !== "balancing_factor";
      case "ElectricalVerticalLiftingPlatform":
        return field !== "balancing_factor";
      default:
        return field;
    }
  });
  const fieldShownForEscalator = [
    "provided_model_name",
    "type",
    "angle_of_inclination",
    "rated_speed",
    "maximum_rise",
    "manufacturer_files",
  ];
  //manufacturer files is for displaying type test cert
  let modelFields =
    model.LE_type === "L" ? fieldShownForLift : fieldShownForEscalator;

  if (appendix.table?.length > 0) {
    const addedField = appendix.table.map((row) => row.field);
    modelFields.splice(-1, 0, ...addedField);
  }

  modelFields = modelFields.filter((field) => !appendix.hideTableProps.includes(field));

  return (
    <View style={styles.tableContainer}>
      {modelFields.map((field) => {
        const addedRow = appendix.table?.find((row) => row.field === field);
        if (
          (model[field] && !Array.isArray(model[field])) ||
          (Array.isArray(model[field]) && model[field].length > 0) ||
          addedRow
        )
          return (
            <PartBTableRow
              appendix={appendix}
              application={application}
              addedRow={addedRow}
              model={model}
              field={field}
              key={`${model._id}-${field}`}
              certificates={certificates}
              simpleDisplay={simpleDisplay}
              manu_code={manu_code}
            />
          );
      })}
    </View>
  );
};

export default PartBTable;
