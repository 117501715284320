import axios from 'axios'

import getToken from "../../utils/getAuthToken";

var token

export const getComponentList = async (filter, range, sort) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/components?filter=${encodeURIComponent(filter)}&range=${range}&sort=${sort}`,
        headers: { authorization: token }
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))

// remember to write an api for this --> assign to kt
export const getOneComponent = async (id) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/components/${id}`,
        headers: { authorization: token }
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))

export const getComponentListForRu = (filter, range, sort, encrypted_ruNo) => (new Promise((resolve, reject) => {
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/components/ru?filter=${encodeURIComponent(filter)}&range=${range}&sort=${sort}&encrypted_ruNo=${encrypted_ruNo}`,
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))

export const getOneComponentForRu = async (component_id, encrypted_ruNo) => (new Promise((resolve, reject) => {
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/components/ru/${component_id}/show?encrypted_ruNo=${encrypted_ruNo}`,
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))

export const getComponentListForRuSummary = (filter, range, sort, ruPreCreateData) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/components/ru/summary?filter=${encodeURIComponent(filter)}&range=${range}&sort=${sort}`,
        headers: { authorization: token },
        data: {ruPreCreateData}
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))


export const getOneModelScsForRuSummary = (filter,range, sort) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/components/ru/summary/getOneModelScsForRuSummary`,
        headers: { authorization: token },
        data: {
            filter, range,sort
        }
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))