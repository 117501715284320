import axios from 'axios'

import getToken from "../../utils/getAuthToken";

var token


export const decodeJwt = (token) => (new Promise((resolve, reject) => {
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/jwt/decodeJwt`,
        headers: {authorization: getToken()},
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))