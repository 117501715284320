import {
  Button,
  Box,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  Table,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDataProvider, useNotify, Confirm, useUpdate } from "react-admin";
import { useTranslation } from 'react-i18next';


function AliasCheck(props) {
  const { t : itranslate } = useTranslation();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { upload, userInfo, setUpload, userRoles } = props;
  const [update, { isLoading }] = useUpdate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedSimilarFound, setSelectedSimilarFound] = useState(null);
  // format: { manufacturerId: [...similarFound], ...}
  const [similarManufacturers, setSimilarManufacturers] = useState(
    upload.manufacturer.reduce((acc, manu) => {
      acc[manu._id] = [];
      return acc;
    }, {})
  );

  const handleMerge = (manufacturerId, similarFound) => {
    setSelectedManufacturer(manufacturerId);
    setSelectedSimilarFound(similarFound);
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    update(
      "manufacturers",
      {
        id: selectedManufacturer,
        data: {
          manufacturer_code: selectedSimilarFound.manufacturer_code,
          provided_name: selectedSimilarFound.provided_name,
        },
        meta: "merge",
      },
      {
        onSuccess: () => {
          notify(itranslate("Manufacturer merged"));
          const mapping = { ...similarManufacturers };
          mapping[selectedManufacturer] = mapping[selectedManufacturer].filter(
            (similarFound) => similarFound.id !== selectedSimilarFound.id
          );
          setSimilarManufacturers(mapping);
          setUpload({
            ...upload,
            manufacturer: upload.manufacturer.map((manu) => {
              if (manu._id === selectedManufacturer) {
                return {
                  ...manu,
                  manufacturer_code: selectedSimilarFound.manufacturer_code,
                  provided_name: selectedSimilarFound.provided_name,
                };
              }
              return manu;
            }),
          });
        },
        onError: () => notify(itranslate("Error merging manufacturer")),
      }
    );
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  useEffect(() => {
    // Note that upload.manufacturer use _id, the data returned from getList use id, yes, very confusing
    upload.manufacturer
      .filter((manu) => {
        const isChanged = upload.update_upon
          ? Object.keys(upload?.changes?.manufacturer || {}).includes(manu._id)
          : true;
        return isChanged && manu.newManufacturer;
      })
      .forEach((manu) => {
        dataProvider
          .getList("manufacturers/list/similar", {
            filter: { textSearch: manu.provided_name },
            pagination: { page: 1, perPage: 0 },
          })
          .then(({ data }) => {
            let mapping = { ...similarManufacturers };
            data.forEach((similarManufacturer) => {
              const ids = mapping[manu._id].map(
                (similarFound) => similarFound.id
              );
              if (
                !ids.includes(similarManufacturer.id) &&
                similarManufacturer.manufacturer_code !== manu.manufacturer_code
              )
                mapping[manu._id].push(similarManufacturer);
            });
            setSimilarManufacturers(mapping);
          })
          .catch((err) => console.log(err));
      });
  }, []);

  // disable the button if the user is not EMSDGR (GR), but the application's current role name is not GR.
  const isButtonDisabled =
    (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
      (upload?.status.includes("Pending E") ||
        upload?.status.includes("Pending SE") ||
        upload?.status.includes("Pending CE")) &&
      upload?.current_role_name !== "GR") ||
    userInfo?.role === "Inspector" || //Inspector
    (userInfo?.role !== "EMSDGR" && //Check roleId except GR
      !userRoles.includes(upload?.current_role_id));

  if (Object.values(similarManufacturers).flat().length > 0)
    return (
      <Box sx={{ width: "60vw", marginBottom: "35px" }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: "Frutiger_bold", color: "red" }}
        >
          {/* Similar Manufacturers Found */}
          {itranslate("Similar Manufacturers Found")}
        </Typography>
        <TableContainer sx={{ my: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", width: "25%" }}>
                  {/* Manufacturer */}
                  {itranslate("Manufacturer")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "25%" }}>
                  {/* Similar Manufacturer found */}
                  {itranslate("Similar Manufacturer found")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "25%" }}>
                  {/* Address */}
                  {itranslate("Address")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                  {/* Manufacturer Code change when merged */}
                  {itranslate("Manufacturer Code change when merged")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "5%" }}>
                  {/* Action */}
                  {itranslate("Action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(similarManufacturers)?.map((manufacturerId) =>
                similarManufacturers[manufacturerId].map((similarFound) => {
                  const original = upload.manufacturer.find(
                    (manu) => manu._id === manufacturerId
                  );
                  return (
                    <TableRow key={similarFound.id}>
                      <TableCell>{original?.provided_name}</TableCell>
                      <TableCell>{similarFound.provided_name}</TableCell>
                      <TableCell>
                        {similarFound?.certsPopulated?.[0]?.cert_corrections
                          ?.iso_manufacturer_address ||
                          similarFound?.certsPopulated?.[0]?.cert_type_metadata
                            ?.iso_manufacturer_address}
                      </TableCell>
                      <TableCell>
                        {itranslate("From")} {original.manufacturer_code} {itranslate("to")}{" "}
                        {similarFound.manufacturer_code}
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={isButtonDisabled}
                          sx={{ p: 0 }}
                          color="primary"
                          onClick={() =>
                            handleMerge(manufacturerId, similarFound)
                          }
                        >
                          {/* Merge */}
                          {itranslate("Merge")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
              <Confirm
                title={itranslate("Merging manufacturer")}
                content={itranslate("The manufacturer code will be changed, proceed?")}
                isOpen={showConfirm}
                onConfirm={handleConfirm}
                onClose={handleClose}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
}

export default AliasCheck;
