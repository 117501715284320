import MyAdmin from "./admin";
// import { CacheProvider } from '@emotion/react'
// import createCache from '@emotion/cache'

import { Buffer } from "buffer";

import { v4 as uuid } from "uuid";
const nonce = Buffer.from(uuid(), 'base64');



const App = () => {
    // const cache = createCache({
    //     key: 'css',
    //     prepend: true,
    //     nonce: uuid(),
    // });

    return (
        // <CacheProvider cache={cache}>
        <MyAdmin />
        // </CacheProvider>
    )
};

export default App;