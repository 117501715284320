import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import emsdLogo from "../../../admin/styles/logo/emsd_appendix.jpg";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";


export default function Header() {
  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || i18n.language);

  const { t: itranslate } = useTranslation();

  // check language
  // window.addEventListener('storage', (e) => {
  //   if (e.key === 'selectedLanguage') {
  //     i18n.changeLanguage(e.newValue);
  //     setLanguage(e.newValue);
  //     window.location.reload();
  //   }
  // });

  
  // useEffect(() => {
  //   const handleStorageChange = (e) => {
  //     if (e.key === "selectedLanguage") {
  //       const newLanguage = e.newValue;
  //       i18n.changeLanguage(newLanguage);
  //       setLanguage(newLanguage);
  //     }
  //   };

  //   window.addEventListener("storage", handleStorageChange);

  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);


  
  // Language change event
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
      // console.log("🚀 ~ Header ~ language:", lng);
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);



  const headingStyle = language === "en" ? (
      {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: "1rem",
        borderBottom: "5px solid #7bbbbb",
        paddingBottom: "3px",
      }
  ) : (
    {
      display: "flex",
      alignItems: "center",
      justifyContent: language === "ch" ? "center" : "center",
      gap: language === "ch" ? "30px" : "0px",
      marginTop: "1rem",
      paddingBottom: "3px",
      borderBottom: "5px solid #7bbbbb",
    }
  );

  return (
    <div
      style={headingStyle}
    >
      <img src={emsdLogo} style={{ width: "200px" }} alt="EMSD logo" />
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Frutiger_bold"
        }}
      >
        {itranslate("Type Approval Processing and Authentication System")}
      </Typography>
    </div>
  );
}
