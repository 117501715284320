import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ResourceContextProvider,
  ReferenceField,
  DateField,
  EditButton,
  FunctionField
} from "react-admin";
import { useTranslation } from 'react-i18next';


const RecordLogs = () => {
  const { t : itranslate } = useTranslation();

  return (
    <ResourceContextProvider value="records/list/logs">
    <List 
      title={`- ${itranslate('Record')}`}
      empty={false} exporter={false} 
      disableSyncWithLocation>
      <Datagrid bulkActionButtons={false}>

        {/* <TextField 
          label={itranslate('Action')}
          source="action" 
        /> */}

        <FunctionField 
          label={itranslate('Action')}
          source="action"
          render={(record) => {
            return (
              <div style={{ 
                minWidth: "150px", 
                fontFamily: "Frutiger_Light",
                fontWeight: '400',
                fontSize: "1.09375rem",
              }}>
                {record && itranslate(record.action)}
              </div>
            )
          }}
        />


        <ReferenceField source="user_id" reference="users"
           label={itranslate('User')}
        >
          <TextField source="username" />
        </ReferenceField>

        <ReferenceField
          label={itranslate('Application')}
          source="application_id"
          reference="apps"
          link={(record, reference) =>
            `/v2/applications/${record.application_id}/show`
          }
        >
          <TextField source="application_number" />
        </ReferenceField>

        <ReferenceField
          source="application_id_before_change"
          reference="records"
          label={itranslate('Record before changes')}
          link={(record, reference) =>
            `/v2/applications/${record.application_id_before_change}/show`
          }
        >
          <TextField source="application_number" />
        </ReferenceField>

        <TextField source="description" 
          label={itranslate('Description')}
        />

        <DateField source="created_at" 
        label={itranslate('Created At')}
        />

        <EditButton 
          label={itranslate('Edit')}
          sx={{ padding: '0' }}
        />

      </Datagrid>
    </List>
  </ResourceContextProvider>
  )
}



export default RecordLogs;
