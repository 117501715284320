import {
  Divider,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  ListItem,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setComponents } from "../../redux/componentsSlice";
import _ from "lodash";
import { useNotify } from "ra-core";
import keyToField from "../../utils/keyToField";
import { useTranslation } from 'react-i18next';


export const UpdateModeSelector = (props) => {
  const { t : itranslate } = useTranslation();
  const { applicationFlow, models, components, userInfo, upload } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notify = useNotify();

  const approvedStandAloneCompList = components?.filter((comp) => comp && !comp.model_id);

  const [chosenModel, setChosenModel] = useState(null); // For manufacturer name change, storing the applicationId of chosen model
  const [chosenComponents, setChosenComponents] = useState([]); // For safety component, storing the applicationId of chosen component

  const getMode = (applicationFlow) => {
    // a: Manufacturer Name Change
    // b: Model Cert Update (Range Extension)
    // c: LE model range extension
    // d: Update SC Certificate
    // e: SC range extension
    // f: New SC from New Manufacturer
    const type = applicationFlow.application_type;
    const sc = applicationFlow.sc_type;
    const system = applicationFlow.system_type;

    let mode = "";
    if (applicationFlow.action === "update") {
      if (type === "add sc" && sc === "leModelSc") {
        mode = "f";
      } else if (type === "manu name change") {
        mode = "a";
      } else if (type === "update cert") {
        mode = system === "lift" ? "b" : system === "sc" && "d";
      } else if (type === "range ext") {
        mode = system === "lift" ? "c" : system === "sc" && "e";
      }
    }

    return mode;
  };

  const handleTarget = (value) => {
    const mode = getMode(applicationFlow);
    if (
      (mode === "d" || mode === "e") &&
      applicationFlow.sc_type === "standAloneSc"
    ) {
      setChosenComponents(value);
    }
    //If manufacturer name change, let user choose manufacturer, otherwise, go straight to update page
    else if (mode !== "a") navigate(`/applications_${mode}/${value.id}`);
    else setChosenModel(value);
  };

  const handleManufacturer = (id) => {
    navigate(`/applications_a/${chosenModel.id}?manufacturer=${id}`);
  };

  const handleComponents = (components) => {
    const mode = getMode(applicationFlow);
    const uniqueRc = _.uniqBy(components, "rc_id");
    if (uniqueRc.length === 0) return notify(itranslate("Please select components"));
    if (uniqueRc.length > 1)
      return notify(itranslate("You cannot choose more than one RC"));

    const ids = components.map((comp) => comp.id);

    const populated = approvedStandAloneCompList.filter((comp) => ids.includes(comp.id));

    dispatch(setComponents(populated));
    navigate(`/applications_${mode}/${components[0].app_id}`);
  };

  const autocompleteProps = {
    disablePortal: true,
    renderInput: (params) => <TextField {...params} fullWidth />,
    onChange: (e, newValue) => {
      handleTarget(newValue);
    },
  };

  if (!models || !approvedStandAloneCompList) return null;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="loader">
        {(applicationFlow.system_type === "lift" ||
          applicationFlow.system_type === "escalator" ||
          (applicationFlow.system_type === "sc" &&
            applicationFlow.sc_type === "leModelSc")) &&
          itranslate("Choose a lift/escalator model")}
        {applicationFlow.system_type === "sc" &&
          applicationFlow.sc_type === "standAloneSc" &&
          itranslate("Choose a safety component")}
      </InputLabel>

      {models &&
        (applicationFlow.system_type === "lift" ||
          applicationFlow.system_type === "escalator" ||
          (applicationFlow.system_type === "sc" &&
            applicationFlow.sc_type === "leModelSc")) && (
          //model select
          <Autocomplete
            {...autocompleteProps}
            id="model_select"
            options={models
              .filter((record) =>
                userInfo?.role === "EMSDGR" &&
                applicationFlow.application_type === "add sc"
                  ? record.rc_id === upload?.rc_id
                  : record
              )
              .map((record) => {
                return {
                  id: record.application_id,
                  label: `${record?.manufacturersPopulated
                    ?.map((m) => m.provided_name)
                    ?.join(", ")} - ${record.provided_model_name}${
                    userInfo.role === "EMSDGR"
                      ? " - " +
                        (record.LE_type === "E"
                          ? record.rcsPopulated?.[0]?.rc_number_escalator
                          : record.rcsPopulated?.[0]?.rc_number_lift)
                      : ""
                  }`,
                  manufacturers: record.manufacturersPopulated,
                };
              })}
            renderOption={(props, option) => {
              return (
                <ListItem {...props} key={option.id}>
                  {option.label}
                </ListItem>
              );
            }}
          />
        )}


      {approvedStandAloneCompList &&
        applicationFlow.system_type === "sc" &&
        applicationFlow.sc_type === "standAloneSc" && (
          //sc select
          <>
            <Autocomplete
              {...autocompleteProps}
              freeSolo
              multiple
              filterSelectedOptions
              value={chosenComponents}
              id="comp_select"
              options={approvedStandAloneCompList.map((record) => {
                return {
                  id: record.id,
                  app_id: record.application_id,
                  name: `${
                    record?.manufacturersPopulated?.[0]?.provided_name
                  } - ${record.provided_comp_name} (${
                    keyToField[record.type]
                  })${
                    userInfo.role === "EMSDGR"
                      ? " - " + record.rcsPopulated?.[0]?.rc_number_lift
                      : ""
                  }`,
                  rc_id: record.rc_id,
                };
              })}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => {
                return (
                  <ListItem {...props} key={option.id}>
                    {option.name}
                  </ListItem>
                );
              }}
              getOptionLabel={(option) => option.name || ""}
            />
            <Button onClick={() => handleComponents(chosenComponents)}>
              {/* Finish selection */}
              {itranslate("Finish selection")}
            </Button>
          </>
        )}


      {chosenModel && (
        <>
          <InputLabel sx={{ mt: 4 }} id="manufacturerSelectLabel">
            {/* Choose a manufacturer */}
            {itranslate("Choose a manufacturer")}
          </InputLabel>{" "}
          <Select
            sx={{ mt: 2 }}
            fullWidth
            labelId="manufacturerSelectLabel"
            id="manufacturerSelect"
            defaultValue=""
            label="operation"
            onChange={(e) => handleManufacturer(e.target.value)}
          >
            {chosenModel?.manufacturers?.map((manu) => (
              <MenuItem key={manu._id} value={manu._id}>
                {manu.provided_name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}

    </React.Fragment>
  );
};
