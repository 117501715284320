import { franc } from "franc-min";

//react pdf does not support fall back fontfamily, so you will have to add this to the styles props in View/Text
function renderChinese(text) {
  const dynamicStyles = {
    ...(franc(text, {
      minLength: 0,
    }) === "cmn" && { fontFamily: "mYuenLight" }),
  };
  return dynamicStyles;
}

export default renderChinese;
