import { visuallyHidden } from '@mui/utils';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
// import { useTranslate } from 'react-admin';
import { useTranslation } from 'react-i18next';

const headCells = [
    {
      id: 'client_user',
      numeric: false,
      disablePadding: false,
      label: 'Client User',
    },
    {
      id: 'remark',
      numeric: false,
      disablePadding: false,
      label: 'Remark',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
    },
    {
      id: 'expiry_date',
      numeric: false,
      disablePadding: false,
      label: 'Expiry Date',
    },
  ];

function EnhancedTableHead(props) {
  const { t : itranslate } = useTranslation();
  
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    // const translate = useTranslate();
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{'aria-label': 'select all rows'}}
              aria-checked={numSelected > 0 && numSelected === rowCount ? true : numSelected > 0 && numSelected < rowCount ? 'mixed' : false}
            />
          </TableCell>

          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              role="columnheader"
              scope='col'
              aria-sort={orderBy === headCell.id ? order === "desc" ? "descending" : "ascending" : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {/* {translate(`phase2.ru.enhancedTableHead.${headCell.label}`)} */}
                {itranslate(`${headCell.label}`)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

export default EnhancedTableHead