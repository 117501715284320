// import { useTranslate } from "react-admin"
import { useTranslation } from 'react-i18next';

const TableTitle = ({data, ...props}) => {
	// const translate = useTranslate()
	const { t : itranslate } = useTranslation();
	return (
		<div
			className={props.className}
		>
			{/* <div>{translate("phase2.ru.enhancedTableHead.Model Code")} : {data?.model_code}</div> */}
			<div>{itranslate('Model Code')} : {data?.model_code}</div>

			{/* <div>{translate("phase2.ru.enhancedTableHead.Model Name")} : {data?.provided_model_name}</div> */}
			<div>{itranslate('Model Name')} : {data?.provided_model_name}</div>
		</div>
	)
}


export default TableTitle