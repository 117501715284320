import React, {useEffect, useState} from "react";
import fontRegister from "../../../utils/pdf/fontRegister.js";

import parseContentJson from "./pdf/revocationLetter_format_parser.js";

import emsdLogo from "../../../styles/logo/emsd_coverLetter.jpeg";
import formatDate from "../../../utils/misc/formatDate.js";
import getToken from "../../../utils/getAuthToken";
import renderChinese from "../../../utils/pdf/renderChinese";

import { fixInvalidDates } from "../../../utils/misc/fixInvalidDates";
import useRevokeHook from "../../../useHooks/useRevokeHook";  


export const RevocationLetter = ({ application, appendix, rcName, rcAddress, rcItem, format, inputs, setInputs }) => {  
// console.log("format", format)
const [signatureResponse, setSignatureResponse] = useState("");
const { getSignature } = useRevokeHook();
const [isFocused, setIsFocused] = useState(false);


const handleInputFocus = () => {
  setIsFocused(true);
};

const handleInputBlur = () => {
  setIsFocused(false);
};


useEffect(() => {
  const fetchSignature = async () => {
    try {
      const response = await getSignature();
      setSignatureResponse(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchSignature();
},[])


  fontRegister();

  if (!application) return null;

  const manufacturerName = "{manufacturer_name}";
  const manufacturerAddress = "{manufacturer_address}";

 
  const approvalLetter = (
    <div className="rl-column">
      <img src={emsdLogo} className="rl-logo" alt="emsdLogo" />

      <div className="rl-address">
        <p>香港特別行政區政府 機電工程署</p>
        <p>香港九龍啟成街3號</p>
      </div>
      <div className="rl-address_eng rl-bold">
        <p>Electrical and Mechanical Services Department</p>
        <p>Government of the Hong Kong Special Administrative Region</p>
        <p>3 Kai Shing Street, Kowloon, Hong Kong</p>
        <p>www.emsd.gov.hk</p>
      </div>

      <div className="rl-letterHeader rl-row">
        <div></div>

        <div className="rl-letterHeaderRight rl-row">
          <div>
            <div className="rl-reference rl-row">
              <p className="rl-thinFont rl-bold">Telephone</p>
              <p className="rl-chinese">電話號碼 :</p>
            </div>
            <div className="rl-reference rl-facsimile rl-row">
              <p className="rl-thinFont rl-bold">Facsimile</p>
              <p className="rl-chinese">圖文傳真 :</p>
            </div>
          </div>

          <div className="rl-phoneNumber">
            <p> 28083861</p>
            <p className="rl-fax"> 25045970</p>
          </div>
        </div>
      </div>

      <div className="rl-approval">
        <div className="rl-reference_no rl-padding">
          <p>Our reference </p>
          <p className="rl-chinese">本署檔案 : </p>
          <input
            value={inputs.our_reference}
            onChange={(e) => {
              setInputs({
                ...inputs,
                our_reference: e.target.value,
              });
            }}
          />
          <p>{`${format === "pdf" ? inputs.our_reference : ""}`}</p>
        </div>

        <div className="rl-reference_no rl-yourRef_padding">
          <p>Your reference </p>
          <p className="rl-chinese">來函檔號 : </p>
          <input
            value={inputs.your_reference}
            onChange={(e) => {
              setInputs({
                ...inputs,
                your_reference: e.target.value,
              });
            }}
          />
          <p>{`${format === "pdf" ? inputs.your_reference : ""}`}</p>
        </div>

        <div className="rl-date_padding">
          <input
            value={
              isFocused
                ? inputs.reference_date
                : inputs.reference_date
                ? fixInvalidDates(inputs.reference_date)
                : ""
            }
            onChange={(e) => {
              setInputs({
                ...inputs,
                reference_date: e.target.value,
              });
            }}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder="DD-MM-YYYY"
          />
          <p>{`${
            format === "pdf" ? fixInvalidDates(inputs.reference_date) : ""
          }`}</p>
        </div>

        <div className="rl-padding">
          <div className="rl-sendAddress">
            {rcAddress && (
              <div>
                <p>{rcAddress.company}</p>
                <p>
                  {rcAddress.floor && `${rcAddress.floor}/F, `}
                  {rcAddress.flat && `${rcAddress.flat}, `}
                  {rcAddress.block && `Block ${rcAddress.block}, `}
                  {rcAddress.building && `${rcAddress?.building}, `}
                  {rcAddress.estate && `${rcAddress?.estate}, `}
                </p>
                <p>
                  {rcAddress.street_start_no && rcAddress.street_end_no
                    ? `${rcAddress.street_start_no} - ${rcAddress.street_end_no} `
                    : `${rcAddress.street_start_no} `}
                  {rcAddress.street},
                </p>
                <p>{rcAddress.district_name}</p>
              </div>
            )}
          </div>

          <div className="rl-rcname">
            <p className="rl-dear">
              {`${
                format === "pdf"
                  ? inputs.rc_name !== ""
                    ? `Dear ${inputs.rc_name}`
                    : rcName && rcName !== "undefined"
                    ? `Dear ${rcName},`
                    : "Dear Sir/Madam,"
                  : "Dear"
              }`}
            </p>
            <input
              value={
                isFocused
                  ? inputs.rc_name
                  : inputs.rc_name !== ""
                  ? inputs.rc_name
                  : rcName && rcName !== "undefined"
                  ? rcName
                  : "Sir/Madam"
              }
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onChange={(e) => {
                setInputs({
                  ...inputs,
                  rc_name: e.target.value,
                });
              }}
            />
          </div>

          {appendix?.revocation_letter?.heading.map((section, i) => {
            // console.log(section)
            return (
              <React.Fragment key={i}>
                {section && section.includes(manufacturerName) ? (
                  <p className="rl-title" key={i}>
                    {parseContentJson(section, rcItem, "plainHtml")}
                    {/* <span style={renderChinese(rcItem.manufacturer[0]?.provided_manufacturer_name)}> {parseContentHeadingChineseJson(section, rcItem, "plainHtml")} </span> */}
                  </p>
                ) : (
                  <p className="rl-title">
                    {parseContentJson(section, rcItem, "plainHtml")}
                  </p>
                )}
              </React.Fragment>
            );
          })}

          <div className="rl-content rl-main">
            {appendix?.revocation_letter?.body.map((section, i) => {
              return (
                <React.Fragment key={i}>
                  {section &&
                  section.includes(manufacturerName || manufacturerAddress) ? (
                    <p
                      style={{
                        ...(section.length > 0 &&
                          i !== section.length - 1 && { paddingBottom: "0px" }),
                      }}
                    >
                      {parseContentJson(section, rcItem, "plainHtml")}
                    </p>
                  ) : (
                    <p
                      style={{
                        ...(section.length > 0 &&
                          i !== section.length - 1 && { paddingBottom: "0px" }),
                      }}
                    >
                      {parseContentJson(section, rcItem, "plainHtml")}
                    </p>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );


  const approvalLetterSign = (
    <div className="rl-sign rl-content">
      <p>Yours faithfully,</p>
      <img
        crossOrigin="anonymous"
        src={`${
          process.env.REACT_APP_ENV_ENDPOINT
        }/lift/options/signature_image?token=${getToken()}`}
        className="rl-signature"
        alt="signature"
      />
      <p>( {signatureResponse && signatureResponse?.[0]?.signature} )</p>
      <p>for Director of Electrical and Mechanical Services</p>
    </div>
  );


  return (
    <div className="revoke revocationLetter">
      {approvalLetter}
      {approvalLetterSign}
    </div>
  )
};
