import axios from 'axios'

import getToken from "../../utils/getAuthToken";

var token

export const getModels_Phase1Api = () => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/lift/models?filter={"q":""}&range=[]&sort=[]`,
        headers: {authorization: token},
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))

// ru create stage: 2_1, option: models
export const getModelList = async (filter, range, sort, selectedModels) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/models?filter=${encodeURIComponent(filter)}&range=${range}&sort=${sort}`,
        data: {selectedModels},
        headers: { authorization: token }
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))

// ru create stage: 2_3, options: models, getOne
export const getOneModel = async (id) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/models/${id}`,
        headers: { authorization: token }
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))


export const getModelListForRu = async (filter, range, sort, encrypted_ruNo) => (new Promise((resolve, reject) => {
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/models/ru?filter=${encodeURIComponent(filter)}&range=${range}&sort=${sort}&encrypted_ruNo=${encrypted_ruNo}`,
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))

export const getOneModelForRu = async (model_id, encrypted_ruNo) => (new Promise((resolve, reject) => {
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/models/ru/${model_id}/show?encrypted_ruNo=${encrypted_ruNo}`,
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))

export const getModelListForRuSummary = async (filter, range, sort, ruPreCreateData) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/models/ru/summary?filter=${encodeURIComponent(filter)}&range=${range}&sort=${sort}`,
        headers: { authorization: token },
        data: {ruPreCreateData}
    }).then((res) => {
        resolve(res)
    }).catch((err) => {
        reject(err)
    })
}))