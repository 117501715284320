import { NotFound } from "react-admin";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const RequireAuth = () => {
	const userRole = useSelector((state) => state.userInfo.value.role);

	const allowedRoles = ["TapasAdmin", "EMSDCE", "EMSDSE", "EMSDEngineer", "EMSDGR"];

	const isAllowed = allowedRoles.includes(userRole);

	const content = isAllowed ? <Outlet /> : <NotFound />

	return content
}

export default RequireAuth