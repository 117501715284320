import { useDelete, useNotify } from "react-admin";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';


//Use to discard the application after AI validation before sending to EMSD
function DiscardButton(props) {
  const { t : itranslate } = useTranslation();
  const { id } = props;
  const [deleteOne, { isLoading }] = useDelete();
  const navigate = useNavigate();
  const notify = useNotify();
  const handleDiscard = () => {
    confirmAlert({
      title: itranslate("Confirm to discard application"),
      message: itranslate(`Are you sure to do this?`),
      buttons: [
        {
          label: itranslate("Yes"),
          onClick: () => {
            deleteOne(
              "app_preSubmit",
              { id },
              {
                onSuccess: () => {
                  navigate("/applications");
                },
                onError: (e) => notify(itranslate(e.message)),
              }
            );
          },
        },
        {
          label: itranslate("No"),
        },
      ],
    });
  };
  return (
    <Button variant="outlined" color="error" onClick={handleDiscard}>
      {itranslate("Discard")}
    </Button>
  );
}

export default DiscardButton;
