import {
  PDFDownloadLink,
  Page,
  Document,
  View,
  Text,
  Image,
  Font
} from "@react-pdf/renderer";
import fontRegister from "../../utils/pdf/fontRegister";
import Html from "react-pdf-html";

import renderSymbol from "../../utils/pdf/renderSymbol";

import { franc } from "franc-min";

// eslint-disable-next-line import/no-webpack-loader-syntax

function CommLogPDF(props) {
  fontRegister();
  Font.registerHyphenationCallback((word) => {
    if (franc(word) === "cmn") {
      if (word.length === 1) {
        return [word];
      }
      return Array.from(word)
        .map((char) => [char, ''])
        .reduce((arr, current) => {
          arr.push(...current);
          return arr;
        }, []);
    } else if (word === " " || word === "\n" || word === "\r\n" || word === "\t" || word === "\r") {
      return [" "]

    } else {
      return [word]
    }
  });
  const { messages } = props;
  return (
    <Document>
      <Page
        wrap
        style={{
          padding: "48px 61.64px",
          fontSize: "11px",
          fontFamily: "NotoSansSC",
          lineHeight: 1.6,
        }}
        size="A4"
      >
        {messages.map((comment, i) => {
          return (
            <View key={i}
              style={{
                width: "100%",
                margin: "10px"
              }}
            >
              <Text style={{ fontSize: 16, fontFamily: "NotoSansSC", fontWeight: "bold" }}>
                {`Message from ${comment.user_id?.role?.includes("RC") ? "RC" : "EMSD"
                  }`}
                ,{" "}
                {new Date(comment.createdAt).toLocaleString("en-HK", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Text>
              <Text style={{ fontFamily: "NotoSansSC", fontWeight: "bold" }}>
                {comment.subject}
              </Text>
              <Html
                style={{
                  fontSize: 10,
                }}>
                {comment.content}
              </Html>
              <View
                style={{
                  borderBottom: "1px solid black",
                  width: "100%",
                  marginBottom: "30px",
                }}
              />
            </View>
          );
        })}
      </Page>
    </Document>
  );
}

const CommLogExport = (props) => {
  const { messages } = props;
  if (messages.length === 0) return null;
  return (
    <PDFDownloadLink
      document={<CommLogPDF messages={messages} />}
      fileName="Communication Log.pdf"
    >
      {({ blob, url, loading, error }) => {
        return loading ? "Loading export..." : "Export"
      }
      }
    </PDFDownloadLink>
  );
};

export default CommLogExport;
