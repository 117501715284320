// components and functions
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, MenuItemLink, useLogout } from "react-admin";
import { useSelector } from "react-redux";
import { Box, Button, Divider, Typography } from "@mui/material";

// icons
import PeopleIcon from "@mui/icons-material/People";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { Dashboard } from "@mui/icons-material";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FactoryIcon from "@mui/icons-material/Factory";
import ElevatorIcon from "@mui/icons-material/Elevator";
import EngineeringIcon from "@mui/icons-material/Engineering";
import GroupsIcon from "@mui/icons-material/Groups";
import RuleIcon from "@mui/icons-material/Rule";
import BlockIcon from "@mui/icons-material/Block";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DescriptionIcon from "@mui/icons-material/Description";

// others
import palette from "../../styles/palette";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import { useTranslation } from 'react-i18next';


export const CustomMenu = (props) => {
  const { t : itranslate } = useTranslation();

  useEffect(() => {
    const portal = window.location.pathname === "/lift_rc" ? "RC" : "EMSD";
    document.title = `TAPAS ${portal}`;
  }, []);

  const location = useLocation();
  const logout = useLogout();
  const userInfo = useSelector((state) => state.userInfo.value);

  const classes = {
    menu: {
      backgroundColor: "white",
      "&.RaMenu-closed": {
        marginLeft: "-20px",
        width: "75px",
      },
      "&.RaMenu-closed > *": {
        fontSize: "0.8rem",
      },
      "&.RaMenu-closed > .MuiTypography-root": {
        paddingLeft: "10px",
      },
      "&.RaMenu-closed > .newApplication": {
        marginLeft: "25px",
        width: "40px",
        minWidth: "10px",
      },
      "&.RaMenu-closed > .newApplication:after": {
        content: '"+"',
        position: "absolute",
        left: "38%",
      },
      "&.RaMenu-closed > .logOut": {
        marginLeft: "25px",
        width: "40px",
        minWidth: "10px",
        color: "transparent",
      },
      "&.RaMenu-closed > .logOut > svg": {
        color: "black",
        paddingLeft: "25px",
      },
    },
    menuItem: {
      fontSize: "1rem",
      fontWeight: "600",
      color: "black",
      margin: "10px 15px",
      borderRadius: "4px",
    },
    newApplication: {
      backgroundColor: palette.primary,
      fontWeight: "600",
      margin: "0px 15px",
      "&::after": {
        // content: `"+ ${t("New Application")}"`,
        content: `"+ ${itranslate("sideMenu.New Application")}"`,
        position: "absolute",
        left: "18%",
      },
      height: "40px",
    },
    menuHeader: {
      margin: "25px 15px 0px 15px",
      fontWeight: "600",
      color: palette.menuHeader,
    },
    profileBox: {
      display: "flex",
      alignItems: "center",
      gap: "3px",
      marginLeft: "15px",
    },
    name: {
      fontSize: "0.85rem",
      fontWeight: "600",
    },
    email: {
      fontSize: "0.85rem",
      color: palette.menuHeader,
    },
    logOut: {
      margin: "10px 15px",
      backgroundColor: palette.shadow,
      color: "black",
    },
    logOutIcon: {
      marginRight: "5px",
      fontSize: "1rem",
    },
  };

  const Profile = (props) => {
    return (
      <Box sx={classes.profileBox}>
        <AccountCircleOutlinedIcon
          //stroke to makes it thinner
          sx={{ fontSize: "55px", stroke: "#ffffff", strokeWidth: 1.5 }}
        />
        <Box>
          <Typography sx={classes.name}>
            {userInfo?.rc} - {userInfo?.username}
          </Typography>
          <Typography sx={classes.email}>{userInfo?.email}</Typography>
        </Box>
      </Box>
    );
  };

  const handleLogout = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/users/logout`,
        {},
        {
          headers: {
            authorization: getToken(),
          },
        }
      );
      if (data) logout();
    } catch (err) {
      console.log(err);
    }
  };

  return window.location.pathname === "/lift_rc" ? (
    <Menu {...props} sx={classes.menu}>
      {!location.pathname.includes("applications_new") && (
        <Button
          className="newApplication"
          sx={classes.newApplication}
          component={Link}
          to="/applications_new"
          variant="contained"
        />
      )}
      <Typography 
        sx={classes.menuHeader}>
          {itranslate("menu")}
      </Typography>
      <MenuItemLink
        sx={classes.menuItem}
        to={{
          pathname: "/",
        }}
        primaryText={itranslate("Statistics Dashboard")}
        leftIcon={<GridViewOutlinedIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to={{
          pathname: "/applications",
        }}
        primaryText={itranslate("Applications")}
        leftIcon={<PendingActionsIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/applications_validating"
        primaryText={itranslate("AI Validation")}
        leftIcon={<RuleIcon />}
      />
      {/* {process.env.REACT_APP_PHASE_TWO && ( */}
        <MenuItemLink
          sx={classes.menuItem}
          to="/restrictedUrls"
          primaryText={itranslate("Restricted Urls")}
          leftIcon={<SummarizeIcon />}
        />
      {/* )} */}
      <MenuItemLink
        sx={classes.menuItem}
        to="/manufacturers"
        primaryText={itranslate("Manufacturers")}
        leftIcon={<FactoryIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/models"
        primaryText={itranslate("Lift/Escalator Models ")}
        leftIcon={<ElevatorIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/components"
        primaryText={itranslate("Safety components")}
        leftIcon={<EngineeringIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/users"
        primaryText={itranslate("Account management")}
        leftIcon={<PeopleIcon />}
      />
      {/* {process.env.REACT_APP_PHASE_TWO && */}
      {window.location.pathname === "/lift_emsd" && (
          <MenuItemLink
            sx={classes.menuItem}
            to="/revoke"
            primaryText={itranslate("Revoke")}
            leftIcon={<CancelScheduleSendIcon />}
          />
        )}
      <MenuItemLink
        sx={classes.menuItem}
        to="/configuration"
        primaryText={itranslate("Settings")}
        leftIcon={<SettingsOutlinedIcon />}
      />
      <Divider sx={{ margin: "0px 20px" }} />
      <Typography 
        sx={classes.menuHeader}>
          {itranslate("profile")}
        </Typography>
      <Profile />
      <Button
        className="logOut"
        sx={classes.logOut}
        onClick={() => handleLogout()}
      >
        <LogoutIcon sx={classes.logOutIcon} />
        {itranslate("Log Out")}
      </Button>
    </Menu>
  ) : (
    <Menu {...props} sx={classes.menu}>
      {/* //EMSD */}
      <MenuItemLink
        sx={classes.menuItem}
        to="/"
        primaryText={itranslate("Statistics Dashboard")}
        leftIcon={<Dashboard />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to={{
          pathname: "/applications",
        }}
        primaryText={itranslate("Awaiting Approval")}
        leftIcon={<PendingActionsIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to={{
          pathname: "/v2/applications",
        }}
        primaryText={itranslate("Applications")}
        leftIcon={<AssignmentTurnedInIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/manufacturers"
        primaryText={itranslate("Manufacturers")}
        leftIcon={<FactoryIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/models"
        primaryText={itranslate("Lift/Escalator Models ")}
        leftIcon={<ElevatorIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/components"
        primaryText={itranslate("Components")}
        leftIcon={<EngineeringIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/rcs"
        primaryText={itranslate("RC")}
        leftIcon={<GroupsIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/team"
        primaryText={itranslate("Teams")}
        leftIcon={<PeopleOutlineIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/users"
        primaryText={itranslate("Users/Assignment")}
        leftIcon={<PeopleIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/report"
        primaryText={itranslate("Report")}
        leftIcon={<AssessmentIcon />}
      />
      <MenuItemLink
        sx={classes.menuItem}
        to="/logs"
        primaryText={itranslate("Logs")}
        leftIcon={<SyncAltIcon />}
      />
      {(
        <MenuItemLink
          sx={classes.menuItem}
          to="/revoke"
          primaryText={itranslate("Revoke")}
          leftIcon={<BlockIcon />}
        />
      )}
      {userInfo?.allowManageRecords && (
        <MenuItemLink
          sx={classes.menuItem}
          to="/record_management"
          primaryText={itranslate("Record Management")}
          leftIcon={<DescriptionIcon />}
        />
      )}
      <Divider sx={{ margin: "0px 20px" }} />
      <Typography sx={classes.menuHeader}>
        {itranslate("profile")}
      </Typography>
      <Profile />
      <Button
        className="logOut"
        sx={classes.logOut}
        onClick={() => handleLogout()}
      >
        <LogoutIcon sx={classes.logOutIcon} />
        {itranslate("Log Out")}
      </Button>
    </Menu>
  );
};
