import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import keyToField from "../../utils/keyToField.js";

const manufacturersExporter = (manufacturers) => {
  const manufacturersForExport = manufacturers.map((manufacturer) => {
    const manufacturerForExport = {
      manufacturer_code: manufacturer.manufacturer_code,
      manufacturer_name: manufacturer.provided_name,
      scope: keyToField[manufacturer.type],
      address: manufacturer.certsPopulated.cert_type_metadata?.iso_manufacturer_address || manufacturer.certsPopulated.cert_corrections?.iso_manufacturer_address,
      approval_date: manufacturer.approval_date,
    };
    return manufacturerForExport;
  });
  jsonExport(
    manufacturersForExport,
    {
      headers: [
        "manufacturer_code",
        "manufacturer_name",
        "scope",
        "address",
        "approval_date",
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "manufacturers"); // download as 'manufacturers.csv` file
    }
  );
};

export default manufacturersExporter;
