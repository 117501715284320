import { Text } from "@react-pdf/renderer";
import { franc } from "franc-min";


const formatParser = (text, format) => {
  // Format:
  // **text**  Ultra bold
  // __text__ underline
  // **__text__** bold+underline
  // **~text~** bold
  // `text` switch font family (to display missing symbol)
  // ##text## switch font family (to display chinese)
  //To add more format, simply nest a new function inside index%2===0 block
  const TextComponent = format === "plainHtml" ? "span" : Text;

  function traditionalChinese(textOutsideSymbols, textArray) {
    const arr4 = textOutsideSymbols.split("##");
    arr4.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        styledText =
          format === "plainHtml" ? (
            <span key={Math.random()}>{subText}</span>
          ) : (
            // We replace all white space after a new line(\n) with \u00A0 for non-breaking space in pdf, in html, it is handled by css "white-space: pre-wrap"
            <Text key={Math.random()}>
              {subText.replace(/(?<=\n)\s+/g, (match) =>
                "\u00A0".repeat(match.length)
              )}
            </Text>
          );
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ fontFamily: "mYuenLight" }}
            // style={{ fontFamily: "NotoSansSC" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  function switchFontFamily(textOutsideUnderline, textArray) {
    const arr3 = textOutsideUnderline.split("`");
    arr3.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        traditionalChinese(subText, textArray);
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ fontFamily: "DejaVu Sans" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  function checkUnderline(textOutsideBold, textArray) {
    const arr2 = textOutsideBold.split("__");
    arr2.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        switchFontFamily(subText, textArray);
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ textDecoration: "underline" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  if (text) {
    const arr = text.split("**");
    const newTextArr = [];
    // Loop over split text
    arr.forEach((text, index) => {
      //outside **..** block, either unbolded text or ** does not exist
      if (index % 2 === 0) {
        checkUnderline(text, newTextArr);
      }
      // If its an odd element then it is inside **...** block
      else if (index % 2 !== 0) {
        let styledText;
        if (text.startsWith("__") && text.endsWith("__")) {
          // for example, **__boldedAndUnderlined__**
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{
                  textDecoration: "underline",
                  fontFamily: "Frutiger_light",
                  fontWeight: 700,
                }}
              >
                {text.slice(2, -2)}
              </span>
            ) : (
              <Text
                key={Math.random()}
                style={{
                  textDecoration: "underline",
                  fontFamily: "Frutiger_bold",
                }}
              >
                {text
                  .slice(2, -2)
                  .replace(/(?<=\n)\s+/g, (match) =>
                    "\u00A0".repeat(match.length)
                  )}
              </Text>
            );
        } else if (text.startsWith("~") && text.endsWith("~")) {
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{ fontFamily: "Frutiger_light", fontWeight: 700 }}
              >
                {text.slice(1, -1)}
              </span>
            ) : (
              <Text key={Math.random()} style={{ fontFamily: "Frutiger_bold" }}>
                {text
                  .slice(1, -1)
                  .replace(/(?<=\n)\s+/g, (match) =>
                    "\u00A0".repeat(match.length)
                  )}
              </Text>
            );
        } else {
          // Wrap with bold text style
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{ fontFamily: "Frutiger_Ultra_bold" }}
              >
                {text}
              </span>
            ) : (
              <Text
                key={Math.random()}
                style={{ fontFamily: "Frutiger_Ultra_bold" }}
              >
                {text.replace(/(?<=\n)\s+/g, (match) =>
                  "\u00A0".repeat(match.length)
                )}
              </Text>
            );
        }
        newTextArr.push(styledText);
      }
    });
    return newTextArr;
  }
};



function parseContentHeadingChinesePDFJson(input, rcItem, format) {
  const { manufacturer } = rcItem;

  let manufacturer_Name = manufacturer[0]?.provided_manufacturer_name;
  if (franc(manufacturer[0]?.provided_manufacturer_name, { minLength: 0 }) === "cmn") {
     manufacturer_Name = "##" + manufacturer[0]?.provided_manufacturer_name + "##";
  } 

  let temp = input;
  const manufacturerName = "{manufacturer_name}";

  // Find the index where "{manufacturer_name}" occurs in the string
  const manufacturerIndex = temp.indexOf(manufacturerName);

  // Extract the first part of the string before "{manufacturer_name}"
  const rest = temp.substring(0, manufacturerIndex);

  let newString = rest + manufacturer_Name

  const parsedFormat = formatParser(newString, format)
  return parsedFormat;
}

export default parseContentHeadingChinesePDFJson;
