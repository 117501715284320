import React from 'react';
import { Snackbar, SnackbarContent } from "@mui/material";

const CustomNotification = ({ message, onClose }) => (
  <Snackbar
    open={!!message}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    onClose={onClose}
    autoHideDuration={3000}
  >
 <SnackbarContent
      message={message}
      style={{
        backgroundColor: '#323232', // Example color
        position: 'fixed',
        bottom: '20px', // Changed from top to bottom
        left: '55%', // Center horizontally
        transform: 'translateX(-50%)', // Ensure perfect centering
        zIndex: 9999,
      }}
    />
  </Snackbar>
);

export default CustomNotification;