import { Container, TableHead } from "@material-ui/core";
import { useEffect, useState } from "react";

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { getOneModelScsForRuSummary } from "../../../../../../admin/useHooks/useComponents";
import CircularProgressHoc from "../../../../../../_hoc/Spinners/CircularProgressHoc";

// redux
import { useSelector, useDispatch } from "react-redux";

// import { useTranslate } from "react-admin";
import { useTranslation } from 'react-i18next';


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

const ShowModalScs = ({oneModel}) => {
    const { t : itranslate } = useTranslation();
    // const translate = useTranslate();

    const [components, setComponents] = useState([])
    const [scs, setScs] = useState([])
    const [loading, setLoading] = useState(true)

    const [relatedModelScCert, setRelatedModelScCert] = useState([])


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // redux
    const ruPageDataStore = useSelector((state) => state.ruPageData.value);
    const ruPageData = structuredClone(ruPageDataStore);

    useEffect(() => {
        setRelatedModelScCert(ruPageData.certsChosen.tec)
        setComponents(ruPageData.model_manu_sc_id_arr.filter((v, _) => v.model === oneModel._id)[0].relatedScChosen)
    }, [])

    useEffect(() => {
        if(components.length > 0){
            getOneModelScsForRuSummary(`{"ids":"${components}"}`, `[0,9999999]`, `["component_code", "ASC"]`).then((res) => {
                setScs(res.data.data)
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [components])

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - scs.length) : 0;
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return ( 
        <div className="">
          {
            !(rowsPerPage > 0 && scs.length > 0 && !loading) && 
              <div className="text-lg">
                {/* {translate("phase2.createRu.noSelectedComponent")} */}
                {itranslate('noSelectedComponent')}
              </div>
          }
          {
            (rowsPerPage > 0 && scs.length > 0 && !loading) &&
              <TableContainer component={Paper}>
                
                  <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                      <TableHead>
                          <TableRow>
                              <TableCell>
                                  {/* {translate("phase2.ru.enhancedTableHead.Component Code")} */}
                                  {itranslate('Component Code')}
                              </TableCell>
                              <TableCell>
                                  {/* {translate("phase2.ru.enhancedTableHead.Type")} */}
                                  {itranslate('Type')}
                              </TableCell>
                              <TableCell align="right">
                                  {/* TEC */}
                                  {itranslate('TEC')}
                              </TableCell>
                          </TableRow>
                      </TableHead>
                      
                      <TableBody>
                          {(rowsPerPage > 0 && scs.length > 0
                          ? scs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : scs
                          ).map((row) => (
                          <TableRow key={row.component_code}>
                              <TableCell component="th" scope="row">
                              {row.component_code}
                              </TableCell>
                              <TableCell style={{ width: 160 }} align="left">
                              {row.type}
                              </TableCell>
                              <TableCell style={{ width: 160 }} align="right">
                              {
                                  row.type_test_certificate_id.map(v => {
                                      return relatedModelScCert.includes(v) ? v : null
                                  }).length
                              }
                              </TableCell>
                          </TableRow>
                          ))}

                          {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                          </TableRow>
                          )}
                          
                      </TableBody>

                      <TableFooter>
                        <TableRow>
                          <TablePagination
                              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                              colSpan={3}
                              count={scs.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              slotProps={{
                                  select: {
                                      inputProps: {
                                      'aria-label': 'rows per page',
                                      },
                                      native: true,
                                  },
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                              labelRowsPerPage={itranslate("Rows per page")}
                          />
                        </TableRow>
                      </TableFooter>

                  </Table>
              </TableContainer>
          }
          <CircularProgressHoc isLoading={loading} />

        </div>

    )
}

export default ShowModalScs