const updateTypeMap = {
  a: "Manufacturer Name Change",
  b: "Update lift/escalator model certificate",
  c: "Lift/escalator model range extension",
  d: "Update safety component certificate",
  e: "Safety component range extension",
  f: "Add new safety component",
};

export default updateTypeMap;
