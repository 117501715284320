// react hooks
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// mui
import { 
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Menu,
  MenuItem
} from "@mui/material";

// radios
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// hooks
import useVerify from "../../../admin/useHooks/verifyRecord/useVerify";

// modal
import BasicModalWithConfirmButton from "../../../_hoc/modals/BasicModalWithConfirmButton";

// Header
import Header from "./header";

import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";


const shuffle = ( array ) => { 
  for (let i = array.length - 1; i > 0; i--) { 
    const j = Math.floor(Math.random() * (i + 1)); 
    [array[i], array[j]] = [array[j], array[i]]; 
  }
  return array; 
}; 



export default function PageVerifyAppendix() {
  // const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || i18n.language);
  // console.log("🚀 ~ PageVerifyAppendix ~ language:", language)
  const { t : itranslate } = useTranslation();

  // get da_no from url
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const encryptedDaNo = urlParams.get("da_no"); // it's encrypted

  // customized hook
  const { verifyAppNoHook, verifyModelNameHook, verifyManuHook } = useVerify();

  // hooks
  const navigate = useNavigate();

  // states
  const [appNo, setAppNo] = useState("");
  const [modelName, setModelName] = useState("");
  const [modelChoice, setModelChoice] = useState([]);
  const [manuName, setManuName] = useState("");
  const [manuChoice, setManuChoice] = useState([]);
  const [verifyName, setVerifyName] = useState("");
  const [componentType, setComponentType] = useState("");
  const [sc_type, setSc_type] = useState("");
  const [manu_ofSCType, setManu_ofSCType] = useState("");

  // fakeCode & fakeName
  const [fakeManuNames, setFakeManuNames] = useState([]);

  // refreshing the page will go back to stage 1
  const [stage, setStage] = useState(1);
  const [daNoHash, setDaNoHash] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setDaNoHash(encryptedDaNo);
  }, [encryptedDaNo]);


  // check language
  // window.addEventListener('storage', (e) => {
  //   if (e.key === 'selectedLanguage') {
  //     i18n.changeLanguage(e.newValue);
  //     setLanguage(e.newValue);
  //   }
  // });



  const scList = [
    { name: "ACOP" },
    { name: "Buffer" },
    { name: "CarDoorLockingDevice" },
    { name: "LandingDoorLockingDevice" },
    { name: "OverspeedGovernor" },
    { name: "Pallet" },
    { name: "RaptureValve" },
    { name: "SafetyCircuitElectricalComponent" },
    { name: "SafetyGear" },
    { name: "Step" },
    { name: "UCMP" },
  ];




  const handleSubmitStage1 = ({ e, appNo }) => {
    let appNoOrDaNo = appNo
    // ! when press enter on keyboard, it refreshes the page
    e.preventDefault();
    verifyAppNoHook(appNoOrDaNo, daNoHash)
      .then((res) => {
        // console.log("🚀 ~ file: index.jsx:82 ~ .then ~ res:", res.data)
        // set fakeCode and fakeName
        setFakeManuNames(res?.fakeManuNames)

        let resp = res.data;
        let scTypes = [];  // temporarily store the names of the sc types in the list
        let scType;   // temporarily store the one of the sc
        let manuOfSCType;  // temporarily store the info of the manufaturer for the sc
        let length = 0;
        setComponentType(resp.app_type.type);  // store the component type 

        // if component type is not SafetyComponent
        if (resp.app_type.type !== "SafetyComponent" && resp?.model.length > 0) {
          length = resp?.model.length;
          // setVerifyName('Model Name');
          setVerifyName(itranslate('Model Name'));
          setManuChoice(shuffle([resp.model[0].manufacturer_files[0]?.manufacturer_id?.provided_name, ...res.fakeManuNames]));
        };

        // else if component type is SafetyComponent
        if (resp.app_type.type === "SafetyComponent") {
          setVerifyName(itranslate('Safety Component Name'));
          for (const key in resp.app_type.app_type_data) {
            scList.forEach((sc) => {
              if (sc.name === key) scTypes.push(sc.name);
            });
          };
          length = scTypes.length;
          scType = resp.app_type.app_type_data[scTypes[0]];   // get 1 sc
          setSc_type(scType[0]);
          manuOfSCType = resp.manufacturer_id.filter(manuf => manuf._id === scType[0].manufacturer_id);  // get the manufacturer of the sc;
          setManu_ofSCType(manuOfSCType[0]);
        };

        if (length === 0) {
          setErrorMsg("No model record found in the application");
          // setErrorMsg(itranslate("No model record found in the application"));
          setOpen(true);
        }
        if (length === 1) {
          // ? randomize the provided modal name ?
          if (resp.app_type.type !== "SafetyComponent") setModelChoice(shuffle([resp?.model[0]?.provided_model_name, ...res.fakeModelNames]));
          if (resp.app_type.type === "SafetyComponent") setModelChoice(shuffle([scType[0]?.provided_comp_name, ...res.fakeScNames]));
        }
        if (length > 1) {
          let randNum = Math.floor(Math.random(length));
          if (resp.app_type.type !== "SafetyComponent") setModelChoice(shuffle([resp?.model[randNum]?.provided_model_name, ...res.fakeModelNames]));
          if (resp.app_type.type === "SafetyComponent") setModelChoice(shuffle([scType[0]?.provided_comp_name, ...res.fakeScNames]));
          // ? shuffle all the modal choices in an array: shuffleChoices ?
        }
        setStage(2);
      })
      .catch((e) => {
        setErrorMsg(e?.response?.data?.message);
        setOpen(true);
      });
  };

  const handleSubmitStage2 = ({ e, modelName }) => {
    e.preventDefault();
    if (componentType !== "SafetyComponent") {
      verifyModelNameHook(modelName, appNo, daNoHash)
        .then((res) => {
          navigate("/verifyRecord/record", { state: { daNoHash } });
        })
        .catch((e) => {
          setStage(3);
          setErrorMsg(e?.response?.data?.message);
          setOpen(true);
        });
    }

    if (componentType === "SafetyComponent") {
      if (sc_type.provided_comp_name === modelName) { // if modelName is correct,
        navigate("/verifyRecord/record", { state: { daNoHash } });
      }
      else if (sc_type.provided_comp_name !== modelName) {
        setManuChoice(shuffle([manu_ofSCType.provided_name, ...fakeManuNames]));
        setStage(3);
        // setErrorMsg(itranslate("Component Not Found"));
        setErrorMsg("Component Not Found");
        setOpen(true);
      }
        // console.log("🚀 ~ file: index.jsx:176 ~ handleSubmitStage2 ~ manu_ofSCType.manufacturer_code, ...fakeManuNames:", manu_ofSCType.manufacturer_code, ...fakeManuNames)
    };
  };

  const handleSubmitStage3 = ({ e, manuName }) => {
    e.preventDefault();
    verifyManuHook(modelName, appNo, daNoHash, manuName)
      .then(() => {
        // TODO navigate to /verifyRecord with state, useLocation to get the "state", remember to change the state
        navigate("/verifyRecord/record", { state: { daNoHash } });
      })
      .catch((e) => {
        // set ErrorMsg and the modal is open
        setStage(1);
        setErrorMsg(e?.response?.data?.message);
        setOpen(true);
      });
  };

  useEffect(() => {
    // set ErrorMsg to null and the modal is close
    if (!open) {
      setErrorMsg(null);
    }
  }, [open]);

  useEffect(() => {
    if(manuChoice){
      setManuName(manuChoice[0]);
    }
    if(modelChoice){
      setModelName(modelChoice[0]);
    }
  }, [manuChoice, modelChoice]);





  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    // setLanguage(language)
  };


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  
  const handleClose = (language) => {
    if (language) handleLanguageChange(language);
    setAnchorEl(null);
  };


  return (
    <Container component="main" maxWidth="lg" sx={{ minHeight: "90vh" }}>
      {/* header */}
      <Header />
      {/* form */}
      {!errorMsg && !open && (
        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >


        {/* { process.env.REACT_APP_DEV &&  */}
        { process.env.REACT_APP_UAT && 
            <Box sx={{ marginBottom: 4 }}>
              <Button onClick={handleClick}>
                {itranslate("Select Language")}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(null)}
              >
                <MenuItem onClick={() => handleClose("en")}>{itranslate("English")}</MenuItem>
                <MenuItem onClick={() => handleClose("ch")}>{itranslate("Chinese")}</MenuItem>
              </Menu>
            </Box>
          }


          {/* title */}
          <Typography
            component="h1"
            variant="h5"
            sx={{ fontFamily: "Frutiger_bold" }}
          >
            {itranslate("Verify Appendix")} 
          </Typography>


          {/* <Button onClick={() => handleLanguageChange("en")}>English</Button>
          <Button onClick={() => handleLanguageChange("ch")}>Chinese</Button>  */}

          {stage === 1 && (
            <div>
              <Box
                component="form"
                onSubmit={handleSubmitStage1}
                noValidate
                sx={{ mt: 1, width: "400px" }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  name="appNo"
                  label={itranslate("BM number or DA number")}
                  id="appNo"
                  value={appNo}
                  variant="outlined"
                  onChange={(e) => setAppNo(e.target.value)}
                  onKeyDown={(e) => {
                    // press enter to submit
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmitStage1({ e, appNo });
                    }
                  }}
                />
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) => handleSubmitStage1({ e, appNo })}
                aria-label="submit"
              >
                {itranslate('Submit')}
              </Button>
            </div>
          )}

          {stage === 2 && (
            <Box sx={{ minWidth: 120, mt: 3 }}>
              <FormControl fullWidth>
                  <FormLabel id="radio-buttons-group-label">{verifyName}</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    defaultValue={modelChoice[0]}
                    onChange={(e) => setModelName(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value={modelChoice[0]} control={<Radio />} label={modelChoice[0]} />
                    <FormControlLabel value={modelChoice[1]} control={<Radio />} label={modelChoice[1]} />
                    <FormControlLabel value={modelChoice[2]} control={<Radio />} label={modelChoice[2]} />
                    <FormControlLabel value={modelChoice[3]} control={<Radio />} label={modelChoice[3]} />
                  </RadioGroup>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={(e) => handleSubmitStage2({ e, modelName })}
                  aria-label="submit"
                >
                  {itranslate('Submit')}
                </Button>
              </FormControl>
            </Box>
          )}

          {stage === 3 && (
            <Box sx={{ minWidth: 120, mt: 3 }}>
              <FormControl fullWidth>
                  <FormLabel id="radio-buttons-group-label">
                    {itranslate("Manufacturer Code")}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    defaultValue={manuChoice[0]}
                    onChange={(e) => setManuName(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value={manuChoice[0]} control={<Radio />} label={manuChoice[0]} />
                    <FormControlLabel value={manuChoice[1]} control={<Radio />} label={manuChoice[1]} />
                    <FormControlLabel value={manuChoice[2]} control={<Radio />} label={manuChoice[2]} />
                    <FormControlLabel value={manuChoice[3]} control={<Radio />} label={manuChoice[3]} />
                  </RadioGroup>
                
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={(e) => handleSubmitStage3({ e, manuName })}
                  aria-label="submit"
                >
                  {itranslate('Submit')}
                </Button>

              </FormControl>
            </Box>
          )}
        </Box>
      )}

      {/* Err modal */}
      {errorMsg && open && (
        // <BasicModal open={open} setOpen={setOpen} title="Error">
        <BasicModalWithConfirmButton open={open} setOpen={setOpen}>
          <Typography>{itranslate(errorMsg)}</Typography>
        </BasicModalWithConfirmButton>
      )}
    </Container>
  );
}
