import React, { useEffect, useState } from "react";
import palette from "../../styles/palette";
import {
  Avatar,
  Box,
  Card,
  Typography,
  FormControl,
  Button,
  ButtonGroup,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  TextField,
  IconButton,
} from "@mui/material";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import logo from "../../styles/logo/emsdNoCaption.png";
import { useDataProvider } from "react-admin";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/font/quill.css";
import DeleteWithConfirmButton from "../../buttons/DeleteWithConfirmButton";
import CommLogExport from "./CommLogExport";

import Tooltip from '@mui/material/Tooltip';

import CommunicationLogPopper from '../../../_hoc/CompPopper/CommunicationLogPopper';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";


export const CommentBox = (props) => {
  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || i18n.language);
  const { t : itranslate } = useTranslation();

  const { add, edit, show, upload, setUpload, userRoles } = props;
  const dataProvider = useDataProvider();
  const [mode, setMode] = useState("newMessage");
  const [id, setId] = useState(null); // id of the message to be edited
  const [messages, setMessages] = useState([]);
  const [displayedMessage, setDisplayedMessage] = useState(null);
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const userInfo = useSelector((state) => state.userInfo.value);
  const modules = {
    toolbar: false,
  };

  // check language
  // window.addEventListener('storage', (e) => {
  //   if (e.key === 'selectedLanguage') {
  //     i18n.changeLanguage(e.newValue);
  //     setLanguage(e.newValue);
  //   }
  // });


  // Function to format date depending on language
  const format_Date = (date) => {
    return language === 'en'
      ? new Date(date).toLocaleString("en-HK", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
      : new Date(date).toLocaleString("zh-HK", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
  };


  const newMessage = () => {
    setMode("newMessage");
    setId(null);
    setSubject("");
    setText("");
  };

  const editCurrentlyDisplayedMessage = () => {
    setMode("editMessage");
    setId(displayedMessage._id);
    setSubject(displayedMessage.subject);
    setText(displayedMessage.content);
  };

  useEffect(() => {
    if (upload) setMessages(upload.dialog);
  }, [upload]);

  useEffect(() => {
    if (messages.length > 0)
      setDisplayedMessage({
        _id: messages[0]._id,
        index: 0,
        subject: messages[0].subject,
        content: messages[0].content,
      });
    else setDisplayedMessage(null);
  }, [messages]);

  const handleChange = async (action, content, date) => {
    // if add
    if (action === "addComment" && add) {
      setUpload({
        ...upload,
        dialog: [
          ...upload?.dialog,
          {
            content,
            subject,
            createdAt: date,
            user_id: { email: userInfo.email, role: userInfo.role },
          },
        ],
      });
      setText("");
      setSubject("");
    }

    //if edit/show, user from user'props'
    if (action === "addComment" && (show || edit)) {
      const send = await dataProvider.updateMessageDialog(upload.id, {
        ...(id && { _id: id }),
        content,
        subject,
        createdAt: date,
        user_id: { email: userInfo.email, role: userInfo.role },
      });
      if (send) {
        const messageObj = {
          _id: send.data?._id,
          content,
          subject,
          createdAt: date,
          user_id: { email: userInfo.email, role: userInfo.role },
        };

        const clone = [...upload.dialog];
        if (id) {
          let editedMessageIndex = clone.findIndex(
            (message) => message._id === id
          );
          clone[editedMessageIndex] = messageObj;
        } else {
          clone.push(messageObj);
        }

        setUpload({
          ...upload,
          dialog: clone,
        });
        setText("");
        setSubject("");
      }
    }

    if (action === "deleteComment") {
      await dataProvider.deleteMessageDialog(displayedMessage._id, upload.id);
      setUpload({
        ...upload,
        dialog: upload.dialog?.filter(
          (message) => message._id !== displayedMessage._id
        ),
      });
    }
  };


  // disable the button if the user is not EMSDGR (GR) or Inspector, but the application's current role name is not GR.
  const isButtonDisabled = window.location.pathname !== "/lift_rc" ? (
      (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
      (upload?.status.includes("Pending E") ||
        upload?.status.includes("Pending SE") ||
        upload?.status.includes("Pending CE")) &&
      upload?.current_role_name !== "GR") ||
    userInfo?.role === "Inspector" || //Inspector
    (userInfo?.role !== "EMSDGR" && //Check roleId except GR
      !userRoles.includes(upload?.current_role_id))
    ) : false


  return (
    <Card>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
        }}
      >
        <Typography sx={{ px: 2, py: 1, fontWeight: "bold" }}>
          {/* {displayedMessage ? displayedMessage.subject : "Communication Log"} */}
          {displayedMessage ? itranslate(displayedMessage.subject) : itranslate("Communication Log")}
        </Typography>
        <CommLogExport messages={messages} />
      </Box>

      <Box
        sx={{
          display: "flex",
          borderTop: `1px solid ${palette.border}`,
          borderBottom: `1px solid ${palette.border}`,
        }}
      >
        <div>
          <List
            sx={{
              width: "300px",
              height: "550px",
              overflowY: "auto",
              borderRight: `1px solid ${palette.border}`,
              boxSizing: "border-box",
              p: 0,
            }}
          >
            {messages.map((comment, i) => {
              return (
                <ListItemButton
                  key={i}
                  alignItems="flex-start"
                  sx={{
                    borderBottom: `1px solid ${palette.border}`,
                    ...(displayedMessage?.index === i && {
                      backgroundColor: palette.selected,
                    }),
                  }}
                  onClick={() =>
                    setDisplayedMessage({
                      _id: comment._id,
                      index: i,
                      subject: comment.subject,
                      content: comment.content,
                    })
                  }
                >
                  <ListItemAvatar>
                    <Avatar alt="emsd logo" src={logo} sx={{ borderRadius: 0 }} />
                  </ListItemAvatar>
                  <ListItemText
                    // primary={`Message from ${comment.user_id?.role?.includes("RC") ? "RC" : "EMSD"}`}
                    primary={itranslate(`Message from ${comment.user_id?.role?.includes("RC") ? "RC" : "EMSD"}`)}
                    primaryTypographyProps={{ fontWeight: "bold" }}
                    // secondary={new Date(comment.createdAt).toLocaleString(
                    //   "en-HK",
                    //   {
                    //     year: "numeric",
                    //     month: "long",
                    //     day: "numeric",
                    //     hour: "2-digit",
                    //     minute: "2-digit",
                    //   }
                    // )}
                    secondary={format_Date(comment.createdAt)}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </div>
        
        <div className="flex-1 overflow-auto">
          <ReactQuill
            className="ql-readOnly ql-display-msg-box"
            modules={modules}
            readOnly={true}
            theme="snow"
            value={displayedMessage?.content}
          />

        </div>
      </Box>

        {/* RC do not allow to comment except in clarifying */}
        {!(show && window.location.pathname === "/lift_rc") &&
        !["Withdrawn", "Approved"].includes(upload.status ) && (
            <FormControl sx={{ px: 3, py: 1, alignItems: "flex-start" }}>
              {window.location.pathname === "/lift_emsd" && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <ButtonGroup variant="text">
                    <Button
                      sx={{ fontSize: "1rem" }}
                      onClick={() => newMessage()}
                      disabled={isButtonDisabled}
                    >
                      {/* New message */}
                      {itranslate("New message")}
                    </Button>
                    <Button
                      sx={{ fontSize: "1rem" }}
                      onClick={() => editCurrentlyDisplayedMessage()}
                      disabled={isButtonDisabled}
                    >
                      {/* Edit currently displayed message */}
                      {itranslate("Edit currently displayed message")}
                    </Button>
                  </ButtonGroup>
                  <DeleteWithConfirmButton
                    sx={{ fontSize: "1rem" }}
                    // label="Delete currently displayed message"
                    label={itranslate("Delete currently displayed message")}
                    customDeleteLogic={() => handleChange("deleteComment")}
                    userInfo={userInfo}
                    upload={upload}
                    userRoles={userRoles}
                  />
                </Box>
              )}

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  marginTop: "3em",
                  alignItems: "center",
                }}              
              >
                <Typography sx={{ mt: 3,
                  my: "auto"
                }}>
                  {mode === "editMessage" ? itranslate("Edit message") : itranslate("New message")}
                </Typography>
                <CommunicationLogPopper>
                    <div>
                      {/* "Symbol 'Ƨ' is not supported here in exported pdf here." */}
                      {itranslate("Symbol 'Ƨ' is not supported here in exported pdf here.")}
                    </div>
                </CommunicationLogPopper>
              </div>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "1000px",
                  gap: "30px",
                }}
              >
                <TextField
                  variant="outlined"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  label={itranslate("Subject")}
                  sx={{ flex: 1 }}
                  disabled={isButtonDisabled}
                />
                <Button
                  aria-label="send"
                  variant="contained"
                  color="primary"
                  disabled={!text || !subject}
                  onClick={() => {
                    if (text) {
                      handleChange("addComment", text, Date.now());
                    }
                  }}
                >
                  {mode === "editMessage" ? itranslate("Edit") : itranslate("Send")}
                </Button>
              </Box>

              <ReactQuill
                readOnly={ isButtonDisabled ? true : false}
                className="ql-msg-box-input"
                theme="snow"
                value={text}
                onChange={setText}
                formats={["bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"]}
              />

            </FormControl>
          )}
    </Card>
  );
};
