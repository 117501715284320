import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  ResourceContextProvider,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
} from "react-admin";
import { useTranslation } from 'react-i18next';

export const RecordLogEdit = () => {
  const { t : itranslate } = useTranslation();
  
  const EditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton 
          label={itranslate("Save")}
        />
      </Toolbar>
    );
  };

  return (
    <ResourceContextProvider value="records/list/logs">
      <Edit
        title={itranslate("Record")}
        redirect={(resource, id, data) => "record_management"}
      >
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput label={itranslate('Description')} source="description" sx={{ width: "100%" }} />
        </SimpleForm>
      </Edit>
    </ResourceContextProvider>
  );
};
