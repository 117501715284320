import React, { useEffect, useState, useRef } from 'react';
import {
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Button,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Pagination
} from "@mui/material"; 
import { useSelector, useDispatch } from 'react-redux';
import { changeModelsPage, changeModelstemsPerPage } from '../../redux/revoke/revokeSlice';
import keyToField from "../../utils/keyToField";
import { useTranslation } from 'react-i18next';


const selectProps = {
  sx: { mt: 2 },
  fullWidth: true,
  label: "operation",
};


const fields = {
  "Electric Inclined Lift": "InclinedLift",
  "Electric Traction Lift (Machine Room)": "ElectricTractionLiftMachineRoom", 
  "Electric Traction Lift (Machine Roomless)": "ElectricTractionLiftRoomless",
  "Hydraulic Lift": "HydraulicLift",
  "Stairlift": "StairLift",
  "Service Lift": "ServiceLift",
  "Mechanized Vehicle Parking System (MVPS)": "MVPS",
  "Vertical Lifting Platform": "ElectricalVerticalLiftingPlatform",
  "Escalator":"Escalator",
  "Passenger Conveyor":"PassengerConveyor",
  "Others": " Others"
};


const SelectModel = ({ setUpload, upload }) => {
  const dispatch = useDispatch();
  const { t : itranslate } = useTranslation();
  const { models, currentPage, itemsPerPage, totalItems } = useSelector((state) => state.revoke.models);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchQueryResult, setSearchQueryResult] = useState('');

  const totalModelsRef = useRef(totalItems);
  const [totalPages, setTotalPages] = useState(Math.ceil(totalModelsRef.current / itemsPerPage));

  // Calculate the indices of the objects to display based on the current page and items per page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage; 

  let allModels = searchQueryResult !== "" ? searchQueryResult : models;
  let sortedModels = allModels && [...allModels].sort((a, b) => a.model_code.localeCompare(b.model_code));  // sort by model_code
  const paginatedModels = sortedModels?.slice(startIndex, endIndex);  // slice the objects to display based on the current page and items per page


  useEffect(() => {
    setSelectedOptions(upload.model);
    setSelectAll(upload.model?.length === sortedModels?.length);
  }, [upload.model, sortedModels]);


  useEffect(() => {
    dispatch(changeModelsPage(1));
  
    if (searchQueryResult.length === 0) {
      totalModelsRef.current = totalItems;
    } else if (searchQueryResult.length > 0 && searchQueryResult !== "") {
      totalModelsRef.current = searchQueryResult.length;
    } else {
      totalModelsRef.current = 0;
    }

    setTotalPages(Math.ceil(totalModelsRef.current / itemsPerPage)); // Update totalPages
  }, [searchQueryResult, totalItems, itemsPerPage]);



  const handlePageChange = (e, newPage) => dispatch(changeModelsPage(newPage));

  const handleItemsPerPageChange = (newItemsPerPage) => dispatch(changeModelstemsPerPage(newItemsPerPage));
  

  const handleSearchChange = () => { 
    sortedModels = models;  // reset to all models when user press "enter" to search
  
    if (searchValue.trim() === '') {
      setSearchQueryResult(''); // Set the search query result to an empty string
    } else {
      let filteredModels = sortedModels.filter((model) => {
        const { model_code, provided_model_name, type, manufacturer_files } = model;
        const manufacturerName = manufacturer_files?.manufacturer_name || '';
  
        // filter the matched fields based on the search value. 
        // then using map() to extract the corresponding values into matchedValues
        const matchedValues = Object.entries(fields)
          .filter(([key, value]) => {
            return key.toLowerCase().includes(searchValue.toLowerCase().trim());
          })
          .map(([key, value]) => value);
  
        return (
          (model_code &&model_code.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (provided_model_name &&provided_model_name.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (type && type.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (manufacturerName && manufacturerName.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          // matchedValues.some() to check if any of the matched values are included in the "type" field.
          (matchedValues && matchedValues.some((matchedValue) => type.toLowerCase().includes(matchedValue.toLowerCase().trim())))
        );
      });
  
      setSearchQueryResult(filteredModels);
    }
  };


  const handleClearSearch = () => setSearchValue(''); // Clear the search field value


  const handleCheckboxChange = (e, id, modelCode, uniqueModelCode) => {
    const isChecked = e.target.checked;
    const newId = id + uniqueModelCode;

    setUpload((prevState) => {
      let updatedModel = [...prevState.model];

      if (isChecked) {
        updatedModel.push(newId);
      } else {
        updatedModel = updatedModel.filter((modelId) => modelId !== newId);
      };

      return {
        ...prevState,
        model: updatedModel,
      };
    });

    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => {
        return [...prevSelectedOptions, newId];
      });
    } else {
      setSelectedOptions((prevSelectedOptions) => {
        return prevSelectedOptions.filter((optionId) => optionId !== newId);
      });
    }
  };


  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    // console.log("isChecked", isChecked);

    setSelectedOptions(
      isChecked
        ? sortedModels.map((model) => model.id + model.manufacturer_files.unique_model_code)
        : []
    );

    setUpload((prevState) => ({
      ...prevState,
      model: isChecked
        ? sortedModels.map((model) => model.id + model.manufacturer_files.unique_model_code)
        : [],
    }));
  };
  

  return (
    <>
      <TextField
        sx={{ flex: 1, width: "40%" }}
        variant="filled"
        className="searchBox"
        // label="Search"
        placeholder={itranslate("Search")}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                sx={{
                  cursor: "pointer",
                  color: "#051B44",
                  fontSize: ".7em",
                  textTransform: "none",
                }}
                onClick={handleClearSearch} 
              >
                {/* Clear */}
                {itranslate("Clear")}
              </Button>
              <Button
                onClick={handleSearchChange}
                aria-label='search'
                variant="contained"
                sx={{
                  backgroundColor: "#2A598F",
                  textTransform: "none",
                  '&:hover': {
                    backgroundColor: "#203C5B",
                  },
                  fontSize: ".7em",
                }}
              >
                {/* Search */}
                {itranslate("Search")}
              </Button>
            </InputAdornment>
          ),
        }}

        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // console.log(e.target.value);
            handleSearchChange()
          }
        }}
      />

      {paginatedModels &&paginatedModels?.length > 0 && (
        <FormControl {...selectProps}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{width: "15%"}}> {itranslate("Model Code")} </TableCell>
                  <TableCell sx={{width: "15%"}}> {itranslate("Model Name")} </TableCell>
                  <TableCell sx={{width: "30%"}}> {itranslate("Type")} </TableCell>
                  <TableCell sx={{width: "30%"}}> {itranslate("Manufacturer Name")} </TableCell>
                  <TableCell sx={{width: "20%"}}> {itranslate("RC")} </TableCell>
                  <TableCell sx={{width: "10%"}} align="right">
                    {" "}
                    {/* Select All */}
                    {itranslate("Select All")}
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                      inputProps={{ "aria-label": "Select All" }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedModels && paginatedModels.map((model) => (
                  <TableRow key={model.id + model.manufacturer_files.unique_model_code}>
                    <TableCell>{model.model_code}</TableCell>
                    <TableCell>{model.provided_model_name}</TableCell>
                    <TableCell>{itranslate(keyToField[model.type])}</TableCell>
                    <TableCell>{model.manufacturer_files.manufacturer_name}</TableCell>
                    <TableCell>{model?.rcsPopulated[0]?.name + " / " + model?.rcsPopulated[0]?.address?.company}</TableCell>
                    <TableCell align="right">
                      <Checkbox
                        checked={selectedOptions.includes(model.id + model.manufacturer_files.unique_model_code)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            model.id,
                            model.model_code,
                            model.manufacturer_files.unique_model_code
                          )
                        }
                        inputProps={{"aria-label": model.provided_model_name}}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormControl>
      )}

      {/* Render pagination controls */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <p style={{ marginRight: '10px' }}> {itranslate("Rows per page:")}  </p>
          <Select
            variant='outlined'
            id="select-items-per-page"
            value={itemsPerPage}
            onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
            sx={{ minWidth: '40px', height: '30px'}}
          >
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="20">20</MenuItem>              
          </Select>
        </div>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
}


export default SelectModel;