import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import keyToField from "../../utils/keyToField.js";

const modelsExporter = (models) => {
  const modelsForExport = models.map((model) => {
    const modelForExport = {
      model_code: model.model_code,
      model_name: model.provided_model_name,
      manufacturer: model.manufacturersPopulated
        .map((manu) => manu.provided_name)
        .join(", "),
      type: keyToField[model.type],
    };

    if (window.location.pathname === "/lift_emsd") {
      modelForExport.rc = model.rcsPopulated[0].name;
      modelForExport.rated_speed = model.rated_speed;
      modelForExport.rated_load = model?.rated_load;
    }
    return modelForExport;
  });

  const emsdExport = [
    "model_code",
    "model_name",
    "manufacturer",
    "type",
    "rc",
    "rated_speed",
    "rated_load",
  ];

  const rcExport = ["model_code", "model_name", "manufacturer", "type"];

  jsonExport(
    modelsForExport,
    {
      headers: window.location.pathname === "/lift_rc" ? rcExport : emsdExport, // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "models"); // download as 'manufacturers.csv` file
    }
  );
};

export default modelsExporter;
