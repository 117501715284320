import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useCreate, useNotify } from "react-admin";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import getToken from "../../../utils/getAuthToken";
import {
  IconButton,
  ListItem,
  ListItemText,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';


function AppendixStore(props) {
  const { t : itranslate } = useTranslation();
  const { appendix, application, type } = props;
  const [appendixState, setAppendixState] = useState(null);
  const [create, { isLoading: uploadingFile }] = useCreate();

  const notify = useNotify();

  useEffect(() => {
    if (appendix && !appendixState) setAppendixState(appendix);
  }, [appendix]);

  const saveAppendix = (appendixClone, action, type) => {
    axios
      .post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps/appendix/${application.id}`,
        { appendix: appendixClone },
        {
          headers: { authorization: getToken() },
        }
      )
      .then((res) => {
        notify(action === "upload" ? itranslate("File uploaded") : itranslate("File deleted"));
        if (action === "upload") setAppendixState(res.data.appendix);
        else setAppendixState(res.data.appendix);
      })
      .catch((err) => {
        notify(itranslate(`Error saving file`));
      });
  };

  const onDrop = async (files, type) => {
    //type can be pdfFile and pdfFile2
    //pdfFile is the signed appendix
    //pdfFile2 is the approval letter
    try {
      const processFileAsync = (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const processedFile = Object.assign({}, file, {
              preview: URL.createObjectURL(file),
              base64Image: e.target.result,
            });
            resolve(processedFile);
          };
          reader.readAsDataURL(file);
        });
      };
      const getBase64 = await Promise.all(files.map(processFileAsync));

      const response = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/sign`,
        {
          PDFFile: getBase64[0].base64Image.split(",")[1],
          filename: getBase64[0].path,
        },
        {
          headers: { authorization: getToken() },
        }
      );
      console.log(response);
      const appendixClone = { ...appendixState };
      appendixClone[type] = response.data._id;
      saveAppendix(appendixClone, "upload", type);
    } catch (e) {
      notify(itranslate(`Error uploading file`));
    }
  };

  const downloadFile = async (id) => {
    window.open(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${id}?token=${getToken()}`);
  };

  const deleteFile = (type) => {
    confirmAlert({
      title: itranslate("Confirm to delete file"),
      message: itranslate("Are you sure to do this?"),
      buttons: [
        {
          label: itranslate("Yes"),
          onClick: () => {
            const appendixClone = { ...appendixState };
            appendixClone[type] = null;
            saveAppendix(appendixClone, "delete", type);
          },
        },
        {
          label: itranslate("No"),
        },
      ],
    });
  };

  if (!appendixState || !application) return null;
  return uploadingFile ? (
    <CircularProgress />
  ) : (
    <Box>
      {[appendixState.pdfFile, appendixState.pdfFile2].map((doc, index) =>
        doc ? (
          <ListItem
            key={index}
            sx={{
              py: 0,
              px: 1,
              ml: 2,
              mb: 2,
              border: "1px solid rgba(118, 118, 118, 0.3)",
              backgroundColor: "#efffe5",
              maxWidth: "300px",
            }}
            secondaryAction={
              <>
                <IconButton onClick={() => downloadFile(doc)}>
                  <DownloadIcon />
                </IconButton>
                {type === "edit" && (
                  <IconButton
                    onClick={() =>
                      deleteFile(index === 0 ? "pdfFile" : "pdfFile2")
                    }
                    size="small"
                    sx={{ p: 0 }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </>
            }
          >
            <ListItemText
              primary={index === 0 ? itranslate("Appendix") : itranslate("Approval letter")}
            />
          </ListItem>
        ) : (
          type === "edit" && (
            <Dropzone
              key={index}
              onDrop={(acceptedFiles) =>
                onDrop(acceptedFiles, index === 0 ? "pdfFile" : "pdfFile2")
              }
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  sx={{
                    py: 0,
                    px: 1,
                    ml: 2,
                    mb: 2,
                    border: "3px dashed lightgray",
                    maxWidth: "300px",
                    cursor: "pointer",
                    boxSizing: "border-box",
                  }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Typography>
                      {" "}
                      {index === 0
                        ? itranslate("Appendix upload")
                        : itranslate("Approval letter upload")}
                    </Typography>
                  </div>
                </Box>
              )}
            </Dropzone>
          )
        )
      )}
    </Box>
  );
}

export default AppendixStore;
