import React, { useState, useEffect } from "react";
import { Box, Divider, Button} from "@mui/material";
import DerivedHideManufIdsSC from "./DerivedHideManufIdsSC";
import _ from "lodash";
import { useTranslation } from 'react-i18next';


const DerivedAppendixForSCeditInput = ({
  application,
  appendix,
  setAppendix,
  certificates,
  section,
  simpleDisplay,
  manufacturers,
  components,
  standalones,
  setCompLevel
}) => {
  // console.log("🚀 ~ appendix:", appendix)
  const { t : itranslate } = useTranslation();

  const [hideAll, setHideAll] = useState(false);

  const [comps, setComps] = useState({})
  // console.log("🚀 ~ comps:", comps)

  const [allComponentTypes, setAllComponentTypes] = useState([]) ;
  // console.log("🚀 ~ allComponentTypes:", allComponentTypes)


  useEffect(() => {
    function getComps () {
      const componentTypes = _.uniq([
        ...Object.keys(application.app_type?.components || {}),
        ...standalones.components.map((c) => c.type),
      ]);
    
      const allComps = componentTypes.reduce((acc, type) => {
        const historicalComp = standalones.components.filter(
          (comp) => comp.type === type
        );
        const newComp = application.app_type?.components[type] || [];
        acc[type] = [...historicalComp, ...newComp];
        return acc;
      }, {});
      setComps(allComps)
    }
    getComps();
  }, [])



  useEffect(() => {
    function getComponentTypes() {
      let CompTypes = [];

      // Object.keys(comps?.app_type?.components || {})
      comps && Object.keys(comps || {})
        .filter(
          (component_type) =>
            !["TractionMachine", "Controller"].includes(component_type)
        )
        .sort((a, b) => {
          const order = [
            "Buffer",
            "OverspeedGovernor",
            "LandingDoorLockingDevice",
            "CarDoorLockingDevice",
            "SafetyGear",
            "ACOP",
            "UCMP",
            "SafetyCircuitElectricalComponent",
          ];
          function customSort(item) {
            const index = order.indexOf(item);
            return index !== -1 ? index : order.length;
          }
          return customSort(a) - customSort(b);
        })
        .forEach((component_type, j) => {
          CompTypes.push(component_type);
        });

      setAllComponentTypes(CompTypes);
    }

    getComponentTypes();
  }, [comps]); 




  

  // const comps = standalones.components.reduce(
  //   (result, historicalComp, index) => {
  //     const compType = historicalComp.type;

  //     // combining components from the new application
  //     if (index === 0) {
  //       for (const newCompType in application.app_type?.components) {
  //         const newComponents = application.app_type?.components?.[newCompType] || [];
  //         result[newCompType] = result[newCompType] || [];
  //         result[newCompType].push(...newComponents);
  //       }
  //     }

  //     // Initialize the type array
  //     result[compType] = result[compType] || [];

  //     // Add the current standalone component to the array
  //     result[compType].push(historicalComp);

  //     return result;
  //   },
  //   {}
  // );




  // extract component ids
  let comp_ids = [];

  for (let key in comps) {
    if (comps.hasOwnProperty(key)) {
      let compList = comps[key];
      for (let i = 0; i < compList.length; i++) {
        comp_ids.push(compList[i]._id);
      }
    }
  };


  // const handleHideAll = () => {
  //   setAppendix({ ...appendix, hideCerts: comp_ids });
  //   setHideAll(true);
  // };

  // const handleUnhideAll = () => {
  //   setAppendix({ ...appendix, hideCerts: [] });
  //   setHideAll(false);
  // } 



  const handleHideAll = () => {  
    const unique = [];
  
    comp_ids.forEach((cert_id) => {
      allComponentTypes.forEach((component_type) => {
        unique.push({
          component_type: component_type,
          cert_id: cert_id,
        });
      });
    });
  
    setAppendix({ ...appendix, hideSCCerts: unique });
    setHideAll(true);
  };


  const handleUnhideAll = () => {
    let section = structuredClone(appendix["hideSCCerts"]);
    section = [];
    setAppendix({ ...appendix, hideSCCerts: section });
    setHideAll(false);
  };
   


  const order = [
    "Buffer",
    "OverspeedGovernor",
    "LandingDoorLockingDevice",
    "CarDoorLockingDevice",
    "SafetyGear",
    "ACOP",
    "UCMP",
    "SafetyCircuitElectricalComponent",
  ];

  function customSort(item) {
    const index = order.indexOf(item);
    return index !== -1 ? index : order.length;
  }

  return (
    <>
    <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
      <Button onClick={hideAll ? handleUnhideAll : handleHideAll}>
        {hideAll ? itranslate("Unhide All") : itranslate("Hide All")}
      </Button> 
    </Box>

    <Box sx={{ px: 1 }}>
      {section === "hideCertificates" &&
        Object.keys(comps || {})
          .sort((a, b) => customSort(a) - customSort(b))
          .map((component_type, j) => {
            return (
              <div key={`${component_type}-C`}>
                <DerivedHideManufIdsSC
                  application={application}
                  appendix={appendix}
                  setAppendix={setAppendix}
                  certificates={certificates}
                  section={section}
                  manufacturers={manufacturers}
                  components={components}
                  component_type={component_type}
                  standalones={standalones}
                  comps={comps}
                  setCompLevel={setCompLevel}
                />
                <Divider />
              </div>
            );
          })}
    </Box>
    
    </>

  );
};

export default DerivedAppendixForSCeditInput;
