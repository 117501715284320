import { useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
  Paper,
  CircularProgress
} from "@mui/material";
import { Close, ZoomIn, ZoomOut } from '@mui/icons-material';
// import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// const workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// // const workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

// pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;


import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const ShowPDF = ({ pdfFile, openPDF, setOpenPDF  }) => {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.5);

  const handleZoomIn = () => setScale((prevScale) => prevScale + 0.2);
  const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.2, 0.6));


  return (
    <Modal
      open={openPDF}
      sx={{
        overflowY: 'scroll',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '50px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '90vw', // Increased background width
          height: '100vh',
          // backgroundColor: '#f0f0f0',
          backgroundColor: '#ffffff',
          // backgroundColor: 'hsl(50, 10%, 80%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '10px',
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            // marginBottom: '5px', // Margin between buttons and PDF
          }}
        >
          <IconButton
            sx={{
              backgroundColor: '#ffffffb8',
            }}
            aria-label="zoom out"
            onClick={handleZoomOut}
          >
            <ZoomOut sx={{ fontSize: '30px' }} />
          </IconButton>

          <IconButton
            sx={{
              backgroundColor: '#ffffffb8',
              marginRight: '10px',
            }}
            aria-label="zoom in"
            onClick={handleZoomIn}
          >
            <ZoomIn sx={{ fontSize: '30px' }} />
          </IconButton>

        </Box>


        <IconButton
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: 10000,
            backgroundColor: '#ff234ab8',
          }}
          aria-label="close pdf modal"
          onClick={() => setOpenPDF(false)}
        >
          <Close sx={{ fontSize: '40px' }} />
        </IconButton>


        <Box
          sx={{
            overflowY: 'auto',
            display: 'flex',
            justifyContent: 'center', // Center the PDF
            width: '100%',
            height: '100%',
            padding: '10px',
          }}
        >
          <Document
            file={pdfFile}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            loading={<CircularProgress />}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Box
                key={`page_${index + 1}`}
                sx={{
                  marginBottom: '5px',
                  border: '3px solid #ccc',
                  padding: '10px',
                  backgroundColor: '#fff',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)', // Added shadow
                }}
              >
                <Page pageNumber={index + 1} scale={scale} loading={<CircularProgress />} />
              </Box>
            ))}
          </Document>
        </Box>

      </Box>
    </Modal>
  );

};

export default ShowPDF;
