import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { visuallyHidden } from '@mui/utils';


import BasicModal from '../../../_hoc/modals/BasicModal';

import moment from 'moment';

import _ from 'lodash'

import { styled } from '@mui/material/styles';

// react-admin
import {useLogout} from "react-admin";

// components
import EnhancedTableHead from "../../../_components/CompRestrictedUrlMgt/CompEnhancedTable/EnhancedTableHead";
import EnhancedTableToolbar from "../../../_components/CompRestrictedUrlMgt/CompEnhancedTable/EnhancedTableToolbar";
import CompExpiryDate from "../../../_components/CompRestrictedUrlMgt/CompExpiryDate/index"

// hooks
import { useNavigate, useLocation } from 'react-router-dom';

// custom hooks
import useRestrictedUrl from "../../../admin/useHooks/restrictedURL/useRestrictedURL";
import { setOption } from '../../redux/optionSlice';
import { setStage } from '../../redux/stageSlice';
import { useDispatch, useSelector } from 'react-redux';
import {decodeJwt} from "../../useHooks/useJwt/jwt"
import { type } from '@testing-library/user-event/dist/type';
import CircularProgressHoc from '../../../_hoc/Spinners/CircularProgressHoc';

// import { useTranslate } from 'react-admin';
import { useTranslation } from 'react-i18next';


const CopyBtn = styled(Button)(({ theme }) => ({
    backgroundColor: "#121212",
    color: "white",
    '&:hover': {
        backgroundColor: "#121212",
    },
}));

const ViewBtn = styled(Button)(({ theme }) => ({
    backgroundColor: "#0288d1",
    color: "white",
    '&:hover': {
        backgroundColor: "#01579b",
    },
}));

const DuplicateBtn = styled(Button)(({ theme }) => ({
    backgroundColor: "#f50057",
    color: "white",
    '&:hover': {
        backgroundColor: "#ab003c",
    },
}));

const ExpiryBtn = styled(Button)(({ theme }) => ({
    backgroundColor: "#339388",
    color: "white",
    '&:hover': {
        backgroundColor: "#00796b",
    },
}));


export default function EnhancedTable() {
    // const translate = useTranslate();
    const { t : itranslate } = useTranslation();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('client_user');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [ openAlertModal, setOpenAlertModal]= useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [totalCount, setTotalCount] = useState(0)
    const [range, setRange] = useState("[0, 4]")
    const [filter, setFilter] = useState("{}")
    const [sort, setSort] = useState(`["createdAt", "DESC"]`)

    const { data, error, isLoading, delRuInfo, updateExpiryDate } = useRestrictedUrl(filter, range, sort);
    const [rows, setRows] = useState([])

    // ! do I need to implement eliminate all the session storage of create url process?

    // for CompExpiryDate
    const [open, setOpen] = useState(false);
    const [ruId, setRuId] = useState(null)

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const logout = useLogout();

    // initialize
    let query = useQuery();
    useEffect(() => {
        setRange(query.get("range"))
        setFilter(query.get("filter"))
        setSort(query.get("sort"))
    }, [])


    // setRange when page changes
    useEffect(() => {
        setRange(`[${page * rowsPerPage},${page * rowsPerPage + rowsPerPage - 1}]`)
        setSelected([])
    }, [page, rowsPerPage])

    // setSort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort(`["${property}","${isAsc ? "DESC" : "ASC"}"]`)
    };


    // handle query value update
    useEffect(() => {
        navigate(`/restrictedUrls?filter=${filter}&range=${range}&sort=${sort}`)
    }, [filter, range, sort])


    useEffect(() => {
        if (!isLoading) {
            setRows(data?.ruResults)
            setTotalCount(data?.totalCount)
            if(_.isEmpty(data?.ruResults) && !_.isEmpty(error)){
                console.log(error);
            }
        }

        // e.g.
        // change to 1st page after deletion makes ruResults empty
        if (data?.ruResults?.length === 0) {
            setRange(`[0,4]`)
            setSort(`{"createdAt": "DESC"}`)
            setPage(0)
        }

        if (error) {
            console.log(error)
            setRows([])
            if(error.response.status === 401 && error?.response?.statusText === "Unauthorized") {
                logout()
            }
        }
    }, [data, isLoading, error])

    // create ru
    const handleCreateUrl = () => {
        decodeJwt().then((res) => {
            if(!res?.rc?._id){
                setOpenAlertModal(true)
            } else {
                navigate('/createRestrictedUrl', { state: {stage: '1'} });
            }
        })
    };

    // del
    const handleDelete = (selected_ids) => {
        delRuInfo(selected_ids).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setSelected([])
        })
        console.log(selected_ids)
    };

    // seletAll rows
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    // select one
    const handleClick = (event, _id) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    // setPage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // set rowsPerPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, rowsPerPage - rows?.length) : 0;


    // row btn handlers 4 btns
    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
    }

    const handleView = (rowInfo) => {
        navigate(`/ruInfoShow/${rowInfo._id}`);
    }

    const handleDuplicate = (rowInfo) => {
        decodeJwt().then((res) => {
            if(!res?.rc?._id){
                setOpenAlertModal(true)
            } else {
                dispatch(setOption(""))
                dispatch(setStage(""))
                navigate(`/createRestrictedUrl`, { state: {duplicatedData: rowInfo, stage: 'more options?', dataType: "duplicate"} });
            }
        })
    }

    const handleExpiry = (ru_id) => {
        setRuId(ru_id)
        setOpen(true)
    }

    return (
        <Container component="main" maxWidth="xl" sx={{ my: 4 }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleCreateUrl}
                // aria-label={translate('phase2.ru.createRu')}
                aria-label={itranslate('Create Restricted Url')}

            >
                {/* {translate('phase2.ru.createRu')} */}
                {itranslate('Create Restricted Url')}
            </Button>

            <Box sx={{ 
                    width: '100%',
                    boxShadow: "0 0 0 2rem rgba(246,248,249)",
                }}
            >
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar
                        selected={selected}
                        numSelected={selected.length}
                        handleDelete={handleDelete}
                        setFilter={setFilter}
                    />

                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size='medium'
                        >
                            {
                                !isLoading && rows?.length > 0 &&
                                (
                                    <>
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows?.length}
                                        />

                                        <TableBody>
                                            {rows?.length > 0 && rows.map((row, index) => {
                                                const isItemSelected = isSelected(row._id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                let createdDate = new Date(row.createdAt);
                                                let localCreatedDate = createdDate.toString()

                                                let expiryDate = new Date(row.expiry_date);
                                                let localExpiryDate = expiryDate.toString()
                                                let today = new Date(Date.now())
                                                let expired = moment(expiryDate).isBefore(today)
                                                return (
                                                    <Tooltip
                                                        key={row._id}
                                                        title={
                                                            expired ? `${itranslate('url for')} ${row.client_user} ${itranslate("is expired")}` : ''
                                                        }
                                                        placement="top"
                                                        arrow
                                                        followCursor
                                                    >
                                                        <TableRow
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{'aria-labelledby': labelId,}}
                                                                    onClick={(event) => handleClick(event, row._id)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                    aria-checked={isItemSelected}
                                                                />
                                                                <Box component="span" id={labelId} sx={visuallyHidden}>
                                                                    {isItemSelected ? itranslate("selected") : itranslate("not yet selected")}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                onClick={(event) => handleClick(event, row._id)}
                                                                sx={{ cursor: 'pointer' }}
                                                            >
                                                                {row.client_user}
                                                            </TableCell>

                                                            <Tooltip
                                                                title={row.remark.length > 20 ? row.remark : ''}
                                                                placement="bottom"
                                                                arrow
                                                                followCursor
                                                            >
                                                                <TableCell
                                                                    sx={{ 
                                                                        cursor: 'pointer', 
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        maxWidth: "8em"
                                                                    }}
                                                                    onClick={(event) => handleClick(event, row._id)}
                                                                >{row.remark}</TableCell>
                                                            </Tooltip>

                                                            <TableCell
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={(event) => handleClick(event, row._id)}
                                                            >{row.createdAt && localCreatedDate.split(" ")[1] + ' ' + localCreatedDate.split(" ")[2] + ' ' + localCreatedDate.split(" ")[3]}
                                                            </TableCell>


                                                            <TableCell
                                                                sx={{ cursor: 'pointer' }}
                                                                onClick={(event) => handleClick(event, row._id)}
                                                            >
                                                                {
                                                                    row.expiry_date && localExpiryDate.split("GMT")[0]
                                                                }
                                                            </TableCell>

                                                            <TableCell
                                                                size="small"
                                                            >
                                                                {/* view btn */}
                                                                <ViewBtn
                                                                    size='small'
                                                                    sx={{ mr: "0.5rem", mb: "0.5rem",  width: "5rem" }} //padding: "14px 0px"
                                                                    onClick={() => handleView(row)}
                                                                    aria-label={`view ${row?.ru_no}`}
                                                                >
                                                                    {/* {translate(`phase2.ru.tableBtn.View`)} */}
                                                                    {itranslate(`View`)}
                                                                </ViewBtn>

                                                                {/* copy btn */}
                                                                <CopyBtn
                                                                    size="small"
                                                                    sx={{ mr: "0.5rem", mb: "0.5rem",  width: "5rem" }}
                                                                    onClick={() => handleCopy(`${window.location.origin}/verifyRecord/record/ru/${row.encrypted_ruNo}`)}
                                                                    aria-label={`copy ${row?.ru_no} url`}
                                                                >
                                                                    {/* {translate(`phase2.ru.tableBtn.Copy`)} */}
                                                                    {itranslate(`Copy Link`)}
                                                                </CopyBtn>

                                                                {/* duplicate btn */}
                                                                <DuplicateBtn
                                                                    size="small"
                                                                    sx={{ mr: "0.5rem", mb: "0.5rem",  width: "5rem" }}
                                                                    onClick={() => handleDuplicate(row)}
                                                                    aria-label={`duplicate ${row?.ru_no}`}
                                                                >
                                                                    {/* {translate(`phase2.ru.tableBtn.Duplicate`)} */}
                                                                    {itranslate(`Duplicate`)}
                                                                </DuplicateBtn>

                                                                {/* expiry_date btn */}
                                                                <ExpiryBtn
                                                                    size="small"
                                                                    sx={{ mr: "0.5rem", mb: "0.5rem",  width: "5rem" }}
                                                                    onClick={() => handleExpiry(row._id)}
                                                                    aria-label={`extend ${row?.ru_no} url expiry date`}
                                                                >
                                                                    {/* {translate(`phase2.ru.tableBtn.Extend`)} */}
                                                                    {itranslate(`Extend`)}
                                                                </ExpiryBtn>

                                                            </TableCell>
                                                        </TableRow>
                                                    </Tooltip>

                                                );
                                            })}

                                            {/* fill up the empty rows for last page's items of the list */}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: 60 * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}

                                        </TableBody>
                                    </>
                                )
                            }
                        </Table>
                    </TableContainer>

                    {/* show if no data found */}
                    {!isLoading && rows?.length === 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <h1 className='text-2xl my-12'>
                                    {/* No restricted url found */}
                                    {itranslate("No restricted url found")}
                                </h1>
                            </div>
                        </Box>
                    )}

                    {
                        (!isLoading && rows?.length !== 0) && (
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={itranslate("Rows per page")}
                            />
                        )
                        
                    }

                    {/* spinner */}
                    {
                        isLoading && <CircularProgressHoc isLoading={isLoading} />
                    }

                </Paper>
            </Box>


            <CompExpiryDate
                ru_id={ruId}
                open={open}
                setOpen={setOpen}
                updateExpiryDate={updateExpiryDate}
            />

            {openAlertModal && (
                <BasicModal
                    open={openAlertModal}
                    setOpen={setOpenAlertModal}
                    title="Alert"
                >
                    {/* only RC can create restricted urls */}
                    {itranslate("only RC can create restricted urls")}
                </BasicModal>
            )}
        </Container>

    );
}