import React, { useState } from "react";
import {
  Popper,
  IconButton,
  Paper,
  Grid,
  CircularProgress,
  Typography,
  Divider,
  ClickAwayListener,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useGetOne } from "react-admin";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import keyToField from "../../utils/keyToField";
import { useTranslation } from 'react-i18next';


const PreviewSc = (props) => {
  const { t : itranslate } = useTranslation();
  const { id } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const { data: res } = useGetOne(
    "models/previewsc",
    { id: id },
    { enabled: Boolean(anchorEl) }
  );
  //enabled to ensure it does not call when the popper is not open

  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <IconButton
          size="small"
          onClick={handleToggle}
          aria-describedby={anchorEl ? "mouse-enter-popper" : undefined}
        >
          <HelpOutlineIcon />
        </IconButton>
        <Popper
          sx={{ zIndex: 2 }}
          id="mouse-enter-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="left-start"
          disablePortal
        >
          {({ TransitionProps }) => (
            <Paper
              sx={{ p: 3, maxHeight: 800, maxWidth: "70vw", overflow: "auto" }}
            >
              {!res ? (
                <CircularProgress />
              ) : (
                <TableContainer>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {/* Safety Components */}
                    {itranslate("Safety Components")}
                  </Typography>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {/* Type */}
                          {itranslate('Type')}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {/* Component Name */}
                          {itranslate('Component Name')}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {/* Manufacturers */}
                          {itranslate('Manufacturers')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {Object.keys(res?.components || {})
                        .sort((a, b) => {
                          const order = [
                            "SafetyGear",
                            "Buffer",
                            "OverspeedGovernor",
                            "LandingDoorLockingDevice",
                            "CarDoorLockingDevice",
                            "ACOP",
                            "UCMP",
                            "SafetyCircuitElectricalComponent",
                            "OneWayValve",
                            "RaptureValve",
                            "Buffer",
                          ];
                          function customSort(item) {
                            const index = order.indexOf(item);
                            return index !== -1 ? index : order.length;
                          }
                          return customSort(a) - customSort(b);
                        })
                        .map((type) =>
                          res.components[type].map((comp, compIndex, compList) => {
                              return (
                                <TableRow key={comp._id}>
                                  {compIndex === 0 && (
                                    <TableCell
                                      rowSpan={compList.length}
                                      sx={{ verticalAlign: "text-top" }}
                                    >
                                      {/* {keyToField[type]} */}
                                      {itranslate(`${keyToField[type]}`)}
                                    </TableCell>
                                  )}
                                  <TableCell>
                                    {comp.provided_comp_name}
                                  </TableCell>
                                  <TableCell>
                                    {comp?.manufacturer_id?.provided_name}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default PreviewSc;
