const failedClarifyingValidation = (upload, clarifying) => {
  //EMSD require for confirm button in clarifying
  //Assumed when edit.clarifying === "new", do this check
  //automatically success when other scenario
  const allComponentConfirmed = Object.values(upload.app_type.components).every(
    (componentArray) => componentArray.every((comp) => comp.confirmed)
  );
  return clarifying === "new" ? !allComponentConfirmed : false;
};

export default failedClarifyingValidation;
