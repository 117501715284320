import { Box } from "@mui/material";
import { useState } from "react";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function PdfModal({ pdfUrl }) {
  const [numPages, setNumPages] = useState(null); // Initialize numPages as null

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      {/* <Document
        file={pdfUrl}
        onLoadSuccess={() => setNumPages(5)}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => (
            <Page pageNumber={page} key={page} />
          ))}
      </Document> */}

      <Document 
        file={pdfUrl} 
        onLoadSuccess={({ numPages }) => setNumPages(Math.min(numPages, 5))}  // Limit the number of pages to 5
      >
        {numPages &&
          Array.from({ length: numPages }, (_, index) => index + 1).map(
            (pageNumber) => (
              <Page key={pageNumber} pageNumber={pageNumber} />
            )
          )}
      </Document>
    </Box>
  );
}

export default PdfModal;
