import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import keyToField from "../../utils/keyToField.js";

const applicationsExporter = (applications) => {
  const applicationsForExport = applications.map((application) => {
    const applicationForExport = {
      application_number: application.application_number,
      type_of_application: application.update_type ?? "Brand New Application",
      type_of_machine: keyToField[application.app_type.type],
      status: application.status || keyToField[application.validationStatus],
      models: (application.modelsPopulated.length > 0
        ? application.modelsPopulated
        : application.model
      )
        .map((model) => model.provided_model_name)
        .join(", "),
      application_date: application.created_at,
    };

    if (window.location.pathname === "/lift_emsd") {
      applicationForExport.rc = application.rcsPopulated[0].name;
    }
    return applicationForExport;
  });

  const emsdExport = [
    "application_number",
    "type_of_application",
    "type_of_machine",
    "status",
    "models",
    "application_date",
    "rc",
  ];

  const rcExport = [
    "application_number",
    "type_of_application",
    "type_of_machine",
    "status",
    "models",
    "application_date",
  ];

  jsonExport(
    applicationsForExport,
    {
      headers: window.location.pathname === "/lift_rc" ? rcExport : emsdExport, // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "applications"); // download as 'manufacturers.csv` file
    }
  );
};

export default applicationsExporter;
