import { useEffect, useState } from "react";
import { required } from "react-admin";
import { SelectArrayInput } from "react-admin";
import {
  SelectInput,
  SimpleForm,
  TextInput,
  DateField,
  Datagrid,
  List,
  TextField,
  Create,
  Edit,
  EditButton,
  ArrayInput,
  SimpleFormIterator,
  useDataProvider,
  useNotify,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import EngineerComponent from "../components/EngineerComponent";
import { useTranslation } from 'react-i18next';


const roles = ["Permanent Role", "Temp Role"];
  // const roles = [`${itranslate("team.Permanent Role")}`, `${itranslate("team.Temp Role")}`,];

const applicationTypes = [
  "ElectricTractionLiftMachineRoom",
  "ElectricTractionLiftRoomless",
  "HydraulicLift",
  "StairLift",
  "ServiceLift",
  "Escalator",
  "PassengerConveyor",
  "MVPS",
  "SafetyComponent",
  "ElectricalVerticalLiftingPlatform",
  "InclinedLift",
];

export const TeamShow = () => {
  return null;
};

export const TeamList = () => {
  const { t : itranslate } = useTranslation();

  return (
    // <List title={`- Teams`}>
    <List title={`- ${itranslate('team.Teams')}`}>
      <Datagrid bulkActionButtons={false}>
        <DateField 
          // label="Created At" 
          label={itranslate('team.Created At')}
          source="created_at" 
        />
        <TextField 
          // label="Name" 
          label={itranslate('team.Name')}
          source="name" 
        />
        <TextField 
          // label="Description" 
          label={itranslate('team.Description')}
          source="description" 
        />
        <EngineerComponent 
          // label="Chief Engineer" 
          label={itranslate('team.Chief Engineer')}
          source="chiefEngineer" 
        />
        <EngineerComponent 
          // label="Senior Engineer"
          label={itranslate('team.Senior Engineer')}
          source="seniorEngineers" 
        />
        <EngineerComponent 
          // label="Engineer(s)" 
          label={itranslate('team.Engineer(s)')}
          source="engineers" 
        />
        {/* <EditButton label="Edit" basePath="/team" /> */}
        <EditButton basePath="/team" sx={{ padding: '0' }} />
      </Datagrid>
    </List>
  );
};



export const TeamEdit = (props) => {
  const { t : itranslate } = useTranslation();
  const dataProvider = useDataProvider();
  const [engineers, setEngineers] = useState([]);
  const [seniorEngs, setSeniorEngs] = useState([]);
  const [chiefEngs, setChiefEngs] = useState([]);
  const notify = useNotify();
  const navigate = useNavigate();
  

  const combinedChiefEngs = chiefEngs.concat(seniorEngs);
  const combinedSeniorEngs = seniorEngs.concat(engineers, chiefEngs);
  const combinedEngineers = engineers.concat(seniorEngs);


  const fetchData = async () => {
    try {

      const { data } = await dataProvider.getList("users/engineers", {
        pagination: { page: 1, perPage: null },
      });

      const engineersList = data?.filter((user) => user.role === "EMSDEngineer");

      const seniorEngsList = data?.filter((user) => user.role === "EMSDSE");
      const chiefEngsList = data?.filter((user) => user.role === "EMSDCE");
      setEngineers(engineersList);
      setSeniorEngs(seniorEngsList);
      setChiefEngs(chiefEngsList);
    } catch (error) {
      console.error(error);
    }
  };


  const handleEdit = async (values) => {
    try {
      const editedTeam = await dataProvider.update("team", { data: values });
      // notify("Team edited successfully", "success");
      notify(itranslate("team.Team edited successfully"), "success");
      navigate(`/team`);
    } catch (error) {
      if (error?.response?.status === 409) {
        error?.response?.data && notify(`${error?.response?.data}`, "error");
      } else {
        notify(itranslate("team.Error editing team"), "error");
      }
    }
  };


  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Edit {...props}>
      <SimpleForm onSubmit={handleEdit}>
        <TextInput
          source="name"
          // label="Name"
          label={itranslate("team.Name")}
          validate={required(itranslate("team.Please add a name for this team"))}
        />
        <TextInput
          source="description"
          // label="Description"
          label={itranslate("team.Description")}
          validate={required(itranslate("team.Please add a description for this team"))}
        />


        <ArrayInput
          source="chiefEngineer"
          // label="Chief Engineer"
          label={itranslate("team.Chief Engineer")}
          sx={{
            margin: 1,
            padding: 1,
          }}
        >
          <SimpleFormIterator>
            <TextInput
              source="role_name"
              // label="Role Name"
              label={itranslate("team.Role Name")}
              validate={required(itranslate("team.Please add a name for this role"))}
            />
            <SelectInput
              // label={`Role User: `}
              label={`${itranslate("team.Role User")}: `}
              source="default_ce_username"
              choices={combinedChiefEngs.map((eng) => ({
                id: eng.username,
                name: eng.username,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              validate={required(itranslate("team.Please select a user for this role"))}
            />
            <SelectInput
              // label={`Double Role: `}
              label={`${itranslate("team.Double Role")}: `}
              source="ce_role_level"
              choices={roles
                .filter((eng) => eng !== "")
                // .map((eng) => ({ id: eng, name: eng }))}
                .map((eng) => ({ id: eng, name: itranslate(`team.${eng}`) }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              defaultValue={roles[0]}
              validate={required(itranslate("team.Please select a user for this role"))}
            />
            {/* <SelectArrayInput
              label={`Special Application (If any): `}
              source="special_application_type"
              choices={applicationTypes.map((eng) => ({
                id: eng,
                name: eng,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
            /> */}
          </SimpleFormIterator>
        </ArrayInput>


        <ArrayInput
          source="seniorEngineers"
          // label="Senior Engineer"
          label={itranslate("team.Senior Engineer")}
          sx={{
            margin: 1,
            padding: 1,
          }}
        >
          <SimpleFormIterator>
            <TextInput
              source="role_name"
              // label="Role Name"
              label={`${itranslate("team.Role Name")}: `}
              validate={required(itranslate("team.Please add a name for this role"))}
            />
            <SelectInput
              // label={`Role User: `}
              label={`${itranslate("team.Role User")}: `}
              source="default_se_username"
              choices={combinedSeniorEngs.map((eng) => ({
                id: eng.username,
                name: eng.username,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              validate={required("Please add a user for this role")}
            />
            <SelectInput
              // label={`Double Role: `}
              label={`${itranslate("team.Double Role")}: `}
              source="se_role_level"
              choices={roles
                .filter((eng) => eng !== "")
                // .map((eng) => ({ id: eng, name: eng }))}
                .map((eng) => ({ id: eng, name: itranslate(`team.${eng}`) }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              defaultValue={roles[0]}
              validate={required("Please select a role")}
            />
            {/* <SelectArrayInput
              label={`Special Application (If any): `}
              source="special_application_type"
              choices={applicationTypes.map((eng) => ({
                id: eng,
                name: eng,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
            /> */}
          </SimpleFormIterator>
        </ArrayInput>


        <ArrayInput
          source="engineers"
          // label="Engineers"
          label={itranslate("team.Engineers")}
          sx={{
            margin: 1,
            padding: 1,
          }}
        >
          <SimpleFormIterator>

            <TextInput
              source="role_name"
              // label="Role Name"
              label={itranslate("team.Role Name")}
              // validate={required("Please add a name for this role")}
              validate={required(itranslate("team.Please add a name for this role"))}
            />

            <SelectInput
              // label={`Role User: `}
              label={`${itranslate("team.Role User")}: `}
              source="default_en_username"
              choices={combinedEngineers.map((eng) => ({
                id: eng.username,
                name: eng.username,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              validate={required("Please add a user for this role")}
            />

            <SelectInput
              // label={`Double Role: `}
              label={`${itranslate("team.Double Role")}: `}
              source="en_role_level"
              choices={roles
                .filter((eng) => eng !== "")
                // .map((eng) => ({ id: eng, name: eng }))}
                .map((eng) => ({ id: eng, name: itranslate(`team.${eng}`) }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              defaultValue={roles[0]}
              validate={required(itranslate("team.Please select a role"))}
            />

            <SelectArrayInput
              // label={`Special Application (If any): `}
              label={`${itranslate(`team.Special Application (If any)`)}`}
              source="special_application_type"
              choices={applicationTypes.map((eng) => ({
                id: eng,
                // name: eng,
                name: itranslate(`team.${eng}`)
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
            />

          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};




export const TeamCreate = (props) => {
  const { t : itranslate } = useTranslation();

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const navigate = useNavigate();
  const [engineers, setEngineers] = useState([]);
  const [seniorEngs, setSeniorEngs] = useState([]);
  const [chiefEngs, setChiefEngs] = useState([]);

  const combinedChiefEngs = chiefEngs.concat(seniorEngs);
  const combinedSeniorEngs = seniorEngs.concat(engineers, chiefEngs);
  const combinedEngineers = engineers.concat(seniorEngs);


  const handleCreate = async (values) => {
    try {
      values.chiefEngineer.forEach((ce) => {
        if (ce.special_application_type === "") {
          delete ce.special_application_type;
        }
      });

      values.seniorEngineers.forEach((se) => {
        if (se.special_application_type === "") {
          delete se.special_application_type;
        }
      });

      values.engineers.forEach((eng) => {
        if (eng.special_application_type === "") {
          delete eng.special_application_type;
        }
      });

      const createdTeam = await dataProvider.create("team", { data: values });
      notify(itranslate("team.Team created successfully"), "success");
      navigate(`/team`);
    } catch (error) {
      if (error?.response?.status === 409) {
        if (error.response.data && error.response.data.error) {
          notify(`${error.response.data.error}`, "error");
        } else {
          notify(`${error.response.data}`, "error");
        }
      } else {
        notify(itranslate("team.Error creating team"), "error");
      }
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await dataProvider.getList("users/engineers", {
        pagination: { page: 1, perPage: null },
      });

      const engineersList = data?.filter((user) => user.role === "EMSDEngineer");

      const seniorEngsList = data?.filter((user) => user.role === "EMSDSE");
      const chiefEngsList = data?.filter((user) => user.role === "EMSDCE");
      setEngineers(engineersList);
      setSeniorEngs(seniorEngsList);
      setChiefEngs(chiefEngsList);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleCreate}>
        <TextInput
          source="name"
          // label="Name"
          label={itranslate("team.Name")}
          // validate={required("Please add a name for this team")}
          validate={required(itranslate("team.Please add a name for this team"))}
        />
        <TextInput
          source="description"
          // label="Description"
          label={itranslate("team.Description")}
          // validate={required("Please add a description for this team")}
          validate={required(itranslate("team.Please add a description for this team"))}
        />

        <ArrayInput
          source="chiefEngineer"
          label={itranslate('team.Chief Engineer')}
          sx={{
            margin: 1,
            padding: 1,
          }}
        >
          <SimpleFormIterator>
            <TextInput
              source="role_name"
              // label="Role Name"
              label={itranslate("team.Role Name")}
              // validate={required("Please add a name for this role")}
              validate={required(itranslate("team.Please add a name for this role"))}
            />

            <SelectInput
              // label={`Role User: `}
              label={`${itranslate("team.Role User")}: `}
              source="default_ce_username"
              choices={combinedChiefEngs.map((eng) => ({
                id: eng.username,
                name: eng.username,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              validate={required(itranslate("team.Please select a user for this role"))}
            />

            <SelectInput
              // label="Double Role:"
              label={`${itranslate("team.Double Role")}: `}
              source="ce_role_level"
              choices={roles
                .filter((eng) => eng !== "")
                // .map((eng) => ({ id: eng, name: eng }))}
                .map((eng) => ({ id: eng, name: itranslate(`team.${eng}`) }))}
              fullWidth
              sx={{ width: ["50%", "50%", "50%", "25%"], marginY: "10px" }}
              defaultValue={roles[0]}
              validate={required("Please select a role")}
            />
            {/* <SelectArrayInput
              label={`Special Application (If any): `}
              source="special_application_type"
              choices={applicationTypes.map((eng) => ({
                id: eng,
                name: eng,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
            /> */}
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput
          source="seniorEngineers"
          label={itranslate('team.Senior Engineer')}
          sx={{
            margin: 1,
            padding: 1,
          }}
        >
          <SimpleFormIterator>
            <TextInput
              source="role_name"
              // label="Role Name"
              label={itranslate("team.Role Name")}
              validate={required(itranslate("team.Please add a name for this role"))}
            />
            <SelectInput
              // label={`Role User: `}
              label={`${itranslate("team.Role User")}: `}
              source="default_se_username"
              choices={combinedSeniorEngs.map((eng) => ({
                id: eng.username,
                name: eng.username,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              validate={required(itranslate("team.Please select a user for this role"))}
            />
            <SelectInput
              // label={`Double Role: `}
              label={`${itranslate("team.Double Role")}: `}
              source="se_role_level"
              choices={roles
                .filter((eng) => eng !== "")
                // .map((eng) => ({ id: eng, name: eng }))}
                .map((eng) => ({ id: eng, name: itranslate(`team.${eng}`) }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              defaultValue={roles[0]}
              validate={required(itranslate("team.Please select a user for this role"))}
            />
            {/* <SelectArrayInput
              label={`Special Application (If any): `}
              source="special_application_type"
              choices={applicationTypes.map((eng) => ({
                id: eng,
                name: eng,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
            /> */}
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput
          source="engineers"
          label={itranslate('team.Engineers')}
          sx={{
            margin: 1,
            padding: 1,
          }}
        >
          <SimpleFormIterator>
            <TextInput
              source="role_name"
              // label="Role Name"
              label={itranslate("team.Role Name")}
              validate={required(itranslate("team.Please add a name for this role"))}
            />
            <SelectInput
              // label={`Role User: `}
              label={`${itranslate("team.Role User")}: `}
              source="default_en_username"
              choices={combinedEngineers.map((eng) => ({
                id: eng.username,
                name: eng.username,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              validate={required(itranslate("team.Please select a user for this role"))}
            />
            <SelectInput
              // label={`Double Role: `}
              label={`${itranslate("team.Double Role")}: `}
              source="en_role_level"
              choices={roles.map((eng) => ({
                id: eng,
                name: eng,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
              defaultValue={roles[0]}
              validate={required(itranslate("team.Please select a role"))}
            />
            <SelectArrayInput
              // label={`Special Application (If any): `}
              label={`${itranslate(`team.Special Application (If any)`)}`}
              source="special_application_type"
              choices={applicationTypes.map((eng) => ({
                id: eng,
                name: eng,
              }))}
              fullWidth
              sx={{
                width: ["50%", "50%", "50%", "25%"],
                marginY: "10px",
              }}
            />
          </SimpleFormIterator>
        </ArrayInput>

      </SimpleForm>
    </Create>
  );
};
