import { useState } from "react";
import axios from "axios";
import getToken from "../utils/getAuthToken";
import { useNotify } from "react-admin";


const useRevokeHook = (dataProvider) => {
// console.log("🚀 ~ useRevokeHook ~ dataProvider:", dataProvider)

  const notify = useNotify();
  const [componentsResp, setComponentsResp] = useState(null);


  const revokeSubmission = async (upload) => {
    console.log("revokeSubmission...")
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke`,
        {
          params: upload,
        },
        {
          headers: {
            authorization: getToken(),
          },
        }
      );
      setComponentsResp(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const revokeCreation = async (upload) => {
    try {
      console.log("upload from revokeCreation", upload);
      const response = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke`,
        {
          params: upload,
        },
        {
          headers: {
            authorization: getToken(),
          },
        }
      );
      // console.log("🚀 ~ revokeCreation ~ response:", response)
      return response.data
    } catch (error) {
      console.log("error", error);
    }
  };


  const revokeGetList = async () => {
    console.log("revokeGetList...")
    try {
      const response = axios.get(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke`,
        {
          headers: {
            authorization: getToken(),
          },
        }
      );
      console.log("revokeGetList", response);
    } catch (error) {
      console.log(error);
    }
  };


  const RevokeStatusToRevoked = async (id) => {
    console.log("RevokeStatusToRevoked...")
    try {
      await dataProvider.update("revoke", {
        id,
        data: { status: "Revoked" },
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };


  const revokeStatusUpdate = async (id) => {
    console.log("revokeStatusUpdate...")
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/revoke/status/${id}`,
        {},
        {
          headers: {
            authorization: getToken(),
          },
        }
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };


  const getAllComponents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/models/revoke`,
        {
          headers: {
            authorization: getToken(),
          },
          params: {
            pagination: {
              page: 1,
              perPage: 0,
            },
          },
        }
      );
    } catch (err) {
      console.log(err)
    }
  };  


  const getSignature = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/options/signature`,
        {
          headers: {
            authorization: getToken(),
          },
        }
      );
      return response.data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return {
    componentsResp,
    revokeSubmission,
    revokeCreation,
    revokeGetList,
    RevokeStatusToRevoked,
    revokeStatusUpdate,
    getAllComponents,
    getSignature
  };
};

export default useRevokeHook;
