import React, { useState } from "react";
import {
  Popper,
  IconButton,
  Paper,
  Typography,
  ClickAwayListener,
  Box,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ModelForm from "../../model/ModelForm";
import template from "../../../utils/static_fields.json";
import { useUpdate, useNotify } from "react-admin";
import _ from "lodash";

const ModelInput = (props) => {
  const { setUpload, models, setModels, userInfo, userRoles, upload: application } = props;
  const notify = useNotify();
  const [updateAPI, { isLoading: isUpdating }] = useUpdate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const modelFields = Object.keys(
    models[0]?.type === "Others"
      ? template.others
      : models[0]?.LE_type === "E"
      ? template.model_e
      : template.model_l
  ).filter(
    (fieldname) =>
      fieldname !== "manufacturer_files" &&
      fieldname !== "LE_type" &&
      fieldname !== "provided_model_name"
  );

  const handleUpdate = () => {
    updateAPI(
      "models",
      {
        id: models[0]._id,
        data: _.pick(models[0], modelFields),
      },
      {
        onSuccess: () => {
          setUpload((prev) => ({
            ...prev,
            model: prev.model.map((model) =>
              model._id === models[0]._id ? { ...models[0] } : model
            ),
          }));
          notify("Model successfully edited");
        },
        onError: (e) => notify(e.response.data),
      }
    );
  };

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  // const isDisabled =
  // (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  // (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  // (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <IconButton
          size="small"
          onClick={handleToggle}
          aria-describedby={anchorEl ? "mouse-enter-popper" : undefined}
        >
          <EditIcon />
          <Typography>Edit model parameter</Typography>
        </IconButton>
        <Popper
          sx={{ zIndex: 2 }}
          id="mouse-enter-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="right-start"
          disablePortal
        >
          {({ TransitionProps }) => (
            <Paper
              sx={{ p: 3, maxHeight: 800, maxWidth: "70vw", overflow: "auto" }}
            >
              <ModelForm
                models={models}
                setModels={setModels}
                modelIndex={0} //assumed only one model (always true)
                isForEMSD={true}
                fields={modelFields}
              />
              <Button
                disabled={isUpdating}
                onClick={handleUpdate}
                color="primary"
                variant="outlined"
                sx={{ my: 3 }}
              >
                Save Changes
              </Button>
            </Paper>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default ModelInput;
