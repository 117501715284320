import {
    Container, Typography, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Button,
    Box
} from "@mui/material";


import React, { useEffect, useState } from 'react';

import { fixInvalidDates } from '../../../../../../admin/utils/misc/fixInvalidDates';
import { ShowFileBtn } from "../../../../../../_hoc/Buttons/ShowFileBtn";
import { useTranslation } from "react-i18next";

// mapping component types
const component_types = {
    ACOP: "Ascending car overspeed protection means",
    Buffer: "Buffer",
    CarDoorLockingDevice: "Car door locking device",
    LandingDoorLockingDevice: "Landing door locking device",
    OverspeedGovernor: "Overspeed Governor",
    Pallet: "Pallet",
    SafetyCircuitElectricalComponent: "Safety circuit containing electrical components",
    SafetyGear: "Safety Gear",
    Step: "Step",
    UCMP: "Unintended car movement protection means",
    RaptureValve: "Rapture Valve",
    TractionMachine: "Traction Machine",
    Controller: "Controller",
    Others: "Others",
}


export default function ComponentPerspectiveShow({ componentPerspectiveShowData, ruResult, handleGetPDFFile }) {
    // const { t } = useTranslation("pages", {keyPrefix: "typeApprovalRecord"});
    const { t : itranslate } = useTranslation()
    const fixNameFormat = (name) => {
        if (name) {
            return name.includes(" ") ? name : name.split(/(?=[A-Z])/).join(" ")
        }
        return name
    }

    return (
        <Container style={{ maxWidth: '100%' }}>
            {
                <Card variant="outlined" style={{ marginTop: '50px', marginBottom: '50px', border: '1px solid black' }}>
                    {/* General Info */}
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>{itranslate("Component Code")}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{itranslate("Component Name")}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{itranslate("Approval Date")}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{itranslate("Type")}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{componentPerspectiveShowData.component_code}</TableCell>
                                    <TableCell>{componentPerspectiveShowData.provided_comp_name}</TableCell>
                                    <TableCell>{fixInvalidDates(new Date(componentPerspectiveShowData.approval_date).toLocaleDateString())}</TableCell>
                                    <TableCell>{itranslate(component_types[componentPerspectiveShowData?.type]) || itranslate(componentPerspectiveShowData.type.split(/(?=[A-Z])/).join(' '))}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>

                    {/* comp */}

                    <TableContainer component={Paper}>

                        <Table>
                            <TableHead>
                                {
                                    componentPerspectiveShowData.certsPopulated.length > 0 && (
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold'}}>{itranslate("Type Examination Certificate Number")}</TableCell>
                                            <TableCell style={{ fontWeight: 'bold'}}>{itranslate("TEC Issue Date")}</TableCell>
                                            {ruResult?.certsChosenForScPerspective?.tec?.length > 0 && <TableCell style={{ fontWeight: 'bold'}}>{itranslate("TEC Certificate")}</TableCell>}
                                        </TableRow>
                                    )
                                }
                            </TableHead>

                            {/* comp manu file & tec */}
                            <TableBody>
                                {
                                    componentPerspectiveShowData.certsPopulated.map((cert, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{cert?.cert_type_metadata?.tec_certificate_number || "N/A"}</TableCell>
                                                <TableCell>
                                                    { 
                                                        cert?.cert_type_metadata?.tec_issue_date ? 
                                                            fixInvalidDates(new Date(cert?.cert_type_metadata?.tec_issue_date).toLocaleDateString()) === "Invalid Date" ? 
                                                                fixInvalidDates(cert?.cert_type_metadata?.tec_issue_date) : 
                                                                fixInvalidDates(new Date(cert?.cert_type_metadata?.tec_issue_date).toLocaleDateString())
                                                            : "N/A"
                                                    }
                                                </TableCell>

                                                <TableCell>
                                                {
                                                    ruResult?.certsChosenForScPerspective?.tec?.length > 0 && ruResult?.certsChosenForScPerspective?.tec?.filter(tec => tec._id === cert._id).map((tec, i) => 
                                                        <ShowFileBtn 
                                                            handleGetPDFFile={handleGetPDFFile} 
                                                            id={tec._id}
                                                            key={`${tec._id}-${i}`}
                                                            style={{ whiteSpace: 'nowrap' }}
                                                        />
                                                    )
                                                }
                                                </TableCell>
                                            </TableRow>
                                        )

                                    })

                                }
                            </TableBody>
                        </Table>



                        {/* comp manu populated iso */}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>{itranslate("Manufacturer")}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{itranslate("ISO Certificate Issue Date")}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{itranslate("ISO Certificate")}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    componentPerspectiveShowData?.manufacturersPopulated?.length > 0 && componentPerspectiveShowData?.manufacturersPopulated.map((manuf, i) => {
                                        let iso = manuf?.iso_certificate_id
                                        let iso_metadata = manuf?.iso_certificate_id?.cert_type_metadata
                                        return (
                                                <TableRow key={i}>
                                                    <TableCell>{fixNameFormat(manuf?.provided_name) || "N/A"}</TableCell>
                                                    <TableCell>
                                                    { 
                                                        iso_metadata?.iso_issue_date ? 
                                                            fixInvalidDates(new Date(iso_metadata?.iso_issue_date).toLocaleDateString()) === "Invalid Date" ? 
                                                                fixInvalidDates(iso_metadata?.iso_issue_date) : 
                                                                fixInvalidDates(new Date(iso_metadata?.iso_issue_date).toLocaleDateString())
                                                            : "N/A"
                                                    }
                                                    </TableCell>

                                                    {
                                                        ruResult?.certsChosenForScPerspective?.iso?.includes(iso._id) && (
                                                            <TableCell>
                                                                <ShowFileBtn
                                                                    handleGetPDFFile={handleGetPDFFile} 
                                                                    id={iso.file}
                                                                />
                                                            </TableCell>
                                                        )
                                                    }
                                                </TableRow>

                                        )
                                    })
                                }
                            </TableBody>
                        </Table>



                    </TableContainer>

                </Card >
            }

        </Container>
    )
}