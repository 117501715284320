import {
  SelectInput,
  TextInput,
  ReferenceInput,
  Filter,
  BooleanInput,
} from "react-admin";
import { useTranslation } from 'react-i18next';


const ComponentFilter = (props) => {
  const { t : itranslate } = useTranslation()
  return (
    <Filter {...props}>
      <TextInput
        source="q"
        label={itranslate('Search component code / component name / model name / manufacturer / type / RC / cert data')}
        alwaysOn
        sx={{ width: "800px" }}
      />
      <TextInput
        label={itranslate('Component Code')}
        source="component_code"
        defaultValue="RC"
      />
      <TextInput 
        label={itranslate('Component Name')}
        source="provided_comp_name" 
      />
      <SelectInput
        label={itranslate('Type of component')}
        source="type"
        choices={[
          {
            id: "ACOP",
            name: itranslate('Ascending car overspeed protection means'),
          },
          {
            id: "OverspeedGovernor",
            name: itranslate('Overspeed Governor'),
          },
          { id: "UCMP", 
            name: itranslate('Unintended car movement protection means')
          },
          { id: "CarDoorLockingDevice", 
            name: itranslate('Car door locking device')
          },
          {
            id: "LandingDoorLockingDevice",
            name: itranslate('Landing door locking device'),
          },
          {
            id: "SafetyCircuitElectricalComponent",
            name: itranslate('Safety circuit containing electrical components'),
          },
          { id: "Pallet", 
            name: itranslate('Pallet')
          },
          { id: "Step", 
            name: itranslate('Step')
          },
          {
            id: "OneWayValve",
            name: itranslate('One Way Valve'),
          },
          { id: "RaptureValve", 
            name: itranslate('Rapture Valve')
          },
          { id: "SafetyGear", 
            name: itranslate('Safety Gear')
          },
          { id: "Buffer", 
            name: itranslate('Buffer')
          },
          { id: "TractionMachine", 
            name: itranslate('Traction Machine')
          },
          { id: "Controller", 
            name: itranslate('Controller')
          },
        ]}
      />

      <SelectInput
        label={itranslate('Lift/escalator Model Type')}
        source="modelsPopulated.type"
        choices={[
          {
            id: "ElectricTractionLiftMachineRoom",
            name: itranslate('Electric Traction Lift (Machine Room)'),
          },
          {
            id: "ElectricTractionLiftRoomless",
            name: itranslate('Electric Traction Lift (Machine Roomless)'),
          },
          { id: "InclinedLift", 
            name: itranslate('Electric Inclined Lift')
          },
          { id: "ServiceLift", 
            name: itranslate('Service Lift')
          },
          { id: "StairLift", 
            name: itranslate('Stairlift')
          },
          { id: "HydraulicLift",  
            name: itranslate('Hydraulic Lift')
          },
          {
            id: "ElectricalVerticalLiftingPlatform",
            name: itranslate('Vertical Lifting Platform'),
          },
          { id: "MVPS", 
            name: itranslate('Mechanized Vehicle Parking System (MVPS)')
          },
          { id: "Escalator", 
            name: itranslate('Escalator')
          },
          { id: "PassengerConveyor", 
            name: itranslate('Passenger Conveyor')
          },
          { id: "SafetyComponent", 
            name: itranslate('Safety Component')
          },
          { id: "Others", 
            name: itranslate('Others')
          },
        ]}
      />
      <TextInput
        label={itranslate('Lift/escalator models')}
        source="modelsPopulated.provided_model_name"
      />
      <TextInput
        label={itranslate('Manufacturers')}
        source="manufacturersPopulated.provided_name"
      />
      {window.location.pathname === "/lift_emsd" && (
        <ReferenceInput source="rc_id" 
          label={itranslate('RC')}
          reference="rcs" perPage={100}>
          {/* <SelectInput optionText="name" /> */}
          <SelectInput
            optionText={(choice) => {
              let displayText = "";
              displayText = choice?.rc_number_lift;
              if (choice?.rc_number_escalator) {
                displayText += ` / ${choice.rc_number_escalator}`;
              }
              if (choice?.address?.company) {
                displayText += ` / ${choice.address.company}`;
              }
              return displayText;
            }}
          />
        </ReferenceInput>
      )}
      <BooleanInput 
        label={itranslate('Comply standard')}
        source="comply_standard" 
      />
      <BooleanInput 
        label={itranslate('Is standalone')}
        source="standalone" 
      />
    </Filter>
  );
};

export default ComponentFilter;
