import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Card,
  Typography,
} from "@mui/material";
import { useGetList } from "react-admin";
import { useEffect, useState } from "react";
import { useUpdate } from "react-admin";
import { useNavigate } from "react-router-dom";
import { useNotify } from "react-admin";
import { romanize } from "../../utils/romanize";
import keyToField from "../../utils/keyToField";
import DateStringInput from "../../utils/certEditUtils/DateStringInput";
import React from "react";
import DescriptionInput from "./DescriptionInput";
import { useTranslation } from 'react-i18next';


function RecordAppendixEdit(props) {
  const { t : itranslate } = useTranslation();

  const navigate = useNavigate();
  const notify = useNotify();
  const { upload, setUpload, update, id, certificates, setCertificates } = props;
  const [updateAPI, { isLoading: isUpdating }] = useUpdate();

  if (!certificates || !upload) return null;

  const componentCerts = Object.values(upload?.app_type?.components || {})
    .flat()
    .flatMap((comp) => comp.type_test_certificate_id);

  const modelCerts =
    upload?.model?.[0]?.manufacturer_files?.flatMap(
      (f) => f.type_test_certificate_id
    ) || [];

  const manufacturerCerts =
    upload?.manufacturer?.map((m) => m.iso_certificate_id) || [];

  const userUploadedCerts = componentCerts
    .concat(modelCerts)
    .concat(manufacturerCerts)
    .filter((c) => c.id) // is user uploaded
    .filter((c) => !certificates.find((cert) => cert.id === c.id)); //is not already in the list

  const combinedCertificates = certificates.concat(userUploadedCerts);

  const manufacturer_code_reference =
    upload?.appendix?.manufacturer_code?.length > 0
      ? upload?.appendix?.manufacturer_code
      : Array.isArray(upload?.model?.[0]?.LEOSdata?.[0]?.manufacturer_code)
      ? upload?.model?.[0]?.LEOSdata?.[0]?.manufacturer_code
      : [];

  const makeCorrections = (value, certificateId, field) => {
    if (!combinedCertificates) return;
    setCertificates(
      combinedCertificates.map((c) => {
        if (c.id === certificateId) {
          return {
            ...c,
            cert_corrections: { ...c.cert_corrections, [field]: value },
          };
        } else {
          return c;
        }
      })
    );
  };

  const handleUpdate = () => {
    if (!upload?.description) return notify(itranslate("Please fill in the remark"));
    updateAPI(
      "records",
      {
        id: id,
        data: upload,
        meta: {
          update: update,
          certificates: combinedCertificates,
        },
      },
      {
        onSuccess: () => (
          navigate(`/applications`), notify(itranslate("Record successfully edited"))
        ),
        onError: (e) => notify(e.response.data),
      }
    );
  };

  const simpleDisplay = (cert_id, field) => {
    const matchCert = combinedCertificates.find((cert) => cert.id === cert_id);
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field]
    );
  };

  if (!combinedCertificates || !upload) return null;

  return (
    <Card>
      <CardHeader title={itranslate("Appendix certificates edit")} />
      <CardContent>
        <Box sx={{ height: 900, display: "flex" }}>
          <div className="appendix appendix_rm">
            
            <p className="appendixHeader2">
              {/* Manufacturer Code */}
              {itranslate("Manufacturer Code")}
            </p>
            {manufacturer_code_reference.map((manufacturer, i) => {
              return (
                <div className="manufacturer_code" key={i}>
                  <p>{String.fromCharCode(65 + i)}. </p>
                  <p>{manufacturer.short_name || manufacturer.name}</p>
                  <p>{manufacturer.address}</p>
                </div>
              );
            })}

            {upload?.model.map((model, index) => {
              return (
                <React.Fragment key={index}>
                  <p className="romanIndex">
                    {romanize(index + 1)}. {itranslate("Lift Model")} -{" "}
                    {model.provided_model_name}
                  </p>

                  <table className="tableCmodel_rm">
                    <thead>
                      <tr>
                        <th> {itranslate("Type Exam. Certificat")} </th>
                        <th> {itranslate("Manufacturer Code")}  </th>
                        <th>  {itranslate("EMSD Approval Ref./Issue Date")} </th>
                        <th>  {itranslate("Filename")} </th>
                      </tr>
                    </thead>
                    
                    <tbody>
                      {combinedCertificates
                        .filter(
                          (tec) =>
                            (tec?.reference === model.model_code &&
                              tec.type === "ModelTypeCert") ||
                            model.manufacturer_files.some((mmf) =>
                              mmf.type_test_certificate_id.some(
                                (c) => c.id === tec.id
                              )
                            )
                        )
                        .map((tec, i) => {
                          const filename = tec.filename || tec.migrationFile?.filename;

                          return (
                            <tr key={i}>
                              <td>
                                <input
                                  onChange={(e) =>
                                    makeCorrections(
                                      e.target.value,
                                      tec.id,
                                      "tec_certificate_number"
                                    )
                                  }
                                  value={simpleDisplay(
                                    tec.id,
                                    "tec_certificate_number"
                                  )}
                                />{" "}
                                {itranslate("dated")}
                                <DateStringInput
                                  onChange={(e) =>
                                    makeCorrections(
                                      e.target.value,
                                      tec.id,
                                      "tec_issue_date"
                                    )
                                  }
                                  value={simpleDisplay(
                                    tec.id,
                                    "tec_issue_date"
                                  )}
                                />
                              </td>
                              <td>
                                <input
                                  onChange={(e) =>
                                    makeCorrections(
                                      e.target.value,
                                      tec.id,
                                      "manufacturer_code"
                                    )
                                  }
                                  value={simpleDisplay(
                                    tec.id,
                                    "manufacturer_code"
                                  )}
                                />
                              </td>
                              <td>
                                <input
                                  onChange={(e) =>
                                    makeCorrections(
                                      e.target.value,
                                      tec.id,
                                      "Approval Reference"
                                    )
                                  }
                                  value={simpleDisplay(
                                    tec.id,
                                    "Approval Reference"
                                  )}
                                />
                              </td>
                              <td>{filename}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </React.Fragment>
              );
            })}
            
            {/* Components */}
            {Object.keys(upload?.app_type?.components)
              .filter((component_type) => {
                if (["TractionMachine", "Controller"].includes(component_type))
                  return false;
                return upload.app_type?.components[component_type]?.length > 0;
              })
              .sort((a, b) => {
                const order = [
                  "Buffer",
                  "OverspeedGovernor",
                  "LandingDoorLockingDevice",
                  "CarDoorLockingDevice",
                  "SafetyGear",
                  "ACOP",
                  "UCMP",
                  "SafetyCircuitElectricalComponent",
                ];
                function customSort(item) {
                  const index = order.indexOf(item);
                  return index !== -1 ? index : order.length;
                }
                return customSort(a) - customSort(b);
              })
              .map((component_type, index) => {
                return (
                  <>
                    <p className="romanIndex">
                      {romanize(upload?.model?.length + index + 1)}. {itranslate("Safety Components")} - {itranslate(keyToField[component_type])}
                    </p>

                    <table className="tableCcomp_rm">
                      <thead>
                        <tr>
                          <th> {itranslate("Model")} </th>
                          <th> {itranslate("Type Exam. Certificate")} </th>
                          <th> {itranslate("Issue Date")} </th>
                          <th> {itranslate("Filename")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {upload?.manufacturer?.map((manufacturer, i) => {
                          const components = upload?.app_type?.components[
                            component_type
                          ].filter(
                            (compModel) =>
                              compModel.manufacturer_id === manufacturer._id ||
                              compModel.manufacturer_id ===
                                manufacturer.provided_name
                          );
                          if (components.length === 0) return null;
                          return (
                            <React.Fragment key={i}>
                              <tr className="font-bold">
                                <td>
                                  {manufacturer?.short_name ||
                                    manufacturer?.provided_name}
                                </td>
                              </tr>
                              {components.map((component) => {
                                return combinedCertificates
                                  .filter(
                                    (tec) =>
                                      (tec?.reference ===
                                        component.component_code &&
                                        tec.component_type === component_type &&
                                        tec.type === "ComponentTypeCert") ||
                                      component.type_test_certificate_id.some(
                                        (c) => c.id === tec.id
                                      )
                                  )
                                  .map((tec, i) => {
                                    const filename =
                                      tec.filename ||
                                      tec.migrationFile?.filename;
                                    return (
                                      <tr key={i}>
                                        <td>{component.provided_comp_name}</td>
                                        <td>
                                          <input
                                            className="font-bold italic"
                                            onChange={(e) =>
                                              makeCorrections(
                                                e.target.value,
                                                tec.id,
                                                "tec_certificate_number"
                                              )
                                            }
                                            value={simpleDisplay(
                                              tec.id,
                                              "tec_certificate_number"
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <DateStringInput
                                            className="w-2/5 font-bold italic"
                                            onChange={(e) =>
                                              makeCorrections(
                                                e.target.value,
                                                tec.id,
                                                "tec_issue_date"
                                              )
                                            }
                                            value={simpleDisplay(
                                              tec.id,
                                              "tec_issue_date"
                                            )}
                                          />
                                        </td>
                                        <td>{filename}</td>
                                      </tr>
                                    );
                                  });
                              })}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                );
              })}
          </div>

          <Typography variant="caption">
            {itranslate("* Uploading type test certificates in previous page will add a row in this appendix")}
          </Typography>

        </Box>

        <DescriptionInput upload={upload} setUpload={setUpload} />

        <Button
          disabled={isUpdating}
          sx={{ mt: 2 }}
          color="primary"
          variant="outlined"
          onClick={handleUpdate}
        >
          {itranslate("Send")}
        </Button>

      </CardContent>
    </Card>
  );
}

export default RecordAppendixEdit;
