import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next'

// emotion
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const cache = createCache({
  key: 'css',
  nonce: window.__CSP_NONCE__ || '',
});


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};


export default function BasicModalWithConfirmButton({open, setOpen, setOpen2, children, title}) {
  // console.log("🚀 ~ BasicModalWithConfirmButton ~ title:", title)
  // console.log("🚀 ~ BasicModalWithConfirmButton ~ children:", children)
  const { t : itranslate } = useTranslation();

  const handleClose = () => {
    if(setOpen){
      setOpen(false)
    }
    
    if(setOpen2){
      setOpen2(false)
    }

  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <CacheProvider cache={cache}> */}
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
              {/* {itranslate(title)} */}
            </Typography>
            <div id="modal-modal-description" sx={{ mt: 2 }}>
              {/* {children} */}
              {itranslate(children?.props?.children)}
            
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{ mt: 2}}
                onClick={handleClose}
              >
                {/* Confirm */}
                {itranslate("Confirm")}
              </Button>
            </div>  

            </div>
          </Box>
        {/* </CacheProvider> */}
      </Modal>
    </div>
  );
}