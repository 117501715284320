import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import keyToField from "../../../../utils/keyToField";
import PartCModelTable from "./PartCModelTable";
import { romanize } from "../../../../utils/romanize";
import renderSymbol from "../../../../utils/pdf/renderSymbol";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
});

const PartBTableRow = (props) => {
  const {
    application,
    appendix,
    addedRow,
    model,
    field,
    certificates,
    simpleDisplay,
    manu_code,
  } = props;

  const fieldName = (field) => {
    switch (field) {
      case "type":
        return "Type";
      case "provided_model_name":
        return model.LE_type === "E" ? "Escalator Model" : "Lift Model";
      case "rated_speed":
        return "Rated Speed";
      case "rated_load":
        return "Rated Load";
      case "maximum_travel_of_height":
        return "Travel of Height";
      case "rope_factor":
        return "Roping";
      case "balancing_factor":
        return "Balancing Factor";
      case "maximum_rise":
        return "Maximum Rise";
      case "angle_of_inclination":
        return "Inclination";
      case "manufacturer_files":
        return `${
          appendix?.tecHeader || "Type Exam. Certificate No."
        } / Manufacturer Code / [EMSD Approval Ref.]`;
      default:
        return field;
    }
  };

  const processedFieldData = (field, data) => {
    switch (field) {
      case "type":
        return keyToField[data];
      case "rated_speed":
        return (
          <>
            <Text style={{ fontFamily: "DejaVu Sans" }}>≤</Text> {data} m/s
             {/* <Text style={{ fontFamily: "DejaVu Sans Bold Oblique", fontSize: "10px" }}>≤ {data} m/s</Text> */}
          </>
        );
      case "rated_load":
        return (
          <>
            <Text style={{ fontFamily: "DejaVu Sans" }}>≤</Text> {data} kg
          </>
        );
      case "maximum_travel_of_height":
        return (
          <>
            <Text style={{ fontFamily: "DejaVu Sans" }}>≤</Text> {data} m
          </>
        );
      case "rope_factor":
        return data.join(", ");
      case "balancing_factor":
        return data + " %";
      case "maximum_rise":
        return data + " m";
      case "angle_of_inclination":
        return (
          <>
             {/* <Text style={{ fontFamily: "DejaVu Sans Bold Oblique", fontSize: "10px" }}>≤ {data}</Text>  */}
            <Text style={{ fontFamily: "DejaVu Sans" }}>≤</Text> {data}
            {`\u00B0`} kg
          </>
        );
      default:
        return data;
    }
  };

  return (
    <View style={styles.row}>
      <Text
        style={{
          padding: "2px",
          width: "30%",
          height: "100%",
          fontFamily: "Frutiger_bold",
          fontSize: "11px",
          backgroundColor: "lightgray",
          border: "1px solid black",
          borderLeft: "0px",
          borderTop: "0px",
        }}
      >
        {fieldName(field)}
      </Text>
      <View
        style={{
          width: "70%",
          height: "100%",
          ...(field !== "manufacturer_files" && {
            textAlign: "center",
            padding: "2px",
          }),
          border: "1px solid black",
          borderLeft: "0px",
          borderTop: "0px",
          ...(field === "provided_model_name" && {
            fontFamily: "Frutiger_bold",
          }),
        }}
      >
        {field === "manufacturer_files" ? (
          <PartCModelTable
            application={application}
            appendix={appendix}
            model={model}
            manu_code={manu_code}
            romanize={romanize}
            certificates={certificates}
            simpleDisplay={simpleDisplay}
            partB={true}
          />
        ) : addedRow ? (
          <Text>
            {renderSymbol(
              addedRow.value?.replace(/{([^{}]*)}/g, function (match, field) {
                return (
                  simpleDisplay(
                    model?.manufacturer_files?.[0]
                      ?.type_test_certificate_id?.[0],
                    field
                  ) || ""
                );
              })
            )}
          </Text>
        ) : (
          <Text>{processedFieldData(field, model[field])}</Text>
        )}
      </View>
    </View>
  );
};

export default PartBTableRow;
