import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    daAppendixBase64: null,
  },
}

export const daPdfSlice = createSlice({ 
  name: "daPdf",
  initialState,
  reducers: {
    setDaAppendixBase64: (state, action) => {
      state.value.daAppendixBase64 = action.payload;
    },
    clear: (state) => {
      state.value.daAppendixBase64 = null;
    },
  }
})


export const { setDaAppendixBase64, clear } = daPdfSlice.actions;
export const getDaPDF = (state) => state.daPdf.value;
export default daPdfSlice.reducer;