import React, { useState } from "react";
import { Confirm } from "react-admin";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from 'react-i18next';


const RevokeDeleteHasIconConfirmButton = (props) => {
  const { selectedIds, customDeleteLogic, sx, size, userInfo, record, userRoles } = props;
  const { t : itranslate } = useTranslation();

  const [showConfirm, setShowConfirm] = useState(false);

  const handleDelete = () => {
    setShowConfirm(true);
  };

  const handleConfirm = async () => {
    setShowConfirm(false);
    if (selectedIds) await customDeleteLogic(selectedIds);
    else await customDeleteLogic();
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };


  const isButtonDisabled =
  ((record?.status ==="Pending E" || record?.status === "Pending SE") &&
  !userRoles.includes(record?.current_role_id)) ||
  ((record?.status === "Pending" && !(userInfo?.role === "EMSDGR" || userInfo?.role === "EMSDEngineer"))) ||
  userInfo?.role === "Inspector" ||
  userInfo?.role === "TapasAdmin" || 
  userInfo?.role === "EMSDCE" 


  return (
    <>
      <Button 
        sx={sx} 
        onClick={handleDelete}
        disabled={isButtonDisabled}
      >
        <DeleteIcon sx={size} />
      </Button>
      <Confirm
        title={itranslate("Are you sure?")}
        content={itranslate("This action is irreversible. Do you want to delete this file?")}
        isOpen={showConfirm}
        onConfirm={handleConfirm}
        onClose={handleCancel}
        confirmLabel={itranslate('confirm')}
        cancelLabel={itranslate('cancel')}
      />
    </>
  );
};

export default RevokeDeleteHasIconConfirmButton;