import axios from "axios";
import React, { useState } from "react";
import { useNotify, useLogout } from "react-admin";
import { useSelector } from "react-redux";
import { Typography, Box, Button, TextField } from "@mui/material";
import getToken from "../../utils/getAuthToken";
import UpdatePublicHolidays from "../update/UpdatePublicHolidays";
import { useTranslation } from 'react-i18next';


const UserProfile = () => {
  const { t : itranslate } = useTranslation();

  const notify = useNotify();
  const logout = useLogout();
  const userInfo = useSelector((state) => state.userInfo.value);
  const userRole = useSelector((state) => state.userInfo.value.role);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmNewPassword] = useState("");

  const allowedRoles = ["EMSDGR"];
  const canUpload = allowedRoles.includes(userRole);

  const handlePasswordReset = async () => {
    if (newPassword !== confirmPassword)
      return notify("Confirm password doesn't match!");

    axios
      .post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/auth/reset_password`,
        {
          username: userInfo.username,
          currentPassword,
          newPassword,
        },
        { headers: { authorization: getToken() } }
      )
      .then((response) => {
        notify(response.data);
        if (
          response.data ===
          "Successfully Reset password, please login with your new password"
        )
          logout();
        else {
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
        }
      })
      .catch((error) => {
        notify(error.response.data);
      });
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <Box paddingTop="100px">
        <Typography variant="h5">
          {/* User Profile */}
          {itranslate('userProfile.User Profile')}
          </Typography>

        <Box marginTop="20px">
          {[
            {
              // label: "Email",
              label: itranslate('userProfile.Email'),
              value: userInfo.email,
            },
            {
              // label: "Username",
              label: itranslate('userProfile.Username'),
              value: userInfo.username,
            },
            {
              // label: "Rank",
              label: itranslate('userProfile.Rank'),
              value: userInfo.role,
            },
          ].map((field, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Typography variant="subtitle1" marginRight={1}>
                {field.label}:
              </Typography>
              <Typography>{field.value}</Typography>
            </Box>
          ))}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mt: 5,
            }}
          >
            <Typography sx={{ fontFamily: "Frutiger_bold" }}>
              {/* Reset Password */}
              {itranslate('userProfile.Reset Password')}
            </Typography>

            <TextField
              // label="Current Password"
              label={itranslate('userProfile.Current Password')}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              type="password"
              variant="outlined"
            />
            <TextField
              // label="New Password"
              label={itranslate('userProfile.New Password')}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              variant="outlined"
            />
            <TextField
              // label="Confirm Password"
              label={itranslate('userProfile.Confirm Password')}
              value={confirmPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              type="password"
              variant="outlined"
            />
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: 1 }}
              onClick={handlePasswordReset}
            >
              {/* Send */}
              {itranslate('userProfile.Send')}
            </Button>
          </Box>
        </Box>
      </Box>
      
      {canUpload &&
       <Box paddingTop="100px">
        <UpdatePublicHolidays />
      </Box>}
    </div>
  );
};

export default UserProfile;
