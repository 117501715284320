import { Text } from "@react-pdf/renderer";

const interpolate = (text, models) => {
  // this function replace string "{model_name}" to model name
  const modelNamesArray = models.map((model) => model.provided_model_name);
  const modelNames = modelNamesArray.join(", ");
  // return text.replace(/{([^{}]*)}/g, modelNames);
  return text.replace("{model_name}", modelNames);
};

const formatParser = (text, format) => {
  // Format:
  // **text**  Ultra bold
  // __text__ underline
  // **__text__** bold+underline
  // **~text~** bold
  // `text` switch font family (to display missing symbol)
  // ##text## switch font family (to display chinese)
  //To add more format, simply nest a new function inside index%2===0 block
  const TextComponent = format === "plainHtml" ? "span" : Text;

  // function traditionalChinese(textOutsideSymbols, textArray) {
  //   // const arr4 = textOutsideSymbols.split("##");

  //   const arr4 = textOutsideSymbols.split(/###|##/);
  //   let isTripleHash = textOutsideSymbols.includes('###');

  //   arr4.forEach((subText, index) => {
  //     let styledText;
  //     if (index % 2 === 0) {
  //       styledText =
  //         format === "plainHtml" ? (
  //           <span key={Math.random()}>{subText}</span>
  //         ) : (
  //           // We replace all white space after a new line(\n) with \u00A0 for non-breaking space in pdf, in html, it is handled by css "white-space: pre-wrap"
  //           <Text key={Math.random()}>
  //             {subText.replace(/(?<=\n)\s+/g, (match) =>
  //               "\u00A0".repeat(match.length)
  //             )}
  //           </Text>
  //         );
  //     } else {
  //       const style = isTripleHash
  //       ? { fontFamily: "mHeiBold"}
  //       : { fontFamily: "mYuenLight" };

  //       styledText = (
  //         <TextComponent
  //           key={Math.random()}
  //           // style={{ fontFamily: "mYuenLight" }}
  //           style={style}
  //         >
  //           {subText}
  //         </TextComponent>
  //       );
  //     }
  //     textArray.push(styledText);
  //   });
  // }



  function traditionalChinese(textOutsideSymbols, textArray) {
    const segments = textOutsideSymbols.split(/(###|##)/);
    
    let isInsideHashes = false;
    let currentHashType = '';
  
    segments.forEach((segment, index) => {
      if (segment === '###' || segment === '##') {
        isInsideHashes = !isInsideHashes;
        currentHashType = segment;
        return; // Skip the delimiter itself
      }
  
      let styledText;
      if (!isInsideHashes) {
        // Text outside hashes
        styledText = format === "plainHtml" ? (
          <span key={Math.random()}>{segment}</span>
        ) : (
          <Text key={Math.random()}>
            {segment.replace(/(?<=\n)\s+/g, (match) =>
              "\u00A0".repeat(match.length)
            )}
          </Text>
        );
      } else {
        // Text inside hashes
        const isTripleHash = currentHashType === '###';
        const style = isTripleHash
          ? { fontFamily: "mHeiBold" }
          : { fontFamily: "mYuenLight" };
  
        styledText = (
          <TextComponent
            key={Math.random()}
            style={style}
          >
            {segment}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }
  


  function switchFontFamily(textOutsideUnderline, textArray) {
    const arr3 = textOutsideUnderline.split("`");
    arr3.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        traditionalChinese(subText, textArray);
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ fontFamily: "DejaVu Sans" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  function checkUnderline(textOutsideBold, textArray) {
    const arr2 = textOutsideBold.split("__");
    arr2.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        switchFontFamily(subText, textArray);
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ textDecoration: "underline" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  if (text) {
    const arr = text.split("**");
    const newTextArr = [];
    // Loop over split text
    arr.forEach((text, index) => {
      //outside **..** block, either unbolded text or ** does not exist
      if (index % 2 === 0) {
        checkUnderline(text, newTextArr);
      }
      // If its an odd element then it is inside **...** block
      else if (index % 2 !== 0) {
        let styledText;
        if (text.startsWith("__") && text.endsWith("__")) {
          // for example, **__boldedAndUnderlined__**
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{
                  textDecoration: "underline",
                  fontFamily: "Frutiger_light",
                  fontWeight: 700,
                }}
              >
                {text.slice(2, -2)}
              </span>
            ) : (
              <Text
                key={Math.random()}
                style={{
                  textDecoration: "underline",
                  fontFamily: "Frutiger_bold",
                }}
              >
                {text
                  .slice(2, -2)
                  .replace(/(?<=\n)\s+/g, (match) =>
                    "\u00A0".repeat(match.length)
                  )}
              </Text>
            );
        } else if (text.startsWith("~") && text.endsWith("~")) {
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{ fontFamily: "Frutiger_light", fontWeight: 700 }}
              >
                {text.slice(1, -1)}
              </span>
            ) : (
              <Text key={Math.random()} style={{ fontFamily: "Frutiger_bold" }}>
                {text
                  .slice(1, -1)
                  .replace(/(?<=\n)\s+/g, (match) =>
                    "\u00A0".repeat(match.length)
                  )}
              </Text>
            );
        } else if (text.startsWith("_") && text.endsWith("_")) {
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{ fontFamily: "Frutiger_light", fontStyle: "italic" }}
              >
                {text.slice(1, -1)}
              </span>
            ) : (
              <Text
                key={Math.random()}
                style={{ fontFamily: "Frutiger_italic" }}
              >
                {text
                  .slice(1, -1)
                  .replace(/(?<=\n)\s+/g, (match) =>
                    "\u00A0".repeat(match.length)
                  )}
              </Text>
            );
        } else {
          // Wrap with bold text style
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{ fontFamily: "Frutiger_Ultra_bold" }}
              >
                {text}
              </span>
            ) : (
              <Text
                key={Math.random()}
                style={{ fontFamily: "Frutiger_Ultra_bold" }}
              >
                {text.replace(/(?<=\n)\s+/g, (match) =>
                  "\u00A0".repeat(match.length)
                )}
              </Text>
            );
        }
        newTextArr.push(styledText);
      }
    });
    return newTextArr;
  }
};

function parseAppendixJson(input, models, format) {
  /* const parsedCode = codeParser(input, certificates); */
  const parsedModelName = interpolate(input, models);
  const parsedFormat = formatParser(parsedModelName, format);
  return parsedFormat;
}

export default parseAppendixJson;
