// react hooks
import * as React from 'react';
import { useEffect, useState } from "react"

// mui
import {
    TextField,
    Typography,
    Box,
    Button,
    Divider
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import useMediaQuery from '@mui/material/useMediaQuery';

// components
import DateRangeComponent from "../../_hoc/DateRangePicker/index";
import Chart from "./Chart/index";
import CompLeaderboard from "./CompLeaderboard/index";

// custom hooks
import { getDataByDateTime, getGraphByPeriod } from "../../admin/useHooks/statPublicDashboard/useStatPublic";
import { getApps_Phase1Api } from "../../admin/useHooks/useApps/index";
import { getModels_Phase1Api } from "../../admin/useHooks/useModels/index";
import { getRcs_Phase1Api } from "../../admin/useHooks/useRcs/index";

import { useTranslation } from 'react-i18next';

const fields = [
    {
      value: 'Application Number',
      label: 'Application Number',
    },
    {
      value: 'L/E Model Code',
      label: 'L/E Model Code',
    },
    {
      value: 'L/E Model Name',
      label: 'L/E Model Name',
    },
    {
        value: "RC Company",
        label: "RC Company"
    },
    {
        value: "RC Code",
        label: "RC Code"
    }
  ];

const mapEntity = {
    "Application Number": "Application",
    "L/E Model Code": "L/E Model",
    "L/E Model Name": "L/E Model",
    "RC Company": "Registered Contractor",
    "RC Code": "Registered Contractor"
}

const CompStatPublic = () => {
    // const { t } = useTranslation("pages", { keyPrefix: "statistic dashboard" });
    const { t : itranslate } = useTranslation();

    const matches = useMediaQuery('(max-width: 600px)');

    // data
    const [entity, setEntity] = useState("All");
    const [searchValue, setSearchValue] = useState("");
    const [filterField, setFilterField] = useState("");

    const [startDate, setStartDate] = useState(null); // for api
    const [endDate, setEndDate] = useState(null); // for api
    // data result
    const [visitData, setVisitData] = useState({});
    const [appsNo, setAppsNo] = useState([]);
    const [modelsCode, setModelsCode] = useState([]);
    const [modelsName, setModelsName] = useState([]);
    const [rcsCompany, setRcsCompany] = useState([]);
    const [rcsCode, setRcsCode] = useState([]);
    const [autoCompleteList, setAutoCompleteList] = useState([]);

    // error
    const [errMsg, setErrMsg] = useState("");

    const [trigger, setTrigger] = useState(false)

    useEffect(() => {
        getApps_Phase1Api().then((res) => {
            setAppsNo(res.map((v, i) => v.application_number))
        }).catch((err) => {
            console.log(err);
        })

        getModels_Phase1Api().then((res) => {
            setModelsCode(res.map((v, i) => v.model_code))
            setModelsName(res.map((v, i) => v.provided_model_name))
        }).catch((err) => {
            console.log(err);
        })
        
        getRcs_Phase1Api().then((res) => {
            setRcsCompany(res.map((v, i) => v.address.company))
            let names = res.map((v, i) => v.name) 
            let rlc = res.map((v, i) => v.rc_number_lift)
            let rec = res.map((v, i) => v.rc_number_escalator)
            let list = names.concat(rlc).concat(rec)
            setRcsCode(list)
        }).catch((err) => {
            console.log(err);
        })

    }, [])

    // reset to all time btn
    useEffect(() => {
        // in case you have search value without filter field.
        if(searchValue && !filterField){
            setErrMsg("Please select filter field")
            // setErrMsg(itranslate("Please select filter field"))
        } else {
            setErrMsg("")
        }

        // set auto complete list based on the filter field
        switch (filterField) {
            case "Application Number":
                setAutoCompleteList(appsNo)
                break;
            case "L/E Model Code":
                setAutoCompleteList(modelsCode)
                break;
            case "L/E Model Name":
                setAutoCompleteList(modelsName)
                break;
            case "RC Company":
                setAutoCompleteList(rcsCompany)
                break;
            case "RC Code":
                setAutoCompleteList(rcsCode)
                break;
            default:
                break;
        }

        if(trigger){
            if (!searchValue && !startDate && !endDate) {
                getDataByDateTime(null, null, null).then((res) => {
                    setVisitData(res);
                    setErrMsg("")
                    setEntity("All");
                }).catch((err) => {
                    console.log(err);
                    setErrMsg(err?.response?.data?.message);
                }).finally(() => {
                    setTrigger(!trigger)
                })
            }
        }

    }, [filterField, searchValue, trigger])

    function handleSubmit(event) {
        event.preventDefault();
        getDataByDateTime(`{"${filterField}":"${searchValue}"}`, startDate, endDate).then((res) => {
            setVisitData(res);
            if(filterField){
                if(searchValue){
                    setEntity(mapEntity[filterField])
                }
                setErrMsg("")
            } else {
                if(startDate && endDate){
                    setEntity("")
                } else if( !startDate && !endDate){
                    setEntity("All")
                }
            }
            
        }).catch((err) => {
            console.log(err);
            setErrMsg(err?.response?.data?.message);
        })
    }

    const handleDateChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    // graph
    const [graphRange, setGraphRange] = useState("6");
    const [graphData, setGraphData] = useState([]);

    const handleRangeChange = (range) => {
        setGraphRange(range);
    };

    useEffect(() => {
        getGraphByPeriod(graphRange).then((res) => {
            setGraphData(res?.responseData);
        }).catch((err) => {
            console.log(err);
        })
    }, [graphRange])

    return (
        <Box
            sx={{
                width: "100%",
            }}
        >
            <CssBaseline />
            {/* header */}
            <Box
                sx={{
                    m: "0.5em",
                    display: "flex",
                    width: "100%",
                }}
            >
                {/* title */}
                <Typography
                    variant="h5"
                    sx={{ fontFamily: "Frutiger_bold" }}
                >
                    {itranslate("Statistics: Public Enquires")}
                </Typography>
            </Box>

            {/* body */}
            <Box sx={{
                flexGrow: 1,
                m: "0.5em",
            }}>
                <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                    {/* Col 1 */}
                    <Grid item xs={4} sm={8} md={6}>

                        {/* input */}
                        <Box
                            sx={{
                                my: 3,
                            }}
                        >
                            <div className='my-5'>
                                {/* date range picker */}
                                <DateRangeComponent
                                    onDateChange={handleDateChange}
                                    date1={startDate}
                                    date2={endDate}
                                />
                            </div>

                            {/* search bar */}
                            <div>
                                

                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo={true}
                                    options={autoCompleteList}
                                    getOptionLabel={option => (option ? option : "")}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={`${option}-${props.id}}`}>
                                                {option}
                                            </li>
                                        )
                                    }}
                                    value={searchValue}
                                    onChange={(e, newValue) => {
                                        e.preventDefault()
                                        setSearchValue(newValue)
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSubmit(e);
                                        }
                                    }}
                                    sx={{
                                        width: "100%",
                                        mb: "0.5em",
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            error={errMsg ? true : false}
                                            helperText={errMsg ? itranslate(errMsg) : itranslate("Application No. | L/E Model code / RC Company")}
                                            id="outlined-required"
                                            variant='outlined'
                                            size="big"
                                            sx={{
                                                mb:2
                                            }}
                                            label={itranslate("Search")}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: <InputAdornment position="start">
                                                    <TextField
                                                        id="filterField"
                                                        select
                                                        label={itranslate("Filter")}
                                                        defaultValue="Application Number"
                                                        variant="filled"
                                                        onChange={(e) => setFilterField(e.target.value)}
                                                        value={filterField}
                                                        size="big"
                                                    >
                                                        {fields.map((option, i ) => (
                                                            <MenuItem key={`${option.value}-${i}`} value={option.value}>
                                                                {/* {itranslate(option.label)} */}
                                                                {itranslate(`${option.label}`)}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </InputAdornment>,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Button
                                                            sx={!matches ? {
                                                                cursor: "pointer",
                                                                color: "#051B44",
                                                            } : {
                                                                cursor: "pointer",
                                                                color: "#051B44",
                                                                fontSize: "8px",
                                                            }}
                                                            onClick={(e) => setSearchValue("")}
                                                        >
                                                            {itranslate("Clear")}
                                                        </Button>
                                                        <Button
                                                            onClick={(e) => {
                                                                handleSubmit(e);
                                                            }}
                                                            aria-label='search'
                                                            variant="contained"
                                                            sx={!matches ? {
                                                                backgroundColor: "#2A598F",
                                                                '&:hover': {
                                                                backgroundColor: "#203C5B",
                                                                },
                                                            } : {
                                                                backgroundColor: "#2A598F",
                                                                '&:hover': {
                                                                backgroundColor: "#203C5B",
                                                                },
                                                                fontSize: "8px",
                                                            }}
                                                        >
                                                            {itranslate("Search")}
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(e) => {
                                                e.preventDefault()
                                                setSearchValue(e.target.value)
                                            }}
                                        />
                                    )}
                                />

                            </div>

                            {/* reset to all time */}
                            <Button
                                sx={{
                                    my: 1,
                                }}
                                onClick={async (e) => {
                                    setStartDate(null);
                                    setEndDate(null);
                                    setSearchValue("");
                                    setFilterField("");
                                    setTrigger(!trigger);
                                }}
                                aria-label='Reset to all time'
                            >
                                {itranslate("reset to all time")}
                            </Button>
                        </Box>

                        {/* amount of visit in each channel */}
                        <Box
                            sx={{
                                my: 3,
                            }}
                        >
                            <Typography>
                                {itranslate(entity)}
                            </Typography>
                            <Typography>
                                {itranslate("Amount of appendix visited by QR Code")}: {visitData?.visitByQrCode}
                            </Typography>
                            
                            <Typography>
                                {itranslate("Amount of restricted url visited")}: {visitData?.ruVisited}
                            </Typography>
                            <Typography>
                                {itranslate("Amount of restricted url created")}: {visitData?.ruCreated}
                            </Typography>
                        </Box>
                        
                        <Divider />
                        

                        {/* graph */}
                        <Box
                            sx={{
                                my: "1em",
                            }}
                        >
                            {graphData && (
                                <Chart data={graphData} rangeChange={handleRangeChange} />
                            )}
                        </Box>

                    </Grid>

                    {/* Col 2 */}
                    <Grid item xs={4} sm={8} md={6}>
                        <CompLeaderboard />
                    </Grid>

                </Grid>

            </Box>
        </Box>
    )
}

export default CompStatPublic