import { useState } from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import formatDate from "../../utils/misc/formatDate";
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";


const ApprovalHistory = (props) => {
  const { upload, sx } = props;

  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || i18n.language);
  // console.log("🚀 ~ ApplicationInfo ~ language:", language)
  const { t : itranslate } = useTranslation();

  // check language
  // window.addEventListener('storage', (e) => {
  //   if (e.key === 'selectedLanguage') {
  //     i18n.changeLanguage(e.newValue);
  //     setLanguage(e.newValue);
  //   }
  // });

  return (
    <List sx={{ ...sx }}>
      <Typography variant="h6">
        {/* Approval History: */}
        {itranslate("Approval History:")}
      </Typography>

      {upload?.approval_history?.length > 0 ? (
        upload?.approval_history?.map((history, index) => (
          <ListItem key={index} sx={{ p: 0 }}>
            <ListItemText
              // primary={
              //   formatDate(history.date, true) +
              //   (history.approved_by
              //     ? ` - Approved by ${history.approved_by}`
              //     : history.passed_from || history.passed_to
              //     ? ` - Passed from ${history.passed_from || ""} to ${
              //         history.passed_to || ""
              //       }`
              //     : "")
              // }
              // secondary={`Remark: ${history.remark || ""}`}

              primary={
                formatDate(history.date, true, language) +
                (history.approved_by
                  ? `${itranslate(" - Approved by")} ${history.approved_by}`
                  : history.passed_from || history.passed_to
                  ? `${itranslate(" - Passed from")} ${history.passed_from || ""} ${itranslate("to")} ${
                      history.passed_to || ""
                    }`
                  : "")
              }
              secondary={` ${itranslate("Remark: ")}  ${history.remark || ""}`}
            />
          </ListItem>
        ))
      ) : (
        <Typography variant="caption">
          {/* No approval history */}
          {itranslate("No approval history")}
        </Typography>
      )}
    </List>
  );
};

export default ApprovalHistory;
