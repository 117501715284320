import "../../../styles/derivedAppendix/DerivedAppendix.css";
import MyComponent from "./MyComponent";

const DerivedAppendixHtml = ({
  application,
  appendix,
  certificates,
  hideModelCerts,
  compLevel,
  certLevel,
  hideTableProps,
  hideManufs
}) => {
  return (
    <MyComponent 
      application={application}
      appendix={appendix}
      certificates={certificates}
      hideModelCerts={hideModelCerts}
      compLevel={compLevel}
      certLevel={certLevel}
      hideTableProps={hideTableProps}
      hideManufs={hideManufs}
    />
  );
};

export default DerivedAppendixHtml;
