import React, { forwardRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";
import getToken from "../../utils/getAuthToken";

const CertPdfDisplay = forwardRef((props, ref) => {
  const HighlightPopup = ({ comment }) =>
    comment.text ? (
      <div className="Highlight__popup">
        {comment.emoji} {comment.text}
      </div>
    ) : null;

  const {
    cert,
    openBackdrop,
    resetHash,
    scrollToHighlightFromHash,
  } = props;

  return (
    <div className="w-[75%] h-full relative overflow-x-auto">
      {cert && (
        <PdfLoader
          url={`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/cert/${
            cert.file
          }?token=${getToken()}`}
          beforeLoad={
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          {(pdfDocument) => (
            <PdfHighlighter
              pdfDocument={pdfDocument}
              enableAreaSelection={(event) => event.altKey}
              onScrollChange={resetHash}
              ref={ref}
              scrollRef={(scrollTo) => {
                console.log(scrollTo);
                this.scrollViewerTo = scrollTo;

                scrollToHighlightFromHash();
              }}
              onSelectionFinished={(
                position,
                content,
                hideTipAndSelection,
                transformSelection
              ) => {
                console.log(position);
                return (
                  <Tip
                    onOpen={transformSelection}
                    onConfirm={(comment) => {
                      hideTipAndSelection();
                    }}
                  />
                );
              }}
              highlightTransform={(
                highlight,
                index,
                setTip,
                hideTip,
                viewportToScaled,
                screenshot,
                isScrolledTo
              ) => {
                const isTextHighlight = !Boolean(
                  highlight.content && highlight.content.image
                );
                const component = isTextHighlight ? (
                  <Highlight
                    isScrolledTo={isScrolledTo}
                    position={highlight.position}
                    comment={highlight.comment}
                  />
                ) : (
                  <AreaHighlight
                    isScrolledTo={isScrolledTo}
                    highlight={highlight}
                    onChange={(boundingRect) => {}}
                  />
                );

                return (
                  <Popup
                    popupContent={<HighlightPopup {...highlight} />}
                    onMouseOver={(popupContent) => {
                      return setTip(highlight, (highlight) => popupContent);
                    }}
                    onMouseOut={hideTip}
                    key={index}
                    children={component}
                  />
                );
              }}
              highlights={cert.position_data}
            />
          )}
        </PdfLoader>
      )}
    </div>
  );
});

export default CertPdfDisplay;
