import { Layout } from "react-admin";
import { CustomMenu } from "./Menu";
import { MyAppBar } from "./MyAppBar";

export const CustomLayout = (props) => {
  return (
    <Layout
      {...props}
      menu={CustomMenu}
      appBar={MyAppBar}
      sx={{ marginTop: "10px" }}
    />
  );
};
