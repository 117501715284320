import { Text } from "@react-pdf/renderer";


const formatParser = (text, format) => {
  // Format:
  // **text**  Ultra bold
  // __text__ underline
  // **__text__** bold+underline
  // **~text~** bold
  // `text` switch font family (to display missing symbol)
  // ##text## switch font family (to display chinese)
  //To add more format, simply nest a new function inside index%2===0 block
  const TextComponent = format === "plainHtml" ? "span" : Text;
  
  function traditionalChinese(textOutsideSymbols, textArray) {
    const arr4 = textOutsideSymbols.split("##");
    arr4.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        styledText =
          format === "plainHtml" ? (
            <span key={Math.random()}>{subText}</span>
          ) : (
            // We replace all white space after a new line(\n) with \u00A0 for non-breaking space in pdf, in html, it is handled by css "white-space: pre-wrap"
            <Text key={Math.random()}>
              {subText.replace(/(?<=\n)\s+/g, (match) =>
                "\u00A0".repeat(match.length)
              )}
            </Text>
          );
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ fontFamily: "mYuenLight" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  function switchFontFamily(textOutsideUnderline, textArray) {
    const arr3 = textOutsideUnderline.split("`");
    arr3.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        traditionalChinese(subText, textArray);
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ fontFamily: "DejaVu Sans" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  function checkUnderline(textOutsideBold, textArray) {
    const arr2 = textOutsideBold.split("__");
    arr2.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        switchFontFamily(subText, textArray);
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ textDecoration: "underline" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  if (text) {
    const arr = text.split("**");
    const newTextArr = [];
    // Loop over split text
    arr.forEach((text, index) => {
      //outside **..** block, either unbolded text or ** does not exist
      if (index % 2 === 0) {
        checkUnderline(text, newTextArr);
      }
      // If its an odd element then it is inside **...** block
      else if (index % 2 !== 0) {
        let styledText;
        if (text.startsWith("__") && text.endsWith("__")) {
          // for example, **__boldedAndUnderlined__**
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{
                  textDecoration: "underline",
                  fontFamily: "Frutiger_light",
                  fontWeight: 700,
                }}
              >
                {text.slice(2, -2)}
              </span>
            ) : (
              <Text
                key={Math.random()}
                style={{
                  textDecoration: "underline",
                  fontFamily: "Frutiger_bold",
                }}
              >
                {text
                  .slice(2, -2)
                  .replace(/(?<=\n)\s+/g, (match) =>
                    "\u00A0".repeat(match.length)
                  )}
              </Text>
            );
        } else if (text.startsWith("~") && text.endsWith("~")) {
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{ fontFamily: "Frutiger_light", fontWeight: 700 }}
              >
                {text.slice(1, -1)}
              </span>
            ) : (
              <Text key={Math.random()} style={{ fontFamily: "Frutiger_bold" }}>
                {text
                  .slice(1, -1)
                  .replace(/(?<=\n)\s+/g, (match) =>
                    "\u00A0".repeat(match.length)
                  )}
              </Text>
            );
        } else {
          // Wrap with bold text style
          styledText =
            format === "plainHtml" ? (
              <span
                key={Math.random()}
                style={{ fontFamily: "Frutiger_Ultra_bold" }}
              >
                {text}
              </span>
            ) : (
              <Text
                key={Math.random()}
                style={{ fontFamily: "Frutiger_Ultra_bold" }}
              >
                {text.replace(/(?<=\n)\s+/g, (match) =>
                  "\u00A0".repeat(match.length)
                )}
              </Text>
            );
        }
        newTextArr.push(styledText);
      }
    });
    return newTextArr;
  }
};




function parseContentJson(input, rcItem, format) {
  const {manufacturer, models, components} = rcItem;

  let temp = input;
  const manufacturerName = "{manufacturer_name}";
  const manufacturerAddress = "{manufacturer_address}";
  const modelsName = "{models_name}";
  const safetyComponentsName = "{safety_components_name}";

  // Check if {manufacturer_name} is present in the input and replace with manufacturer name
  if (temp.includes(manufacturerName)) temp = temp.replace(new RegExp(manufacturerName, 'g'), manufacturer[0]?.provided_manufacturer_name);
  // if (temp.includes(manufacturerName)) {
  //  temp = (temp !== null || undefined | "") ? temp.replace(new RegExp(manufacturerName, 'g'), manufacturer[0]?.provided_manufacturer_name) : temp.replace(new RegExp(manufacturerName, 'g'), "");
  // }
  
  
  // Check if {manufacturer_address} is present in the input and replace with manufacturer address
  if (temp.includes(manufacturerAddress)) temp = temp.replace(new RegExp(manufacturerAddress, 'g'), manufacturer[0]?.manufacturer_address);
  

  // Check if {models_name} is present in the input and replace with models information
  if (temp.includes(modelsName)) {
   // extract model names, model codes, and unique IDs
    const modelDetails = models.map(model => {
      const uniqueCode = model?.model_code + "/" +model?.unique_id_lastDigit;
      return {
        provided_model_name: model?.provided_model_name,
        unique_code: uniqueCode
      };
    });
    const modelsString = modelDetails.map(detail => `${detail?.unique_code} - ${detail?.provided_model_name}`).join(", ");
    temp = temp.replace("{models_name}", modelsString);
  };


  // Check if {safety_components_name} is present in the input and replace with safety components information
  if (temp.includes(safetyComponentsName)) {
    const componentDetails = components.map(comp => {
      return {
        provided_component_name: comp?.provided_comp_name,
        component_code: comp?.component_code,
        component_type: comp?.type
      };
    });
    const componentsString = componentDetails.map(detail => `${detail?.component_code} - ${detail?.provided_component_name} - ${detail?.component_type}`).join(", ");
    temp = temp.replace(new RegExp(safetyComponentsName, 'g'), componentsString);
  };

  // return temp

  const parsedFormat = formatParser(temp, format);
  return parsedFormat;
}

export default parseContentJson;
