import React from "react";
import { Button, List, ListItem, ListItemText, Typography } from "@mui/material";
import keyToField from "../../../utils/keyToField";
import { useTranslation } from 'react-i18next';


const DerivedHideManufIdsSC = ({
  application,
  appendix,
  setAppendix,
  component_type,
  standalones,
  comps,
  setCompLevel
}) => {
  const { t : itranslate } = useTranslation();

  const combinedManufacturers = (standalones?.manufacturers ?? []).concat(application?.manufacturer ?? []);

  function getCompList(manu) {
    // return comps["LandingDoorLockingDevice"].filter((comp) => comp.manufacturer_id === manu._id);
    return comps[component_type].filter((comp) => comp.manufacturer_id === manu._id);
  }

  const manufs = combinedManufacturers?.filter((manu) => getCompList(manu).length > 0);

  const uniqueManufacturers = Array.from(new Set(manufs?.map(obj => obj._id))).map(_id => {
    const objectsWithId = manufs?.filter(obj => obj._id === _id);
    const sortedByDate = objectsWithId.sort((a, b) => {
      const dateA = a.approval_date ? new Date(a.approval_date) : new Date(0);
      const dateB = b.approval_date ? new Date(b.approval_date) : new Date(0);
      return dateB - dateA;
    });
    return sortedByDate[0];
  });



  const handleHide2 = ({ component_type, cert_id }) => {
    let section = [...appendix["hideSCCerts"]];
    if (!section.some(item => item.component_type === component_type && item.cert_id === cert_id)) {
      section.push({ component_type, cert_id });
    }
    setAppendix({ ...appendix, hideSCCerts: section });
  };



  const handleUnhide2 = ({ component_type, cert_id }) => {
    let section = appendix["hideSCCerts"].filter((item) => item.cert_id !== cert_id || item.component_type !== component_type);
    setAppendix({ ...appendix, hideSCCerts: section });
  };



  return (
    <>
      <Typography
        style={{
          width: "100%",
          fontFamily: "Frutiger_bold",
          fontSize: "18px",
          marginTop: "15px",
          marginBottom: "10px",
          textDecoration: "underline"
        }}
      >
        {itranslate(keyToField[component_type])}
      </Typography>

      {uniqueManufacturers.map((manu, i) => {
        const list = getCompList(manu);

        const uniqueComponents = Array.from(new Set(list.map(item => item.type_test_certificate_id[0])))
          .map(typeTestId => list.find(item => item.type_test_certificate_id[0] === typeTestId));

        const componentsOfManu = uniqueComponents.filter((comp) => comp.manufacturer_id === manu._id);

        if (componentsOfManu.length > 0) {
          return (
            <List key={i}>
              <ListItem sx={{ marginTop: "20px" }}>
                {manu.short_name || manu.provided_name}
              </ListItem>

              {componentsOfManu.map((comp, index) => {
                const isHidden2 = appendix["hideSCCerts"].find(
                  (item) => item.cert_id === comp._id && item.component_type === component_type
                );

                return (
                  <List key={index}>
                    <ListItem
                      onMouseEnter={() => setCompLevel({ component_type, cert_id: comp._id })}
                      onMouseLeave={() => setCompLevel(null)}
                      secondaryAction={
                        <Button
                          sx={{ marginRight: "7px" }}
                          onClick={() => (isHidden2 ? handleUnhide2({ component_type, cert_id: comp._id }) : handleHide2({ component_type, cert_id: comp._id }))}
                        >
                          {isHidden2 ? itranslate("Unhide") : itranslate("Hide")}
                        </Button>
                      }
                    >
                      <ListItemText
                        primary={comp.provided_comp_name || comp.provided_name}
                      />
                    </ListItem>
                  </List>
                );
              })}
            </List>
          );
        }
        return null;
      })}
    </>
  );
};

export default DerivedHideManufIdsSC;
