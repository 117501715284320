import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Divider, Stack, Button, TextField } from "@mui/material";
import { useNotify } from "react-admin";
import areEqual from "../../utils/misc/arraysAreEqual";
import DiscardButton from "../../buttons/DiscardButton";
import { useTranslation } from 'react-i18next';


const ValidationResult = (props) => {
  const {
    upload,
    setUpload,
    handleSend,
    validate,
    application_type,
    isCreating,
    isUpdating,
  } = props;
    // console.log("🚀 ~ ValidationResult ~ upload:", upload)

  const { t : itranslate } = useTranslation();

  const [certChanged, setCertChanged] = useState(false);
  function getFailurePercent(data) {
    const numbersOfFailedCheck = data?.aiFeedBack?.filter(
      (item) => item.failed && !item.fault
    )?.length;
    const numbersOfCheck = data?.aiFeedBack?.length;
    return Math.floor((numbersOfFailedCheck / numbersOfCheck) * 100);
  }

  const notify = useNotify();
  const allowSend =
    (!certChanged &&
      getFailurePercent(upload) <= 10 &&
      upload?.aiFeedBack.every((item) => {
        if (item.fault) return item.comment && item.comment.trim() !== "";
        else return true;
      })) ||
    upload.validationErrorCount >= 3;

  const style = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  useEffect(() => {
    if (upload && application_type !== "update") {
       
      //Check all cert in upload, if different from validated certs, set certChanged to true
      const tecModel = upload.model.flatMap((model) =>
        model.manufacturer_files.flatMap((file) => file.type_test_certificate_id)
      ); 

      const fqModel = upload.model.flatMap((model) =>
        model.manufacturer_files.flatMap((file) => file.full_quality_certificate_id)
      );

      const tecComp = Object.values(upload.app_type.components)
        .flat()
        .flatMap((comp) => comp.type_test_certificate_id);

      const iso = upload.manufacturer.map((manu) => manu.iso_certificate_id);
      
      const compat = upload.model
        .flatMap((model) => model.manufacturer_files.map((file) => file.compatibility_cert_id))
        .filter((compat) => compat);
      //compat maybe undefined

      const existedCerts = [
        ...tecModel,
        ...fqModel,
        ...tecComp,
        ...iso,
        ...compat,
      ];

      const existedCertsId = existedCerts.map((cert) => cert.id);

      if (
        !areEqual(
          upload.certs.map((cert) => cert.id),
          existedCertsId
        )
      )
        setCertChanged(true);
    }
  }, []);

  const blameAI = (index) => {
    let clone = { ...upload };
    clone.aiFeedBack = clone.aiFeedBack.map((item, i) => {
      if (i === index) {
        return { ...item, fault: !item.fault };
      } else return item;
    });
    setUpload(clone);
  };

  const comment = (value, index) => {
    let clone = { ...upload };
    clone.aiFeedBack = clone.aiFeedBack.map((item, i) => {
      if (i === index) {
        return { ...item, comment: value };
      } else return item;
    });
    setUpload(clone);
  };

  return (
    <Box>
      <Stack
        spacing={2}
        direction="column"
        divider={<Divider orientation="horizontal" flexItem />}
        sx={{ "&:first-of-type": { mt: 2 } }}
      >
        {certChanged ? (
          <Typography
            sx={{
              color: "red",
            }}
          >
            {itranslate("Certificates have been changed, please re-validate")}
          </Typography>
        ) : (
          upload?.aiFeedBack?.map((result, i) => {
            return (
              <React.Fragment key={i}>
                <div style={style}>

                  <Typography
                    sx={{
                      ...(result.fault ? { color: "green" } : { color: "red" }),
                      ...(result.fault && { textDecoration: "line-through" }),
                      width: "80%",
                    }}
                  >
                    {/* {result.message}{" "}
                    {!certChanged && `(${result.type}: ${result.name})`} */}
                    {itranslate(result.message)}{" "}
                    {/* {!certChanged && `(${itranslate(result.type)}: ${result.name})`} */}
                    {!certChanged && `(${itranslate(`${result.type}`)}: ${result.name})`}

                  </Typography>

                  {!certChanged && (
                    <Button
                      variant="outlined"
                      color={result.fault ? "success" : "warning"}
                      onClick={() => blameAI(i)}
                    >
                      {result.fault
                        ? itranslate("Unmark AI's fault")
                        : itranslate("Mark as AI's fault")}
                    </Button>
                  )}
                </div>

                {result.fault && (
                  <TextField
                    onChange={(e) => comment(e.target.value, i)}
                    value={result.comment || ""}
                    label={itranslate("Please add comment here (required)")}
                    required
                  />
                )}
                
              </React.Fragment>
            );
          })
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          
          <DiscardButton id={upload._id} />

          <Box>
            {validate}
            <Button
              color="primary"
              variant="outlined"
              disabled={!allowSend || isCreating || isUpdating}
              onClick={() => {
                if (allowSend) {
                  handleSend();
                } else {
                  notify(
                    itranslate(`Error creating record, ensure the AI validation has been passed!`)
                  );
                }
              }}
            >
              {itranslate("Send (Submit to EMSD)")}
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default ValidationResult;
