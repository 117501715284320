/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import {
    Tooltip,
    Typography
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';

// components
import EnhancedTableHead from "../CompEnhancedTable/ModelListTable/EnhancedTableHead.jsx";
import InputSearchBar from "../CompEnhancedTable/ModelListTable/InputSearchBar"
// import ModelShow from "../../../_components/CompRestrictedUrlMgt/CreateStage1-v2/ShowModel.jsx"


// custom hooks
import { getModelList } from "../../../../admin/useHooks/useModels/index.jsx";

// redux
import { useSelector, useDispatch } from "react-redux";
import {setStage} from "../../../../admin/redux/stageSlice.js";

//usehooks
import { useSessionStorage } from "@uidotdev/usehooks";

import { headCells } from '../constants/headCells.js'
import { ShowBtn } from '../../../../_hoc/Buttons/ShowBtn.jsx';

// _hoc
import BasicModal from '../../../../_hoc/modals/BasicModal.jsx';
import { useNavigate, useLocation } from 'react-router-dom';

// import { useTranslate } from 'react-admin';
import { useTranslation } from 'react-i18next';


const BackBtn = styled(Button)(({ theme }) => ({
    color: "#434343",
    backgroundColor: "white",
    '&:hover': {
        color: "white",
        backgroundColor: "#434343",
    },
    variant:"outlined"
}));

export default function CreateStage2ModelList({
    setNextBtnDisabled, nextBtnDisabled,
    dataModel, setDataModel, 
    setModel_manu_sc_id_arr,
    setCertsChosen
}) {
    // const translate = useTranslate();
    const { t : itranslate } = useTranslation();
    const [tableHeadCells, setTableHeadCells] = useState([])

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('client_user');
    const [selected, setSelected] = useSessionStorage("CreateStage2ModelList_selected", []);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [isLoading, setIsLoading] = useState(true);
    const [openLoadingModal, setOpenLoadingModal] = useState(true)
    const [openBackOptionsModal, setOpenBackOptionsModal] = useState(false)

    const [totalCount, setTotalCount] = useState(0)
    const [range, setRange] = useState(`[0, 9]`)
    const [filter, setFilter] = useState(`{}`)
    const [sort, setSort] = useState(`["model_code","DESC"]`)

    // useNavigate
    const navigate = useNavigate();

    // redux
    const dispatch = useDispatch();
    const stageStore = useSelector((state) => state.stage.value);
    const stage = structuredClone(stageStore);
    const optionStore = useSelector((state) => state.option.value);
    const option = structuredClone(optionStore);
    const ruPageDataStore = useSelector((state) => state.ruPageData.value);
    const ruPageData = structuredClone(ruPageDataStore);

    const [isExistingSummary, setIsExistingSummary] = useState(ruPageData?.model_manu_sc_id_arr?.length || ruPageData?.sc_id_arr?.length)

    let previousStage = useSelector((state) => state.stage.previousValue)


    const handleBack = () => {
        setOpenBackOptionsModal(true)
    }


    useEffect(() => {
        if (option === "models") {
            if (stage === "2_1") {
                setTableHeadCells(headCells.models)
            }
        }
        if(previousStage === "more options?"){
            setModel_manu_sc_id_arr([])
            setCertsChosen({})

            setSelected([])
        }
    }, [stage])

    // setRange when page changes
    useEffect(() => {
        setRange(`[${page * rowsPerPage},${page * rowsPerPage + rowsPerPage - 1}]`)
    }, [page, rowsPerPage])

    // setSort
    const handleRequestSort = (event, property) => {
        event.preventDefault()
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort(`["${property}","${isAsc ? "DESC" : "ASC"}"]`)
    };

    // handle query value update
    useEffect(() => {
        if (option === "models") {
            if (stage === "2_1") {

                getModelList(filter, range, sort).then((res) => {
                    setDataModel(res?.data)
                    setTotalCount(+ res?.headers["x-total-count"] || 999999)
                    setIsLoading(false)
                }).catch((err) => {
                    console.log("🚀 ~ file: CreateStage2ModelList.jsx:97 ~ getModelList ~ err:", err)
                    setIsLoading(false)
                })
            }
        }
    }, [filter, range, sort])

    useEffect(() => {
        if (isLoading === false) {
            setOpenLoadingModal(false)
        }
    }, [isLoading])


    // seletAll rows
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = dataModel.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    // select one
    const handleClick = (event, _id) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    // setPage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // set rowsPerPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, rowsPerPage - dataModel?.length) : 0;

    // row btn handlers
    const handleView = (rowInfo) => {
        window.open(`lift_rc#/models/${rowInfo._id}/show`, '_blank');
    }

    // handleAdd
    useEffect(() => {
        if (selected.length > 0) {
            setNextBtnDisabled(false)
        } else {
            setNextBtnDisabled(true)
        }
        setModel_manu_sc_id_arr(selected.map(v => {
            return {
                model: v, // model id
                relatedManufacturersChosen: [],
                relatedScChosen: [],
            }
        }))
    }, [selected])


    function handleSelectAllFoundItemsInAllPages() {
        if(selected.length === 0){
            setIsLoading(true)
            setOpenLoadingModal(true)
            getModelList(filter, `[0,999999]`, sort).then((res) => {
                // once they all get selected, it will update model_manu_sc_id_arr
                setSelected(res?.data.map(v => v._id))
            }).finally(() => {
                setIsLoading(false)
                setOpenLoadingModal(false)
            })
        } else {
            setSelected([])
        }
    }

    const handleNext = (obj) => {
        obj.e.preventDefault()
        if(option === 'models'){
            if(stage === '2_1'){
                dispatch(setStage("2_2"))
            }
        }
    }

    return (
        <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>

            {/* Backdrop */}
            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoadingModal}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }

            <Box
                component={"div"}
                sx={{
                    height: "5em",
                    display: "flex",
                    justifyContent: "space-between",
                }}
                aria-label="menu for the table"
            >
                {/* BackBtn */}
                <Box
                    component={"div"}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                    aria-label="Back button and Help Icon"
                >
                    <BackBtn 
                        onClick={handleBack}
                        size="small"
                        sx={{
                            mb: 6,
                            border: "1px solid #434343",
                        }}
                    >
                        {/* {translate("button.back")}  */}
                        {itranslate("back")}
                    </BackBtn>

                </Box>

                {/* handleSelectAllFoundItemsInAllPages */}
                {
                    (selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === ""))  && (
                        <Button
                            onClick={handleSelectAllFoundItemsInAllPages}
                            aria-label='select all found items in all pages'
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{
                                float: 'right',
                                mb: 6
                            }}
                        >
                            {/* {translate("phase2.createRu.selectAllFoundItemsInAllPages")} */}
                            {itranslate("selectAllFoundItemsInAllPages")}
                        </Button>
                    )
                }
                {
                    !(selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === ""))  && (
                        <>
                            <Typography
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    mr: 2
                                }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                <Typography
                                    bgcolor="#648BC5"
                                    component={"span"}
                                    height={"1.5em"}
                                    className='rounded text-white font-weight-bold text-center px-4'
                                >
                                    {/* {selected.length} {translate("phase2.ru.enhancedTableToolbar.numSelected")} */}
                                    {selected.length} {itranslate("numSelected")}
                                </Typography>
                            </Typography>
                            <Button
                                onClick={handleSelectAllFoundItemsInAllPages}
                                aria-label='select all found items in all pages'
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{
                                    float: 'right',
                                    mb: 6
                                }}
                            >
                                {/* {translate("phase2.createRu.deselect")} */}
                                {itranslate("deselect")}
                            </Button>
                        </>
                    )
                }
            </Box>

            {/* outside the box */}
            <Box 
                sx={{ 
                    width: '100%',
                    boxShadow: "0 0 0 2rem rgba(246,248,249)",
                }}
            >
                {/* inside the box */}
                <Paper sx={{ width: '100%', mb: 2, overflowX: "auto" }}>
                    <InputSearchBar
                        selected={selected}
                        numSelected={selected.length}
                        setFilter={setFilter}
                    />

                    {
                        (selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === "")) && !isLoading && (
                            <div className='text-red-500 text-center text-2xl mb-4'>
                                {/* {translate("phase2.createRu.selectAtLeastOne")} */}
                                {itranslate("selectAtLeastOne")}
                            </div>
                        )
                    }
                    {
                        !(selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === ""))  && (
                            <div className='text-red-500 text-center text-2xl mb-4 invisible h-8'>
                            </div>
                        )
                    }
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size='medium'
                        >
                            {
                                !isLoading && dataModel?.length > 0 &&
                                (
                                    <>
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            handleSelectAllFoundItemsInAllPages={handleSelectAllFoundItemsInAllPages}
                                            onRequestSort={handleRequestSort}
                                            rowCount={dataModel?.length}
                                            tableHeadCells={tableHeadCells}
                                        />
                                        <TableBody>
                                            {dataModel?.length > 0 && dataModel.map((row, index) => {
                                                const isItemSelected = isSelected(row._id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                let rowTypeWithSpace = !row.type.includes(" ") ? row.type.split(/(?=[A-Z])/).join(" ") : row.type
                                                // let rowTypeWithSpace = !row.type.includes(" ") ? row.type.split(/(?=[A-Z])/) : row.type
                                                
                                                
                                                return (
                                                    <TableRow
                                                        key={row._id}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                                onClick={(event) => handleClick(event, row._id)}
                                                                sx={{ cursor: 'pointer' }}
                                                                aria-checked={isItemSelected}
                                                            />
                                                            <Box component="span" id={labelId} sx={visuallyHidden}>
                                                                {isItemSelected ? itranslate("selected") : itranslate("not yet selected")}
                                                            </Box>
                                                        </TableCell>

                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            onClick={(event) => handleClick(event, row._id)}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            {row.model_code}
                                                        </TableCell>
                                                        
                                                        <TableCell
                                                            sx={{
                                                                cursor: 'pointer',
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "8em"
                                                            }}
                                                            onClick={(event) => handleClick(event, row._id)}
                                                        >{row.provided_model_name}</TableCell>

                                                        <Tooltip title={itranslate(rowTypeWithSpace)}
                                                            placement="bottom"
                                                            arrow
                                                            followCursor
                                                        >
                                                            <TableCell
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "8em"
                                                                }}
                                                                onClick={(event) => handleClick(event, row._id)}
                                                            >{itranslate(rowTypeWithSpace)}</TableCell>
                                                        </Tooltip>

                                                        <TableCell
                                                            sx={{
                                                                cursor: 'pointer',
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                maxWidth: "8em"
                                                            }}
                                                            onClick={(event) => handleClick(event, row._id)}
                                                        >
                                                            {
                                                                row.manufacturersPopulated.map((manu, index) => (
                                                                    <Tooltip title={manu.provided_name}
                                                                        key={index}
                                                                        placement="bottom"
                                                                        arrow
                                                                        followCursor
                                                                    >
                                                                        <div className="cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden max-w-[10em]">
                                                                            {manu.provided_name}
                                                                        </div>
                                                                    </Tooltip>
                                                                ))
                                                            }
                                                        </TableCell>

                                                        <TableCell
                                                            size="small"
                                                        >
                                                            <ShowBtn 
                                                                handleView={handleView}
                                                                row={row}
                                                                code={row.model_code}
                                                                size='small'
                                                            />

                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}

                                            {/* fill up the empty dataModel for last page's items of the list */}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    className={`h-20`}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}

                                        </TableBody>
                                    </>
                                )
                            }
                        </Table>
                    </TableContainer>

                    {!isLoading && dataModel?.length === 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <h1>
                                    {/* translate("phase2.ru.noRuFound") */}
                                    {itranslate("noRuFound")}
                                </h1>
                            </div>
                        </Box>
                    )}

                    {
                        !isLoading && dataModel?.length >= 0 && (
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={itranslate("Rows per page")}
                            />
                        )
                    }
                </Paper>
            </Box>
            <Button
                type="submit"
                sx={{
                    // center the button
                    display: 'flex',
                    m: "auto",
                    width: "95%",
                }}
                variant="contained"
                color="primary"
                onClick={(e) => handleNext({ e })}
                aria-label="submit"
                disabled={nextBtnDisabled}
            >
                {/* {translate("phase2.createRu.next")} */}
                {itranslate("next")}
            </Button>

            {/* Back option modal */}
            <BasicModal open={openBackOptionsModal} setOpen={setOpenBackOptionsModal}>
                <Box className='flex flex-col gap-3'
                    component={"div"}
                >
                    <Button
                        onClick={
                            () => {
                                if(!(selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === ""))){
                                    setSelected([])
                                }
                                navigate(`/restrictedUrls`)
                            }
                            
                        }
                    >
                        {/* {translate("phase2.createRu.gotoUrlTable")} */}
                        {itranslate("gotoUrlTable")}
                    </Button>
                    <Button
                        onClick={() => {
                            if(!(selected.length <= 0 || selected.every(v => v === undefined ||  v === null || v === ""))){
                                setSelected([])
                            }
                            dispatch(setStage("1"))
                        }}
                    >
                        {/* {translate("phase2.createRu.gotoPerspectiveOptions")} */}
                        {itranslate("gotoPerspectiveOptions")}
                    </Button>
                    <Button
                        onClick={() => {
                            dispatch(setStage("more options?"))
                        }}
                        disabled={
                            // make it object format and check if they both empty
                            !isExistingSummary
                        }
                    >
                        {/* {`${translate("phase2.createRu.gotoSummary")} ( ${isExistingSummary ? translate("phase2.createRu.reviewSelectedOptions") : translate("phase2.createRu.nothingToReview") }  )`} */}
                        {`${itranslate("gotoSummary")} ( ${isExistingSummary ? itranslate("reviewSelectedOptions") : itranslate("nothingToReview") }  )`}
                    </Button>
                </Box>
            </BasicModal>
        </Container>
    );
}