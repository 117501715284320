import React from "react";
import _ from "lodash";
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  EditButton,
  DateField,
  FunctionField,
  ShowButton,
  WithRecord,
  ReferenceArrayField,
  SingleFieldList,
  ArrayField,
} from "react-admin";
import ApplicationFilter from "./filters/ApplicationFilter";
import applicationsExporter from "./exporters/applicationsExporter";
import updateTypeMap from "../utils/applications/updateTypeMap";
import keyToField from "../utils/keyToField";
import ObjectID from "bson-objectid";
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';


export const ApplicationList = () => {
  const { t : itranslate } = useTranslation();
  return (
    <List
      filters=<ApplicationFilter />
      exporter={applicationsExporter}
      // title={`- Applications`}
      // title={`- ${itranslate('application.Applications')}`}
      title={`- ${itranslate('Applications')}`}
      sort={{ field: "updatedAt", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false} isRowSelectable={(x) => false}>

        {/* Leaving this here for debugging */}
        {/* <FunctionField
          label="Click"
          render={(record) =>{
            return (
              <>
                <button onClick={() => console.log(record)}>click</button>
              </>
          )}}
        />  */}


        <FunctionField
          render={(record) =>
            record.validationStatus
              ? record.temp_application_number
              : record.application_number
          }
          label={itranslate('Application number')}
          source="application_number"
        />


        
        {/* <FunctionField
          render={(record) =>
            record.validationStatus
              ? updateTypeMap[record.update_type] ?? "Brand New Application" //AI prechecking update application has different update type
              : record.update_type ?? "Brand New Application"
          }
          // label="Type of Application"
          label={itranslate('application.Type of Application')}
          source="update_type"
        /> */}
        

        <FunctionField
          // sx={{ whiteSpace: "nowrap" }}
          sx={{   
            whiteSpace: "normal", 
            overflowWrap: "break-word"
          }}
          render={(record) => {
            const updateType = updateTypeMap[record.update_type?.trim()]; // Trim and get from map
            // const translatedUpdateType = updateType ? itranslate(`application.${updateType}`) : null;
            const translatedUpdateType = updateType ? itranslate(`${updateType}`) : null;

            // Handle validation status and possible undefined cases
            // return record.validationStatus 
            //   ? translatedUpdateType || itranslate("application.Brand New Application")
            //   : (record.update_type && itranslate(`application.${record.update_type}`)) || itranslate("application.Brand New Application");
            return record.validationStatus 
            ? translatedUpdateType || itranslate("Brand New Application")
            : (record.update_type && itranslate(`${record.update_type}`)) || itranslate("Brand New Application");
          }}
          label={itranslate('Type of Application')}
          source="update_type"
        />



        {/* <FunctionField
          render={(record) => keyToField[record?.app_type?.type]}
          // label="Type of Machine"
          label={itranslate('application.Type of Machine')}
          source="app_type.type"
        /> */}

        <FunctionField
          render={(record) => {
            const key = keyToField[record?.app_type?.type];
            return itranslate(`${key}`);
          }}
           label={itranslate('Type of Machine')}
            source="app_type.type"
        />



        {/* {window.location.pathname !== `/lift_rc` ? (
          <FunctionField
            render={(record) => {
              return keyToField[record?.validationStatus] ?? record?.status;
            }}
            // label="Status"
            label={itranslate('application.Status')}
            source="status_for_sorting" //status_for_sorting is added in backend by $addField for sorting
          />
        ) : (
          <FunctionField
            render={(record) => {
              return record?.status?.split(" ")[0] === "Pending" ||
                record?.status === "ReturningClarifying"
                ? "Processing"
                : keyToField[record.validationStatus] ?? record.status;
            }}
            // label="Status"
            label={itranslate('application.Status')}
            source="status_for_sorting"
          />
        )} */}


        {window.location.pathname !== `/lift_rc` ? (
          <FunctionField
            sx={{   
              // whiteSpace: "normal", 
              // overflowWrap: "break-word"
              whiteSpace: "nowrap"
            }}
            render={(record) => {
              const key = keyToField[record?.validationStatus];

              // return itranslate(`application.${key}`) ?? itranslate(`application.${record.status}`);

              // return key !== undefined ? itranslate(`application.${key}`) : itranslate(`application.${record.status}`);
              return key !== undefined ? itranslate(`${key}`) : itranslate(`${record.status}`);
            }}
            label={itranslate('Status')}
            source="status_for_sorting" //status_for_sorting is added in backend by $addField for sorting
          />
        ) : (
          <FunctionField
            sx={{   
              // whiteSpace: "normal", 
              // overflowWrap: "break-word"
               whiteSpace: "nowrap"
            }}
            render={(record) => {
              const key = keyToField[record?.validationStatus]

              return record?.status?.split(" ")[0] === "Pending" ||
                record?.status === itranslate("ReturningClarifying")
                ? itranslate("Processing")
                // : itranslate(`application.${key}`) ?? itranslate(`application.${record.status}`);
                : key !== undefined ? itranslate(`${key}`) : itranslate(`${record.status}`);
            }}
            label={itranslate('Status')}
            source="status_for_sorting"
          />
        )}




        {/* <FunctionField
          render={(record) =>
            ObjectID?.isValid(record?.model?.[0]) ? (
              <ReferenceArrayField
                label="Models"
                reference="models"
                source="model"
              >
                <SingleFieldList linkType={false}>
                  <TextField source="provided_model_name" />
                </SingleFieldList>
              </ReferenceArrayField>
            ) : (
              <ArrayField source="model">
                <SingleFieldList linkType={false}>
                  <TextField source="provided_model_name" />
                </SingleFieldList>
              </ArrayField>
            )
          }
          label="Models"
          source="model_for_sorting" //model_for_sorting is added in backend by $addField for sorting
        /> */}


        <FunctionField
          label={itranslate('Models')}
          source="model_for_sorting" 
          render={(record) => (
            <React.Fragment>
              <Typography sx={{
                fontSize: "1.09375rem",
                lineHeight: "1.43",
              }}>
                {record.model_for_sorting}
              </Typography>            
            </React.Fragment>
          )}
        />  
        

        {window.location.pathname === `/lift_emsd` && (
          <TextField 
            sx={{ whiteSpace: "nowrap" }}
            label={itranslate('Role Assigned')}
            source="current_role_name" 
          />
        )}
        

        {window.location.pathname !== `/lift_rc` && (
          <ReferenceField 
            label={itranslate('RC')}
            source="rc_id" reference="rcs" link="show"
          >
            <FunctionField
              render={(record) => {
                let rcDisplay = "";
                rcDisplay = record?.rc_number_lift;
                if (record?.rc_number_escalator)
                  rcDisplay += " / " + record?.rc_number_escalator;
                if (record?.address?.company)
                  rcDisplay += " / " + record?.address?.company;
                return rcDisplay || record.name;
              }}
            />
          </ReferenceField>
        )}

        {window.location.pathname === `/lift_emsd` && (
          <ReferenceField
            label={itranslate('User')}
            source="owner_id"
            reference="users"
            link="show"
          >
            <TextField source="username" />
          </ReferenceField>
        )}

        <DateField 
          source="created_at" 
          label={itranslate('application.Application Date')}
        />

        {/* <DateField source="updatedAt" label="Last modified" /> */}
        {/* <DateField source="approval_date" label="Approval Date" /> */}

{/* 
        {window.location.pathname === `/lift_emsd` && window.location.hash === "#/applications" ? (
          <DateField source="updatedAt" label="Last modified" />
        ) : (
          <DateField source="approval_date" label="Approval Date" />
        )} 
         */}


        {window.location.pathname === `/lift_emsd` && window.location.hash === "#/v2/applications" ? (
          <DateField 
            source="approval_date" 
            label={itranslate('Approval Date')}
          />
        ) : (
          <DateField 
            source="updatedAt" 
            label={itranslate('Last modified')}
          />
        )} 
        

        <WithRecord
          // editButton redirect to "/resources/:id"
          // changed the resource = "application_new" to make it redirect to /application_new/:id
          render={(record) => {
            const allowAppEdit =
              window.location.pathname === "/lift_rc" &&
              (record.status === "Clarifying" || record.status === "AIerror");
            const allowAppPresubmitEdit =
              window.location.pathname === "/lift_rc" &&
              record.validationStatus &&
              record.validationStatus !== "validating";
            const allowShow =
              !record.validationStatus &&
              record.status !== "Pending AI" &&
              (record.status !== "Clarifying" ||
                window.location.pathname === "/lift_emsd");
            return allowAppEdit ? (
              <EditButton label={`${itranslate('Edit')}`}/>
            ) : allowAppPresubmitEdit ? (
              <EditButton label={`${itranslate('Edit')}`} resource="applications_new" />
            ) : (
              allowShow && <ShowButton label={`${itranslate('Show')}`} sx={{ padding: '0' }} />
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export { default as ApplicationShow } from "./applications/ApplicationShow";
export { default as ApplicationEdit } from "./applications/ApplicationEdit";
export { default as ApplicationCreate } from "./applications/ApplicationCreate";
