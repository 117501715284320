import React, { useState, useEffect } from "react";

// icon
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

export default function DateRangeComponent({ onDateChange, date1, date2, useCaseType }){
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  
    useEffect(() => {
      if(!date1 && !date2){
          setStartDate(null);
          setEndDate(null);
      }
    }, [date1, date2]);
  
    const handleStartDateChange = (event) => {
      const newStartDate = event.target.value;
      setStartDate(newStartDate);
      onDateChange(newStartDate, endDate);
    };
  
    const handleEndDateChange = (event) => {
      const newEndDate = event.target.value;
      setEndDate(newEndDate);
      onDateChange(startDate, newEndDate);
    };
  
    return (
      <div>
        <input
          type="date"
          value={startDate || ""}
          onChange={handleStartDateChange}
          className={useCaseType === "ru listing" ? "rounded-lg p-3 min-w-[200px] border-0 focus:outline-none" : "shadow-md rounded-lg p-3 min-w-[200px]"}
        />
        <HorizontalRuleIcon />
        <input
          type="date"
          value={endDate || ""}
          onChange={handleEndDateChange}
          className={useCaseType === "ru listing" ? "rounded-lg p-3 min-w-[200px] border-0 focus:outline-none" : "shadow-md rounded-lg p-3 min-w-[200px]"}
        />
      </div>
    );
  };




// import React, { useState, useEffect } from 'react';
// // icon
// import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
// import { useTranslation } from 'react-i18next';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// export default function DateRangeComponent({ onDateChange, date1, date2, useCaseType }) {
//     const { t } = useTranslation(); // For translation
//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);

//     useEffect(() => {
//         if (!date1 && !date2) {
//             setStartDate(null);
//             setEndDate(null);
//         }
//     }, [date1, date2]);

//     const handleStartDateChange = (newStartDate) => {
//         setStartDate(newStartDate);
//         onDateChange(newStartDate, endDate);
//     };

//     const handleEndDateChange = (newEndDate) => {
//         setEndDate(newEndDate);
//         onDateChange(startDate, newEndDate);
//     };

//     return (
//         // <div>
//         <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             <DatePicker
//                 selected={startDate}
//                 onChange={handleStartDateChange}
//                 className={useCaseType === "ru listing" ? "rounded-lg p-3 min-w-[200px] border-0 focus:outline-none" : "shadow-md rounded-lg p-3 min-w-[200px]"}
//                 placeholderText={t('selectStartDate')}
//                 dateFormat="dd/MM/yyyy" // Adjust format as needed
//             />
//             {/* <span>—</span> */}
//             <HorizontalRuleIcon />

//             <DatePicker
//                 selected={endDate}
//                 onChange={handleEndDateChange}
//                 className={useCaseType === "ru listing" ? "rounded-lg p-3 min-w-[200px] border-0 focus:outline-none" : "shadow-md rounded-lg p-3 min-w-[200px]"}
//                 placeholderText={t('selectEndDate')}
//                 dateFormat="dd/MM/yyyy" // Adjust format as needed
//             />
//         </div>
//     );
// }