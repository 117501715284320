// in src/Dashboard.js
import React, { memo } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import emsdLogo from "../../../styles/logo/emsd_appendix.jpg";
import keyToField from "../../../utils/keyToField";
// import parseAppendixJson from "../../../utils/pdf/appendix_format_parser";
// import { romanize, alphabet } from "../../../utils/romanize";
import { romanize } from "../../../utils/romanize";
import fontRegister from "../../../utils/pdf/fontRegister";
import renderChinese from "../../../utils/pdf/renderChinese";
// import reactStringReplace from "react-string-replace";
import QRCode from "qrcode";
import { fixInvalidDates } from "../../../utils/misc/fixInvalidDates";
import {
  // renderContent,
  renderIndexedContent,
} from "../../../utils/pdf/renderContent";
import parseDerivedAppendixPDF from "./pdf/derived_appendix_format_parser";
import { franc } from "franc-min";
import _ from "lodash";
import renderSymbol from "../../../utils/pdf/renderSymbol";


const styles = StyleSheet.create({
  pageNumber: {
    position: "absolute",
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
  },
  logo: {
    position: "absolute",
    bottom: 30,
    left: 720,
    width: "66.4px",
    height: "21.25px",
  },
  lightBold: {
    fontFamily: "Frutiger_bold",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "no-wrap",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    margin: "0px 3px 18px 0px",
  },
  tableCell: {
    textAlign: "center",
    padding: "3px",
    fontSize: 10,
    minHeight: 19,
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  tableCell_header2: {
    textAlign: "center",
    fontFamily: "Frutiger_bold",
  },
  tableCell_header: {
    textAlign: "left",
    backgroundColor: "#f1f1f1",
    fontFamily: "Frutiger_bold",
  },
  tableCell_multipleRow: {
    display: "flex",
    flexDirection: "column",
  },
  tableCell_multipleRowItem: {
    flexGrow: 1,
    width: "100%",
  },
  row: {
    flexDirection: "row",
    flexGrow: 1,
  },
  address: {
    fontSize: 9,
    paddingTop: "10px",
  },
});


const tableFields = {
  ACOP: {
    Manufacturer: 10,
    Model: 8.571,
    tec_car_load: 8.571,
    tec_balancing_factor: 8.571,
    tec_rated_load: 8.571,
    tec_rated_speed: 8.571,
    tec_tripping_speed: 8.571,
    tec_rope_factor: 8.571,
    tec_certificate_number: 15,
    "Approval Reference": 15,
  },
  Buffer: {
    Manufacturer: 30,
    Model: 13,
    tec_total_mass: 12,
    tec_impact_speed: 10,
    tec_certificate_number: 15,
    "Approval Reference": 20,
  },
  CarDoorLockingDevice: {
    Manufacturer: 15,
    Model: 12.5,
    tec_door_type: 25,
    tec_contact_rating: 15,
    tec_certificate_number: 20,
    "Approval Reference": 12.5,
  },
  LandingDoorLockingDevice: {
    Manufacturer: 20,
    Model: 10,
    tec_door_type: 20,
    tec_contact_rating: 10,
    tec_certificate_number: 20,
    "Approval Reference": 20,
  },
  OverspeedGovernor: {
    Manufacturer: 23,
    Model: 8,
    tec_rated_speed: 13,
    tec_tripping_speed: 13,
    tec_certificate_number: 20,
    "Approval Reference": 23,
  },
  Pallet: {
    Manufacturer: 23,
    Model: 8,
    tec_rated_load: 13,
    tec_rated_speed: 13,
    tec_certificate_number: 20,
    "Approval Reference": 23,
  },
  SafetyCircuitElectricalComponent: {
    Manufacturer: 27,
    Model: 8,
    tec_circuit_description: 29,
    tec_certificate_number: 18,
    "Approval Reference": 18,
  },
  SafetyGear: {
    Manufacturer: 20,
    Model: 15,
    tec_total_mass: 15,
    tec_rated_speed: 15,
    tec_tripping_speed: 15,
    tec_certificate_number: 15,
    "Approval Reference": 20,
  },
  Step: {
    Manufacturer: 23,
    Model: 8,
    tec_rated_load: 13,
    tec_rated_speed: 13,
    tec_certificate_number: 20,
    "Approval Reference": 23,
  },
  UCMP: {
    Manufacturer: 10,
    Model: 8.571,
    tec_car_load: 8.571,
    tec_balancing_factor: 8.571,
    tec_rated_load: 8.571,
    tec_rated_speed: 8.571,
    tec_tripping_speed: 8.571,
    tec_rope_factor: 8.571,
    tec_certificate_number: 15,
    "Approval Reference": 15,
  },
  TractionMachine: {
    Manufacturer: 23,
    Model: 8,
    tec_rated_load: 13,
    tec_rated_speed: 13,
    tec_certificate_number: 20,
    "Approval Reference": 23,
  },
  Controller: {
    Manufacturer: 23,
    Model: 8,
    tec_rated_load: 13,
    tec_rated_speed: 13,
    tec_certificate_number: 20,
    "Approval Reference": 23,
  },
};


const DerivedAppendixForSC = memo(({ application, appendix, standalones, certificates, da_url }) => {
  fontRegister();
  Font.registerHyphenationCallback((word) => {
    //https://github.com/diegomura/react-pdf/issues/419
    //Ensure the certificate number wrap and do not go out of the cell
    return word.includes("-")
      ? Array.from(word).flatMap((char) => [char, ""])
      : Array.from(word);
  });

  if (!application || !appendix || !standalones || !certificates) return null;

  const componentTypes = _.uniq([
    ...Object.keys(application.app_type?.components || {}),
    ...standalones.components.map((c) => c.type),
  ]);

  const components = componentTypes.reduce((acc, type) => {
    const historicalComp = standalones.components.filter(
      (comp) => comp.type === type
    );
    const newComp = application.app_type?.components[type] || [];
    acc[type] = [...historicalComp, ...newComp];
    return acc;
  }, {});

  const combinedCertificates = standalones.certificates.concat(certificates);

  const combinedManufacturers = standalones.manufacturers.concat(application.manufacturer);


  const simpleDisplay = (cert_id, field) => {
    const matchCert = combinedCertificates?.find(cert => (cert?._id || cert?.id) === cert_id);

    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field] ||
      ""
    );
  };


  function detectChineseParts(sentence) {
    const isChinese = text => franc(text, { minLength: 0 }) === "cmn";
  
    const parts = sentence.split(/([\u4e00-\u9fa5]+)/); // Split at Chinese characters
  
    const chineseParts = [];
  
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      if (isChinese(part)) {
        chineseParts.push(part);
      }
    }
  
    return chineseParts;
  };



  function getCertList(component, component_type) {
    const hideSCCerts = appendix.hideSCCerts;
    if (!hideSCCerts || hideSCCerts.length === 0) {
      return true; // Return true when hideSCCerts is undefined or empty
    }
  
    const isHidden = hideSCCerts.some(
      (item) =>
        item.cert_id === component._id && item.component_type === component_type
    );
  
    return !isHidden;
  }

  
  function getCompListCount(comps, component_type) {
    if (!component_type) {
      return 0; // Return 0 when component_type is undefined
    }
  
    const components = comps?.[component_type].filter((component) => {
      const list = getCertList(component, component_type);
      return list;
    });
  
    return components ? components.length : 0;
  };;


  return (
    <Document>
      <Page
        orientation="landscape"
        wrap
        style={{
          padding: "34.156px 43.037px 67.13px 40.3px",
          fontSize: "11px",
          fontFamily: "Frutiger_light",
        }}
      >

       <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontFamily: "Frutiger_Ultra_bold",
          }}
          fixed
        >
          <Text>{`Application No.: ${application.application_number}`}</Text>
          <Text>Appendix</Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <View
            style={{
              margin: "0 auto",
              padding: "23px 0px",
              textAlign: "center",
              fontFamily: "Frutiger_Ultra_bold",
              fontSize: "14px",
            }}
          >
            <Text>Technical Specifications for</Text>
            <Text>Various Safety Components</Text>
          </View>

          {da_url && (
            <View>
              <Image style={{ width: 50, height: 50 }} src={QRCode.toDataURL(da_url || "google.com")} />
            </View>
          )}
        </View>



        <Text style={{ fontFamily: "Frutiger_Ultra_bold" }}>
          Registered Lift Contractor:{" "}
          <Text style={{ textDecoration: "underline" }}>
            {application.rc_id?.address?.company} (RLC no.:
            {application.rc_id.rc_number_lift})
          </Text>
        </Text>

        <Text style={{ padding: "18px 0px", fontFamily: "Frutiger_Ultra_bold" }}>
          Specifications and Conditions
        </Text>
        <Text style={{ fontFamily: "Frutiger_bold" }}>
          Safety Components Specifications:
        </Text>

        {componentTypes
          .sort((a, b) => {
            const order = [
              "Buffer",
              "OverspeedGovernor",
              "LandingDoorLockingDevice",
              "CarDoorLockingDevice",
              "SafetyGear",
              "ACOP",
              "UCMP",
              "SafetyCircuitElectricalComponent",
            ];
            function customSort(item) {
              const index = order.indexOf(item);
              return index !== -1 ? index : order.length;
            }
            return customSort(a) - customSort(b);
          })
          .map((type, i) => {

            function getCompList(manu) {
              return components[type].filter((comp) => comp.manufacturer_id === manu._id);
            }

            //some mnaufacturers has no component, don't render them
            const manufacturers = combinedManufacturers.filter((manu) => getCompList(manu).length > 0);


            // remove the duplicate manufacturers
            // if duplicates exist, remove the one with the latest approval date (if approval date exists)
            const uniqueManufacturers = Array.from(new Set(manufacturers.map(obj => obj._id))).map(_id => {
              const objectsWithId = manufacturers.filter(obj => obj._id === _id);
              const sortedByDate = objectsWithId.sort((a, b) => {
                const dateA = a.approval_date ? new Date(a.approval_date) : new Date(0);
                const dateB = b.approval_date ? new Date(b.approval_date) : new Date(0);
                return dateB - dateA;
              });
              return sortedByDate[0];
            });

            return (
              (getCompListCount(components, type)) > 0 && (
                <View
                  style={{
                    ...styles.tableContainer,
                    ...(appendix["SCLineBreak"]?.[type] && {
                      marginBottom: appendix["SCLineBreak"][type],
                    }),
                  }}
                  key={type}
                >

                  <View style={{ minHeight: "19px" }}>
                    <Text
                      style={{
                        ...styles.tableCell,
                        ...styles.tableCell_header,
                      }}
                    >
                      ({romanize(i + 1).toLowerCase()}){"       "}
                      {keyToField[type]}
                    </Text>
                  </View>

                  <View style={styles.row}>
                    {Object.keys(tableFields[type]).map((field) => {
                      return (
                        <Text
                          style={{
                            ...styles.tableCell,
                            ...styles.tableCell_header2,
                            width: `${tableFields[type][field]}%`,
                          }}
                          key={field}
                        >
                          {field === "Model"
                            ? "Model"
                            : field === "tec_certificate_number"
                              ? "Type Exam. Cert. No."
                              : keyToField[field] ?? field}
                        </Text>
                      );
                    })}
                  </View>
            

                  {uniqueManufacturers.map((manu, index) => {

                    const widthExceptFirst = Object.keys(tableFields[type]).reduce((acc, field) => {
                      if (!["Manufacturer"].includes(field)) return acc + tableFields[type][field];
                      else return acc;
                    }, 0);

                    const matchCert = combinedCertificates.find((cert) => (cert?._id || cert?.id) === manu.iso_certificate_id);

                    const manufacturerAddress =
                      matchCert?.cert_corrections?.iso_manufacturer_address ||
                      matchCert?.cert_type_metadata?.iso_manufacturer_address;

                    let list = getCompList(manu);
                    // filter out duplicate components based on "type_test_certificate_id"
                    let uniqueComponents = Array.from(new Set(list.map(item => item.type_test_certificate_id[0])))
                      .map(typeTestId => list.find(item => item.type_test_certificate_id[0] === typeTestId))

                    // Do not show the manufacturer(s) if they have no components
                    // let filteredUniqueComponents = uniqueComponents.filter(comp => !appendix.hideCerts?.includes(comp._id));
                    let filteredUniqueComponents = uniqueComponents.filter((comp) => 
                      !appendix.hideSCCerts?.some(item => item.cert_id === comp._id && item.component_type === type) 
                    );

                    return (
                      <View style={styles.row} key={index}>
                        {filteredUniqueComponents && filteredUniqueComponents.length > 0 && (
                            <View
                              style={{
                                ...styles.tableCell,
                                width: `${tableFields[type]["Manufacturer"]}%`,
                                textAlign: "left",
                              }}
                            >
                              <Text
                                style={{
                                  ...styles.lightBold,
                                  ...renderChinese(manu.short_name || manu.provided_name),
                                }}
                              >
                                {detectChineseParts(manu.short_name || manu.provided_name).length > 0
                                  ? parseDerivedAppendixPDF(manu.short_name || manu.provided_name)
                                  : manu.short_name || manu.provided_name}
                              </Text>

                              <Text
                                style={{
                                  ...styles.address,
                                  ...renderChinese(manufacturerAddress),
                                }}
                              >
                                {manufacturerAddress}
                              </Text>
                            </View>
                          )}

                        <View style={{ width: `${widthExceptFirst}%` }}>
                          {uniqueComponents.map((comp, index) => {
                            const cellRatio = 100 / widthExceptFirst;
                            const fieldWidth = widthExceptFirst - tableFields[type]["Model"];

                            // only render the component if it is not hidden
                            // if (!appendix.hideCerts?.includes(comp._id)) 
                            if ( !appendix.hideSCCerts?.some(item => item.cert_id === comp._id && item.component_type === type) )
                              {
                              return (
                                <View style={{ ...styles.row }} key={index}>
                                  <Text
                                    style={{
                                      ...styles.tableCell,
                                      width: `${tableFields[type]["Model"] * cellRatio}%`,
                                    }}
                                  >
                                    {comp.provided_comp_name}
                                  </Text>

                                  <View
                                    style={{
                                      width: `${fieldWidth * cellRatio}%`,
                                    }}
                                  >
                                    {comp.type_test_certificate_id.map((cert, compIndex) => {
                                        const matchCert = combinedCertificates.find((c) => (c?._id || c?.id) === cert);
                                        const cellRatio2 = 100 / fieldWidth;

                                        return (
                                          <View
                                            style={{ ...styles.row }}
                                            key={compIndex}
                                          >


                                            {/* {Object.keys(tableFields[type]).filter((field) =>
                                                !["Manufacturer","Model",].includes(field)
                                              ).map((field) => {
                                                return (
                                                  <Text
                                                    key={field}
                                                    style={{
                                                      ...styles.tableCell,
                                                      ...styles.tableCell_multipleRowItem,
                                                      width: `${tableFields[type][field] * cellRatio2}%`,
                                                      //If we can find the cert in certificates (not combinedCertificate), means this one is new, so we bold them
                                                      // ...(certificates.find((c) => c.id === cert) &&
                                                      //   (field === "tec_certificate_number" ||
                                                      //     field === "Approval Reference") && {fontFamily: "Frutiger_bold"}),
                                                    }}
                                                  >
                                              {field === "Approval Reference" ? (
                                                  <>
                                                    {simpleDisplay(cert, field) ? (renderSymbol(simpleDisplay(cert, field))): ( "N/A" )}    

                                                    {matchCert?.comply_standard?.lift && (
                                                      <>
                                                        {" "}
                                                        [
                                                        <Text style={{ fontFamily:"DejaVu Sans" }} >Ƨ</Text>
                                                        ]
                                                      </>
                                                    )}
                                                  </>
                                                ) : field === "tec_certificate_number" ? (
                                                  <>
                                                    {renderSymbol(simpleDisplay(cert, field))}{" "}
                                                    issued on{" "}
                                                    {simpleDisplay(cert,"tec_issue_date")}
                                                  </>
                                                ) : (
                                                  simpleDisplay(cert, field) ? renderSymbol(simpleDisplay(cert, field)) : "N/A"
                                                )}
                                                  </Text>
                                                );
                                              })} */}



                                                {Object.keys(tableFields[type])
                                                  .filter((field) => !["Manufacturer", "Model"].includes(field))
                                                  .map((field) => {
                                                    return (
                                                      <Text
                                                        key={field}
                                                        style={{
                                                          ...styles.tableCell,
                                                          ...styles.tableCell_multipleRowItem,
                                                          width: `${tableFields[type][field] * cellRatio2}%`,
                                                          ...(certificates.find((c) => c.id === cert) &&
                                                            (field === "tec_certificate_number" || field === "Approval Reference") && { fontFamily: "Frutiger_bold" }),
                                                        }}
                                                      >
                                                        {field === "Approval Reference" ? (
                                                          <>
                                                            {simpleDisplay(cert, field) ? (
                                                              renderSymbol(simpleDisplay(cert, field))
                                                            ) : (
                                                              `${application.application_number} dated ${
                                                                (application.approval_date &&
                                                                  fixInvalidDates((application.approval_date || "").replace(/–/g, "-"))) 
                                                                //   ||
                                                                // (application.updatedAt &&
                                                                //   fixInvalidDates((application.updatedAt || "").replace(/–/g, "-")))
                                                              }`.split("T")[0]
                                                            )}

                                                            {matchCert?.comply_standard?.lift && (
                                                              <>
                                                                {" "}
                                                                [
                                                                <Text style={{ fontFamily: "DejaVu Sans" }}>Ƨ</Text>
                                                                ]
                                                              </>
                                                            )}
                                                          </>
                                                        ) : field === "tec_certificate_number" ? (
                                                          <>
                                                            {renderSymbol(simpleDisplay(cert, field))} issued on{" "}
                                                            {simpleDisplay(cert, "tec_issue_date")}
                                                          </>
                                                        ) : (
                                                          simpleDisplay(cert, field) ? renderSymbol(simpleDisplay(cert, field)) : "N/A"
                                                        )}
                                                      </Text>
                                                    );
                                                  })}



                                          </View>
                                        );
                                      }
                                    )}
                                  </View>
                                </View>
                              );
                            }
                          })}
                        </View>
                      </View>
                    );
                  })}

                </View>
              )
            );
          })}



        <View break>
          {appendix?.remarks?.length > 0 && (
            <View>
              <Text style={{ fontFamily: "Frutiger_bold" }}>Remarks:</Text>
              {renderIndexedContent(
                appendix?.remarks,
                "pdf",
                application.model,
                "scRemarks"
              )}
            </View>
          )}

          <Text style={{ fontFamily: "Frutiger_bold", marginTop: 10 }}>
            Conditions:
          </Text>
          {renderIndexedContent(appendix?.conditions, "pdf", application.model)}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
        <Image src={emsdLogo} style={styles.logo} fixed />

      </Page>
    </Document>
  );
});


export default DerivedAppendixForSC;
