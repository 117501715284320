import { Modal, IconButton, Box } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { useState } from "react";
import Close from "@mui/icons-material/Close";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import getToken from "../../utils/getAuthToken";
import Button from "@mui/material/Button";


const workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

function PdfModal(props) {
  const { isOpen, onClose, pdfUrl, requestID } = props;
  const [numPages, setNumPages] = useState(null);

  const showPdfFileAllowDownload = async () =>
    window.open(
      `${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/showCertPdfLimitedPages/${requestID}?token=${getToken()}`,
    );

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box>
        <IconButton
          sx={{
            position: "absolute",
            right: 0,
            zIndex: 9999,
            backgroundColor: "#ff234ab8",
          }}
          onClick={onClose}
          aria-label="close modal"
        >
          <Close sx={{ fontSize: "40px" }} />
        </IconButton>
        <Box
          sx={{
            position: "absolute",
            top: "0%",
            left: "0%",
            bgcolor: "background.paper",
            color: "blue",
            borderRadius: "10px",
            zIndex: 9999,
          }}
        >
          {window.location.pathname === "/lift_emsd" &&
            <Button className="w-auto" onClick={() => showPdfFileAllowDownload()}>
              <p className="text-2xl">
                <strong>
                  Show origin in new tab
                </strong>
              </p>
            </Button>
          }
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            //doing transform will cause the pdf looks blurry. I choose to do that because it centers the pdf, there's another way to do that
            //such as making it's parent a flex container, but doing so will cause the user not able to close the modal when clicking outside of the pdf
            //so more logic like useRef is required if do not want to use transform
            transform: "translate(-50%, -50%)",
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <Document
            file={pdfUrl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page pageNumber={page} key={page} />
              ))}
          </Document>
        </Box>
      </Box>
    </Modal>
  );
}

export default PdfModal;
