function formatDate(dateString, includeTime, language) {
  try {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // const monthsCH =[
    //   "一月",
    //   "二月",
    //   "三月",
    //   "四月",
    //   "五月",
    //   "六月",
    //   "七月",
    //   "八月",
    //   "九月",
    //   "十月",
    //   "十一月",
    //   "十二月",
    // ]

    const monthsCH =[
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ]

    const date = new Date(dateString);
    const day = date.getDate();
    // const month = months[date.getMonth()];
    const month = language==="ch" ? monthsCH[date.getMonth()] : months[date.getMonth()];
    const year = date.getFullYear();

    if (includeTime) {
      // Convert to Hong Kong Time (UTC+8)
      date.setUTCHours(date.getUTCHours() + 8);
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      const ch_ampm = hours >= 12 ? "下午" : "上午";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

      // const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, "0")}${ampm}`;

      const formattedTime = language === "ch" ? `${ch_ampm}${formattedHours}:${minutes.toString().padStart(2, "0")}` : `${formattedHours}:${minutes.toString().padStart(2, "0")}${ampm}`

      // const formattedDate = `${day} ${month}, ${year} ${formattedTime}`;
      const formattedDate = language === "ch" ? `${year}年 ${month} ${day}日 ${formattedTime}` : `${day} ${month}, ${year} ${formattedTime}` 
      return formattedDate;
    } else {
      // const formattedDate = `${day} ${month}, ${year}`;
      const formattedDate = language === "ch" ? `${year}年 ${month} ${day}日` : `${day} ${month}, ${year}` 
      return formattedDate;
    }
  } catch {
    return dateString;
  }
}

export default formatDate;
