import { createSlice } from "@reduxjs/toolkit";

export const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    value: null,
  },
  reducers: {
    saveData: (state, action) => {
      state.value = action.payload;
    },
    clearDraft: (state) => {
      state.value = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveData, clearDraft } = uploadSlice.actions;

export default uploadSlice.reducer;
