import { useState } from "react";
import { Confirm, useNotify } from "react-admin";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  ButtonGroup,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getdaAppendice, clearAappendice } from "../../redux/pdfSlice";
import useDeAppendix from "../../useHooks/useDeAppendix"; // hooks
import palette from "../../styles/palette";
import Appendix from "../../components/appendix/Appendix";
import getBase64 from "../../components/appendix/getBase64";
import AppendixForSC from "../../components/appendix/AppendixForSC";
import ApprovalLetter from "../../components/appendix/ApprovalLetter";

export const RecordGenAppendix = (props) => {
  const dispatch = useDispatch();
  const { id, openAppendix, setOpenAppendix } = props;
  const [open, setOpen] = useState(false);
  const pdfProps = useSelector((state) => state.pdf.value);
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const { saveDeAppendix } = useDeAppendix(id);
  const daAppendice = useSelector(getdaAppendice);

  const Spinner = () => (
    <Backdrop
      sx={{ color: "#fff", zIndex: 9999, top: "-350px" }}
      open={isLoading}
    >
      <CircularProgress color="inherit" size={40} />
    </Backdrop>
  );

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      const appendixBase64 = await getBase64(
        pdfProps.appendixInfo.isSC ? (
          <AppendixForSC {...pdfProps.appendixInfo} />
        ) : (
          <Appendix {...pdfProps.appendixInfo} />
        )
      );
      const approvalLetterBase64 = await getBase64(
        <ApprovalLetter {...pdfProps.approvalLetterInfo} />
      );
      if (!appendixBase64 || !approvalLetterBase64) throw new Error();
      const pdf = {
        appendixBase64,
        approvalLetterBase64,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/records/appendix/${id}`,
        { PDFFiles: pdf },
        {
          headers: {
            authorization: getToken(),
          },
        }
      );

      if (process.env.REACT_APP_PHASE_TWO) {
        // create a derived appendix record to be used for verifying qrcode
        const {
          appendix,
          _id,
          application_number,
          rc_id,
          app_type: { type },
        } = res.data.application;
        const originalAppendix = true;
        await saveDeAppendix(
          appendix,
          _id,
          application_number,
          rc_id,
          type,
          originalAppendix,
          daAppendice.url,
          daAppendice.encoded
        );
        dispatch(clearAappendice());
      }
      notify(`Appendix generated`);
      window.location.reload();
    } catch (err) {
      console.log(err);
      notify(`Error generating appendix, please try again later`);
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  return (
    <Box>
      <ButtonGroup color="primary" sx={{ ml: 4 }}>
        <Button
          onClick={() => {
            setOpenAppendix(true);
          }}
        >
          Edit Appendix / Approval Letter
        </Button>
        <Button onClick={handleClick}>Generate Appendix / Approval Letter</Button>
      </ButtonGroup>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Generate Appendix"
        content="This will replace the current appendix. Are you sure you want to proceed?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />

      <Spinner />
    </Box>
  );
};
