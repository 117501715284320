import { useEffect, useState } from "react";
import { Button, Confirm, useNotify, useUpdate } from "react-admin";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import axios from "axios";
import getToken from "../utils/getAuthToken";
import palette from "../styles/palette";
import { useTranslation } from 'react-i18next';


export const TransferButton = (props) => {
  const { t : itranslate } = useTranslation();
  const { latestApp, upload, id, type, teams, applicationRole } = props;
  const [open, setOpen] = useState(false);
  const [remark, setRemark] = useState("");
  const navigate = useNavigate();
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate();
  const handleClick = () => setOpen(true);
  const [selectedRole, setSelectedRole] = useState("");
  const handleDialogClose = () => setOpen(false);

  const setRole = (e) => {setSelectedRole(e)};

  let engRoles = [];
  let seRoles = [];
  let ceRoles = [];

  teams &&
    teams.map((team) => {
      engRoles.push(...team.engineers);
      seRoles.push(...team.seniorEngineers);
      ceRoles.push(...team.chiefEngineer);

      return null;
    });

  const handleText = () => {
    return {
      label: itranslate("Transfer to another Engineer"),
      title: itranslate("Confirm Transfer"),
      content:
        itranslate("Are you sure you want to transfer this application to another Engineer?"),
    };
  };

  const handleTransfer = () => {
    axios
      .put(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps/transfer/${id}`,
        {
          id,
          data: {
            status: type,
            current_role_id: selectedRole,
            remark,
          },
        },
        {
          headers: { authorization: getToken() },
        }
      )
      .then((response) => {
        setOpen(false);
        if (response.status === 200) {
          notify(itranslate(`Application Transferred`));
          navigate(`/applications`);
        }
      })
      .catch((error) => {
        setOpen(false);
        if (error.response && error.response.status === 409) {
          notify(itranslate(`Application already assigned to this Engineer`));
        } else {
          notify(itranslate(`Error while transferring application`));
        }
      });
  };

  const handleRemark = (e) => {
    setRemark(e.target.value);
  };

  useEffect(() => {
    applicationRole?._id && setSelectedRole(applicationRole._id || "");
  }, [applicationRole]);

  return (
    <Box>
      <FormControl
        fullWidth
        sx={{
          width: "250px",
        }}
      >
        <>
          <InputLabel id="demo-simple-select-label">
            {handleText().label}
          </InputLabel>

          <Select
            sx={{ borderRadius: "5px", mb: 1 }}
            value={selectedRole}
            onChange={(event) => setRole(event.target.value)}
          >
            {(type === "Pending" || type === "Pending E") &&
              engRoles &&
              engRoles.map((engRole, index) => (
                <MenuItem value={engRole._id} key={index}>
                  {engRole.role_name}
                </MenuItem>
              ))}
            {type === "Pending SE" &&
              seRoles &&
              seRoles.map((seRole, index) => (
                <MenuItem value={seRole._id} key={index}>
                  {seRole.role_name}
                </MenuItem>
              ))}

            {type === "Pending CE" &&
              ceRoles &&
              ceRoles.map((ceRole, index) => (
                <MenuItem value={ceRole._id} key={index}>
                  {ceRole.role_name}
                </MenuItem>
              ))}
          </Select>
        </>

        <Button
          sx={{
            backgroundColor: palette.shadow,
            color: "black",
            height: "100%",
            border: "1px solid black",
          }}
          label={handleText().label}
          onClick={handleClick}
          size="medium"
        />
      </FormControl>

      <Confirm
        isOpen={open}
        loading={isLoading}
        title={handleText().title}
        content={
          <Box>
            <Typography>{handleText().content}</Typography>
            {latestApp?.application_number &&
              upload?.update_upon &&
              latestApp.application_number !== upload.update_upon && (
                <Typography sx={{ color: "red" }}>
                  {itranslate("This application does not contain the latest approved application data, check application info for refreshing")}
                </Typography>
              )}
            <TextField
              value={remark}
              label={itranslate("Remarks")}
              variant="standard"
              onChange={handleRemark}
              inputProps={{ maxLength: 500 }}
              fullWidth
            />
          </Box>
        }
        onConfirm={handleTransfer}
        onClose={handleDialogClose}
        confirmLabel={itranslate('confirm')}
        cancelLabel={itranslate('cancel')}
      />

    </Box>
  );
};
