import jsonExport from "jsonexport/dist";
import { downloadCSV, useRecordContext } from "react-admin";
import keyToField from "../../utils/keyToField.js";

const usersExporter = (users, fetchRelatedRecords) => {
  fetchRelatedRecords(users, "permissions_data.rc_id", "rcs").then((rcs) => {
    const usersForExport = users.map((user) => {
      const userForExport = {
        username: user.username,
        email: user.email,
        Rank: user.role,
      };
      let rcDisplay = "";
      rcDisplay = rcs[user?.permissions_data?.rc_id]?.rc_number_lift;
      if (rcs[user?.permissions_data?.rc_id]?.rc_number_escalator)
        rcDisplay +=
          " / " + rcs[user?.permissions_data?.rc_id]?.rc_number_escalator;
      userForExport.RC = rcDisplay;
      return userForExport;
    });
    jsonExport(
      usersForExport,
      {
        headers: ["username", "email", "RC", "Rank"], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "users"); // download as 'users.csv` file
      }
    );
  });
};

export default usersExporter;
