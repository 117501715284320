import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from "./App";
import store from "./admin/redux/store";
import { Provider } from "react-redux";

import "react-confirm-alert/src/react-confirm-alert.css";
import "./admin/styles/confirmModal.css";

// translation
import './i18n.js'
import "./tw-index.css";
// da
import PageVerifyAppendix from "./general/pages/PageVerifyAppendix"
import PageShowRecord from "./general/pages/PageVerifyAppendix/PageShowRecord/index"
// ru_record
import PageShowRuRecord from "./general/pages/PageRestrictedUrl/index"

// emotion
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

// helmet for csp
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { v4 as uuid } from "uuid";

let uuidNounce = uuid()

// generate hashes for nonce
const sha256 = require('crypto-js/sha256');
const hashStyle = sha256(uuidNounce).toString();


window.__CSP_NONCE__ = hashStyle
const csp = `
  default-src 'self' ${process.env.REACT_APP_ENV_ENDPOINT} ${process.env.NODE_ENV === 'development' ? process.env.PUBLIC_URL_DOMAIN : process.env.REACT_APP_ENV_ENDPOINT} blob:;
  connect-src 'self' ${process.env.REACT_APP_ENV_ENDPOINT} ${process.env.NODE_ENV === 'development' ? process.env.PUBLIC_URL_DOMAIN : process.env.REACT_APP_ENV_ENDPOINT} blob:;
  img-src 'self' data: blob: ${process.env.REACT_APP_ENV_ENDPOINT} ${process.env.NODE_ENV === 'development' ? process.env.PUBLIC_URL_DOMAIN : process.env.REACT_APP_ENV_ENDPOINT};
  font-src 'self' data: https://fonts.googleapis.com https://apis.googleapis.com https://fonts.gstatic.com;
  frame-src 'self' ${process.env.REACT_APP_ENV_ENDPOINT} ${process.env.NODE_ENV === 'development' ? process.env.PUBLIC_URL_DOMAIN : process.env.REACT_APP_ENV_ENDPOINT} blob:;
  script-src 'self' blob:;
  style-src 'self' 'nonce-${hashStyle}' blob: https://fonts.googleapis.com https://apis.googleapis.com https://fonts.gstatic.com;
`;

const cache = createCache({
  key: 'css',
  nonce: hashStyle || '',
});

const htmlElements = document.getElementsByTagName("*")
for (let i = 0; i < htmlElements.length; i++) {
  htmlElements[i].setAttribute("nonce", hashStyle)
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <Helmet>
          <meta http-equiv="Content-Security-Policy" content={csp} />
          <meta property="csp-nonce" content={hashStyle} />
        </Helmet>
        <CacheProvider value={cache}>
          {/* will not redirect to login page */}
          {window.location.pathname.includes("/verifyRecord") &&
            <BrowserRouter>
              <Routes>
                <Route path="/verifyRecord" element={<PageVerifyAppendix />} />
                <Route path="/verifyRecord/record" element={<PageShowRecord />} />
                <Route path="/verifyRecord/record/ru/:encrypted_ruNo" element={<PageShowRuRecord />} />
              </Routes>
            </BrowserRouter>
          }

          {/* will redirect to login page if not logged in */}
          {!window.location.pathname.includes("verifyRecord") && <App />}
        </CacheProvider>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);
