const fields = {
  provided_name: "Manufacturer Name",
  iso_certificate_id: "Certification of Quality Assurance Scheme",
  history: "History Of Manufacturer Document",
  organization_man: "Organization of Manufacturer Document",
  size_location_cap:
    "Size of Manufacturing Plants, Location and Capabilities Document",
  product_range_yearly_production:
    "Product Range and Yearly Production Document",
  other_man_documents: "Other Relevant Document",
  compatibility_cert_id: "Compatibility Declaration",
  calculation: "Calculation",
  type_test_certificate_id: "Type Examination Certificate and Test Report",
  full_quality_certificate_id: "Full Quality Assurance Certificate",
  compliance_confirmation: "Manufacturer Confirmation Letter",
  install_operation_maint: "Installation, Operation and Maintenance Manual",
  maintenance_schedule: "Maintenance Schedule",
  arr_training_tech_support:
    "Arrangement of Training and Technical Support from Manufacturer",
  EMC_compliance: "EMC Compliance Declaration",
  rated_load: "Maximum Rated Load (kg)",
  rated_speed: "Maximum Rated Speed (m/s)",
  maximum_travel_of_height: "Maximum Travel Height (m)",
  rope_factor: "Roping",
  balancing_factor: "Balancing Factor (%)",
  model_code: "Model Code",
  model_id: "Model",
  provided_model_name: "Provided Model Name",
  maximum_rise: "Maximum Rise (m)",
  angle_of_inclination: "Angle Of Inclination (°)",
  other_man_model_documents: "Other Relevant Document",
  other_comp_documents: "Other Relevant Document",
  manufacturer_id: "Manufacturer",
  component_code: "Component Code",
  provided_comp_name: "Provided Component Name",
  type: "Type",
  InclinedLift: "Electric Inclined Lift",
  ElectricTractionLiftMachineRoom: "Electric Traction Lift (Machine Room)",
  ElectricTractionLiftRoomless: "Electric Traction Lift (Machine Roomless)",
  HydraulicLift: "Hydraulic Lift",
  StairLift: "Stairlift",
  ServiceLift: "Service Lift",
  MVPS: "Mechanized Vehicle Parking System (MVPS)",
  ElectricalVerticalLiftingPlatform: "Vertical Lifting Platform",
  Escalator: "Escalator",
  PassengerConveyor: "Passenger Conveyor",
  Buffer: "Buffer",
  OverspeedGovernor: "Overspeed Governor",
  LandingDoorLockingDevice: "Landing door locking device",
  CarDoorLockingDevice: "Car door locking device",
  SafetyGear: "Safety Gear",
  ACOP: "Ascending car overspeed protection means",
  UCMP: "Unintended car movement protection means",
  SafetyCircuitElectricalComponent:
    "Safety circuit containing electrical components",
  RaptureValve: "Rapture Valve",
  OneWayValve: "One Way Valve",
  Step: "Step",
  Pallet: "Pallet",
  TractionMachine: "Traction Machine",
  Controller: "Controller",
  Others: "Others",
  iso_manufacturer_name: "Manufacturer name (ISO Certificate)",
  iso_manufacturer_address: "Manufacturer Address",
  iso_standard: "Standard",
  iso_cert_number: "Certificate Number",
  iso_scope: "Scope",
  iso_issue_date: "Issue Date",
  iso_expiry_date: "Expiry Date",
  first_issue_date: "First Issue Date",
  manufacturer: "Manufacturer",
  address: "Address",
  short_name: "Short Name",
  alias_name: "Alias Name",
  Both: "Both Lift/Escalator System Model and Safety Components",
  SafetyComponent: "Safety Component",
  Model: "Lift/Escalator System Model",
  tec_total_mass: "Total mass, P+Q (kg)",
  tec_impact_speed: "Max. Impact Speed, m/s",
  tec_door_type: "Type of Door",
  tec_rope_factor: "Roping",
  tec_balancing_factor: "Balancing %",
  tec_circuit_description: "Description",
  tec_contact_rating: "Contact Ratings",
  tec_contact_rated: "Contact Ratings",
  tec_tripping_speed: "Tripping Speed, m/s",
  tec_certificate_number: "Type Exam. Certificate No.",
  tec_certificate_body: "Certificate Body",
  tec_manufacturer_name: "Manufacturer Name",
  tec_manufacturer_address: "Manufacturer Address",
  tec_cert_type: "Type of Certificate",
  tec_standard: "Standard",
  tec_model_no: "Model No.",
  tec_rated_speed: "Rated Speed m/s (maximum)",
  tec_rated_speed_max: "Rated Speed m/s (maximum)",
  tec_rated_speed_min: "Rated Speed m/s (minimum)",
  tec_rated_load: "Total Load kg",
  tec_rated_load_max: "Rated Load kg (maximum)",
  tec_rated_load_min: "Rated Load kg (minimum)",
  tec_car_load: "Car Load kg",
  tec_lift_type: "Type of Lift",
  tec_travel_height_min: "Travel Height (minimum), m",
  tec_travel_height_max: "Travel Height (maximum), m",
  tec_total_mass_min: "Total Mass (minimum)",
  tec_total_mass_max: "Total Mass (maximum)",
  tec_roping_factor: "Roping Factor",
  tec_report_type: "Report type",
  tec_report_no: "Report No.",
  tec_issue_date: "Issue Date",
  tec_first_issue_date: "First Issue Date",
  tec_expiry_date: "Expiry Date",
  tec_test_item: "Test Item",
  tec_test_model_no: "Test Item Model No.",
  tec_buffer: "Buffer",
  tec_overspeed_governor: "Overspeed Governor",
  tec_safety_gear: "Safety Gear",
  tec_landing_door_locking_device: "Landing Door Locking Device",
  tec_car_door_locking_device: "Car Door Locking Device",
  tec_acop: "Ascending Car Overspeed Protection Means",
  tec_ucmp: "Unintended Car Movement Protection Means",
  tec_circuit_electronic: "Safety Circuit Containing Electronic Components",
  tec_description: "Description of the Safety Circuit",
  tec_pallet: "Pallet",
  tec_step: "Step",
  tec_applicable_environment: "Applicable Environment",
  tec_material: "Material",
  tec_width: "Width",
  tec_depth: "Depth",
  tec_inclination_angle: "Inclination Angle",
  traction_machine: "Traction Machine",
  controller: "Controller",
  tec_tripping_speed_max: "Tripping speed m/s (maximum)",
  tec_max_stroke: "Stroke",
  tec_ropping_factor: "Roping",
  tec_rope_diameter: "Diameter of driving rope",
  tec_guide_rail_running_surface_manufacture:
    "Manufacture of the guide rail running surface",
  tec_guide_rail_condition: "Condition of guide rail running surface",
  tec_braking_torque_max: "Braking moment",
  validating: "Validating",
  validated: "Validated",
  AIerror: "AI error",
  // model show page comp tec info
  "Approval Reference": "Approval Reference",
  tec_guide_rail_running_surface_minimum_width: "Guide Rail Running Surface Minimum Width",
  tec_impact_speed_max: "Impact Speed m/s (maximum)",

  SC_RC_CODE: "SC_RC_CODE",
  Permissible_Nominal_load_kg: "Permissible Nominal load, kg",
  Permissible_braking_moment_Nm: "Permissible braking moment, N/m",
  Max_triapping_rotary_speed_min1: "Max. tripping rotary speed, min-1",
  Max_Rated_Rotary_Speed_min1: "Max. Rated Rotary Speed, min-1",
  Contact_Ratings: "Contact Ratings",
  cleaned_cert_num: "cleaned_cert_num",
  Max_Rated_Rotary_Speed_of_Traction_Sheave: "Max. Rated Rotary Speed of Traction Sheave",
  Max_Tripping_Rotary_Speed_of_Traction_Sheave: "Max. Tripping Rotary Speed of Traction Sheave",
  Striking_Speed_m_s: "Striking Speed, m/s",
  Guide_Thickness_mm: "Guide Thickness (mm)",
  Minimum_Gripping_Area_Width_mm: "Minimum Gripping Area Width (mm)",
  Max_Tripping_Rotary_Speed_of_Lifting_Machine: "Max. Tripping Rotary Speed of Lifting Machine",
  
};

export default fields;
