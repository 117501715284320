
const TableTitle = ({data, ...props}) => {
	
	return (
		<div
			className={props.className}
		>
			<div>Model code : {data?.model_code}</div>
			<div>Model_name: {data?.provided_model_name}</div>
		</div>
	)
}

export default TableTitle