import Sugar from "sugar";
import "sugar/locales";

export function fixInvalidDates(input, type) {
  let notDefined = true;
  let validDate = "";

  try {
    function helperFn(input) {
      const ChineseRegex = /[年月日零零〇一二三四五六七八九]/;
      let trimmedString;

      if (ChineseRegex.test(input)) {  // check if date include Chinese Characters
        trimmedString = input.replace(
          /[^0-9年月日a-zA-Z0〇零零一二三四五六七八九]/g,   // clean all characters except the characters in the regex, replace the rest with empty string
          ""
        );
      } else {
        trimmedString = input;
      }

      let modifiedInput = Sugar.Date.create(trimmedString, "zh-CN");

      if (Sugar.Date(modifiedInput).isValid().raw) {
        // if valid date
        const date = new Date(modifiedInput);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);

        const formattedDate = day + "-" + month + "-" + year;
        validDate = formattedDate;
        if (validDate.includes("undefined")) notDefined = true;
        if (!validDate.includes("undefined")) notDefined = false;
      } 
      // else {
      //   // replace [Ƨ] with empty string, or replace - with hyphen
      //   const regexPattern = /\[Ƨ\]|–/;
      //   if (regexPattern.test(input)) {
      //     validDate = input.replace(/\[Ƨ\]/g, "").replace(/–/g, "-");

      //     // if (validDate.includes("undefined")) notDefined = true;
      //     // if (!validDate.includes("undefined")) notDefined = false;
      //     console.log(Sugar.Date(validDate).isValid().raw)

      //     if (Sugar.Date(validDate).isValid().raw) { 
      //       notDefined = false;
      //     } else {
      //       notDefined = true;
      //     }
      //   }
      // }

      return notDefined;
    }

    notDefined = helperFn(input);

    if (notDefined) {
      throw new Error(
        `In fixInvalidDates.js: the input date does not match for any of the existing date formats: ${input}`
      );
    } else {
      return validDate;
    }
  } catch (error) {
    if (type === "checking") {
      // console.log(input, error);
      return false;
    };
    // console.log(input, error);
    return input;
  }
}
