import { Box, TextField } from "@mui/material";
import _ from "lodash";
import { useTranslation } from 'react-i18next';

const ApprovalLetterInput = (props) => {
  const { appendix, setAppendix, application, userInfo, userRoles } = props;

  const { t : itranslate } = useTranslation();

  const handleText = (e) => {
    const clone = _.cloneDeep(appendix.approvalLetterMisc || {});
    clone.your_reference = e.target.value;
    setAppendix({ ...appendix, approvalLetterMisc: clone });
  };

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role""
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))

  return (
    <Box>
      <TextField
        disabled={isDisabled} 
        label={itranslate("Your reference")}
        fullWidth
        variant="standard"
        onChange={(e) => handleText(e)}
        value={appendix?.approvalLetterMisc?.your_reference || ""}
      />
    </Box>
  );
};

export default ApprovalLetterInput;
