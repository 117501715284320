import React, { useState, useRef, useEffect } from "react";
import { TextField, Button, Popper, ClickAwayListener } from "@mui/material";
import { useTranslation } from 'react-i18next';


function CertFieldAdder(props) {
  const { certId, makeCorrections, application, userInfo, userRoles } = props;
  const { t : itranslate } = useTranslation();
  const anchorRef = useRef(null);
  const [value, setValue] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => setSelectedKey(""), [certId]);

  const handleAddClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    makeCorrections(value, certId, selectedKey);
    setSelectedKey("");
    setValue("");
  };


  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))

  if (!certId) return null;

  return (
    <div style={{ padding: "0px 10px" }}>
      <Button
        disabled={isDisabled}
        ref={anchorRef}
        aria-controls={open ? "add-form-popper" : undefined}
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleAddClick}
      >
        {/* Add Fields */}
        {itranslate("Add Fields")}
      </Button>
      <Popper
        id="add-form-popper"
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        sx={{
          backgroundColor: "white",
          zIndex: 999,
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          boxShadow: "1px 1px 5px black",
        }}
        disablePortal
      >
        {/* https://github.com/mui/material-ui/issues/12034#issuecomment-828240836  Check this for detail*/}
        <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <TextField
              disabled={isDisabled}
              // label="Field"
              label={itranslate("Field")}
              value={selectedKey}
              onChange={(e) => setSelectedKey(e.target.value)}
              fullWidth
            />

            <TextField
              disabled={isDisabled}
              // label="Value"
              label={itranslate("Value")}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <Button
              disabled={isDisabled}
              type="submit"
              variant="contained"
              color="inherit"
              sx={{ alignSelf: "flex-end" }}
            >
              {/* Add */}
              {itranslate("Add")}
            </Button>
          </form>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default CertFieldAdder;
