
import React from 'react';
import { styled } from '@mui/material/styles';

// radios
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";

// mui icons
import ElevatorIcon from "@mui/icons-material/Elevator";
import EngineeringIcon from "@mui/icons-material/Engineering";


// redux
import { setOption } from '../../../admin/redux/optionSlice';
import { Container } from '@mui/system';

// import { useTranslate } from 'react-admin';
import { useTranslation } from 'react-i18next';

const CreateStage1 = ({ handleNext}) => {
    // const translate = useTranslate();
    const { t : itranslate } = useTranslation();
    const dispatch = useDispatch()
    const optionStore = useSelector((state) => state.option.value);
    const option = structuredClone(optionStore);
    
    return (
        <Container 
            className='w-full h-[75%] text-center'
            component="main" maxWidth="xl" sx={{ padding: "40px 0px" }}
        >
            <div className='h-full flex flex-col justify-center'>

                <FormControl fullWidth className='flex'>
                    <FormLabel id="radio-buttons-group-label" className='my-3'>
                        <p className='font-bold text-3xl'>
                            {/* {translate("phase2.createRu.perspectiveOptions")} */}
                            {itranslate("perspectiveOptions")}
                            {/* {itranslate("Options")} */}
                        </p>
                    </FormLabel>
                    
                    <RadioGroup
                        row
                        aria-labelledby="radio-buttons-group-label"
                        defaultValue="models"
                        name="radio-buttons-group"
                        className='mx-auto flex justify-center my-10'
                    >
                        <div className='mx-5 flex justify-center'>
                            <input type="radio" id="model" name="option" value="models" checked={option === "models"} className='cursor-pointer radio1 focus:ring-indigo-500 h-8 w-8 text-indigo-600 border-gray-300 self-center'
                                onChange={(e) => dispatch(setOption(e.target.value))}
                            />
                            <ElevatorIcon fontSize='large' className='cursor-pointer'
                                    onClick={() => dispatch(setOption("models"))}
                            />
                            <label htmlFor="model" className='cursor-pointer self-center text-3xl'>
                            {/* {translate("phase2.createRu.model")} */}
                            {itranslate("model")}

                            </label>
                        </div>

                        <div className='mx-5 flex justify-center'>
                            <input type="radio" id="component" name="option" value="components" checked={option === "components"} className='cursor-pointer radio1 focus:ring-indigo-500 h-8 w-8 text-indigo-600 border-gray-300 self-center'
                                onChange={(e) => {
                                    dispatch(setOption(e.target.value))}
                                }
                            />
                            <EngineeringIcon fontSize='large' className='cursor-pointer'
                                onClick={() => dispatch(setOption("components"))}
                            />
                            <label htmlFor="component" className='cursor-pointer self-center text-3xl'>
                            {/* {translate("phase2.createRu.component")} */}
                            {itranslate("component")}
                            </label>
                        </div>
                    </RadioGroup>
                    
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={(e) => handleNext({ e })}
                        aria-label="submit"
                    >
                        {/* {translate("phase2.createRu.next")} */}
                        {itranslate("next")}
                    </Button>


                </FormControl>
            </div>
        </Container>
    )
}


export default CreateStage1

