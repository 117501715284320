import React, { useEffect, useState } from "react";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  EditBase,
  useNotify,
  useUpdate,
  useEditController,
  Title,
  useGetList,
  useGetOne,
  Loading,
  useCreate,
} from "react-admin";
import {
  Box,
  Button,
  StepButton,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ManufacturerDisplay } from "../../components/manufacturer/ManufacturerDisplay";
import { ModelDisplay } from "../../components/model/ModelDisplay";
import { DocumentStack } from "../../components/document/DocumentStack";
import { ComponentDisplay } from "../../components/component/ComponentDisplay";
import { CommentBox } from "../../components/comment/CommentBox";
import * as static_fields from "../../utils/static_fields.json";
import variable_fields from "../../utils/variable_fields.json";
import { UpdateCheck } from "../../components/update/UpdateCheck";
import SaveDraft from "../../utils/applications/SaveDraft";
import { useSelector, useDispatch } from "react-redux";
import { clearDraft } from "../../redux/uploadSlice";
import { clearComponents } from "../../redux/componentsSlice";
import isGetOneData from "../../utils/applications/isGetOneData";
import { useTranslation } from 'react-i18next';


// Explaining edit variable:
// edit.mode === "a" -> update application
// edit.clarifying === "a" -> clarifying of update application/brand new application
const ApplicationEdit = (props) => {
  const { t : itranslate } = useTranslation();
  const { mode } = props;

  const navigate = useNavigate();
  const notify = useNotify();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.value);
  const location = useLocation();
  const uploadStore = useSelector((state) => state.upload.value);
  const chosenComponents = useSelector((state) => state.components.value);
  const draft = structuredClone(uploadStore);

  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useEditController({ resource: "applications" });
  const { id } = useParams();
  // PerPage: 0 result in .limit(0) in backend, which returns all data instead of the default range (0-25)
  // null for non GR (RC) as backend will filter by rc_id automatically with user's token
  const getListParams = {
    ...(userInfo?.role === "EMSDGR" && {
      filter: {
        rc_id: record?.rc_id?._id || record?.rc_id,
      },
    }),
    pagination: { page: null, perPage: null },
  };
  const getListOptions =
    userInfo?.role === "EMSDGR" ? { enabled: Boolean(record) } : null;
  const { data: manufacturersLoad } = useGetList(
    "manufacturers",
    getListParams,
    getListOptions
  );

  const { data: modelsLoad } = useGetList(
    "models",
    getListParams,
    getListOptions
  );
  const { data: componentsLoad } = useGetList(
    "components",
    getListParams,
    getListOptions
  );

  const { data: filenames } = useGetOne("files/filenames", { id: id });

  const [options, setOptions] = useState([]);

  const [updateAPI, { isLoading: isUpdating }] = useUpdate();
  const [create, { isLoading: isCreating }] = useCreate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);

  const [optional, setOptional] = useState({});
  const [currentDraft, setCurrentDraft] = useState(
    draft && draft.title ? [draft.id, draft.title] : []
  );
  const [uploadCopy, setuploadCopy] = useState(draft);
  const [upload, setUpload] = useState(draft); //If user load draft, it will set to draft, if not it will be null, and updated by useEffect
  const [update, setUpdate] = useState(
    draft?.update || {
      file_code: uuidv4(),
      manufacturer: [],
      model: [],
      app_type: {
        type: "",
        components: {},
      },
      other_documents: [],
      dialog: [],
    }
  );
  const isValidated =
    upload?.validationStatus === "validated" ||
    upload?.validationStatus === "AIerror";

  const getPatchedUpload = (record) => {
    //patch the data from useGetOne to fix various bugs / limit the data to the standAlone SC/ load draft
    let updatedComponents = record.app_type.components;
    let updatedManufacturers = record.manufacturer;

    const query = new URLSearchParams(location.search);
    const manufacturerQuery = query.get("manufacturer");
    if (chosenComponents.length > 0) {
      // For updating standalone SC, we need to filter out the component that is not chosen
      const types = Array.from(
        new Set(chosenComponents.map((item) => item.type))
      );
      updatedComponents = types.reduce((acc, type) => {
        acc[type] = chosenComponents
          .filter((item) => item.type === type)
          .map((item) => {
            const { id, manufacturer, ...rest } = item;
            return {
              ...rest,
              _id: id,
              manufacturer_id: manufacturer,
            };
          });
        return acc;
      }, {});
      const manufacturers = chosenComponents.map((item) => {
        return { ...item.manufacturersPopulated[0] };
      });
      updatedManufacturers = _.uniqBy(manufacturers, "_id");
    } else if (
      mode?.mode === "f" ||
      clarifyingDeterminant(record).clarifying === "f" ||
      clarifyingDeterminant(record).clarifying === "new"
    ) {
      updatedComponents = Object.keys(
        variable_fields.app_type[record.app_type.type] || {}
      ).reduce((acc, compType) => {
        //For new application, we need to set the confirmed of component to true, and require user reconfirm if they upload changes
        if (record.app_type.components[compType]) {
          acc[compType] = record.app_type.components[compType].map((item) => {
            return {
              ...item,
              confirmed: true,
            };
          });
        } else {
          //We need empty array so that user can add new components
          acc[compType] = [];
        }
        return acc;
      }, {});
    }

    const updatedData = {
      ...record,
      app_type: {
        ...record.app_type,
        components: updatedComponents,
      },
      manufacturer: updatedManufacturers,
      //Using will choose a manuf for manufacturer name change, and we save it in the data for load draft/AI validation
      ...(manufacturerQuery && { changedManufacturer: manufacturerQuery }),
    };

    return updatedData;
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.addEventListener("popstate", unloadCallback);
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  useEffect(() => {
    //If user didn't load draft upload will be null
    if (!upload && record && isGetOneData(record, "applications")) {
      const patchedUpload = getPatchedUpload(record);
      setUpload(_.cloneDeep(patchedUpload));
      setuploadCopy(_.cloneDeep(patchedUpload));
      dispatch(clearComponents()); //clean up when user make a new app for update cert for standaloneSC (don't put it in return as Strict mode will call the unmount immediately on mount)
    }
    return () => {
      dispatch(clearDraft());
    };
  }, [record]);

  useEffect(() => {
    if (componentsLoad) {
      setOptions(
        componentsLoad?.map((object) => {
          return {
            category: "Old",
            ...object,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentsLoad]);

  const typeDeterminant = (path) => {
    switch (path) {
      case "applications_a": {
        return "Manufacturer Name Change";
      }
      case "applications_b": {
        return "Update lift/escalator model certificate";
      }
      case "applications_c": {
        return "Lift/escalator model range extension";
      }
      case "applications_d": {
        return "Update safety component certificate";
      }
      case "applications_e": {
        return "Safety component range extension";
      }
      case "applications_f": {
        return "Add new safety component";
      }
      default: {
        return "Free Edit";
      }
    }
  };

  const clarifyingDeterminant = (upload) => {
    //For clarifying application, set the mode base on the update_type
    const updateType = upload?.update_type;
    switch (updateType) {
      case "Manufacturer Name Change": {
        return { clarifying: "a" };
      }
      case "Update lift/escalator model certificate": {
        return { clarifying: "b" };
      }
      case "Lift/escalator model range extension": {
        return { clarifying: "c" };
      }
      case "Update safety component certificate": {
        return { clarifying: "d" };
      }
      case "Update safety component certificate (synchronised)": {
        return { clarifying: "d" };
      }
      case "Safety component range extension": {
        return { clarifying: "e" };
      }
      case "Add new safety component": {
        return { clarifying: "f" };
      }
      case "Add new standalone safety component": {
        return { clarifying: "f2" };
      }
      default: {
        return { clarifying: "new" };
      }
    }
  };

  const displayMode = (upload) => mode ?? clarifyingDeterminant(upload);

  const handleUpdate = () => {
    updateAPI(
      "application",
      {
        id: record.id,
        data: upload,
        previousData: record,
        meta: {
          type: typeDeterminant(window.location.hash.split("/")[1]),
          update: update,
        },
      },
      {
        onSuccess: () => (
          navigate(`/applications`),
          notify(itranslate("Application successfully sent")),
          localStorage.removeItem("tapas_application_draft")
        ),
        onError: (e) => notify(e.response.data),
      }
    );
  };

  const handleValidate = () => {
    const data = { ...upload };
    data.update = update;
    data.update_type = location.pathname
      .split("/")
      .find((item) => item.includes("applications_"))
      .split("_")[1];
    create(
      "app_preSubmit/validate",
      { data, meta: { type: "update" } },
      {
        onSuccess: () => (
          notify(
            itranslate("Your application is saved and is validating by AI, please check application (validating) for more information")
          ),
          navigate(`/applications`),
          localStorage.removeItem("tapas_application_draft")
        ),
        onError: () => notify(itranslate(`Some error occured, please try again`)),
      }
    );
  };

  const iconDeterminant = (key, step) => {
    //4 options, activeStep = blue | gray , edited = pen | dots
    var color = "gray";
    var edited = false;
    if (key === "app_type") {
      if (Object.keys(update.app_type.components).length > 0) edited = true;
    } else if (update[key].length > 0) {
      edited = true;
    }

    if (activeStep === step) {
      color = "blue";
    }

    return edited ? (
      <EditIcon sx={{ color }}></EditIcon>
    ) : (
      <MoreHorizIcon sx={{ color }}></MoreHorizIcon>
    );
  };

  const displayDeterminant = (activeStep, type, edit, step) => {
    //manu, model, component, additional documents, comments, confirmation
    const mode = edit.mode ?? edit.clarifying;
    let isClarifying = false;
    if (edit.clarifying) isClarifying = true;
    if (activeStep || activeStep === 0) {
      //basically if clarifying display the comments section
      switch (mode) {
        case "a": {
          // manufacturer name change
          if (activeStep === 0 && type === "manu") return true;
          if (activeStep === 1 && type === "model") return true;
          if (type === "component") return false;
          if (type === "additional documents") return false;
          if (isClarifying && activeStep === 2 && type === "comments")
            return true;
          if (activeStep === (isClarifying ? 3 : 2) && type === "confirmation")
            return true;
          break;
        }
        case "b": {
          //model cert update
          if (type === "manu") return false;
          if (activeStep === 0 && type === "model") return true;
          if (type === "component") return false;
          if (type === "additional documents") return false;
          if (isClarifying && activeStep === 1 && type === "comments")
            return true;
          if (activeStep === (isClarifying ? 2 : 1) && type === "confirmation")
            return true;
          break;
        }
        case "c": {
          //le model range extension
          if (type === "manu") return false;
          if (activeStep === 0 && type === "model") return true;
          if (activeStep === 1 && type === "component") return true;
          if (type === "additional documents") return false;
          if (isClarifying && activeStep === 2 && type === "comments")
            return true;
          if (activeStep === (isClarifying ? 3 : 2) && type === "confirmation")
            return true;
          break;
        }
        case "d": {
          //update sc certificate
          if (type === "manu") return false;
          if (type === "model") return false;
          if (activeStep === 0 && type === "component") return true;
          if (type === "additional documents") return false;
          if (isClarifying && activeStep === 1 && type === "comments")
            return true;
          if (activeStep === (isClarifying ? 2 : 1) && type === "confirmation")
            return true;
          break;
        }
        case "e": {
          //sc range extension
          if (type === "manu") return false;
          if (type === "model") return false;
          if (activeStep === 0 && type === "component") return true;
          if (type === "additional documents") return false;
          if (isClarifying && activeStep === 1 && type === "comments")
            return true;
          if (activeStep === (isClarifying ? 2 : 1) && type === "confirmation")
            return true;
          break;
        }
        case "f": {
          //add new SC to LE model
          if (activeStep === 0 && type === "manu") return true;
          if (activeStep === 1 && type === "model") return true;
          if (activeStep === 2 && type === "component") return true;
          if (type === "additional documents") return false;
          if (isClarifying && activeStep === 3 && type === "comments")
            return true;
          if (activeStep === (isClarifying ? 4 : 3) && type === "confirmation")
            return true;
          break;
        }
        case "f2": {
          //add new standalone SC, this will always be clarifying as adding standalone SC is done in ApplicationCreate
          if (activeStep === 0 && type === "manu") return true;
          if (type === "model") return false;
          if (activeStep === 1 && type === "component") return true;
          if (activeStep === 2 && type === "additional documents") return true;
          if (activeStep === 3 && type === "comments") return true;
          if (activeStep === 4 && type === "confirmation") return true;
          break;
        }
        default: {
          if (activeStep === 0 && type === "manu") return true;
          if (activeStep === 1 && type === "model") return true;
          if (activeStep === 2 && type === "component") return true;
          if (activeStep === 3 && type === "additional documents") return true;
          if (isClarifying && activeStep === 4 && type === "comments")
            return true;
          if (activeStep === (isClarifying ? 5 : 4) && type === "confirmation")
            return true;
          break;
        }
      }
    } else {
      switch (mode) {
        case "a": {
          // manufacturer name change
          if (type === "manu") return step ? 0 : true;
          if (type === "model") return step ? 1 : true;
          if (type === "component") return false;
          if (type === "additional documents") return false;
          if (type === "comments") return isClarifying && (step ? 2 : true);
          if (type === "confirmation")
            return step ? (isClarifying ? 3 : 2) : true;
          break;
        }
        case "b": {
          //model cert update
          if (type === "manu") return false;
          if (type === "model") return step ? 0 : true;
          if (type === "component") return false;
          if (type === "additional documents") return false;
          if (type === "comments") return isClarifying && (step ? 1 : true);
          if (type === "confirmation")
            return step ? (isClarifying ? 2 : 1) : true;
          break;
        }
        case "c": {
          //le model range extension
          if (type === "manu") return false;
          if (type === "model") return step ? 0 : true;
          if (type === "component") return step ? 1 : true;
          if (type === "additional documents") return false;
          if (type === "comments") return isClarifying && (step ? 2 : true);
          if (type === "confirmation")
            return step ? (isClarifying ? 3 : 2) : true;
          break;
        }
        case "d": {
          //update sc certificate
          if (type === "manu") return false;
          if (type === "model") return false;
          if (type === "component") return step ? 0 : true;
          if (type === "additional documents") return false;
          if (type === "comments") return isClarifying && (step ? 1 : true);
          if (type === "confirmation")
            return step ? (isClarifying ? 2 : 1) : true;
          break;
        }
        case "e": {
          //sc range extension
          if (type === "manu") return false;
          if (type === "model") return false;
          if (type === "component") return step ? 0 : true;
          if (type === "additional documents") return false;
          if (type === "comments") return isClarifying && (step ? 1 : true);
          if (type === "confirmation")
            return step ? (isClarifying ? 2 : 1) : true;
          break;
        }
        case "f": {
          //add new SC
          if (type === "manu") return step ? 0 : true;
          if (type === "model") return step ? 1 : true;
          if (type === "component") return step ? 2 : true;
          if (type === "additional documents") return false;
          if (type === "comments") return isClarifying && (step ? 3 : true);
          if (type === "confirmation")
            return step ? (isClarifying ? 4 : 3) : true;
          break;
        }
        case "f2": {
          //add new standalone SC, this will always be clarifying as adding standalone SC is done in ApplicationCreate
          if (type === "manu") return step ? 0 : true;
          if (type === "model") return false;
          if (type === "component") return step ? 1 : true;
          if (type === "additional documents") return step ? 2 : true;
          if (type === "comments") return step ? 3 : true;
          if (type === "confirmation") return step ? 4 : true;
          break;
        }
        default: {
          if (type === "manu") return step ? 0 : true;
          if (type === "model") return step ? 1 : true;
          if (type === "component") return step ? 2 : true;
          if (type === "additional documents") return step ? 3 : true;
          if (type === "comments") return isClarifying && (step ? 4 : true);
          if (type === "confirmation")
            return step ? (isClarifying ? 5 : 4) : true;
          break;
        }
      }
    }
  };

  const titleDeterminant = (edit) => {
    const mode = edit.mode ?? edit.clarifying;
    switch (mode) {
      case "a": {
        return "- Update Application - Manufacturer Name Change";
      }
      case "b": {
        return "- Update Application - Lift/Escalator Model Certificate Update";
      }
      case "c": {
        return "- Update Application - Lift/Escalator Model Range Extension";
      }
      case "d": {
        return "- Update Application - Update SC Certificate";
      }
      case "e": {
        return "- Update Application - SC Range Extension";
      }
      case "f": {
        return "- Update Application - Add New SC to Lift/Escalator Model";
      }
      case "f2": {
        return "- Update Application - Add New Standalone SC";
      }
      default: {
        return "- Edit Application";
      }
    }
  };

  if (!upload) return <Loading />;
  return (
    // <ResourceContextProvider {...props} value='v2/applications'>
    <EditBase
      {...props}
      // basePath="applications_edit"
    >
      <Title title={itranslate(titleDeterminant(displayMode(upload)))} />
      <>
        <Stepper nonLinear activeStep={activeStep} sx={{ m: 5 }}>
          
          {displayDeterminant("", "manu", displayMode(upload)) && (
            <Step key={"manufacturer"}>
              <StepButton
                icon={iconDeterminant(
                  "manufacturer",
                  displayDeterminant("", "manu", displayMode(upload), true)
                )}
                color="inherit"
                onClick={(e) =>
                  setActiveStep(
                    displayDeterminant("", "manu", displayMode(upload), true)
                  )
                }
              >
                <StepLabel>{itranslate("Update Manufacturers")}</StepLabel>
              </StepButton>
            </Step>
          )}

          {displayDeterminant("", "model", displayMode(upload)) && (
            <Step key={"model"}>
              <StepButton
                icon={iconDeterminant(
                  "model",
                  displayDeterminant("", "model", displayMode(upload), true)
                )}
                color="inherit"
                onClick={(e) =>
                  setActiveStep(
                    displayDeterminant("", "model", displayMode(upload), true)
                  )
                }
              >
                <StepLabel>{itranslate("Update Models")}</StepLabel>
              </StepButton>
            </Step>
          )}

          {displayDeterminant("", "component", displayMode(upload)) && (
            <Step key={"component"}>
              <StepButton
                icon={iconDeterminant(
                  "app_type",
                  displayDeterminant("", "component", displayMode(upload), true)
                )}
                color="inherit"
                onClick={(e) =>
                  setActiveStep(
                    displayDeterminant(
                      "",
                      "component",
                      displayMode(upload),
                      true
                    )
                  )
                }
              >
                <StepLabel>{itranslate("Update Components")}</StepLabel>
              </StepButton>
            </Step>
          )}

          {displayDeterminant(
            "",
            "additional documents",
            displayMode(upload)
          ) && (
            <Step key={"additional"}>
              <StepButton
                icon={iconDeterminant(
                  "other_documents",
                  displayDeterminant(
                    "",
                    "additional documents",
                    displayMode(upload),
                    true
                  )
                )}
                color="inherit"
                onClick={(e) =>
                  setActiveStep(
                    displayDeterminant(
                      "",
                      "additional documents",
                      displayMode(upload),
                      true
                    )
                  )
                }
              >
                <StepLabel>{itranslate("Additional Documents")}</StepLabel>
              </StepButton>
            </Step>
          )}

          {displayDeterminant("", "comments", displayMode(upload)) && (
            <Step key={"comment"}>
              <StepButton
                icon={iconDeterminant(
                  "dialog",
                  displayDeterminant("", "comments", displayMode(upload), true)
                )}
                color="inherit"
                onClick={(e) =>
                  setActiveStep(
                    displayDeterminant(
                      "",
                      "comments",
                      displayMode(upload),
                      true
                    )
                  )
                }
              >
                <StepLabel>{itranslate("Comments")}</StepLabel>
              </StepButton>
            </Step>
          )}

          {displayDeterminant("", "confirmation", displayMode(upload)) && (
            <Step key={"confirm"}>
              <StepButton
                icon={
                  <SaveIcon
                    sx={{
                      color:
                        activeStep ===
                        displayDeterminant(
                          "",
                          "confirmation",
                          displayMode(upload),
                          true
                        )
                          ? "blue"
                          : "gray",
                    }}
                  />
                }
                color="inherit"
                onClick={(e) =>
                  setActiveStep(
                    displayDeterminant(
                      "",
                      "confirmation",
                      displayMode(upload),
                      true
                    )
                  )
                }
              >
                <StepLabel>{itranslate("Confirmation")}</StepLabel>
              </StepButton>
            </Step>
          )}
        </Stepper>
        {/*see what component looks like, make a component then see in show/edit screen, see record */}

        {displayDeterminant(activeStep, "manu", displayMode(upload)) &&
          upload && (
            <ManufacturerDisplay
              show={false}
              edit={displayMode(upload)}
              add={false}
              title={"Edit Manufacturer(s)"}
              existing={manufacturersLoad || []}
              upload={upload}
              setUpload={setUpload}
              setuploadCopy={setuploadCopy}
              update={update}
              setUpdate={setUpdate}
              template={static_fields}
              variable={variable_fields}
              filenames={filenames}
            />
          )}

        {displayDeterminant(activeStep, "model", displayMode(upload)) &&
          upload && (
            <ModelDisplay
              show={false}
              edit={displayMode(upload)}
              add={false}
              title={"Edit Model(s)"}
              existing={modelsLoad || []}
              uploadCopy={uploadCopy}
              upload={upload}
              setUpload={setUpload}
              update={update}
              setUpdate={setUpdate}
              template={static_fields}
              variable={variable_fields}
              filenames={filenames}
            />
          )}

        {displayDeterminant(activeStep, "component", displayMode(upload)) &&
          upload && (
            <ComponentDisplay
              show={false}
              edit={displayMode(upload)}
              add={false}
              title={"Edit Safety Component(s)"}
              options={options}
              setOptions={setOptions}
              upload={upload}
              setUpload={setUpload}
              update={update}
              setUpdate={setUpdate}
              optional={optional}
              setOptional={setOptional}
              template={static_fields}
              variable={variable_fields}
              filenames={filenames}
            />
          )}

        {displayDeterminant(
          activeStep,
          "additional documents",
          displayMode(upload)
        ) &&
          upload && (
            <DocumentStack
              show={false}
              edit={displayMode(upload)}
              add={false}
              title={"Edit Additional Documents"}
              upload={upload}
              update={update}
              setUpload={setUpload}
              setUpdate={setUpdate}
              variable={variable_fields}
            />
          )}

        {displayDeterminant(activeStep, "comments", displayMode(upload)) &&
          upload && (
            <CommentBox
              show={false}
              edit={displayMode(upload)}
              add={false}
              title={"Add Comments"}
              upload={upload}
              setUpload={setUpload}
              setUpdate={setUpdate}
            />
          )}

        {displayDeterminant(activeStep, "confirmation", displayMode(upload)) &&
          upload && (
            <Box>
              <UpdateCheck
                edit={displayMode(upload)}
                update={update}
                old={record}
                setUpdate={setUpdate}
                upload={upload}
                setUpload={setUpload}
                handleSend={handleUpdate}
                handleValidate={handleValidate}
                isUpdating={isUpdating}
                isCreating={isCreating}
                setIsLastPage={setIsLastPage}
              />
            </Box>
          )}
      </>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button
          disabled={activeStep === 0}
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        >
          {/* Back */}
          {itranslate("Back")}
        </Button>
        {props.mode && (
          <SaveDraft
            isValidated={isValidated}
            draftType="update"
            upload={upload}
            setUpload={setUpload}
            update={update}
            optional={optional}
            currentDraft={currentDraft}
            setCurrentDraft={setCurrentDraft}
          />
        )}
        <Box sx={{ flex: "1 1 auto" }} />
        {!isLastPage && (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
          >
            {/* Next */}
            {itranslate("Next")}
          </Button>
        )}
      </Box>
    </EditBase>
    // </ResourceContextProvider>
  );
};

export default ApplicationEdit;
