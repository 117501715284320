import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
} from "@mui/material";
import { useTranslation } from 'react-i18next';

import keyToField from "../../utils/keyToField";
import FileUploadZoneAndShowFileImage from "../../utils/fileUploadZone";
import ComponentExtraDetail from "./ComponentExtraDetail";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import getToken from "../../utils/getAuthToken";


export const ComponentShowOne = (props) => {
  const { t : itranslate } = useTranslation()
  const { upload, template } = props;
  const isModelExisting = React.useRef(false)

  const fileDownload = async (id) => {
    window.open(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${id}?token=${getToken()}`);
  };

  const textOnlyField = [
    "type",
    "component_code",
    "provided_comp_name",
    "model_id",
    "manufacturer_id",
  ];

  const bannedFields = [
    "type_test_certificate_id"
  ];
  
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: "lightgray" }}
      >
        <Typography>
          {upload.provided_comp_name ? upload.provided_comp_name : itranslate(`Component`)}
        </Typography>
        
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={2}
          wrap="wrap"
          sx={{ "&:first-of-type": { mt: 1 }, width: "80%" }}
        >
          {Object.keys(template["component"])
            .filter((key) => key !== "provided_comp_name")
            .map((fieldname) => {
              // template comes from static field JS, and there is no manufacturer_id in the template, can't edit the template either as it will cause bug in other page
              if ( upload[fieldname]?.length > 0 || fieldname === "manufacturer_id")
                return (
                  <React.Fragment key={fieldname}>

                    {textOnlyField.includes(fieldname) ? (
                      <Grid item xs={5}>
                        <Typography display="inline" sx={{ mr: 1 }}>
                          {itranslate(keyToField[fieldname] ?? itranslate(fieldname))}:
                        </Typography>

                        {fieldname === "model_id" ? (
                          <span className="p-3">
                              {upload.scRelatedModels.map(v => {
                                isModelExisting.current = true
                                return (
                                  <Link key={v._id} className="mr-1" to={`/models/${v._id}/show`}>
                                    {v.provided_model_name}
                                  </Link>
                                )
                              })}

                          </span>
                        ) 
                        : fieldname === "manufacturer_id" ? (
                          <Link to={`/manufacturers/${upload.manufacturer}/show`}>
                            {upload?.manufacturersPopulated?.[0]?.provided_name}
                          </Link>
                        ) : (
                          <Typography display="inline">
                            {itranslate(keyToField[upload[fieldname]] ?? itranslate(upload[fieldname]))}
                          </Typography>
                        )}
                      </Grid>
                    ) : ((!isModelExisting.current || !bannedFields.includes(fieldname)) || window.location.pathname === "/lift_emsd") && (
                      // only show sc tec in standalone component
                      <Grid item xs={5}>
                        <FileUploadZoneAndShowFileImage
                          show={true}
                          showOne={true}
                          edit={false}
                          data={upload}
                          fieldname={fieldname}
                          downloadFile={fileDownload}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                );
            })}
        </Grid>

        <ComponentExtraDetail component={upload} />

      </AccordionDetails>
    </Accordion>
  );
};
