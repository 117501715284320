import axios from 'axios'

import getToken from "../../utils/getAuthToken";

var token

export const getApps_Phase1Api = () => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/lift/apps?filter={"q":""}&range=[]&sort=[]`,
        headers: {authorization: token},
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))