import React from "react";
import {
  Box,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  Typography,
  Button,
  Grid,
  Paper,
} from "@mui/material";

import { experimentalStyled as styled } from "@mui/material/styles";

import keyToField from "../../utils/keyToField";
import { Link } from "react-router-dom";
import { fixInvalidDates } from "../../utils/misc/fixInvalidDates";

import PdfModal from "../../utils/pdf/PdfModal";
import BasicModalLarge from "../../../_hoc/modals/BasicModalLarge";

import getToken from "../../utils/getAuthToken";

import { useTranslation } from 'react-i18next';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const fieldToInclude = {
  tec_cert_type: "Type of Certificate",
  tec_certificate_body: "Certificate Body",
  tec_certificate_number: "Type Exam. Certificate No.",
  tec_expiry_date: "Expiry Date",
  tec_issue_date: "Issue Date",
  tec_manufacturer_address: "Manufacturer Address",
  tec_manufacturer_name: "Manufacturer Name",
  tec_model_no: "Model No.",
  tec_report_no: "Report No.",
  tec_report_type: "Report Type",
  tec_standard: "Standard",
  tec_test_item: "Test Item",
  tec_buffer: "Buffer",
  "Approval Reference": "Approval Reference",
  tec_guide_rail_running_surface_minimum_width:
    "Guide Rail Running Surface Minimum Width",
  tec_impact_speed_max: "Impact Speed m/s (maximum)",
  tec_rated_speed_max: "Rated Speed m/s (maximum)",
  tec_rated_speed_min: "Rated Speed m/s (minimum)",
  tec_rated_load_max: "Rated Load kg (maximum)",
  tec_rated_load_min: "Rated Load kg (minimum)",
  tec_total_mass_min: "Total Mass (minimum)",
  tec_tripping_speed_max: "Tripping Speed m/s (maximum)",
};

function ModelExtraDetail(props) {
  const { model } = props;

  const { t : itranslate } = useTranslation();

  const [hoveredCert, setHoveredCert] = React.useState("");
  const [isPdfModalOpen, setIsPdfModalOpen] = React.useState(false);

  const [scTecInfoModalOpen, setScTecInfoModalOpen] = React.useState(false);
  const [scTecInfo, setScTecInfo] = React.useState(null);
  const [certTypeMetaDataKeys, setCertTypeMetaDataKeys] = React.useState([]);

  const [requestID, setRequestID] = React.useState("");
  const handleGetPDFFile = async (certId) => {
    setRequestID(certId);
    setIsPdfModalOpen(true);
  };

  const displayInRc = [
    "tec_certificate_number",
    "tec_standard",
    "tec_issue_date",
  ];
  const simpleDisplay = (certificates, cert_id, field) => {
    const matchCert = certificates?.find(
      (cert) => (cert._id || cert.id) === cert_id
    );
    if (!field) return matchCert;
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field] ||
      ""
    );
  };

  return (
    <Box>
      <TableContainer sx={{ width: "800px" }}>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          {itranslate('Manufacturers')}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                {itranslate('Manufacturer Code')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {itranslate('Manufacturer Name')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {model?.manufacturersPopulated?.map((manufacturer) => {
              return (
                <TableRow key={manufacturer._id}>
                  <TableCell> {manufacturer.manufacturer_code}</TableCell>
                  <TableCell>
                    <Link to={`/manufacturers/${manufacturer._id}/show`}>
                      {manufacturer.provided_name}
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {window.location.pathname === "/lift_emsd" && (
        <TableContainer sx={{ width: "800px" }}>
          <Typography sx={{ fontWeight: "bold", mt: 3 }}>
            {itranslate('Registered Contractor')}
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {itranslate('RC number')}
                </TableCell>
                <TableCell>
                  {itranslate('RC company name')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Link to={`/rcs/${model?.rcPopulated?._id}/show`}>
                    {model?.rcPopulated?.name}
                  </Link>
                </TableCell>
                <TableCell>{model?.rcPopulated?.address?.company}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TableContainer sx={{ width: "800px" }}>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          {itranslate('General Operating Parameters and Characteristics')}
        </Typography>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                {itranslate('Field')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {itranslate('Value')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(model?.appendixInfo || {}).map((key) => {
              return (
                <TableRow key={key}>
                  <TableCell> {itranslate(key)}</TableCell>
                  <TableCell>{model?.appendixInfo[key]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer sx={{ width: "800px" }}>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          {itranslate('Type Test Certificates / Full Quality Certificates')}
        </Typography>

          {model?.certsPopulated?.filter((cert) => ["FullQualityCert", "ModelTypeCert"].includes(cert.type))?.map((cert) => {
            return (
              <React.Fragment key={cert._id}>
                <Typography sx={{ fontFamily: "Frutiger_bold", mt: 3, ml: 2 }}>

                  {simpleDisplay(model.certsPopulated,cert._id,"tec_certificate_number")}{" "}

                  {cert.type === "FullQualityCert" && "(Full Quality Certificate)"}

                </Typography>
                <Table size="small">
                  <TableBody>
                    {Array.from(
                      new Set([
                        ...Object.keys(cert.cert_type_metadata || {}),
                        ...Object.keys(cert.cert_corrections || {}),
                      ])
                    )
                      .filter((field) =>
                        window.location.pathname === "/lift_rc"
                          ? displayInRc.includes(field)
                          : field
                      )
                      .map((field) => {
                        return (
                          <TableRow key={field}>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                              variant="head"
                            >
                              {itranslate(keyToField[field] ?? itranslate(field))}
                            </TableCell>
                            <TableCell>
                              {field === "tec_issue_date" ||
                              field === "tec_expiry_date" ||
                              field === "tec_first_issue_date" ||
                              field === "tec_expiry_date"
                                ? fixInvalidDates(
                                    simpleDisplay(
                                      model.certsPopulated,
                                      cert._id,
                                      field
                                    )
                                  )
                                : simpleDisplay(
                                    model.certsPopulated,
                                    cert._id,
                                    field
                                  )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </React.Fragment>
            );
          })}
      </TableContainer>

      <TableContainer>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          {itranslate('Safety Components')}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", width: "10%",  }}>
                {itranslate('Typed')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "1px" }}>
                {itranslate('Component Code')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "15%" }}>
                {itranslate('Component Name')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "15%" }}>
                {itranslate('Manufacturers')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "15%" }}>
                {itranslate('Cert No.')}
              </TableCell>
              {window.location.pathname === "/lift_emsd" && (
                <TableCell sx={{ fontWeight: "bold", width: "1px" }}>
                  {itranslate('Cert Info')}
                </TableCell>
              )}
              <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                {itranslate('Issue date')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                {itranslate('Approval date')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {Object.keys(model.componentsPopulated)
              .sort((a, b) => {
                const order = [
                  "SafetyGear",
                  "Buffer",
                  "OverspeedGovernor",
                  "LandingDoorLockingDevice",
                  "CarDoorLockingDevice",
                  "ACOP",
                  "UCMP",
                  "SafetyCircuitElectricalComponent",
                  "OneWayValve",
                  "RaptureValve",
                  "Buffer",
                ];
                function customSort(item) {
                  const index = order.indexOf(item);
                  return index !== -1 ? index : order.length;
                }
                return customSort(a) - customSort(b);
              })
              // .map((type) =>
              //   model.componentsPopulated[type].map(
              //     (comp, compIndex, compList) => {
              //       return (
              //         <TableRow key={comp._id}>
              //           {compIndex === 0 && (
              //             <TableCell
              //               rowSpan={compList.length}
              //               sx={{ verticalAlign: "text-top" }}
              //             >
              //               {keyToField[type]}
              //             </TableCell>
              //           )}
              //           <TableCell>{comp.component_code}</TableCell>
              //           <TableCell>
              //             <Link to={`/components/${comp._id}/show`}>
              //               {comp.provided_comp_name}
              //             </Link>
              //           </TableCell>
              //           <TableCell>
              //             <Link
              //               to={`/manufacturers/${comp?.manufacturer_id?._id}/show`}
              //             >
              //               {comp?.manufacturer_id?.provided_name}
              //             </Link>
              //           </TableCell>
              //           <TableCell>
              //             {comp.type_test_certificate_id
              //               ?.map((cert_id) =>
              //                 simpleDisplay(
              //                   model.certsPopulated,
              //                   cert_id,
              //                   "tec_certificate_number"
              //                 )
              //               )
              //               ?.join(", ")}
              //           </TableCell>
              //           <TableCell>
              //             {comp.type_test_certificate_id
              //               ?.map(
              //                 (cert_id) =>
              //                   simpleDisplay(
              //                     model.certsPopulated,
              //                     cert_id,
              //                     "tec_issue_date"
              //                   ) &&
              //                   fixInvalidDates((simpleDisplay(model.certsPopulated, cert_id, "tec_issue_date") || "").replace(/–/g, "-"))
              //               )
              //               ?.join(", ")}
              //           </TableCell>
              //         </TableRow>
              //       );
              //     }
              //   )
              // )

              .map((type) => {
                const components = model.componentsPopulated[type];
                // filter out any components which are not active (revoked)
                const activeComponents = components?.filter((comp) => comp.active);

                if (activeComponents?.length > 0) {
                  return activeComponents.map((comp, compIndex, compList) => {
                    return (
                      <TableRow 
                        key={comp._id}
                      >
                        {compIndex === 0 && (
                          <TableCell
                            rowSpan={compList.length}
                            sx={{ 
                              verticalAlign: "text-top",
                            }}
                          >
                            {itranslate(keyToField[type])}
                          </TableCell>
                        )}

                        <TableCell>{comp.component_code}</TableCell>

                        <TableCell>
                          <Link to={`/components/${comp._id}/show`}>
                            {comp.provided_comp_name}
                          </Link>
                        </TableCell>

                        <TableCell>
                          <Link
                            to={`/manufacturers/${comp?.manufacturer_id?._id}/show`}
                          >
                            {comp?.manufacturer_id?.provided_name}
                          </Link>
                        </TableCell>

                        <TableCell>
                          {comp.type_test_certificate_id?.map((certId) => {
                            return (
                              <div
                                key={certId}
                                className={
                                  hoveredCert === certId
                                    ? "bg-sky-200 cursor-pointer py-2"
                                    : "py-2"
                                }
                                onMouseEnter={() => {
                                  setHoveredCert(certId);
                                }}
                                onMouseLeave={() => {
                                  setHoveredCert(null);
                                }}
                                onClick={() => handleGetPDFFile(certId)}
                              >
                                {simpleDisplay(
                                  model.certsPopulated,
                                  certId,
                                  "tec_certificate_number"
                                )}
                                ;
                              </div>
                            );
                          })}
                        </TableCell>

                        {window.location.pathname === "/lift_emsd" && (
                          <TableCell>
                            {comp.type_test_certificate_id?.map((certId) => {
                              return (
                                <div
                                  key={certId}
                                  className={
                                    hoveredCert === certId
                                      ? "bg-sky-200 cursor-pointer py-2"
                                      : "py-2"
                                  }
                                  onMouseEnter={() => {
                                    setHoveredCert(certId);
                                  }}
                                  onMouseLeave={() => {
                                    setHoveredCert(null);
                                  }}
                                  onClick={() => {
                                    let temp = model.certsPopulated.find(
                                      (cert) => cert._id === certId
                                    );
                                    if (temp) {
                                      setScTecInfo(temp.cert_type_metadata);
                                      setCertTypeMetaDataKeys(
                                        Object.keys(temp.cert_type_metadata)
                                      );
                                      setScTecInfoModalOpen(true);
                                    }
                                  }}
                                >
                                  <button className="text-blue-btn-color">
                                    {itranslate("Show")}
                                  </button>
                                </div>
                              );
                            })}
                          </TableCell>
                        )}

                        <TableCell>
                          {comp.type_test_certificate_id?.map(
                            (certId) =>
                              simpleDisplay(
                                model.certsPopulated,
                                certId,
                                "tec_issue_date"
                              ) && (
                                <div
                                  key={certId}
                                  className={
                                    hoveredCert === certId
                                      ? "bg-sky-200 py-2"
                                      : "py-2"
                                  }
                                  onMouseEnter={() => {
                                    setHoveredCert(certId);
                                  }}
                                  onMouseLeave={() => {
                                    setHoveredCert(null);
                                  }}
                                >
                                  {fixInvalidDates(
                                    (
                                      simpleDisplay(
                                        model.certsPopulated,
                                        certId,
                                        "tec_issue_date"
                                      ) || ""
                                    ).replace(/–/g, "-")
                                  )}
                                </div>
                              )
                          )}
                        </TableCell>

                        <TableCell>
                          {comp.type_test_certificate_id?.map(
                            (certId) =>
                              certId && (
                                <div
                                  key={certId}
                                  className={
                                    hoveredCert === certId
                                      ? "bg-sky-200 py-2"
                                      : "py-2"
                                  }
                                  onMouseEnter={() => {
                                    setHoveredCert(certId);
                                  }}
                                  onMouseLeave={() => {
                                    setHoveredCert(null);
                                  }}
                                >
                                  {fixInvalidDates(comp.first_approval_date)}
                                </div>
                              )
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  });
                } else {
                  return null;
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer sx={{ width: "800px" }}>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          {itranslate("Related Applications")}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {itranslate("Application Number")}
              </TableCell>
              <TableCell>
                {itranslate("Approval Date")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {model?.applicationsPopulated?.map((app) => {
              return (
                <TableRow key={app._id}>
                  <TableCell>
                    <Link
                      to={`${
                        window.location.pathname === "/lift_emsd"
                          ? "/v2/applications"
                          : "/applications"
                      }/${app._id}/show`}
                    >
                      {app?.application_number}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {app?.approval_date?.split("T")[0] &&
                      fixInvalidDates(
                        (app?.approval_date?.split("T")[0] || "").replace(
                          /–/g,
                          "-"
                        )
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {isPdfModalOpen && (
        <PdfModal
          isOpen={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfUrl={`${
            process.env.REACT_APP_ENV_ENDPOINT
          }/lift/files/getCertPdf5Page/${requestID}?token=${getToken()}`}
          requestID={requestID}
        />
      )}

      {/* below show TEC Info in a big modal */}
      {scTecInfoModalOpen && window.location.pathname === "/lift_emsd" && (
        <BasicModalLarge
          open={scTecInfoModalOpen}
          setOpen={setScTecInfoModalOpen}
          title={itranslate("TEC Info")}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 1 }}
            columns={{ xs: 1, sm: 4, md: 4 }}
            sx={{ height: "50%" }}
          >
            {certTypeMetaDataKeys.map((v, index) => {
              return (
                certTypeMetaDataKeys?.includes(v) && (
                  <Grid item xs={1} sm={1} md={1} key={index}>
                    <Item sx={{ fontWeight: "bold", height: "5rem" }}>
                      {itranslate(keyToField[v]) || itranslate(v)}
                    </Item>
                    <Item
                      sx={
                        scTecInfo[v].toString()?.length > 50
                          ? { overflowWrap: "break-word" }
                          : { overflowWrap: "break-word", height: "8rem" }
                      }
                    >
                      {scTecInfo?.[v] ||
                        scTecInfo[v]
                          .toString()
                          ?.split(/(?=[A-Z])/)
                          ?.join(" ") ||
                        "N/A"}
                    </Item>
                    <br></br>
                  </Grid>
                )
              );
            })}
          </Grid>
          {/* <TableContainer>
            <Table size="large">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Type of Certificate</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Certificate body
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Type Exam. Certificate No.
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Expiry date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{scTecInfo?.tec_cert_type || "N/A"}</TableCell>
                  <TableCell>{scTecInfo?.tec_certificate_body || "N/A"}</TableCell>
                  <TableCell>{scTecInfo?.tec_certificate_number || "N/A"}</TableCell>
                  <TableCell>
                    {(scTecInfo?.tec_expiry_date?.split("T")[0] &&
                      fixInvalidDates(
                        (scTecInfo?.tec_expiry_date?.split("T")[0] || "").replace(
                          /–/g,
                          "-"
                        )
                      )) || "N/A"}
                  </TableCell>


                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table size="large">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Issue date</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Manufacturer Address
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Manufacturer Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Model No.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {(scTecInfo?.tec_issue_date?.split("T")[0] &&
                      fixInvalidDates(
                        (scTecInfo?.tec_issue_date?.split("T")[0] || "").replace(
                          /–/g,
                          "-"
                        )
                      )) || "N/A"}
                  </TableCell>
                  <TableCell>
                    {scTecInfo?.tec_manufacturer_address || "N/A"}
                  </TableCell>
                  <TableCell>
                    {scTecInfo?.tec_manufacturer_name || "N/A"}
                  </TableCell>
                  <TableCell>{scTecInfo?.tec_model_no || "N/A"}</TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table size="large">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Report No.
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Standard
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Test Item</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Buffer
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{scTecInfo?.tec_report_no || "N/A"}</TableCell>
                  <TableCell>{scTecInfo?.tec_standard || "N/A"}</TableCell>
                  <TableCell>{scTecInfo?.tec_test_item || "N/A"}</TableCell>
                  <TableCell>{scTecInfo?.tec_buffer || "N/A"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}
        </BasicModalLarge>
      )}
    </Box>
  );
}

export default ModelExtraDetail;
