import { createSlice } from "@reduxjs/toolkit";

export const stageSlice = createSlice({
  name: "stage",
  initialState: {
    value: "1",
    previousValue: ""
  },
  reducers: {
    setStage: (state, action) => {
      state.previousValue = state.value;
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStage, getPreviousStage } = stageSlice.actions;

export default stageSlice.reducer;
