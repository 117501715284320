import { ButtonGroup, Button } from "@mui/material";
import { useState } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

import { useTranslation } from "react-i18next";

const Chart = ({ data, rangeChange }) => {
    // const { t } = useTranslation("pages", { keyPrefix: "statistic dashboard" });
    const { t : itranslate } = useTranslation();

    const [seven, setSeven] = useState(0);

    return ( 
        <div
            className="text-gray-200 shadow-md p-5"
        >
            <ButtonGroup
                sx={{
                    mb: "20px",
                }}
                variant="text"
                aria-label="text primary button group"
            >
                <Button
                    sx={
                        seven === 0
                            ? {
                                color: "#2a598f!important",
                            }
                            : {
                                color: "gray",
                            }
                    }
                    disabled={seven === 0}
                    onClick={() => {
                        setSeven(0);
                        rangeChange("6");
                    }}
                >
                    {itranslate(`Last 6 Months`)}
                </Button>
                <Button
                    sx={
                        seven === 1
                            ? {
                                color: "#2a598f!important",
                            }
                            : {
                                color: "gray",
                            }
                    }
                    disabled={seven === 1}
                    onClick={() => {
                        setSeven(1);
                        rangeChange("3");
                    }}
                >
                    {itranslate(`Last 3 Months`)}
                </Button>
                <Button
                    sx={
                        seven === 2
                            ? {
                                color: "#2a598f!important",
                            }
                            : {
                                color: "gray",
                            }
                    }
                    disabled={seven === 2}
                    onClick={() => {
                        setSeven(2);
                        rangeChange("1");
                    }}
                >
                    {itranslate(`Last Month`)}
                </Button>
                <Button
                    sx={
                        seven === 3
                            ? {
                                color: "#2a598f!important",
                            }
                            : {
                                color: "gray",
                            }
                    }
                    disabled={seven === 3}
                    onClick={() => {
                        setSeven(3);
                        rangeChange("0");
                    }}
                >
                    {itranslate(`This Month`)}
                </Button>
            </ButtonGroup>
            <ResponsiveContainer width="100%" aspect={2 / 1}>
                <AreaChart
                    data={data}
                >
                    <defs>
                        <linearGradient id="daVisited" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="ruVisited" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#ff6f61" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ff6f61" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="ruCreated" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#61ff6f" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#61ff6f" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" stroke="gray" />
                    <CartesianGrid strokeDasharray="3 3" stroke="rgb(228,225,225)" />
                    <Tooltip />
                    {/* <Area type="monotone" dataKey={itranslate("statistic dashboard.DA visit by Qr Code")} stroke="#8884d8" fillOpacity={1} fill="url(#daVisited)" />
                    <Area type="monotone" dataKey={itranslate("statistic dashboard.Url Visit")} stroke="#ff6f61" fillOpacity={1} fill="url(#ruVisited)" />
                    <Area type="monotone" dataKey={itranslate("statistic dashboard.Url Creation")} stroke="#61ff6f" fillOpacity={1} fill="url(#ruCreated)" /> */}

                    <Area type="monotone" dataKey={"DA visit by Qr Code"} stroke="#8884d8" fillOpacity={1} fill="url(#daVisited)" />
                    <Area type="monotone" dataKey={"Url Visit"} stroke="#ff6f61" fillOpacity={1} fill="url(#ruVisited)" />
                    <Area type="monotone" dataKey={"Url Creation"} stroke="#61ff6f" fillOpacity={1} fill="url(#ruCreated)" />
                    
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default Chart;
