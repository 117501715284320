import { Text, View } from "@react-pdf/renderer";
import { renderIndexedContent } from "../../../../utils/pdf/renderContent";
import { romanize } from "../../../../utils/romanize";
import renderChinese from "../../../../utils/pdf/renderChinese";
import renderSymbol from "../../../../utils/pdf/renderSymbol";

function PartD(props) {
  const { application, appendix, format } = props;
  const DivComponent = format === "plainHtml" ? "div" : View;
  const TextComponent = format === "plainHtml" ? "p" : Text;
  /* schena: {
    visible: false,
    header: "",
    tables: [
      {
        title: "",
        items: [
          {
            manufacturer: "",
            list: [{ model: "", tec: "", issue_date: "" }],
          },
        ],
      },
    ],
  }; */
  if (!appendix.partD.visible) return null;
  return (
    <DivComponent {...(format === "pdf" && { break: true })}>
      <TextComponent
        className="appendixSection"
        style={{ fontFamily: "Frutiger_Ultra_bold" }}
      >
        D. {appendix?.partD?.header}
      </TextComponent>
      {appendix?.partDremarks?.length > 0 && (
        <DivComponent style={{ marginTop: "25px" }}>
          <TextComponent
            style={{ fontFamily: "Frutiger_bold", fontSize: "11px" }}
          >
            Remarks
          </TextComponent>
          {renderIndexedContent(
            appendix?.partDremarks || [],
            format,
            application.model
          )}
        </DivComponent>
      )}

      {appendix?.partD?.tables.map((table, index) => {
        return (
          <DivComponent
            key={index}
            style={{
              padding: "5px 0px",
              marginRight: "-15px",
            }}
          >
            <TextComponent
              style={{
                textDecoration: "underline",
                fontFamily: "Frutiger_bold",
                fontSize: "11px",
              }}
            >
              {romanize(index + 1)}. {table.title}
            </TextComponent>
            <DivComponent
              style={{
                display: "flex",
                flexDirection: "row",
                borderBottom: "none",
                fontFamily: "Frutiger_bold",
                fontSize: "11px",
                marginRight: "-45px",
                marginBottom: "15px",
              }}
            >
              <TextComponent style={{ width: "33.3333%", paddingLeft: "6px" }}>
                Model
              </TextComponent>
              <TextComponent style={{ width: "33.3333%" }}>
                Type Exam. Certificate
              </TextComponent>
              <TextComponent style={{ width: "33.3333%" }}>
                Issue Date
              </TextComponent>
            </DivComponent>

            {table.items?.map((item, itemIndex) => {
              return (
                <DivComponent
                  key={itemIndex}
                  style={{ paddingBottom: "10px", marginRight: "-45px" }}
                >
                  <TextComponent
                    style={{
                      width: "40%",
                      fontFamily: "Frutiger_bold",
                      fontSize: "11px",
                      paddingLeft: "3px",
                      ...renderChinese(item.manufacturer),
                    }}
                  >
                    {item.manufacturer}
                  </TextComponent>
                  {item.list.map((listItem, listIndex) => (
                    <DivComponent
                      key={listIndex}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <TextComponent
                        style={{ width: "33.3333%", paddingLeft: "6px" }}
                      >
                        {listItem.model}
                      </TextComponent>
                      <TextComponent style={{ width: "33.3333%" }}>
                        {listItem.tec}
                      </TextComponent>
                      <TextComponent style={{ width: "33.3333%" }}>
                        {format === "pdf"
                          ? renderSymbol(listItem.issue_date)
                          : listItem.issue_date}
                      </TextComponent>
                    </DivComponent>
                  ))}
                </DivComponent>
              );
            })}
          </DivComponent>
        );
      })}
    </DivComponent>
  );
}

export default PartD;
