// a: Manufacturer Name Change
// b: Model Cert Update (Range Extension)
// c: LE model range extension
// d: Update SC Certificate
// e: SC range extension
// f: Add new SC
import mandatoryFields from "./mandatoryFields.json";

const failedUpdateValidation = (data, mode, upload) => {
  //note that data here is the update object in applicationEdit, it means only the updated fields are passed in
  //which means if some field is empty, there is no new data
  let failed = false;

  function checkManufacturer(mandatory, changedManufacturer) {
    data.manufacturer
      .filter((manu) =>
        changedManufacturer ? manu._id === changedManufacturer : !manu._id
      )
      .forEach((manu) => {
        if (changedManufacturer) {
          if (mandatory.some((field) => !manu[field]?.id)) failed = true;
        } else if (!manu.manufacturer_code) {
          if (mandatory.some((field) => manu[field] === "")) failed = true;
        } else {
          if (manu.iso_certificate_id === "") failed = true;
        }
      });
  }

  function checkModel(mandatory) {
    if (data.model.length > 0) {
      data.model.forEach((model) => {
        // If the file is not newly uploaded, it doesn't have an id
        const atLeastOneFileMetRequirement = model.manufacturer_files.some(
          (file) => {
            const metRequirement = mandatory.every((field) =>
              Array.isArray(file[field])
                ? file[field].filter((cert) => cert.id).length > 0
                : file[field]?.id
            );
            return metRequirement;
          }
        );
        if (!atLeastOneFileMetRequirement) failed = true;
      });
    } else failed = true;
  }

  function checkNewComponent(mandatory) {
    if (Object.keys(data.app_type.components).length > 0) {
      if (
        // No new component
        Object.values(data.app_type.components).every(
          (componentArray) =>
            componentArray.filter((comp) => !comp._id).length === 0
        )
      )
        failed = true;

      Object.values(data.app_type.components).map((componentArray) => {
        const newComponents = componentArray.filter((comp) => !comp._id);
        if (newComponents.length > 0) {
          // Only checks newly added component
          if (newComponents.some((comp) => !comp.confirmed)) failed = true;
        }
      });
    } else failed = true;
  }

  function checkOldComponent(mandatory) {
    // If length is 0, it means they changed nothing, which means no new cert uploaded
    if (Object.keys(data.app_type.components).length > 0) {
      Object.values(data.app_type.components).map((componentArray) => {
        if (componentArray.length > 0) {
          // Only checks newly added component
          if (
            componentArray.some((comp) => {
              const notMetRequirement = mandatory.some((field) =>
                Array.isArray(comp[field])
                  ? comp[field].filter((cert) => cert.id).length === 0
                  : field === "manufacturer_id"
                  ? comp[field] === ""
                  : !comp[field]?.id
              );
              return notMetRequirement;
            })
          )
            failed = true;
        }
      });
      //When they changed nothing, it can also be the case that there are no component in the first place
    } else if (Object.keys(upload.app_type?.components || {}).length > 0)
      failed = true;
  }

  function checkRangeExtension() {
    const allComponents = Object.values(upload.app_type.components).flat();
    const model = upload.model[0];
    const type = model.LE_type === "L" ? "c_lift" : "c_escalator";
    function isNewFile(file) {
      return Array.isArray(file)
        ? file.filter((cert) => cert.id).length > 0
        : file?.id;
    }

    function isSetSummitted(set) {
      if (!set) return false;

      return (
        model.manufacturer_files.some((file) =>
          mandatoryFields[set].models.every((field) => isNewFile(file[field]))
        ) &&
        (set === "c_lift2"
          ? true
          : allComponents.some((comp) =>
              mandatoryFields[set].components.every((field) =>
                isNewFile(comp[field])
              )
            ))
      );
    }

    const fulfilRequirement = isSetSummitted(upload.mandatoryFileSet); //user can change upload.mandatoryFileSet to either 1 or 2 in RangeExtensionCheckbox.js
    if (!fulfilRequirement) failed = true;
  }

  if (mode === "a") {
    checkModel(mandatoryFields.a.models);
    checkManufacturer(
      mandatoryFields.a.manufacturers,
      upload.changedManufacturer
    );
  } else if (mode === "b") {
    checkModel(mandatoryFields.b.models);
  } else if (mode === "c") {
    checkRangeExtension();
  } else if (mode === "d") {
    // checkOldComponent(["type_test_certificate_id"]);
  } else if (mode === "e") {
    // checkOldComponent(["type_test_certificate_id"]);
  } else if (mode === "f") {
    checkManufacturer(mandatoryFields.f.manufacturers);
    checkModel(mandatoryFields.f.models);
    checkNewComponent(mandatoryFields.f.components);
  }
  return failed;
};

export default failedUpdateValidation;
