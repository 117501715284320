import { createSlice } from "@reduxjs/toolkit";

export const dasSlice = createSlice({
  name: "das",
  initialState: {
    value: null,
  },
  reducers: {
    saveDas: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveDas } = dasSlice.actions;

export default dasSlice.reducer;
