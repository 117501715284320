import React from "react";
import parseAppendixJson from "./appendix_format_parser.js";
import { alphabet, romanize } from "../romanize";
import { Text, View } from "@react-pdf/renderer";
function renderIndexedContent(contents, format, models, type) {
  const DivComponent = format === "plainHtml" ? "div" : View;
  const TextComponent = format === "plainHtml" ? "p" : Text;
  return contents.map((section, i) => {
    return section.map((text, j) => {
      // example :  1. This approval applies to escalator of the model **__{model_name}__** only.   j === 0 here
      //                a. The contractor installing any escalator system    j > 0 or k === 0 if it is array here
      //                    i. copy of this approval letter and the applicable type examination k > 0 here
      if (Array.isArray(text)) {
        return text.map((subText, k) => {
          return (
            <DivComponent
              {...(format === "pdf" && { minPresenceAhead: 10 })}
              style={{
                display: "flex",
                flexDirection: "row",
                padding: k > 0 ? "0px 0px 0px 44px" : "0px 0px 0px 22px",
              }}
              key={k + "overviewsSubSubSection"}
            >
              <TextComponent
                style={{
                  minWidth: "20px",
                }}
              >
                {type === "scRemarks" ? (
                  "*"
                ) : (
                  <>
                    {k === 0
                      ? `${alphabet[j - 1].toLowerCase()}`
                      : `${romanize(k).toLowerCase()}`}
                    .
                  </>
                )}
              </TextComponent>
              <TextComponent
                {...(format === "pdf" && { orphans: 1 })}
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {parseAppendixJson(subText, models, format)}
              </TextComponent>
            </DivComponent>
          );
        });
      }
      return (
        <DivComponent
          {...(format === "pdf" && { minPresenceAhead: 10 })}
          // j === 0 is the first line, we give y padding
          //j > 0 is the items with alphabet index, we give x padding
          style={{
            display: "flex",
            flexDirection: "row",
            ...(j > 0 && { padding: "0px 0px 0px 22px" }),
          }}
          key={j + "overviewsSubsection"}
        >
          <TextComponent
            style={{
              ...(j === 0 && { padding: "5px 0px" }),
              minWidth: "20px",
            }}
          >
            {type === "scRemarks" ? (
              "*"
            ) : (
              <>{j === 0 ? `${i + 1}` : `${alphabet[j - 1].toLowerCase()}`}.</>
            )}
          </TextComponent>
          <TextComponent
            {...(format === "pdf" && { orphans: 1 })}
            style={{
              ...(j === 0 && { padding: "5px 0px" }),
              whiteSpace: "pre-wrap",
            }}
          >
            {parseAppendixJson(text, models, format)}
          </TextComponent>
        </DivComponent>
      );
    });
  });
}

function renderContent(contents, format, models) {
  const TextComponent = format === "plainHtml" ? "p" : Text;
  return contents.map((section, i) => {
    return section.map((text, j) => {
      if (Array.isArray(text)) {
        return text.map((subText, k) => {
          return (
            <TextComponent
              style={{
                //last line has bottom padding
                paddingBottom:
                  j === section.length - 1 && k === text.length - 1
                    ? "15px"
                    : "0px",
                whiteSpace: "pre-wrap",
              }}
              key={k}
            >
              {parseAppendixJson(subText, models, format)}
            </TextComponent>
          );
        });
      }
      return (
        <TextComponent
          style={{
            paddingBottom: j === section.length - 1 ? "15px" : "0px",
            whiteSpace: "pre-wrap",
          }}
          key={j}
        >
          {parseAppendixJson(text, models, format)}
        </TextComponent>
      );
    });
  });
}

export { renderContent, renderIndexedContent };
