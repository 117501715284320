import englishMessages from "ra-language-english";

const en = {
  ...englishMessages,
  variable: {
    approval_date: "All Approval Date",
    manufacturer_code: "Manufacturer Code",
    provided_name: "Manufacturer Name",
    iso_certificate_id: "Certification of Quality Assurance Scheme",
    history: "History Of Manufacturer Document",
    organization_man: "Organization of Manufacturer Document",
    size_location_cap:
      "Size of Manufacturing Plants, Location and Capabilities Document",
    product_range_yearly_production:
      "Product Range and Yearly Production Document",
    other_man_documents: "Other Relevant Document",
    compatibility_cert_id: "Compatibility Declaration",
    calculation: "Calculation",
    type_test_certificate_id: "Type Examination Certificate and Test Report",
    full_quality_certificate_id: "Full Quality Assurance Certificate",
    compliance_confirmation: "Manufacturer Confirmation Letter",
    install_operation_maint: "Installation, Operation and Maintenance Manual",
    maintenance_schedule: "Maintenance Schedule",
    arr_training_tech_support:
      "Arrangement of Training and Technical Support from Manufacturer",
    EMC_compliance: "EMC Compliance Declaration",
    rated_load: "Maximum Rated Load (kg)",
    rated_speed: "Maximum Rated Speed (m/s)",
    maximum_travel_of_height: "Maximum Travel Height (m)",
    rope_factor: "Roping",
    balancing_factor: "Balancing Factor (%)",
    model_code: "Model Code",
    model_id: "Model",
    provided_model_name: "Model Name",
    maximum_rise: "Maximum Rise (m)",
    tec_max_rise: "Maximum Rise (m)",
    angle_of_inclination: "Angle Of Inclination (°)",
    other_man_model_documents: "Other Relevant Document",
    other_comp_documents: "Other Relevant Document",
    manufacturer_id: "Manufacturer",
    component_code: "Component Code",
    provided_comp_name: "Component Name",
    type: "Type",
    iso_manufacturer_name: "Manufacturer name (ISO Certificate)",
    iso_manufacturer_address: "Manufacturer Address",
    iso_standard: "Standard",
    iso_cert_number: "Certificate Number",
    iso_scope: "Scope",
    iso_issue_date: "Issue Date",
    iso_expiry_date: "Expiry Date",
    manufacturer: "Manufacturer",
    address: "Address",
    short_name: "Short Name",
    alias_name: "Alias Name",
    scope: "Scope of manufacturer",
    Both: "Both Lift/Escalator System Model and Safety Components",
    SafetyComponent: "Safety Component",
    Model: "Lift/Escalator System Model",
    tec_total_mass: "Total mass, P+Q (kg)",
    tec_impact_speed: "Max. Impact Speed, m/s",
    tec_impact_speed_max: "Max. Impact Speed, m/s",
    tec_door_type: "Type of Door",
    tec_rope_factor: "Roping",
    tec_balancing_factor: "Balancing %",
    tec_circuit_description: "Description",
    tec_contact_rating: "Contact Ratings",
    tec_tripping_speed: "Tripping Speed, m/s",
    tec_certificate_number: "Type Exam. Certificate No.",
    tec_certificate_body: "Certificate Body",
    tec_certification_body: "Certificate Body",
    tec_manufacturer_name: "Manufacturer Name",
    tec_manufacturer_address: "Manufacturer Address",
    tec_cert_type: "Type of Certificate",
    tec_standard: "Standard",
    tec_model_no: "Model No.",
    tec_rated_speed_max: "Rated Speed m/s (maximum)",
    tec_rated_speed_min: "Rated Speed m/s (minimum)",
    tec_rated_load_max: "Rated Load kg (maximum)",
    tec_rated_load_min: "Rated Load kg (minimum)",
    tec_lift_type: "Type of Lift",
    tec_travel_height_min: "Travel Height (minimum), m",
    tec_travel_height_max: "Travel Height (maximum), m",
    tec_total_mass_min: "Total Mass (minimum)",
    tec_total_mass_max: "Total Mass (maximum)",
    tec_roping_factor: "Roping Factor",
    tec_report_type: "Report type",
    tec_report_no: "Report No.",
    tec_issue_date: "Issue Date",
    tec_first_issue_date: "First Issue Date",
    tec_expiry_date: "Expiry Date",
    tec_test_item: "Test Item",
    tec_test_model_no: "Test Item Model No.",
    tec_buffer: "Buffer",
    tec_overspeed_governor: "Overspeed Governor",
    tec_safety_gear: "Safety Gear",
    tec_landing_door_locking_device: "Landing Door Locking Device",
    tec_car_door_locking_device: "Car Door Locking Device",
    tec_acop: "Ascending Car Overspeed Protection Means",
    tec_ucmp: "Unintended Car Movement Protection Means",
    tec_circuit_electronic: "Safety Circuit Containing Electronic Components",
    tec_description: "Description of the Safety Circuit",
    tec_pallet: "Pallet",
    tec_step: "Step",
    tec_applicable_environment: "Applicable Environment",
    tec_material: "Material",
    tec_width: "Width",
    tec_depth: "Depth",
    tec_inclination_angle: "Inclination Angle",
    traction_machine: "Traction Machine",
    controller: "Controller",
    tec_tripping_speed_max: "Tripping speed m/s (maximum)",
    tec_max_stroke: "Stroke",
    tec_rope_diameter: "Diameter of driving rope",
    tec_guide_rail_running_surface_manufacture:
      "Manufacture of the guide rail running surface",
    tec_guide_rail_condition: "Condition of guide rail running surface",
    tec_braking_torque_max: "Braking moment",
    cert_number: "Certificate Number",
    fulfil_standard: "Fulfil Standard",
    sc_type: "Type of component",
    model_type: "Lift/Escalator Model Type",
  },
  model: {
    lift: "Lift",
    escalator: "Escalator",
    SafetyComponent: "Safety Component",
    InclinedLift: "Electric Inclined Lift",
    ElectricTractionLiftMachineRoom: "Electric Traction Lift (Machine Room)",
    ElectricTractionLiftRoomless: "Electric Traction Lift (Machine Roomless)",
    HydraulicLift: "Hydraulic Lift",
    StairLift: "Stairlift",
    ServiceLift: "Service Lift",
    MVPS: "Mechanized Vehicle Parking System (MVPS)",
    ElectricalVerticalLiftingPlatform: "Vertical Lifting Platform",
    Escalator: "Escalator",
    PassengerConveyor: "Passenger Conveyor",
    Buffer: "Buffer",
    OverspeedGovernor: "Overspeed Governor",
    LandingDoorLockingDevice: "Landing door locking device",
    CarDoorLockingDevice: "Car door locking device",
    SafetyGear: "Safety Gear",
    ACOP: "Ascending car overspeed protection means",
    UCMP: "Unintended car movement protection means",
    SafetyCircuitElectricalComponent:
      "Safety circuit containing electrical components",
    RaptureValve: "Rapture Valve",
    OneWayValve: "One Way Valve",
    Step: "Step",
    Pallet: "Pallet",
    TractionMachine: "Traction Machine",
    Controller: "Controller",
    Others: "Others",
  },
  compdisplay: {
    add_optional: "Add Optional Types",
    amount: "Amount",
    no_model: "No Models Found, Please Add A Model",
    add_comp: "Add Component",
    add_lift: "Add Lift Components",
    add_escalator: "Add Escalator Components",
    confirm: "Confirm",
    confirmed: "Confirmed",
    placeholder: "Add component by typing here",
    add: "Add",
    related_app: "Related Applications",
    search_comp:
      "Search component code / component name / model name / manufacturer / type / RC / cert data",
    registered_contractor: "Registered Contractor",
    rc_company_name: "RC Company Name",
    rc_number: "RC Number",
  },
  manudisplay: {
    placeholder: "Add new manufacturer by typing here",
    placeholder2: "Input Manufacturer Name Here",
    add_manu: "Add Manufacturer",
    manufacturer_files: "Manufacturer Files",
    add_manu_file: "Add Manufacturer-Specific Files (Minimum of 1)",
    nav: "Use Manufacturer From Existing Project",
    search_manu: "Search manufacturer code / manufacturer name / scope ",
  },
  modeldisplay: {
    placeholder: "Add model by typing here",
    placeholder2: "Input Model Name Here",
    add_model: "Add Model",
    rated_speed: "Rated Speed",
    rated_load: "Rated Load",
    preview: "Preview",
    search_model:
      "Search model code / model name / manufacturer / type / RC / cert data",
  },
  application: {
    checkbox:
      "Please confirm the information is correct by ticking the checkbox",
    error:
      "Error creating record, ensure the criteria check has all been passed!",
    summary: "Application Summary",
    confirmation: "Confirmation",
    type: "Type of system",
    model_manufacturer: "Model Manufacturers",
    sc_manufacturer: "Safety Component Manufacturers",
    models: "Models and Components",
    model_details: "Model Details",
    declaration:
      "I declare that all information in this application is correct, complete and true. I also understand that a person who knowingly and wilfully makes a statement or gives information which he knows to be false or does not believe to be true shall be guilty of an offence under law. ",
    send: "Send (Submit to EMSD)",
    validate: "Process to AI validation",
    select_rc: "Choose RC",
    select_app: "Choose type of application",
    new: "Brand new application",
    update: "Update application",
    select_le_type: "Choose type of model",
    select_machine: "Choose type of system",
    add_sc: "Add Safety Component",
    add_sc_desc:
      "Add stand-alone safety component or safety component attached to lift/escalator model",
    manu_name_change: "Manufacturer Name Change",
    manu_name_change_desc:
      "Change the name of manufacturers of a lift/escalator model",
    update_cert: "Update Certificate",
    update_cert_desc:
      "Update the certificate of a lift/escalator model or safety component",
    range_ext: "Range Extension",
    range_ext_desc:
      " Range extension of a lift/escalator model or safety component",
    select_sc_type: "Choose type of safety component",
    le_update_cert: "Update lift/escalator model certificate",
    le_range_ext: "Lift/escalator model range extension",
    sc_update_cert: "Update safety component certificate",
    sc_range_ext: "Safety component range extension",
    add_new_sc: "Add new safety component",
    add_loose_sc: "Add loose safety component",
    standAloneSc: "Add new standalone safety component",
    leModelSc: "Safety component attached to lift/escalator model",
    certChanged: "Certificates have been changed, please re-validate",
    fault: "Mark as AI's fault",
    unmark: "Unmark AI's fault",
    comment: "Please add comment here (required)",
    edit: "Edit Application",
  },
  validation: {
    tec_manufacturer_name:
      "The manufacturer name does not match with the name on type test certificate. Please counter-check the manufacturer name with the certificate or upload the certificate before proceeding with the application.",
    tec_cert_type:
      "The type of certificate uploaded is invalid. Please upload other type of certificate again (e.g. Type Examination Certificate, Certificate of Conformity etc.)",
    tec_expiry_date:
      "The type test certificate uploaded is expired. Please upload the latest valid certificate to continue with the application process.",
    provided_model_name_tec_model_no:
      "Model names in your application does not match in your submission. Please verify the model name and re-enter if neccessary or upload relevant certificate again.",
    provided_comp_name_tec_model_no:
      "Safety component model names in your application does not match in your submission. Please verify the safety component model name and re-enter if neccessary or upload relevant certificate again.",
    iso_manufacturer_name:
      "The manufacturer name does not match with the name on ISO certificate. Please counter-check the manufacturer name with the certificate or upload the certificate before proceeding with the application.",
    iso_expiry_date:
      "The ISO certificate is expired. Please upload the latest valid certificate to continue with the application process.",
    validated: "Application is validated, please continue by clicking here",
    validating: "Validating, please wait for the AI",
    AIerror:
      "Some error occurred and AI was unable to get certificate data, please validate again",
    Clarifying: "Clarifying is required, please continue by clicking here",
    "Pending AI": "Pending AI, please wait for the AI",
  },
  application_info: {
    created_by: "Created By",
    rc: "RC",
    application_process: "Application Process",
    application_type: "Application Type",
    application_no: "Application No.",
    temp_application_number: "Temporary Application Number",
    update_of: "Update Of",
    application_date: "Application Date",
    last_updated: "Last Updated",
    role_assigned: "Role Assigned",
    pledge_time: "Pledge Time",
    justification: "Justification",
    approval_date: "Approval Date",
    update_type: "Type of Application",
    status: "Status",
    app_type_type: "Type of Machine",
    processing: "Processing",
    models: "Models",
    owner_id: "User",
    current_role_name: "Role Assigned",
    Pending: "Pending",
    "Pending E": "Pending Engineer",
    "Pending SE": "Pending Senior Engineer",
    "Pending CE": "Pending Chief Engineer",
    Clarifying: "Clarifying",
    ReturningClarifying: "Returning Clarifying",
    Withdrawn: "Withdrawn",
    Approved: "Approved",
    search_app:
      "Search application no. / type of machine / models / status / RC",
  },
  button: {
    documents: "Documents",
    add_document: "Add Document",
    file_upload: "Upload File",
    file_description: "Please type a description first",
    upload: "Upload",
    download: "Download",
    edit: "Edit",
    send: "Send",
    done: "Done",
    action: "Action",
    merge: "Merge",
    pause: "Pause",
    start: "Start",
    edit_name: "Edit Name",
    drag: "Drag and drop a file here, or click to select files",
    drop: "Drop the files here ...",
    no_file: "No file found",
    back: "Back",
    next: "Next",
    enter: "Enter",
    clear: "Clear",
    search: "Search",
    add_filter: "Add Filter",
    export: "Export",
    notifications: "Notifications",
  },
  title: {
    user_profile: "User Profile",
    tapas_rc: "TAPAS Registered Contractor Portal",
    tapas_emsd: "TAPAS EMSD Portal",
    version: "Version",
    app_validating: "Application (Validating)",
    applications: "- Applications",
    manufacturers: "- Manufacturers",
    components: "- Safety Components",
    update: "- Update Application",
    new: "- New Application",
  },
  menu: {
    new: "New Application",
    menu: "Menu",
    overview: "Overview",
    applications: "Applications",
    ai_validation: "AI Validation",
    restricted_urls: "Restricted URLs",
    manufacturers: "Manufacturers",
    models: "Lift/Escalator Models",
    components: "Safety Components",
    account: "Account Management",
    revoke: "Revoke",
    settings: "Settings",
    profile: "Profile",
    logout: "Logout",
    statistics: "Statistics Dashboard",
    await: "Awaiting Approval",
    rcs: "RC",
    teams: "Teams",
    users: "Users/Assignment",
    report: "Report",
    logs: "Logs",
    language: "Switch Language",
  },
  phrase: {
    add_new: "Add new",
    from: "From",
    to: "To",
    loading: "Loading",
    no_record: "No Record Found",
    retry: "Retry",
    optional: "Optional",
    validating: "Validating",
    validated: "Validated",
    AIerror: "AI error",
    required: "Required",
  },
  notify: {
    success: "Successfully done",
    upload: "File uploaded successfully",
    delete: "File deleted successfully",
  },
  error: {
    fail: "Some error occurred, please try again later",
    upload: "Error uploading file",
    save: "Error saving file",
    size: "Error: File size should be less than 100MB",
  },
  confirm: {
    yes: "Yes",
    no: "No",
    delete_manu: "Confirm to delete manufacturer",
    delete_manu_file: "Confirm to delete manufacturer file",
    delete_model: "Confirm to delete model",
    delete_file: "Confirm to delete file",
    delete_draft: "Confirm to delete draft",
    confirm: "Are you sure to do this.",
    merge: "Confirm to merge",
    confirm_merge: "The manufacturer code will be changed, proceed?",
  },
  team: {
    role_name: "Role Name",
    account: "Account owner",
    special_application: "Special Application",
    double_role: "Double Role",
    temp_role: "Temporary Role",
    permanent_role: "Permanent Role",
  },
  comm_log: {
    title: "Communication Log",
    message: "Message from",
    new: "New Message",
    edit: "Edit message",
    delete: "Delete currently displayed message",
  },
  rules: {
    similar_manufacturer: "Similar Manufacturers Found",
    parameter: "Parameters Check and Amendment",
    short_name_edit: "Manufacturer Short Name Edit",
    ai_checking: "AI Checking",
    rc_ai_checking: "TAPAS RC AI Checking",
    industry_range: "Industry Range",
    ai_result: "AI Result",
    fault: "AI's fault",
    user_input: "User Input",
    status: "Status",
    no_cert: "Cert number not found",
    failed: "Failed",
    passed: "Passed",
    data_checked: "Data checked",
    comment: "Comment",
  },
  appendix: {
    doc_list: "Document List",
    add_subsection: "Add sub-section",
    subject: "Subject",
    body: "Body",
    overviews: "Overviews",
    conditions: "Conditions",
    remarks: "Remarks",
    remarks2: "Remarks (part C)",
    table_b: "Table B",
    table_c: "Table C",
    line_break: "Line Break",
    comply_standard: "Comply Standard",
    previous_approved: "Previously Approved",
    add_field: "Add Field",
    field: "Field",
    value: "Value",
    add: "Add",
    show_position: "Show Position",
    invalid_date: "Invalid date format",
    refer: "Refer",
    previous_cert: "Previous Certificate",
    hide: "Hide",
    unhide: "Unhide",
    bm_length: "Bottom margin length",
  },
  login: {
    title: "Type Approval Processing and Authentication System",
    login: "Sign in",
    signup: "Activation",
    forgot_password_request: "Forgot Password",
    forgot_password_confirm: "Reset Password",
    reset_password: "Reset Password",
    username: "Username",
    email: "Email Address",
    password: "Password",
    confirmPassword: "Confirm Password",
    invite_code: "Invite Code",
    confirm_code: "Confirm Code",
    resend: "Resend Code",
    send_code: "Send Confirm Code",
    wrong_password: "Password not match",
    request_success:
      "Successfully send the confirmation code, please check your email",
    confirm_success:
      "Successfully reset the password, please login with your new password",
  },
  overview: {
    hello: "Hello",
    welcome: "Welcome to TAPAS",
    inProgress: "AI Validation In Progress",
    error: "Error found during validation",
    completed: "AI Validation Completed",
    application: "Application(s)",
    models: "Expiry - Models",
    sc: "Expiry - Safety Components",
    no_expired_model: "No expired/soon to be expired models found",
    no_expired_sc: "No expired/soon to be expired safety components found",
  },
  create: {
    "Select operation": "Select operation",
    "Add Manufacturer": "Add Manufacturer",
    "Add Models": "Add Models",
    "Add Safety Components": "Add Safety Components",
    "Upload Additional Documents": "Upload Additional Documents",
    "Complete Process": "Complete Process",
    optional: "Optional",
    unfinished: "Unfinished",
    comp_confirm: "Please confirm all your components",
    missing_mandatory: "Missing mandatory inputs",
    incomplete: "This step is incomplete, proceed?",
    success_send: "Application successfully sent",
    success_validate:
      "Your application is saved and is validating by AI, please check application (validating) for more information",
    autosave: "Auto Saved Draft",
    load_autosave: "Load Auto Saved Draft",
    draftlist: "Saved Draft",
    applicationCreate: "New Application",
    add_manu: "Add Manufacturer(s)",
    add_model: "Add Model(s)",
    add_comp: "Add Safety Component(s)",
    add_doc: "Upload Additional Documents",
    summary: "Application Summary",
    download_summary: "Download summary",
    loading_doc: "Loading document...",
  },
  edit: {
    update_manu: "Update Manufacturers",
    update_model: "Update Models",
    update_comp: "Update Components",
    additional_doc: "Additional Documents",
    comments: "Comments",
    confirmation: "Confirmation",
    edit_manu: "Edit Manufacturer(s)",
    edit_model: "Edit Model(s)",
    edit_comp: "Edit Safety Component(s)",
    edit_doc: "Edit Additional Documents",
    add_comment: "Add Comments",
    missing_mandatory: "Please fill in all mandatory fields",
    manu_changes: "Manufacturer Changes",
    model_changes: "System Model Changes",
    comp_changes: "Component Changes",
  },
  draft: {
    success: "Successfully saved to draft",
    save: "Save",
    save_as: "Save As",
    validated: "Validated application",
    current_draft: "Current Draft",
    none: "none",
    name: "Name your draft",
  },
  phase2: {
    ru: {
      show: "Show",
      noRuFound: "No restricted URL found",
      createRu: "+ Create a Restricted URL",
      enhancedTableToolbar: {
        numSelected: "selected",
        filterByLabel: "Filter by",
        optionFields: {
          Remark: "Remark",
          "Creation Date": "Creation Date",
          "Client User": "Client User",
        },
        compListFilterBoxPlaceholder:
          "Search Component Code, Name, Type, Model Name or Manufacturer Name",
        modelListTablePlaceholder:
          "Search Model Code, Model Name, Type or Manufacturer Name",
        clear: "Clear",
        search: "Search",
      },
      enhancedTableHead: {
        "Client User": "Client User",
        Remark: "Remark",
        "Creation Date": "Creation Date",
        "Expiry Date": "Expiry Date",
        "Model Code": "Model Code",
        "Model Name": "Model Name",
        Type: "Type",
        "Manufacturer Name": "Manufacturer Name",
        Documents: "Documents",
        "Manufacturer Code": "Manufacturer Code",
        "Component Code": "Component Code",
        "Component Name": "Component Name",
      },
      tableBtn: {
        View: "View",
        Copy: "Copy",
        Duplicate: "Duplicate",
        Extend: "Extend",
      },
    },
    createRu: {
      summary: "Summary",
      confirm: "Confirm",
      discardAllModels:
        "This will discard all the changes you have made for models.",
      discardAllComps:
        "This will discard all the changes you have made for components.",
      backToModelPerspective: "Back to model perspective ?",
      backToComponentPerspective: "Back to component perspective ?",
      selectModels: "Select Models",
      selectComponents: "Select Components",
      backToRuListing: "Back to restricted URL listing",
      gotoFinalStep: "Go to final step",
      goBack: "Go back ( Changes will be discard )",
      gotoSummary: "Go to summary",
      reviewSelectedOptions: "Review the selected options",
      nothingToReview: "Nothing to review",
      gotoPerspectiveOptions: `Go to choose perspective options ( will clear all options )`,
      gotoUrlTable: `Go to URL Table ( will clear all options )`,
      perspectiveOptions: "Select Perspective",
      model: "model",
      Model: "Model",
      component: "component",
      Component: "Component",
      Components: "Components",
      manufacturer: "manufacturer",
      next: "Next",
      back: "Back",
      clientUser: "Client User",
      password: "Password",
      remark: "Remark",
      submit: "Submit",
      selectAllFoundItemsInAllPages: "Select all found items in all pages",
      deselect: "Deselect",
      selectAtLeastOne: "Select at least one",
      clickToSelectComponents: "Click to select components (optional)",
      noComponent: "No component",
      noModel: "No model",
      noSelectedComponent: "No selected component",
      clientUserIsRequired: "Client User is required",
      tokenIsRequired: "Token is required",
      remarkIsRequired: "Remark is required",
    },
    ruShow: {
      title: "Restricted Url Info",
      Creator: "Creator",
      copy: "Copy",
      gotoManagement: "Go to manage Restricted Urls",
      token: "Token",
      url: "Url",
    },
  },
  dashboard: {
    keyMetrics: "Key Metrics",
    lastMonth: "Last Month",
    currentMonth: "Current Month",
    ytd: "YTD",
    approvedApplications: "Approved Applications",
    newLiftModels: "New Lift Models",
    newEscalatorModels: "New Escalator Models",
    newSafetyComponents: "New Safety Components",
    averageHandlingTime: "Average Handling Time",
    applicationsBeingProcessed: "Applications Being Processed",
    applicationsPending: "Applications Pending",
    differentApplications: "Different Applications",
    approvedLiftModels: "Approved Lift Models",
    approvedEscalatorModels: "Approved Escalator Models",
    approvedSafetyComponents: "Approved Safety Components",
    activeRcs: "Active RCs",
    totalManufacturers: "Total Manufacturers",
    statisticsIndividualRcs: "Statistics Individual RCs",
    all: "All",
    averageProcessingTime: "Average Processing Time",
    applicationsSubmitted: "Applications Submitted",
    last6Months: "Last 6 Months",
    last3Months: "Last 3 Months",
    lastMonths: "Last Months",
    thisMonth: "This Month",
    urlVisited: "URL Visited",
    urlCreated: "URL Created",
    statisticsPublicEnquires: "Statistics: Public Enquires",
    top5VisitsFor: "Top 5 Visits For",
    period: "Period",
    channel: "Channel",
    selectHowManyMonthsAgo: "Select how many months ago",
    selectChannel: "Select Channel",
    selectEntityType: "Select Entity Type",
  },
  report: {
    modelsSearch: "Models Search",
    componentsSearch: "Components Search",
    applicationsSearch: "Applications Search",
    noData: "No Data",
  },
  position: {
    chiefEngineer: "Chief Engineer",
    seniorEngineer: "Senior Engineer",
    engineer: "Engineer",
  },
  description: {
    name: "Name",
    description: "Description",
    createdAt: "Created At",
    isStandalone: "Is Standalone",
    type: "Type",
    assigned: "Assigned",
    rank: "Rank",
  },
};

export default en;
