import {  
    Typography,
    Box,
    Container,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Paper,
    Button,
    Menu,
    MenuItem
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { fixInvalidDates } from '../../../../admin/utils/misc/fixInvalidDates'
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import emsdLogo from "../../../../admin/styles/logo/emsd_appendix.jpg";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";

// hooks
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

// customized hooks
import { getDaForQrPage } from "../../../../admin/useHooks/useDeAppendix";

import {v4 as uuid} from "uuid"


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));


const columnNameManu=["Type Exam. Certificate", "Manufacturer Code", "EMSD Approval Ref."]
const colNameSc = ["Manufacturers", "Models"]
const colNameScForStandalone = ["Manufacturers", "Models"]

  // mapping component types
  const component_types = [
    { ACOP: "Ascending car overspeed protection means"},
    { Buffer: "Buffer" },
    { CarDoorLockingDevice: "Car door locking device" },
    { LandingDoorLockingDevice: "Landing door locking device" },
    { OverspeedGovernor: "Overspeed Governor" },
    { Pallet: "Pallet" },
    { SafetyCircuitElectricalComponent: "Safety circuit containing electrical components" },
    { SafetyGear: "Safety Gear" },
    { Step: "Step" },
    { UCMP: "Unintended car movement protection means" },
    { RaptureValve: "Rapture Valve" },
    { TractionMachine: "Traction Machine" },
    { Controller: "Controller" },
    { Others: "Others" }
  ];
  


export default function PageShowRecord_Copy(){
    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || i18n.language);
    const { t : itranslate } = useTranslation();

    
    // check language
    // window.addEventListener('storage', (e) => {
    //   if (e.key === 'selectedLanguage') {
    //     i18n.changeLanguage(e.newValue);
    //     setLanguage(e.newValue);
    //   }
    // });


    const handleLanguageChange = (language) => {
      i18n.changeLanguage(language);
    };


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    
    const handleClose = (language) => {
      if (language) handleLanguageChange(language);
      setAnchorEl(null);
    };


    
    // prevent user from right clicking and download the page
    const handleContextMenu = (e) => e.preventDefault();
    useEffect(() => {
            document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu);
        };
    }, []);
    
    let location = useLocation();

    let {daNoHash} = location.state

    const [application_number, setApplication_number] = useState("")
    const [approval_date, setApproval_date] = useState("")
    const [modelManufacturers, setModelManufacturers] = useState([])
    
    const [models, setModels] = useState([])
    // const [model_certs, setModelCerts] = useState([])
    const [rc_info, setRc_info] = useState({})
    const [rowsScType, setRowsScType] = useState([])
    // console.log("🚀 ~ rowsScType:", rowsScType)
    const [deAppendix, setDeAppendix] = useState({})
    // console.log("🚀 ~ deAppendix:", deAppendix)

    const [appType, setAppType] = useState(null)
    const [table, settable] = useState([])
    // console.log("🚀 ~ table:", table)

    

    const effectCalled = useRef(false)

    useEffect( () => {
        // ? with this ref, it only calls once.
        if(effectCalled.current) return; 
        effectCalled.current = true;

        // ? the data is from /verifyRecord/
        const handleMounting =  async(daNoHash) => {
            await getDaForQrPage(daNoHash).then((res) => {
                setApplication_number(res?.altered?.application_number)
                setApproval_date(res?.altered?.approval_date)
                setModelManufacturers(res?.altered?.manufacturers)
                setModels(res?.altered?.models)
                // setModelCerts(res?.altered?.model_certs)
                setRc_info(res?.altered?.rc_info)
                setRowsScType(res?.altered?.rowsScType)
                setDeAppendix(res?.altered?.deAppendix)
                setAppType(res?.altered?.deAppendix?.app_type)
                settable(res?.altered?.deAppendix?.appendix?.table)
            }).catch(err => {
                console.log(err);
            })
        }

        handleMounting(daNoHash)
    }, [daNoHash])

    
    if(!daNoHash) return <div></div>
    return (
            <Container component="main" maxWidth="lg" sx={{ minHeight: "90vh", mb: "2em" }}>
                {/* header */}
                <Box
                    sx={{
                        marginTop:8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    {/* logo */}
                    <img src={emsdLogo} style={{ width: "200px" }} alt="EMSD logo" />
                    {/* title */}
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={{ fontFamily: "Frutiger_bold" }}
                    >
                        {itranslate("Type Approval Record")}
                    </Typography>
                </Box>

                <Divider variant="middle" sx={{ marginTop:3 }}/>


                {/* { process.env.REACT_APP_DEV &&      */}
                { process.env.REACT_APP_UAT &&     
                <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 4 }}>
                    <Button onClick={handleClick}>
                        {itranslate("Select Language")}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => handleClose(null)}
                    >
                        <MenuItem onClick={() => handleClose("en")}>{itranslate("English")}</MenuItem>
                        <MenuItem onClick={() => handleClose("ch")}>{itranslate("Chinese")}</MenuItem>
                    </Menu>
                </Box>
                }
      
                
                {/* BODY */}
                {/* application, approval date, rc */}
                <Box
                    sx={{
                        marginTop:3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{border: 'none'}}>
                        <Grid item xs={12}>
                            <Item>{itranslate("Application Number")}: <strong>{application_number}</strong></Item>
                        </Grid>
                        <Grid item xs={12}>
                            <Item>{itranslate("Approval date")}: <strong>{approval_date.split('T')[0]}</strong></Item>
                        </Grid>
                        <Grid item xs={12}>
                            <Item>{itranslate("Registered Contractor")}: <strong>{rc_info?.rc_company_name } {!!rc_info?.rc_number_lift && (' | ' + rc_info?.rc_number_lift)} {!!rc_info?.rc_number_escalator && (' | ' + rc_info?.rc_number_escalator)}</strong></Item>
                        </Grid>
                    </Grid>
                </Box>

                <Divider variant="middle" sx={{ marginTop:3 }}/>

                {/* Model */}
                {
                    models.length > 0 && models.map(((model,i) =>  
                    <div key={uuid()}>
                        {/* Lift model and attribute */}
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} 
                            sx={{
                                border: 'none', 
                                marginTop:3,
                            }}>
                            <Grid item xs={12}>
                                <Item><strong>{itranslate("Lift Model")}: </strong>{model.provided_model_name}</Item>
                            </Grid>
                            <Grid item xs={12}>
                                {/* split the type name and replace with space */}
                                <Item><strong>{itranslate("Type")}:</strong>{model.type.trim().split(' ').join('').split(/(?=[A-Z])/).join(' ')}</Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Item>
                                    <strong>Attribute: </strong> 
                                    {model.rated_speed && (
                                        <Item>{itranslate("Rated Speed")}:{model.rated_speed}</Item>
                                    )}

                                    {/* L type */}
                                    {model.rated_load && (
                                        <Item>{itranslate("Rated Load")}:{model.rated_load}</Item>
                                    )}
                                    {model.maximum_travel_of_height && (
                                        <Item>{itranslate("Max. Travel Of Height")}: {model.maximum_travel_of_height}</Item>
                                    )}
                                    {model.rope_factor && (
                                        <Item>{itranslate("Roping")}: {model.rope_factor}</Item>
                                    )}
                                    {model.balancing_factor && (
                                        <Item>{itranslate("Balancing Factor")}: {model.balancing_factor}</Item>
                                    )}

                                    {/* E type */}
                                    {model.maximum_rise && (
                                        <Item>{itranslate("Max. Rise")}: {model.maximum_rise}</Item>
                                    )}
                                    {model.angle_of_inclination && (
                                        <Item>{itranslate("Angle Of Inclination")}: {model.angle_of_inclination}</Item>
                                    )}

                                    {model.controller && (
                                        <Item>{itranslate("Controller")}: {model.controller}</Item>
                                    )}
                                    {model.traction_drive_system && (
                                        <Item>{itranslate("Traction Machine")}: {model.traction_drive_system}</Item>
                                    )}

                                    {model.max_inclination && (
                                        <Item>{itranslate("max_inclination")}: {model.max_inclination}</Item>
                                    )}

                                    {table?.length > 0 && table.map(item => {
                                        if (!deAppendix?.appendix?.hideTableProps.includes(item.field)) {
                                            return <Item key={uuid()}>
                                                {item.field}: {item.value}
                                            </Item>
                                        } else {
                                            return null;
                                        }
                                    })}

                                </Item>
                            </Grid>

                        </Grid>

                        {/* manufacturer */}
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop:3 }}>
                                <Grid item xs={12}>
                                    <Item><strong>{itranslate("Manufacturer")} </strong></Item>
                                    <Item>
                                     
                                        {deAppendix?.appendix?.manufacturer_code?.length > 0 ? (
                                            modelManufacturers?.map(manf => (
                                                <Item key={uuid()}><strong>{`${manf?.name}. `}</strong>{manf?.address}</Item>
                                            ))
                                        ) : (
                                            model?.manufacturer_files?.length > 0 && model?.manufacturer_files?.map(manu => {
                                                    let code = manu?.unique_model_code
                                                    let manuName = manu?.manufacturer_info?.provided_name ? manu?.manufacturer_info?.provided_name : "N/A"
                                                    let addr = null
                                                    if(manu?.manufacturer_info?.iso_certificate_id?.cert_type_metadata){
                                                        addr = manu?.manufacturer_info?.iso_certificate_id?.cert_type_metadata?.iso_manufacturer_address || "N/A"
                                                    } else {
                                                        addr = 'N/A'
                                                    }
                                                    return (
                                                        <Item key={uuid()}><strong>{`${code}. `}</strong>{`${manuName} - ${addr}`}</Item>
                                                    )

                                                }
                                            ) 
                                        )}
                                    </Item>
                                </Grid>
                        </Grid>

                        {/* model cert table */}
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                {/* col */}
                                <TableHead>
                                    <TableRow>
                                        {columnNameManu.map((colName, i) => (
                                            <TableCell key={uuid()}>{itranslate(colName)}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {/* cert */}
                                <TableBody>
                                {model.manufacturer_files.map((v) => v?.type_test_certificate_id[0] !== null && v?.type_test_certificate_id?.map((row, i) => {
                                    if(row == null) return null
                                    let certMeta = row?.cert_type_metadata;

                                    let approval_date = certMeta?.tec_issue_date || model.approval_date.split('T')[0];
                                    return (
                                        <TableRow
                                            key={uuid()}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">{certMeta?.tec_certificate_number}</TableCell>
                                            <TableCell align="left">{v?.unique_model_code}</TableCell>
                                            <TableCell align="left">[{`${application_number} issued at ${approval_date}`}]</TableCell>
                                        </TableRow>
                                    )
                                }))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    ))
                }

                <Divider variant="middle" sx={{ marginTop:3 }}/>

                {/* SC category */}
                {/* SC !== SafetyComponent */}
                {appType && appType !== "SafetyComponent" && rowsScType.map((record, i) => {
                  
                    return (
                    <Box key={uuid()} sx={{mt: 3}}>
                        {/* type */}
                        {
                            record.type && record?.rowsScCert?.length > 0 && (
                                <>
                                    {/* <strong>Safety Component - {component_types.find(entry => entry[record.type])[record.type]}</strong> */}

                                    <TableContainer key={uuid()} component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                                    <TableCell>
                                                        <strong className="text-xl">
                                                            {itranslate("Safety Component")} - {itranslate(component_types.find(entry => entry[record.type])[record.type])}
                                                        </strong>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </TableContainer>
  
                                    <TableContainer key={uuid()} component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            {/* col name */}
                                            <TableHead>
                                                <TableRow>
                                                    { 
                                                        deAppendix.app_type !== "SafetyComponent" && colNameSc.map((colName, i) => (
                                                            <TableCell key={uuid()}>{itranslate(colName)}</TableCell>
                                                        ))
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            {/* cert */}
                                            <TableBody>
                                                {
                                                    record?.rowsScCert?.map((row, i) => {
                                                        let manu_provided_name = row?.manufacturer_id?.provided_name || "";
                                                        let provided_comp_name = row?.provided_comp_name || "";

                                                        // for sc cert, even if multiple certs, we only show one cert for each sc, so we grab the latest created one
                                                        let populated = row?.type_test_certificate_id?.length > 0 && row?.type_test_certificate_id[0].createdAt

                                                        if(!populated) return null;
                                                        let cert = row?.type_test_certificate_id?.length > 1 ? row?.type_test_certificate_id?.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0] : row?.type_test_certificate_id[0];
                                                        let Type_Exam_Certificate = cert?.cert_type_metadata?.tec_type_exam || cert?.cert_type_metadata?.tec_certificate_number;

                                                        let Approval_Reference = deAppendix.app_type === "SafetyComponent" && cert?.cert_type_metadata?.['Approval Reference']?.match(/[Ƨ]/) ? cert?.cert_type_metadata?.['Approval Reference'] + row.approval_date.split("T")[0] : cert?.cert_type_metadata?.['Approval Reference'];
                                                        let tec_issue_date = cert?.cert_type_metadata?.tec_issue_date || Approval_Reference
                                                        
                                                        return (
                                                            <TableRow
                                                                key={uuid()}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="left" sx={{width: '80%'}}>{manu_provided_name}</TableCell>
                                                                <TableCell align="left" sx={{width: '20%'}}>{provided_comp_name}</TableCell>
                                                                {/* <TableCell align="left">{Type_Exam_Certificate.split(/(issued)|(Issued)/)[0]}</TableCell>
                                                                <TableCell align="left">{ (tec_issue_date && fixInvalidDates(tec_issue_date)) || "N/A" }</TableCell> */}

                                                            </TableRow>
                                                        )
                                                    })
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            )
                        }
                    </Box>
                    )
                })}



                {/* SC category */}
                {/* SC === SafetyComponent */}
                {appType && appType === "SafetyComponent" && rowsScType.map((record, i) => {
                 
                 // remove any duplicates by _id and return the most recent one
                 const uniqueRecords = Array.from(new Set(record.rowsScCert.map(obj => obj._id))).map(_id => {
                    const objectsWithId = record.rowsScCert.filter(obj => obj._id === _id);
                    const sortedByDate = objectsWithId.sort((a, b) => {
                      const dateA = a.approval_date ? new Date(a.approval_date) : new Date(0);
                      const dateB = b.approval_date ? new Date(b.approval_date) : new Date(0);
                      return dateB - dateA;
                    });
                    return sortedByDate[0];  // return the most recent one
                  });

                return (
                  <Box key={uuid()} sx={{mt: 3}}>
                      {
                          // record.type && record?.rowsScCert?.length > 0 && (
                          record.type && uniqueRecords?.length > 0 && (
                              <>
                                  {/* <strong>Safety Component - {component_types.find(entry => entry[record.type])[record.type]}</strong> */}
                                  <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                                        {/* {itranslate("Safety Component")} - {component_types.find(entry => entry[record.type])[record.type]} */}
                                                        {itranslate("Safety Component")} - 
                                                          {itranslate(component_types.find(entry => entry[record.type])[record.type])}

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </TableContainer>
  
                                  <TableContainer component={Paper}>
                                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                          <TableHead>
                                              <TableRow>
                                                  {
                                                      deAppendix.app_type === "SafetyComponent" && colNameScForStandalone.map((colName, i) => (
                                                          <TableCell key={uuid()}>{itranslate(colName)}</TableCell>
                                                      ))
                                                  }
                                              </TableRow>
                                          </TableHead>
                                   
  
                                             <TableBody>
                                              {
                                                  // record?.rowsScCert?.map((row, i) => {
                                                  uniqueRecords?.map((row, i) => {
                                                        // !take care of this
                                                    // console.log(row?.type_test_certificate_id);

                                                      let cert = row?.type_test_certificate_id?.length > 1 
                                                          ? row?.type_test_certificate_id?.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0] 
                                                          : row?.type_test_certificate_id[0];
                                  
  
                                                      let manu_provided_name = row?.manufacturer_id?.provided_name || cert?.cert_type_metadata?.tec_manufacturer_name;
  
                                                      let provided_comp_name = row?.provided_comp_name || "";
  
                                                      let Type_Exam_Certificate = cert?.cert_type_metadata?.tec_type_exam || cert?.cert_type_metadata?.tec_certificate_number;
                                                      // console.log("🚀 ~ record?.rowsScCert?.map ~ Type_Exam_Certificate:", Type_Exam_Certificate)
  
                                                      let Approval_Reference = deAppendix.app_type === "SafetyComponent" && cert?.cert_type_metadata?.['Approval Reference']?.match(/[Ƨ]/) ? cert?.cert_type_metadata?.['Approval Reference'] + row.approval_date.split("T")[0] : cert?.cert_type_metadata?.['Approval Reference'];
                                                      let tec_issue_date = cert?.cert_type_metadata?.tec_issue_date || Approval_Reference
                                                      // console.log("🚀 ~ record?.rowsScCert?.map ~ tec_issue_date:", tec_issue_date)
                                                      
                                                      return (
                                                          <TableRow
                                                              key={uuid()}
                                                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                          >
                                                              <TableCell align="left" sx={{width: '80%'}}>
                                                                  {manu_provided_name}
                                                              </TableCell>
                                                              <TableCell align="left" sx={{width: '20%'}}>{provided_comp_name}</TableCell>
                                                             {/* <TableCell align="left">
                                                                  {Type_Exam_Certificate && Type_Exam_Certificate.split(/(issued)|(Issued)|(Dated)|(dated)/)[0]}
                                                              </TableCell>
                                                              <TableCell align="left">
                                                                  {tec_issue_date && fixInvalidDates(tec_issue_date) }
                                                              </TableCell>  */}
                                                          </TableRow>
                                                      )
                                                  })
                                              }
  
                                          </TableBody> 
  
                                          
                                      </Table>
                                  </TableContainer>
                              </>
                          )
                      }
                  </Box>
                  )

                })}

            </Container>
    )
}