import axios from 'axios'

import getToken from "../../utils/getAuthToken";

var token

export const getRcs_Phase1Api = async() => (new Promise((resolve, reject ) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/lift/rcs?filter={"q":""}&range=[]&sort=[]`,
        headers: {authorization: token},
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))


export const getOneRC = async(rc_id) => (new Promise((resolve, reject ) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/lift/rcs/${rc_id}`,
        headers: {authorization: token},
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))


export const getRCs = async(rc_ids) => (new Promise((resolve, reject ) => {
    token = getToken()
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/lift/rcs/getRcs `,
        data: {rc_ids},
        headers: {authorization: token},
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))