import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import PdfModal from "./pdf/PdfModal";
import Dropzone from "react-dropzone";
import keyToField from "./keyToField";
import getToken from "./getAuthToken";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { useTranslation } from 'react-i18next';


function GetFileNames(props) {
  
  const { id } = props;
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/filename/${id}`,
          {
            headers: { authorization: getToken() },
          }
        )
        .then((res) => {
          setFileName([res.data]);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  return fileName;
}

function GetPreviewImage(props) {
  const { field } = props;
  const ReturnImage = (props) => {
    const { requestID } = props;
    const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
    const [imageError, setImageError] = useState(false);
    if (imageError) return null;

    // will use placeholder image if requested inage cannot be loaded
    // if (imageError) {
    //   return (
    //     <img
    //       src={`${process.env.PUBLIC_URL}/pics/No_Picture.jpg`}
    //       alt="No_Pic"
    //       style={{
    //         width: "175px",
    //         height: "220px",
    //         objectFit: "cover",
    //         objectPosition: "top",
    //         borderRadius: "5px",
    //         cursor: "pointer",
    //         margin: '5px 0'
    //       }}
    //     />
    //   );
    // }

    return (
      <>
        <PdfModal
          isOpen={isPdfModalOpen}
          onClose={() => setIsPdfModalOpen(false)}
          pdfUrl={`${
            process.env.REACT_APP_ENV_ENDPOINT
          }/lift/files/cert/${requestID}?token=${getToken()}`}
        />
        <img
          crossOrigin="anonymous"
          src={`${
            process.env.REACT_APP_ENV_ENDPOINT
          }/lift/files/image/${requestID}?token=${getToken()}`}
          style={{
            width: "175px",
            height: "220px",
            objectFit: "cover",
            objectPosition: "top",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onError={() => setImageError(true)}
          onClick={() => setIsPdfModalOpen(true)}
          alt="cert"
        />
      </>
    );
  };
  if (Array.isArray(field)) {
    return field.map((data, index) => (
      <ReturnImage requestID={data.id ?? data} key={index} />
    ));
  } else return <ReturnImage requestID={field.id ?? field} />;
}

function FileUploadZoneAndShowFileImage(props) {
  const {
    AIfeedback,
    edit,
    add,
    isValidated,
    show,
    showOne,
    data,
    fieldname,
    filenames,
    markMandatory,
    deleteFile,
    downloadFile,
    acceptFile,
    uploadingFile,
  } = props;

  const { t : itranslate } = useTranslation()

  const Files = (props) => {
    const { file, filenames, fileIndex, type } = props;

    const fieldFailed =
      add &&
      AIfeedback?.length > 0 &&
      AIfeedback.some((item) => item.fileId === file.id);

    return (
      <ListItem
        sx={{
          py: 0,
          px: 1,
          backgroundColor: fieldFailed ? "pink" : "#efffe5",
          border: "1px solid rgba(118, 118, 118, 0.3)",
          maxWidth: "700px",
          width: "80%",
        }}
        secondaryAction={
          type === "download" ? (
            <IconButton onClick={() => downloadFile(file)}>
              <DownloadIcon />
            </IconButton>
          ) : (
            //not allow delete previous tec cert in record management
            !(
              ["record", "record_comp"].includes(edit.clarifying) &&
              fieldname === "type_test_certificate_id" &&
              !data[fieldname][fileIndex].id
            ) && (
              <IconButton
                onClick={() => deleteFile(fileIndex)}
                size="small"
                sx={{ p: 0 }}
              >
                <Delete />
              </IconButton>
            )
          )
        }
      >
        <ListItemText
          primary={
            filenames?.filenames?.[file] ||
            file.filename ||
            (showOne && <GetFileNames id={file} />)
          }
          primaryTypographyProps={{
            paddingRight: 6,
          }}
          style={{ wordBreak: "break-all" }}
        />
      </ListItem>
    );
  };

  return (
    <React.Fragment>
      <Typography>
        {/* {keyToField[fieldname] ?? fieldname} {markMandatory} */}
        {itranslate(keyToField[fieldname]) ?? itranslate(fieldname)} {markMandatory} 
      </Typography>

      {/* ---------------------------------------------------------UPLOAD FILE------------------------------------------------------------- */}
      {((!Array.isArray(data[fieldname]) && !data[fieldname]) ||
        (Array.isArray(data[fieldname]) && !show) ||
        (!Array.isArray(data[fieldname]) &&
          edit.mode &&
          !data[fieldname].id)) &&
        (uploadingFile ? (
          <CircularProgress />
        ) : (
          <Dropzone
            disabled={!edit && !add}
            multiple={Array.isArray(data[fieldname])}
            onDrop={acceptFile}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <section
                style={{
                  border: "3px dashed lightgray",
                  height: "75px",
                  width: "80%",
                  maxWidth: "700px",
                  cursor: "pointer",
                  boxSizing: "border-box",
                }}
              >
                <div
                  {...getRootProps()}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p
                      className="dropzone"
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* Drop the files here ... */}
                      {itranslate("Drop the files here ...")}
                    </p>
                  ) : edit || add ? (
                    <p
                      style={{
                        color: "#585858",
                        fontSize: "max(0.7rem, 0.8vw)",
                        fontWeight: "bold",
                      }}
                    >
                      {/* Drag and drop a file here, or click to select files */}
                      {itranslate("Drag and drop a file here, or click to select files")}
                    </p>
                  ) : (
                    // <p>No file found</p>
                    <p>{itranslate("No file found")}</p>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        ))}

      {/* -----------------------------------------------------SHOW FILENAME/IMAGE---------------------------------------------------------- */}
      {/* NOT an array and exist file || IS an array and exist file */}
      
      {((!Array.isArray(data[fieldname]) && data[fieldname]) ||
        (Array.isArray(data[fieldname]) && data[fieldname].length > 0)) && (
        <React.Fragment>
          {/*  Free edit (clarifying) || create new || update application and file is new || update application and it is array */}
          {(edit.clarifying ||
            add ||
            (edit.mode &&
              !Array.isArray(data[fieldname]) &&
              data[fieldname].id) ||
            (edit.mode && Array.isArray(data[fieldname]))) &&
            (Array.isArray(data[fieldname]) ? (
              data[fieldname]
                //Here, we want user NOT to see the old file in update application (which is plain id instead of an object containing id)
                //Has to double map to keep the index consistent (can't filter > map)
                .map((file, fileIndex) => ({ file, fileIndex }))
                .filter(({ file, fileIndex }) => (edit.mode ? file.id : file))
                .map(({ file, fileIndex }) => (
                  <Files
                    file={file}
                    filenames={filenames}
                    fileIndex={fileIndex}
                    key={fileIndex}
                    type="delete"
                  />
                ))
            ) : (
              <Files
                file={data[fieldname]}
                filenames={filenames}
                type="delete"
              />
            ))}

          {window.location.pathname === `/lift_emsd` &&
            show &&
            (Array.isArray(data[fieldname]) ? (
              data[fieldname].map((fileId, fileIndex) => (
                <Files
                  file={fileId}
                  filenames={filenames}
                  fileIndex={fileIndex}
                  key={fileIndex}
                  type="download"
                />
              ))
            ) : (
              <Files
                file={data[fieldname]}
                filenames={filenames}
                type="download"
              />
            ))}

          {(isValidated ||
            (show && window.location.pathname === "/lift_rc")) && (
            //The logic of handling array is already in getPreviewImage
            <GetPreviewImage field={data[fieldname]} />
          )}

        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default FileUploadZoneAndShowFileImage;
