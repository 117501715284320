import React, { useEffect, useState } from "react";
import {
  Show,
  useShowController,
  SimpleShowLayout,
  useGetList,
} from "react-admin";
import axios from "axios";
import { Tabs, Tab, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { ManufacturerDisplay } from "../../components/manufacturer/ManufacturerDisplay";
import { ModelDisplay } from "../../components/model/ModelDisplay";
import { ApplicationInfo } from "../../components/approval/ApplicationInfo";
import { RulesCheck } from "../../components/approval/RulesCheck";
import { DocumentStack } from "../../components/document/DocumentStack";
import { ComponentDisplay } from "../../components/component/ComponentDisplay";
import { CommentBox } from "../../components/comment/CommentBox";
import { ApprovalControl } from "../../components/approval/ApprovalControl";
import { ShowAppendix } from "../../components/appendix/ShowAppendix";
import DerivedAppendix from "../../components/appendix/derivedAppendix/DerivedAppendix";
import * as static_fields from "../../utils/static_fields.json";
import variable_fields from "../../utils/variable_fields.json";
import isGetOneData from "../../utils/applications/isGetOneData";
import { useSelector } from "react-redux";
import throttle from "lodash/throttle";
import getToken from "../../utils/getAuthToken";
import { useParams } from "react-router-dom";
import { useGetOne } from "react-admin";
import { useTranslation } from 'react-i18next';


const tabHeight = 69;
const StyledTab = withStyles(() => ({
  root: {
    textTransform: "none",
    height: tabHeight,
    fontWeight: useTheme().typography.fontWeightRegular,
    fontSize: useTheme().typography.pxToRem(15),
    marginRight: useTheme().spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  indicator: {
    padding: useTheme().spacing(1),
  },
  demo2: {
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    // left: 0,
    right: 0,
    // width: "100%"
  },
}));


const ApplicationShow = (props) => {
  const { t : itranslate } = useTranslation();

  const {
    // 	defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
    // 	isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
    isLoading, // boolean that is true until the record is available for the first time
    record, // record fetched via dataProvider.getOne() based on the id from the location
    // refetch, // callback to refetch the record via dataProvider.getOne()
    // resource, // the resource name, deduced from the location. e.g. 'posts'
  } = useShowController();
  // console.log("🚀 ~ ApplicationShow ~ record:", record)

  const { id } = useParams();

  const { data: latestApp } = useGetOne("apps/sync", {id}, {
    enabled: window.location.pathname === "/lift_emsd" && record && record?.app_type?.type !== "SafetyComponent"
  }); //for refresh with latest application in the case of parallel application submission

  const [openAppendix, setOpenAppendix] = useState(false);
  const [update, setUpdate] = useState(null);
  const [optional, setOptional] = useState({});
  const [activeState, setActiveState] = React.useState(null);
  const [itemsServer, setItemsServer] = useState([]);

  const [tabsInScroll, setTabs] = useState([
    {
      // text: itranslate("Application Info"),
      text: "Application Info",
      component: <Typography>{itranslate("Loading")}</Typography>,
    },
    {
      // text: itranslate("Appendix / Approval Letter"),
      text: "Appendix / Approval Letter",
      component: <Typography> {itranslate("Loading")} </Typography>,
    },
    {
      // text: itranslate("Manufacturers"),
      text: "Manufacturers",
      component: <Typography>{itranslate("Loading")}</Typography>,
    },
    {
      // text: itranslate("Models"),
      text: "Models",
      component: <Typography>{itranslate("Loading")}</Typography>,
    },
    {
      // text: itranslate("Component"),
      text: "Component",
      component: <Typography>{itranslate("Loading")}</Typography>,
    },
    {
      // text: itranslate/("Additional Documents"),
      text: "Additional Documents",
      component: <Typography>{itranslate("Loading")}</Typography>,
    },
    {
      // text: itranslate("Comments"),
      text: "Comments",
      component: <Typography>{itranslate("Loading")}</Typography>,
    },
    {
      // text: itranslate("Rules"),
      text: "Rules",
      component: <Typography>{itranslate("Loading")}</Typography>,
    },
    {
      // text: itranslate("Action"),
      text: "Action",
      component: <Typography>{itranslate("Loading")}</Typography>,
    },
    {
      // text: itranslate("Derived Appendix"),
      text: "Derived Appendix",
      component: <Typography>{itranslate("Loading")}</Typography>,
    },
  ]);

  const returnAllRecord = { pagination: { page: 1, perPage: 0 } };
  const { data: teamLoad } = useGetList("team", returnAllRecord);
  const { data: filenames } = useGetOne("files/filenames", { id: id });
  let engRoles = [];

  teamLoad &&
    teamLoad.map((team) => {
      return engRoles.push(...team.engineers);
    });

  const Aside = (props) => {
    const { children } = props;
    return <div style={{ width: 200, margin: "1em" }}>{children}</div>;
  };

  const StyledTabs = withStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > div": {
        maxWidth: 30,
        width: "100%",
        backgroundColor: "#635ee7",
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

  const makeUnique = (hash, unique, i = 1) => {
    const uniqueHash = i === 1 ? hash : `${hash}-${i}`;

    if (!unique[uniqueHash]) {
      unique[uniqueHash] = true;
      return uniqueHash;
    }

    return makeUnique(hash, unique, i + 1);
  };

  const textToHash = (text, unique = {}) => {
    return makeUnique(
      encodeURI(
        text
          .toLowerCase()
          .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
          // eslint-disable-next-line no-useless-escape
          .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
          .replace(/-+/g, "-")
          .replace(/^-|-$/g, "")
      ),
      unique
    );
  };
  const noop = () => { };

  function useThrottledOnScroll(callback, delay) {
    const throttledCallback = React.useMemo(
      () => (callback ? throttle(callback, delay) : noop),
      [callback, delay]
    );

    React.useEffect(() => {
      if (throttledCallback === noop) return undefined;

      window.addEventListener("scroll", throttledCallback);
      return () => {
        window.removeEventListener("scroll", throttledCallback);
        throttledCallback.cancel();
      };
    }, [throttledCallback]);
  }

  // Accessing userInfo saved the redux
  const userInfo = useSelector((state) => state.userInfo.value);
  const userInfo_Role = ["EMSDCE", "EMSDSE"].includes(userInfo.role);

  // Getting the user's all possible roles. Storing the team_role_id(s)
  const [userRoles, setUserRoles] = useState([]);
  
  
  useEffect(() => {
    let temp = []

    if (userInfo.team_role_id) {
      temp.push(userInfo.team_role_id);
    }
  
    if (userInfo.doubling_roles) {
      userInfo.doubling_roles.forEach((role) => {
        temp.push(role.team_role_id);
      });
    }
    setUserRoles(temp)
  }, [userInfo]);
  

  useEffect(() => {
    if (update && Object.keys(update).length > 0) {
      const allowManageRecords = userInfo.allowManageRecords;

      setTabs([
        {
          text: "Application Info",
          component: (
            <div
              style={{
                marginBottom: "50px",
              }}
            >
              {Object.keys(update).length > 0 && (
                <ApplicationInfo
                  latestApp={latestApp}
                  upload={update}
                  openAppendix={openAppendix}
                  setOpenAppendix={setOpenAppendix}
                  userInfo={userInfo}
                  userRoles={userRoles}
                />
              )}
            </div>
          ),
        },
        {
          text: "Manufacturers",
          component: (
            <div style={{ marginBottom: "50px" }}>
              {Object.keys(update).length > 0 && (
                <ManufacturerDisplay
                  appShow={true}
                  show={true}
                  edit={false}
                  add={false}
                  title={"Manufacturers"}
                  upload={update}
                  setUpload={setUpdate}
                  template={static_fields}
                  variable={variable_fields}
                  filenames={filenames}
                  userInfoRole={userInfo.role}
                  userInfo_Role={userInfo_Role}
                  userInfo={userInfo}
                  userRoles={userRoles}
                />
              )}
            </div>
          ),
        },
        ...(update.app_type.type !== "SafetyComponent"
          ? [
            {
              text: "Models",
              component: (
                <div style={{ marginBottom: "50px" }}>
                  {Object.keys(update).length > 0 && (
                    <ModelDisplay
                      show={true}
                      edit={false}
                      add={false}
                      title={"Models"}
                      upload={update}
                      setUpload={setUpdate}
                      template={static_fields}
                      variable={variable_fields}
                      filenames={filenames}
                      userInfo_Role={userInfo_Role}
                    />
                  )}
                </div>
              ),
            },
          ]
          : []),
        {
          text: "Component",
          component: (
            <div style={{ marginBottom: "50px" }}>
              {Object.keys(update).length > 0 && (
                <ComponentDisplay
                  show={true}
                  edit={false}
                  add={false}
                  title={"Safety Components"}
                  optional={optional}
                  setOptional={setOptional}
                  upload={update}
                  setUpload={setUpdate}
                  template={static_fields}
                  variable={variable_fields}
                  filenames={filenames}
                  userInfo_Role={userInfo_Role}
                />
              )}
            </div>
          ),
        },
        {
          text: "Additional Documents",
          component: (
            <div style={{ marginBottom: "50px" }}>
              {Object.keys(update).length > 0 && (
                <DocumentStack
                  show={true}
                  edit={false}
                  add={false}
                  title={"Additional Documents"}
                  upload={update}
                  setUpload={setUpdate}
                  variable={variable_fields}
                  userInfo_Role={userInfo_Role}
                  userInfo={userInfo}
                  userRoles={userRoles}
                />
              )}
            </div>
          ),
        },
        ...(!["EMSDCE", "EMSDSE"].includes(userInfo.role)
          ? [
            {
              text: "Comments",
              component: (
                <div style={{ marginBottom: "50px" }}>
                  {Object.keys(update).length > 0 && (
                    <CommentBox
                      show={true}
                      edit={false}
                      add={false}
                      title={"Comments"}
                      upload={update}
                      setUpload={setUpdate}
                      userRoles={userRoles}
                    />
                  )}
                </div>
              ),
            },
          ]
          : []),
        ...((window.location.pathname !== "/lift_rc" &&
          !["Approved", "Clarifying"].includes(update.status)) || 
          allowManageRecords
          ? [
            {
              text: "Rules",
              component: (
                <div style={{ marginBottom: "50px" }}>
                  <RulesCheck
                    path={window.location.pathname}
                    upload={update}
                    setUpload={setUpdate}
                    template={static_fields}
                    variable={variable_fields}
                    userInfo_Role={userInfo_Role}
                    openAppendix={openAppendix}
                    setOpenAppendix={setOpenAppendix}
                    userInfo={userInfo}
                    userRoles={userRoles}
                    allowManageRecords={allowManageRecords}
                  />
                </div>
              ),
            },
          ]
          : []),
        ...(!["Approved", "Clarifying"].includes(update.status)
          ? [
            {
              text: "Action",
              component: (
                <div style={{}}>
                  {Object.keys(update).length > 0 && (
                    <ApprovalControl
                      latestApp={latestApp}
                      upload={update}
                      userRole={userInfo}
                      teams={teamLoad}
                      userInfo={userInfo}
                      userRoles={userRoles}
                    />
                  )}
                </div>
              ),
            },
          ]
          : []),
        ...(update.status === "Approved"
          ? [
            {
              text: "Appendix / Approval letter",
              component: (
                <div style={{ marginBottom: "50px" }}>
                  {Object.keys(update).length > 0 && (
                    <ShowAppendix 
                      upload={update} 
                      openAppendix={openAppendix} 
                      allowManageRecords={allowManageRecords}
                      setOpenAppendix={setOpenAppendix}
                    />
                  )}
                </div>
              ),
            },
          ]
          : []),
        ...(window.location.pathname === "/lift_rc" &&
          // process.env.REACT_APP_PHASE_TWO &&
          update.status === "Approved"
          ? [
            {
              text: "Derived Appendix",
              component: (
                <div style={{ marginBottom: "50px" }}>
                  <DerivedAppendix
                    path={window.location.pathname}
                    upload={update}
                    setUpload={setUpdate}
                    template={static_fields}
                    variable={variable_fields}
                  />
                </div>
              ),
            },
          ]
          : []),
      ]);
    }
  }, [update, optional, userInfo, openAppendix]);


  useEffect(() => {
    if (record && isGetOneData(record, "applications")) setUpdate(record);
  }, [record]);

  useEffect(() => {
    setItemsServer(
      tabsInScroll.map((tab) => {
        const hash = textToHash(tab.text);
        return {
          icon: tab.icon || "",
          text: tab.text,
          component: tab.component,
          hash: hash,
          node: document.getElementById(hash),
        };
      })
    );
  }, [tabsInScroll, record]);

  const itemsClientRef = React.useRef([]);

  React.useEffect(() => {
    itemsClientRef.current = itemsServer;
  }, [itemsServer, tabsInScroll]);

  const clickedRef = React.useRef(false);
  const unsetClickedRef = React.useRef(null);
  
  const findActiveIndex = React.useCallback(() => {
    if (record) {
      // set default if activeState is null
      if (activeState === null) setActiveState(itemsServer[0].hash);

      // Don't set the active index based on scroll if a link was just clicked
      if (clickedRef.current) return;

      let active;
      for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
        // No hash if we're near the top of the page
        if (document.documentElement.scrollTop < 0) {
          active = { hash: null };
          break;
        }

        const item = itemsClientRef.current[i];

        if (
          item.node &&
          item.node.offsetTop <
          document.documentElement.scrollTop +
          document.documentElement.clientHeight / 8 +
          tabHeight
        ) {
          active = item;
          break;
        }
      }

      if (active && activeState !== active.hash) {
        setActiveState(active.hash);
      }
    }
  }, [activeState, itemsServer, record]);

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer?.length > 0 ? findActiveIndex : null, 166);

  const handleClick = (hash) => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (activeState !== hash) {
      setActiveState(hash);

      if (window)
        window.scrollTo({
          top:
            document.getElementById(hash).getBoundingClientRect().top +
            window.pageYOffset,
          behavior: "smooth",
        });
    }
  };

  React.useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    []
  );

  const classes = useStyles();

  if (isLoading) {
    return <div>
      {/* Loading... */}
      {itranslate("Loading...")}
    </div>;
  }
  if (error) {
    return <div>
      {/* Error! */}
      {itranslate("Error!")}
    </div>;
  }

  return (
    <Show
      // title={`Application - ${update?.application_number}`}
      title={` ${itranslate('Application')} - ${update?.application_number}`}
      actions={<></>}
      aside={
        <Aside
          children={
            <nav className={classes.demo2} style={{}}>
              {itemsServer?.length > 0 && (
                <StyledTabs
                  orientation={"vertical"}
                  value={activeState ? activeState : itemsServer[0].hash}
                >
                  {itemsServer.map((item2) => (
                    <StyledTab
                      key={item2.hash}
                      // label={item2.text}
                      label={itranslate(item2.text) }
                      onClick={handleClick(item2.hash)}
                      value={item2.hash}
                    />
                  ))}
                </StyledTabs>
              )}
              <div className={classes.indicator} />
            </nav>
          }
        />
      }
    >
      {itemsServer?.length > 0 && (
        <SimpleShowLayout sx={{ p: 5 }}>
          <div className="container">
            {itemsServer.map((item1) => (
              <article id={item1.hash} key={item1.text}>
                {item1.component}
              </article>
            ))}
          </div>
        </SimpleShowLayout>
      )}
    </Show>
  );
};

export default ApplicationShow;
