import { Component, useEffect, useState, Suspense, lazy, useRef } from "react";
import {
  Modal,
  Button,
  Box,
  ButtonGroup,
  IconButton,
  Paper,
  // Tabs,
  // Tab,
  Typography,
  CircularProgress,
  Backdrop
} from "@mui/material";
import { useTranslation } from 'react-i18next';

import { useGetOne } from "react-admin";
import { Close } from "@mui/icons-material";
import DerivedAppendixEditInput from "./DerivedAppendixEditInput";
import { PDFViewer } from "@react-pdf/renderer";
import DerivedAppendixForSChtml from "./DerivedAppendixForSChtml";
import DerivedAppendixForSC from "./DerivedAppendixForSC";
import DerivedAppendixHtml from "./DerivedAppendixHtml";
import DerivedAppendixPDF from "./DerivedAppendixPDF";

import DerivedHideModels from "./DerivedHideModels";
import BasicModal from "../../../../_hoc/modals/BasicModal";

import { useDispatch, useSelector } from "react-redux";
import getBase64 from "./getBase64";
import { setDaAppendixBase64, clear, getDaPDF } from "../../../redux/daPdfSlice";
import DerivedHideIsoCert from "./DerivedHideIsoCert";
import DerivedAppendixForSCeditInput from "./DerivedAppendixForSCeditInput";

import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import DerivedHideTableB from "./DerivedHideTableB";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <div>
          <h1>Error</h1>
          <p>{`${error}`}</p>
        </div>
      );
    }
    return children;
  }
}

const DerivedAppendixModal = ({ openModal, setOpenModal, application, data, saveDeAppendix, updateDeAppendixPDF, certificates, simpleDisplay }) => {
  const { t : itranslate } = useTranslation();

  const dispatch = useDispatch();
  const DaPDF = useSelector(getDaPDF);
  const [showPDF, setShowPDF] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dAppendix, setdAppendix] = useState(null);

  const [openSaveLoadingModal, setOpenSaveLoadingModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [openSaveSuccessModal, setOpenSaveSuccessModal] = useState(false)


  const [appendix, setAppendix] = useState(
    data
      ? data
      : {
        overviews: [],
        conditions: [],
        remarks: [],
        remarks2: [],
        approvalLetterSubject: [],
        approvalLetterBody: [],
        table: [],
        hideCerts: [],
        hideSCCerts: [],
        hideComponentCodes: [],
        hideComponentCodes2: [],
        // hideIndividualCerts2: [],
        hideTableProps: [],
        SCLineBreak: {},
      }
  );

  // console.log("🚀 ~ appendix:", appendix)
  

  // get extra standalones component for rendering appendixSC, enabled only when application type is SC
  const { data: standalones } = useGetOne(
    "components/standalones",
    {
      id: application?.rc_id?._id,
    },
    { enabled: application?.app_type.type === "SafetyComponent" }
  );

  // console.log("standalones",  standalones)

  const [section, setSection] = useState("hideCertificates");

  // handle err
  const [error, setError] = useState(null);



  
  // for displaying highlighted certs
  const [hideModelCerts, setHideModelCerts] = useState(null)
  // console.log("🚀 ~ hideModelCerts:", hideModelCerts)
  const [compLevel, setCompLevel] = useState(null);
  // console.log("🚀 ~ compLevel:", compLevel)
  const [hideTableProps, setHideTableProps] = useState(null);
  // console.log("🚀 ~ hideTableProps:", hideTableProps)
  const [hideManufs, setHideManufs] = useState(null)
  // console.log("🚀 ~ hideManufs:", hideManufs)
  
  // currently not using this as not required by EMSD
  const [certLevel, setCertLevel] = useState(null);

  
  
  const Spinner = () => (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" size={40} />
    </Backdrop>
  )


  useEffect(() => {
    if (DaPDF.daAppendixBase64 !== null) {  // if we have the pdf in base64 format    
      setLoading(false);
      setCanSave(true);
    }
  }, [DaPDF])

  useEffect(() => {
    if (canSave === true) {
      // console.log('handleSave');
      // console.log(DaPDF?.daAppendixBase64)
      updateDeAppendixPDF(dAppendix?._id, dAppendix?.da_no, DaPDF?.daAppendixBase64).then(res => {
        console.log(res)
        setdAppendix(null);
        setOpenModal(false);
        setOpenSaveSuccessModal(true);
        dispatch(clear());
      }).catch((err) => {
        if (err?.response?.data?.message) {
          setError(err.response.data.message)
          dispatch(clear());
        } else {
          setError(err);
          dispatch(clear());
        }
      }).finally(() => {
        setCanSave(false);
      })
      setOpenModal(false)
      // setOpenSaveSuccessModal(true)
    }
  }, [canSave]);


  const handleUpdate = async () => {
    setOpenSaveLoadingModal(true)
    saveDeAppendix(appendix, application.id, application.application_number, application.rc_id, application.app_type.type).then((res) => {
      setdAppendix(res.result);  // store the DA so that it can be used to update the pdf afterwards
      setLoading(true);
      setTimeout(() => {
        getPDFBase64(res.result.url)   // use react-pdf to render pdf and then convert to base64 format   
      }, 1000);   // set a delay of 1 second, to make sure our spinner will work
      setOpenSaveLoadingModal(false);
    }).catch((err) => {
      if (err?.response?.data?.message) {
        setError(err.response.data.message)
        dispatch(clear());
      } else {
        setError(err)
        dispatch(clear());
      }
      setOpenErrorModal(true)
      dispatch(clear());
    })
  };

  // function to generate a base64 format of the pdf file, so that it can be sent to EMSd to sign
  const getPDFBase64 = async (dAppendix_url) => {
    if (application && certificates) {
      const daAppendixDocument =
        application.app_type.type === "SafetyComponent" ? (
          <DerivedAppendixForSC
            appendix={appendix}
            application={application}
            certificates={certificates}
            da_url={dAppendix_url}
            standalones={standalones}
          />
        ) : (
          <DerivedAppendixPDF
            appendix={appendix}
            application={application}
            certificates={certificates}
            da_url={dAppendix_url}
          />
        );
      const daAppendixBase64 = await getBase64(daAppendixDocument);
      dispatch(setDaAppendixBase64(daAppendixBase64));
    }
  }

  // fix the height of the pdf problem when using csp
  const handlePDFLoad = () => {
    const iframe = document.getElementsByClassName("dappendix_pdf_iframe");
    if (iframe.length === 0) return 
    const iframeDoc = iframe[0].contentWindow.document;
    const embedTag = iframeDoc.getElementsByTagName("embed")[0];
    if (embedTag) {
      embedTag.style.position = "absolute";
      embedTag.style.left = 0;
      embedTag.style.top = 0;
    }
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ overflowY: "scroll", display: "flex", flexDirection: "row" }}
      >
        <Paper
          sx={{
            margin: "0",
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: 0,
              zIndex: 9999,
              backgroundColor: "#ff234ab8",
            }}
            onClick={() => setOpenModal(false)}
          >
            <Close sx={{ fontSize: "40px" }} />
          </IconButton>

          {/* btn save / preview */}
          <ButtonGroup
            variant="contained"
            color="inherit"
            sx={{ position: "absolute", left: 0, zIndex: 9999 }}
            size="small"
          >
            <Button onClick={() => handleUpdate()}> {itranslate("Save")} </Button>
            <Button onClick={() => setShowPDF((prevState) => !prevState)}>
              {!showPDF ? "PDF" : itranslate("Preview")}
            </Button>
          </ButtonGroup>

          {/* edit side */}
          <Box style={{ width: "40%" }}>
            <div style={{ overflowY: "scroll", height: "100%" }}>
              {/* <Tabs
                variant="scrollable"
                scrollButtons
                value={section}
                onChange={(e, value) => setSection(value)}
                textColor="primary"
                sx={{ mt: 5 }}
              >
                {application.app_type.type !== "SafetyComponent" && [
                  <Tab label="selection" value="hideCertificates" key="table C" />,
                ]}

                {application.app_type.type === "SafetyComponent" && [
                <Tab label="selection" value="hideCertificates" key="table C" />,
                  ]}
              </Tabs> */}

              {/* <>
              {application.app_type.type === "SafetyComponent" ? (
                 <DerivedHideIdsSC
                  appendix={appendix}
                  setAppendix={setAppendix}
                  certificates={certificates}
                />
              ) : ( */}
              <>

                {application.app_type.type !== "SafetyComponent" ? (
                  <>
                    <Typography
                      style={{
                        width: "100%",
                        fontFamily: "Frutiger_bold",
                        fontSize: "16px",
                        marginTop: "60px",
                        marginLeft: "9px"
                      }}
                    >
                      {itranslate("Table - Field(s)")}
                    </Typography>
                    <DerivedHideTableB 
                      appendix={appendix}
                      setAppendix={setAppendix}
                      certificates={certificates}
                      application={application}
                      simpleDisplay={simpleDisplay}
                      model={application.model[0]}
                      setHideTableProps={setHideTableProps}
                    />

                    <Typography
                      style={{
                        width: "100%",
                        fontFamily: "Frutiger_bold",
                        fontSize: "16px",
                        marginTop: "30px",
                        marginLeft: "9px"
                      }}
                    >
                      {/* Table - Model(s) */}
                      {itranslate("Table - Model(s)")}
                    </Typography>
                    <DerivedHideModels
                      appendix={appendix}
                      setAppendix={setAppendix}
                      certificates={certificates}
                      setHideModelCerts={setHideModelCerts}
                    />
                    <Typography
                      style={{
                        width: "100%",
                        fontFamily: "Frutiger_bold",
                        fontSize: "16px",
                        marginTop: "30px",
                        marginLeft: "9px"
                      }}
                    >
                      {/* Manufacturer(s) */}
                      {itranslate("Manufacturer(s)")}
                    </Typography>
                    <DerivedHideIsoCert
                      appendix={appendix}
                      setAppendix={setAppendix}
                      certificates={certificates}
                      application={application}
                      setHideManufs={setHideManufs}
                    />
                  </>
                ) : (
                  <Typography
                    style={{
                      width: "100%",
                      fontFamily: "Frutiger_bold",
                      fontSize: "16px",
                      marginTop: "60px",
                      marginLeft: "9px"
                    }}
                  >{ }</Typography>
                )
                }


                {application.app_type.type !== "SafetyComponent" &&
                  (
                    <DerivedAppendixEditInput
                      application={application}
                      appendix={appendix}
                      certificates={certificates}
                      setAppendix={setAppendix}
                      section={section}
                      applicationType={application.app_type.type}
                      simpleDisplay={simpleDisplay}
                      manufacturers={application?.manufacturer}
                      components={application?.app_type?.components}
                      setCompLevel={setCompLevel}
                      // setCertLevel={setCertLevel}
                    />
                  )}


                {application.app_type.type === "SafetyComponent" && standalones &&
                  (
                    <DerivedAppendixForSCeditInput
                      application={application}
                      appendix={appendix}
                      certificates={certificates}
                      setAppendix={setAppendix}
                      section={section}
                      applicationType={application.app_type.type}
                      simpleDisplay={simpleDisplay}
                      manufacturers={application?.manufacturer}
                      components={application?.app_type?.components}
                      standalones={standalones}
                      setCompLevel={setCompLevel}
                    />
                  )}

              </>

            </div>
          </Box>

          {loading && <Spinner />}

          {/* show pdf or preview */}
          <Box sx={{ display: "flex", width: "60%", flexDirection: "column" }}>
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              {application.app_type.type === "SafetyComponent" ? (
                showPDF ? (
                    <PDFViewer 
                      width="100%" 
                      height="100%"
                      className="dappendix_pdf_iframe"
                      onLoad={handlePDFLoad}
                    >
                      <DerivedAppendixForSC
                        appendix={appendix}
                        application={application}
                        certificates={certificates}
                        standalones={standalones}
                      />
                    </PDFViewer>
                ) : (
                  <DerivedAppendixForSChtml
                    appendix={appendix}
                    application={application}
                    certificates={certificates}
                    standalones={standalones}
                    compLevel={compLevel}
                  />
                )
              ) : showPDF ? (
                <ErrorBoundary>
                  <Suspense fallback={<div>
                    {itranslate("Loading...")}
                  </div>}>
                      <PDFViewer 
                        width="100%" 
                        height="100%"
                        className="dappendix_pdf_iframe"
                        onLoad={handlePDFLoad}
                      >
                        <DerivedAppendixPDF
                          appendix={appendix}
                          application={application}
                          certificates={certificates}
                        />
                      </PDFViewer>
                  </Suspense>
                </ErrorBoundary>
              ) : (
                <DerivedAppendixHtml
                  appendix={appendix}
                  application={application}
                  certificates={certificates}
                  hideModelCerts={hideModelCerts}
                  compLevel={compLevel}
                  certLevel={certLevel}
                  hideTableProps={hideTableProps}
                  hideManufs={hideManufs}
                />
              )}

            </div>
          </Box>

        </Paper>
      </Modal>

      {
        openSaveSuccessModal && <BasicModal open={openSaveLoadingModal} setOpen={setOpenSaveLoadingModal} title="save success">
          <div>
            {itranslate("Saved Successfully")}
          </div>
        </BasicModal>
      }
      {
        openSaveLoadingModal && <BasicModal open={openSaveLoadingModal} setOpen={setOpenSaveLoadingModal} title="Saving">
          <div>
            {itranslate("Saving")}
          </div>
        </BasicModal>
      }
      {
        error && typeof error === 'string' && openErrorModal && <BasicModal open={openErrorModal} setOpen={setOpenErrorModal} setOpen2={setOpenModal} title="Error">
          <div>{error}</div>
        </BasicModal>
      }
      {
        error && typeof error !== "string" && openErrorModal && <BasicModal open={openErrorModal} setOpen={setOpenErrorModal} setOpen2={setOpenModal} title="Error">
          <div>{JSON.stringify(error)}</div>
        </BasicModal>
      }

    </>
  )
}

export default DerivedAppendixModal