import { TextField, InputLabel, Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';


function DescriptionInput(props) {
  const { upload, setUpload } = props;
  const { t : itranslate } = useTranslation();

  const handleText = (e) => {
    setUpload({
      ...upload,
      description: e.target.value,
    });
  };

  return (
    <Box sx={{ mt: 4 }}>
      <InputLabel id="description">
        {itranslate("Remark about changes made")}
        <Typography
          component="span"
          sx={{
            color: "red",
            fontStyle: "italic",
            fontSize: "0.75rem",
            verticalAlign: "top",
          }}
        >
          {itranslate("*Required")}
        </Typography>
      </InputLabel>
      
      <TextField
        labelId="description"
        value={upload?.description}
        onChange={handleText}
        variant="outlined"
        sx={{ width: "600px" }}
      />
    </Box>
  );
}

export default DescriptionInput;
