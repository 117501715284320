function autoSave(newUpload, newUpdate, edit_mode) {
  //optional is a state in componentDisplay that is cruical for rendering
  const existingDraft =
    JSON.parse(localStorage.getItem("tapas_application_draft")) || {};
  let upload = { ...newUpload };
  if (edit_mode) {
    upload.update_type = edit_mode; //for loading draft and redirecting to application_a/b/c/d/e/f
    upload.update_upon = newUpload.id || newUpload.update_upon;
  }

  localStorage.setItem(
    "tapas_application_draft",
    JSON.stringify({
      upload: upload,
      update: newUpdate,
    })
  );
}

export default autoSave;
