import { SelectInput, TextInput, ReferenceInput, Filter } from "react-admin";
import { BooleanInput } from "react-admin";
import { useTranslation } from 'react-i18next';


const ModelFilter = (props) => {
  const { t : itranslate } = useTranslation()
  return (
    <Filter {...props}>

      <TextInput
        source="q"
        label={itranslate('Search model code / model name / manufacturer / type / RC / cert data')}
        alwaysOn
        sx={{ width: "600px" }}
      />

      <TextInput 
        label={itranslate('Model Code')}
        source="model_code" defaultValue="RM" 
      />

      <TextInput 
        label={itranslate('Model Name')}
        source="provided_model_name" 
      />

      <TextInput 
        label={itranslate('Component Code')}
        source="component_code" defaultValue="RC"
      />

      <TextInput 
        label={itranslate('Component Name')}
        source="provided_comp_name" 
      />

      <SelectInput
        label={itranslate('Type of Machine')}
        source="type"
        choices={[
          {
            id: "ElectricTractionLiftMachineRoom",
            name: itranslate('Electric Traction Lift (Machine Room)'),
         
          },
          {
            id: "ElectricTractionLiftRoomless",
            name: itranslate('Electric Traction Lift (Machine Roomless)'),
          },
          { id: "InclinedLift", 
            name: itranslate('Electric Inclined Lift')
          },
          { id: "ServiceLift", 
            name: itranslate('Service Lift')
          },
          { id: "StairLift", 
            name: itranslate('Stairlift')
          },
          { id: "HydraulicLift", 
            name: itranslate('Hydraulic Lift')
          },
          {
            id: "ElectricalVerticalLiftingPlatform",
            name: itranslate('Vertical Lifting Platform'),
          },
          { id: "MVPS", 
            name: itranslate('Mechanized Vehicle Parking System (MVPS)')
          },
          { id: "Escalator",  
            name: itranslate('Escalator')
          },
          { id: "PassengerConveyor", 
            name: itranslate('Passenger Conveyor')
          },
          { id: "SafetyComponent", 
            name: itranslate('Safety Component')
          },
          { id: "Others", 
            name: itranslate('Others')
          },
        ]}
      />

      <TextInput
        label={itranslate('Manufacturers')}
        source="manufacturersPopulated.provided_name"
      />
      {window.location.pathname === "/lift_emsd" && (
        <ReferenceInput source="rc_id"
          label={itranslate('RC')}
          reference="rcs" perPage={100}
        >
          {/* <SelectInput optionText="name" /> */}
          <SelectInput optionText={choice => {
            let displayText = "";
            displayText = choice?.rc_number_lift;
            if (choice?.rc_number_escalator) {
              displayText += ` / ${choice.rc_number_escalator}`;
            }
            if (choice?.address?.company) {
              displayText += ` / ${choice.address.company}`;  
            }
            return displayText;
          }} />  
        </ReferenceInput>
      )}

      <TextInput 
        label={itranslate('Rated Speed')}
        source="rated_speed" 
      />

      <TextInput 
        label={itranslate('Rated Load')} 
        source="rated_load" 
      />

      <BooleanInput 
        label={itranslate('Comply standard')}
        source="comply_standard" 
      />

    </Filter>
  );
};

export default ModelFilter;
