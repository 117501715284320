// popper
import {
    Popper,
    Button,
    Box,
    Paper,
  } from "@mui/material";
  import Fade from '@mui/material/Fade';

  import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
  
  import { useState } from 'react';

export default function CommunicationLogPopper({children}){
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const handleClick = (newPlacement) => (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };
  
    const handleMouseLeave = () => {
        setOpen(false);
    }
    return (
        <>
            <Popper
                // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                sx={{ zIndex: 1200 }}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
            >
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper
                        sx={{
                            borderRadius: '1em',
                            // bgcolor needs to be transparent grey
                            bgcolor: 'rgba(0,0,0,0.1)',
                        }}
                    >
                        <Box 
                            sx={{ 
                                p: 2, 
                            }}
                        >
                                {children}</Box>
                    </Paper>
                </Fade>
                )}
            </Popper>
            <Button onMouseEnter={handleClick('right') } onMouseLeave={handleMouseLeave}><HelpOutlineIcon /></Button>
        </>
    )
}