import {useState, useEffect, useRef} from 'react';
import { styled } from '@mui/material/styles';


import Button from "@mui/material/Button";

//components
import CreateStage2ModelList from './modelsStages/CreateStage2ModelList.jsx'
import CreateStage2ModelManuList from './modelsStages/CreateStage2ModelManuList.jsx'
import CreateStage2ModelCompList from './modelsStages/CreateStage2ModelCompList.jsx'
import CreateStage2ComponentList from './componentsStages/CreateStage2ComponentList.jsx'

import Container from "@mui/material/Container";

import getToken from '../../../admin/utils/getAuthToken.js';

import ShowPDF from "../../../general/pages/PageRestrictedUrl/RestrcitedUrl/ShowPDF.jsx";

// import { useTranslate } from 'react-admin';
import { useTranslation } from 'react-i18next';

const BackBtn = styled(Button)(({ theme }) => ({
    backgroundColor: "#616161",
    '&:hover': {
        backgroundColor: "#434343",
    },
}));



const CreateStage2 =({option, stage, setStage, setOption, 
    // for listing data from getList api
    dataModel, setDataModel, dataComponents, setDataComponents, 
    nextBtnDisabled, setNextBtnDisabled,
    // ru creation data
    setModel_manu_sc_id_arr, model_manu_sc_id_arr, setSc_id_arr, sc_id_arr, setCertsChosen, certsChosen, certsChosenForScPerspective, setCertsChosenForScPerspective,
    // next btn
    handleNext
}) => {
    // const translate = useTranslate();
    const { t : itranslate } = useTranslation();

    	// get cert files
	const [File, setFile] = useState(null);
	const [openInfoModal, setOpenInfoModal] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const handleGetPDFFile = async (certId) => {
		setFile(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${certId}?token=${getToken()}`);
	};
	
	useEffect(() => {
	if(File !== null) setOpenModal(true);
	},[File])
    
    return (
        <Container component="main"
        maxWidth="xl" sx={{ padding: "40px 0px" }}
        >
            {
                option === 'models' && (
                    <>
                        {
                            stage === '2_1' && (
                                <CreateStage2ModelList stage={stage} setStage={setStage} option={option} setOption={setOption}
                                    nextBtnDisabled={nextBtnDisabled}
                                    setNextBtnDisabled={setNextBtnDisabled}
                                    setModel_manu_sc_id_arr={setModel_manu_sc_id_arr}
                                    model_manu_sc_id_arr={model_manu_sc_id_arr}
                                    setCertsChosen={setCertsChosen}
                                    certsChosen={certsChosen}
                                    setDataModel={setDataModel}
                                    dataModel={dataModel}
                                />
                            )
                        }
                        {
                            stage === '2_2' && (
                                <CreateStage2ModelManuList stage={stage} setStage={setStage} option={option} setOption={setOption}
                                    nextBtnDisabled={nextBtnDisabled}
                                    setNextBtnDisabled={setNextBtnDisabled}
                                    setModel_manu_sc_id_arr={setModel_manu_sc_id_arr}
                                    model_manu_sc_id_arr={model_manu_sc_id_arr}
                                    setCertsChosen={setCertsChosen}
                                    certsChosen={certsChosen}
                                    setDataModel={setDataModel}
                                    dataModel={dataModel}
                                    handleGetPDFFile={handleGetPDFFile}
                                />
                            )
                        }
                        {
                            stage === '2_3' && (
                                <CreateStage2ModelCompList stage={stage} setStage={setStage} option={option} setOption={setOption}
                                    nextBtnDisabled={nextBtnDisabled}
                                    setNextBtnDisabled={setNextBtnDisabled}
                                    setModel_manu_sc_id_arr={setModel_manu_sc_id_arr}
                                    model_manu_sc_id_arr={model_manu_sc_id_arr}
                                    setCertsChosen={setCertsChosen}
                                    certsChosen={certsChosen}
                                    dataModel={dataModel}
                                    handleGetPDFFile={handleGetPDFFile}
                                />
                            )
                        }

                    </>
                )
            }

            {
                option === 'components' && (
                    <>
                        {
                            stage === '2_1' && (
                                <CreateStage2ComponentList stage={stage} setStage={setStage} option={option}
                                    setSc_id_arr={setSc_id_arr}
                                    sc_id_arr={sc_id_arr}
                                    setCertsChosenForScPerspective={setCertsChosenForScPerspective}
                                    certsChosenForScPerspective={certsChosenForScPerspective}
                                    nextBtnDisabled={nextBtnDisabled}
                                    setNextBtnDisabled={setNextBtnDisabled}
                                    dataComponents={dataComponents}
                                    setDataComponents={setDataComponents}
                                    handleGetPDFFile={handleGetPDFFile}
                                />
                            )
                        }
                    </>
                )
            }

            {
                !(option === "models" && stage === '2_3') && !(option === "models" && stage === '2_2') && !(option === "models" && stage === "2_1") && (
                    <Button
                        type="submit"
                        sx={{
                            // center the button
                            display: 'flex',
                            m: "auto",
                            width: "95%",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleNext({ e })}
                        aria-label="submit"
                        disabled={nextBtnDisabled}
                    >
                        {/* {translate("phase2.createRu.next")} */}
                        {itranslate("next")}
                    </Button>
                )
            }
                
            {
                openModal && 
                <ShowPDF
                    File={File}
                    setFile={setFile}
                    openInfoModal={openInfoModal}
                    setOpenInfoModal={setOpenInfoModal}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
            }
        </Container>
    )
}

export default CreateStage2