/* eslint-disable no-use-before-define */
/* eslint-disable import/no-webpack-loader-syntax */
import * as React from "react";
import { useState, useEffect, memo } from "react";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import QRCode from "qrcode";

// import PartBTable from "../../pdf/PartBTable";
// import PartCModelTable from "../../pdf/PartCModelTable";
import PartBTable from "./pdf/PartBTable";
import PartCModelTable from "./pdf/PartCModelTable";
import DerivedPartCCompTable from "./DerivedPartCCompTable.js";
import PartD from "./pdf/PartD.js";

// utils
import { romanize } from "../../../utils/romanize";
import fontRegister from "../../../utils/pdf/fontRegister";
import renderChinese from "../../../utils/pdf/renderChinese";
import {
  renderContent,
  renderIndexedContent,
} from "../../../utils/pdf/renderContent";

import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const DerivedAppendixPDF = memo(({ application, certificates, appendix, da_url }) => {


  function getCertList(component, component_type) {
    const certs = certificates?.filter(
      (tec) =>
        tec?.reference === component.component_code &&
        tec?.type === "ComponentTypeCert" &&
        !appendix.hideCerts?.includes(tec?.id) &&
        !appendix.hideComponentCodes2?.some(
          (item) =>
              item.component_code === tec?.reference &&
              item.component_type === component_type
        ) 
        // !appendix.hideIndividualCerts2?.some(
        //   (item) => 
        //       item.cert_id === tec.id &&
        //       item.component_type === component_type
        // )
    );
    return certs;
  }

  function getCompListCount(component_type) {
    const components = application?.app_type?.components?.[component_type].filter(component => {
      const certs = getCertList(component, component_type);
      return certs.length > 0
    })
    return components.length
  }



  fontRegister();

  let PdfContent = () => (
    <Document>
      <Page
        wrap
        style={{
          padding: "48px 61.64px 48px 80.44px",
          fontSize: "11px",
          fontFamily: "Frutiger_light",
          lineHeight: 1.6,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontFamily: "Frutiger_Ultra_bold",
            marginBottom: "10px",
          }}
          fixed
        >
          <Text>Application No.: {application.application_number}</Text>
          <Text>Appendix</Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: "20px",
            textAlign: "center",
            fontFamily: "Frutiger_Ultra_bold",
          }}
        >
          {/* <View style={{ flexGrow: 1, alignItems: "center" }}> */}
          <View style={{ margin: "0 auto" }}>
            <Text style={{ alignSelf: "center" }}>
              Technical Specifications for
            </Text>
            <Text>
              <Text style={renderChinese(model_manufacturer[0].provided_name)}>
                {model_manufacturer[0].provided_name}
              </Text>
              , Model {application.model[0].provided_model_name}
            </Text>
          </View>

          {da_url && (
            <View>
              <Image
                style={{ width: 50, height: 50 }}
                src={QRCode.toDataURL(da_url || "google.com")}
              />
            </View>
          )}
        </View>

        {/* --------------------------------------------------------SECTION A------------------------------------------------------------ */}

        <Text style={{ fontFamily: "Frutiger_bold", fontSize: "11px" }}>
        Registered {application.model[0]?.LE_type === "E" ? "Escalator" : "Lift"} Contractor:{" "}
          <Text style={{ textDecoration: "underline" }}>
            {application.rc_id[
              application.model[0]?.LE_type === "E"
                ? "rc_number_escalator"
                : "rc_number_lift"
            ] || application.rc_id.name}
          </Text>
        </Text>

        <View>
          <Text
            style={{
              padding: "20px 0px 0px 0px",
              fontFamily: "Frutiger_Ultra_bold",
            }}
          >
            Overview
          </Text>
          {/* Parse for model, issue date, end date, parse for standard */}
          {/*parse for parameter text equiv to any certificate field */}
          {renderContent(appendix?.overviews || [], "pdf", application.model)}
        </View>

        <View>
          <Text
            style={{
              paddingBottom: "5px",
              fontFamily: "Frutiger_Ultra_bold",
            }}
          >
            A. Conditions
          </Text>
          {renderIndexedContent(
            appendix?.conditions || [],
            "pdf",
            application.model
          )}
        </View>

        {/* --------------------------------------------------------SECTION B------------------------------------------------------------ */}

        <View break>
          <Text style={{ fontFamily: "Frutiger_Ultra_bold" }}>
            B. General Operating Parameters and Characteristics
          </Text>

          {application?.model.map((model, i) => {
            return (
              <View key={i} style={{ marginRight: "-45px" }}>
                <PartBTable
                  application={application}
                  appendix={appendix}
                  model={model}
                  manu_code={findManufacturerAlphabet(
                    model_manufacturer,
                    model
                  )}
                  certificates={certificates}
                  simpleDisplay={simpleDisplay}
                />
              </View>
            );
          })}

          {/* original appendix remarks */}
          {appendix?.remarks?.length > 0 && (
            <View>
              <Text style={{ fontFamily: "Frutiger_bold", fontSize: "11px" }}>
                Remarks
              </Text>
              {renderIndexedContent(
                appendix?.remarks || [],
                "pdf",
                application.model
              )}
            </View>
          )}

          <Text
            style={{
              paddingTop: "20px",
              textDecoration: "underline",
              fontFamily: "Frutiger_bold",
              fontSize: "11px",
            }}
          >
            Manufacturer Code
          </Text>


          {/* use appendix[manufacturer_code] or iso render manu info */}
          {appendix?.manufacturer_code.length > 0 ? (
            appendix.manufacturer_code?.map((manufacturer, i) => {
              if (!appendix.hideCerts?.includes(manufacturer.name + manufacturer.address) ) {
                return (
                  <View
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  key={i}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Frutiger_bold",
                        fontSize: "11px",
                      }}
                    >
                      {String.fromCharCode(65 + i)}.{" "}
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Frutiger_bold",
                        fontSize: "11px",
                        ...renderChinese(manufacturer.name),
                      }}
                    >
                      {manufacturer.name}
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Frutiger_bold",
                        fontSize: "11px",
                        margin: "0px 3px",
                      }}
                    >
                      -
                    </Text>
                    <Text style={renderChinese(manufacturer.address)}>
                      {manufacturer.address}
                    </Text>
                  </View>
                </View>
                )
              }
            })
          ) : (
            model_manufacturer?.map((manufacturer, i) => {
              if (
                !appendix.hideCerts?.includes(manufacturer.iso_certificate_id)
              ) {
                // iso cert
                // const iso_manufacturer_name = simpleDisplay(
                //   manufacturer.iso_certificate_id,
                //   "iso_manufacturer_name"
                // );
                const iso_manufacturer_address = simpleDisplay(
                  manufacturer.iso_certificate_id,
                  "iso_manufacturer_address"
                );
                return (
                  <View
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    key={i}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Frutiger_bold",
                          fontSize: "11px",
                        }}
                      >
                        {String.fromCharCode(65 + i)}.{" "}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Frutiger_bold",
                          fontSize: "11px",
                          ...renderChinese(manufacturer?.provided_name),
                        }}
                      >
                        {manufacturer?.short_name || manufacturer?.provided_name}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Frutiger_bold",
                          fontSize: "11px",
                          margin: "0px 3px",
                        }}
                      >
                        -
                      </Text>
                      <Text style={renderChinese(iso_manufacturer_address)}>
                        {iso_manufacturer_address}
                      </Text>
                    </View>
                  </View>
                );
              }
            })
          )}
        </View>

        {/* --------------------------------------------------------SECTION C------------------------------------------------------------ */}

        <View style={{ pageBreakBefore: "always" }} break>
          <Text style={{ fontFamily: "Frutiger_Ultra_bold" }}>
            C. List of Applicable Type Examination Certificates
          </Text>
          {appendix?.remarks2?.length > 0 && (
            <View style={{ marginTop: "25px" }}>
              <Text style={{ fontFamily: "Frutiger_bold", fontSize: "11px" }}>
                Remarks
              </Text>
              {renderIndexedContent(
                appendix?.remarks2 || [],
                "pdf",
                application.model
              )}
            </View>
          )}

          {application?.model.map((model, i) => {
            const manufacturer_code = findManufacturerAlphabet(
              model_manufacturer,
              model
            );
            return (
              <View
                key={`${model._id}-C`}
                style={{ boxShadow: "unset !important" }}
              >
                <PartCModelTable
                  appendix={appendix}
                  application={application}
                  model={model}
                  manu_code={manufacturer_code}
                  romanize={romanize}
                  index={i}
                  certificates={certificates}
                  simpleDisplay={simpleDisplay}
                />
              </View>
            );
          })}

          {Object.keys(application.app_type?.components || {})
            .filter(
              (component_type) =>
                !["TractionMachine", "Controller"].includes(component_type)
            ).filter((component_type) => {
              return getCompListCount(component_type) > 0
            })
            .sort((a, b) => {
              const order = [
                "Buffer",
                "OverspeedGovernor",
                "LandingDoorLockingDevice",
                "CarDoorLockingDevice",
                "SafetyGear",
                "ACOP",
                "UCMP",
                "SafetyCircuitElectricalComponent",
              ];
              function customSort(item) {
                const index = order.indexOf(item);
                return index !== -1 ? index : order.length;
              }
              return customSort(a) - customSort(b);
            })
            .map((component_type, j) => {
              return (
                <View
                  key={`${component_type}-C`}
                  style={{ boxShadow: "unset !important" }}
                >
                  <DerivedPartCCompTable
                    appendix={appendix}
                    application={application}
                    component_type={component_type}
                    romanize={romanize}
                    index={j}
                    manufacturers={application?.manufacturer}
                    liftModels={application.model}
                    components={application?.app_type?.components}
                    certificates={certificates}
                    simpleDisplay={simpleDisplay}
                  />
                </View>
              );
            })}
        </View>

        <PartD application={application} appendix={appendix} format="pdf" />

        <Text
          style={{
            position: "absolute",
            fontSize: 10,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />

      </Page>
    </Document>
  );

  const model_manufacturer = application?.model
    .flatMap((model) => model.manufacturer_files)
    .map((file) => {
      return application.manufacturer.find((manu) => manu._id === file.manufacturer_id)
    });

  //manufacturer code is listed as A, B, C ... We want the alphabet of the manufacturer code here
  function findManufacturerAlphabet(model_manufacturer, model) {
    const manufacturers = model.manufacturer_files.map((file) => {
      return application.manufacturer.find(
        (manu) => manu._id === file.manufacturer_id
      );
    });
    let indices = [];
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    model_manufacturer.forEach((manu, i) => {
      if (manufacturers.includes(manu)) {
        indices.push(alphabet[i]);
      }
    });
    return indices.join(", ");
  }

  const simpleDisplay = (cert_id, field) => {
    const matchCert = certificates?.find((cert) => cert?.id === cert_id);
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field]
    );
  };


  if (!application) return null;
  return <PdfContent />
});

export default DerivedAppendixPDF;
