import React from "react";
import keyToField from "../../utils/keyToField";
import parseAppendixJson from "../../utils/pdf/appendix_format_parser";
import { romanize, alphabet } from "../../utils/romanize";
import "../../styles/appendix/AppendixSC.css";
import reactStringReplace from "react-string-replace";
import moment from "moment";
import { renderIndexedContent } from "../../utils/pdf/renderContent";
import { fixInvalidDates } from "../../utils/misc/fixInvalidDates";
import _ from "lodash";




function AppendixForSChtml(props) {
  const {
    application,
    certificates,
    appendix,
    cert,
    setCert,
    makeCorrections,
    setHash,
    userInfo,
    userRoles,
  } = props;
  if (!application || !certificates) return null;

  const simpleDisplay = (cert_id, field) => {
    const matchCert = certificates.find((cert) => cert.id === cert_id);
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field] ||
      ""
    );
  };

  function findAndSetHash(field) {
    const label = cert?.position_data?.find((result) => result.comment.text === field);
    if (label) setHash(label.id);
  }

  const componentTypes =
    application && Object.keys(application.app_type.components);
  const components = application && application.app_type.components;


  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))
  

  return (
    <div className="appendixSC">
      <div>
        <div className="text-center py-12">
          <p className="bold header">Technical Specifications for</p>
          <p className="bold header">Various Safety Components</p>
        </div>

        <p className="bold">
          Registered Lift Contractor:{" "}
          <span className="bold underline">
            {application.rc_id?.address?.company} (RLC no.:
            {application.rc_id.rc_number_lift})
          </span>
        </p>

        <p className="bold py-5">Specifications and Conditions</p>
        <p className="lightBold">Safety Components Specifications:</p>

        {componentTypes.map((type, i) => {
          const displayedCertData = {
            ACOP: [
              "Model",
              "tec_car_load", // no idea what it is
              "tec_balancing_factor",
              "tec_rated_load", // called Total load kg in the form
              "tec_rated_speed",
              "tec_tripping_speed",
              "tec_rope_factor",
              "tec_certificate_number",
            ],
            Buffer: [
              "Model",
              "tec_total_mass",
              "tec_impact_speed",
              "tec_certificate_number",
            ],
            CarDoorLockingDevice: [
              "Model",
              "tec_door_type",
              "tec_contact_rating",
              "tec_certificate_number",
            ],
            LandingDoorLockingDevice: [
              "Model",
              "tec_door_type",
              "tec_contact_rating",
              "tec_certificate_number",
            ],
            OverspeedGovernor: [
              "Model",
              "tec_rated_speed",
              "tec_tripping_speed",
              "tec_certificate_number",
            ],
            Pallet: [
              "Model",
              "tec_rated_load",
              "tec_rated_speed",
              "tec_certificate_number",
            ],
            SafetyCircuitElectricalComponent: [
              "Model",
              "tec_circuit_description",
              "tec_certificate_number",
            ],
            SafetyGear: [
              "Model",
              "tec_total_mass",
              "tec_rated_speed",
              "tec_tripping_speed",
              "tec_certificate_number",
            ],
            Step: [
              "Model",
              "tec_rated_load",
              "tec_rated_speed",
              "tec_certificate_number",
            ],
            UCMP: [
              "Model",
              "tec_car_load",
              "tec_balancing_factor",
              "tec_rated_load",
              "tec_rated_speed",
              "tec_tripping_speed",
              "tec_rope_factor",
              "tec_certificate_number",
            ],
            TractionMachine: [
              "Model",
              "tec_rated_load",
              "tec_rated_speed",
              "tec_certificate_number",
            ],
            Controller: [
              "Model",
              "tec_rated_load",
              "tec_rated_speed",
              "tec_certificate_number",
            ],
          };
          return (
            <table key={type}>
              <thead>
                <tr>
                  <th colSpan="100%" className="tableHeader">
                    ({romanize(i + 1).toLowerCase()}){"       "}
                    {keyToField[type]}
                  </th>
                </tr>
                <tr>
                  <th>Manufacturer</th>
                  {displayedCertData[type].map((field) => (
                    <th key={field}>
                      {field === "Model"
                        ? "Model"
                        : field === "tec_certificate_number"
                        ? "Type Exam. Cert. No."
                        : keyToField[field] ?? field}
                    </th>
                  ))}
                  <th>Approval Reference</th>
                </tr>
              </thead>
              <tbody>
                {application.manufacturer.map((manu) =>
                  components[type]
                    .filter((comp) => comp.manufacturer_id === manu._id)
                    .map((comp, compIndex, compsOfManu) => {
                      const matchCert = certificates.find(
                        (cert) => cert.id === manu.iso_certificate_id
                      );

                      return comp.type_test_certificate_id.map(
                        (type_test_cert_id, certIndex, certList) => {
                          return (
                            <tr key={type_test_cert_id}>
                              {compIndex === 0 && certIndex === 0 && (
                                <td
                                  rowSpan={
                                    compsOfManu.flatMap(
                                      (comp) => comp.type_test_certificate_id
                                    ).length
                                  }
                                >
                                  <p className="manufacturer lightBold">
                                    {manu.short_name || manu.provided_name}
                                  </p>
                                  <p className="manufacturer caption">
                                    <input
                                      disabled={isDisabled} 
                                      value={simpleDisplay(
                                        manu.iso_certificate_id,
                                        "iso_manufacturer_address"
                                      )}
                                      onChange={(e) =>
                                        makeCorrections(
                                          e.target.value,
                                          manu.iso_certificate_id,
                                          "iso_manufacturer_address"
                                        )
                                      }
                                      onSelect={() => {
                                        matchCert.id !== cert.id
                                          ? setCert(matchCert)
                                          : findAndSetHash(
                                              "iso_manufacturer_address"
                                            );
                                      }}
                                    />
                                  </p>
                                </td>
                              )}
                              {displayedCertData[type].map((field) => {
                                const referredCert = certificates.find(
                                  (certificate) =>
                                    certificate.id === type_test_cert_id
                                );
                                const inputProps = (field) => {
                                  return {
                                    value: simpleDisplay(
                                      type_test_cert_id,
                                      field
                                    ),
                                    onChange: (e) => {
                                      makeCorrections(
                                        e.target.value,
                                        type_test_cert_id,
                                        field
                                      );
                                    },
                                    onSelect: () => {
                                      referredCert.id !== cert.id
                                        ? setCert(referredCert)
                                        : findAndSetHash(field);
                                    },
                                  };
                                };
                                switch (field) {
                                  case "Model":
                                    return (
                                      certIndex === 0 && (
                                        <td
                                          key={field}
                                          rowSpan={certList.length}
                                        >
                                          {comp.provided_comp_name}
                                        </td>
                                      )
                                    );
                                  case "tec_certificate_number":
                                    return (
                                      <td key={field}>
                                        <input disabled={isDisabled} {...inputProps(field)} />
                                        issued on
                                        <input disabled={isDisabled} 
                                          {...inputProps("tec_issue_date")}
                                        />
                                      </td>
                                    );
                                  default:
                                    return (
                                      <td key={field}>
                                        <input disabled={isDisabled} {...inputProps(field)} />
                                      </td>
                                    );
                                }
                              })}

                              {certIndex === 0 && (
                                <td rowSpan={certList.length}>
                                  {`${application.application_number} dated ${moment().format("DD-MM-YYYY")}`}

                                  {compsOfManu.every((comp) =>
                                    comp.type_test_certificate_id.every((certId) => {
                                        const match = certificates.find((cert) => cert.id === certId);
                                        return match.comply_standard?.lift;
                                      }
                                    )
                                  ) && " [Ƨ]"}
                                </td>
                              )}

                            </tr>
                          );
                        }
                      );
                    })
                )}
              </tbody>
            </table>
          );
        })}

        {appendix?.remarks?.length > 0 && (
          <div className="mb-2.5">
            <p style={{ fontFamily: "Frutiger_light", fontWeight: "bold" }}>
              Remarks:
            </p>
            {renderIndexedContent(
              appendix?.remarks,
              "plainHtml",
              application.model,
              "scRemarks"
            )}
          </div>
        )}

        <div className="mb-2.5">
          <p
            style={{
              fontFamily: "Frutiger_light",
              fontWeight: "bold",
            }}
          >
            Conditions:
          </p>
          {renderIndexedContent(
            appendix?.conditions,
            "plainHtml",
            application.model
          )}
        </div>
      </div>
    </div>
  );
}


export default AppendixForSChtml;
