import * as React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import PartBTable from "../pdf/PartBTable";
import PartCModelTable from "../pdf/PartCModelTable";
import PartCCompTable from "../pdf/PartCCompTable";
import parseAppendixJson from "../../utils/pdf/appendix_format_parser.js";
import { alphabet, romanize } from "../../utils/romanize";
import fontRegister from "../../utils/pdf/fontRegister";
import renderChinese from "../../utils/pdf/renderChinese";
import QRCode from "qrcode";
import PartD from "../pdf/PartD";
import {
  renderContent,
  renderIndexedContent,
} from "../../utils/pdf/renderContent";

const Appendix = (props) => {
  const { application, certificates, appendix, qrcode_url } = props;
  fontRegister();

  const model_manufacturer = application?.model
    .flatMap((model) => model.manufacturer_files)
    .map((file) => application.manufacturer.find((manu) => manu._id === file.manufacturer_id));

  const simpleDisplay = (cert_id, field) => {
    const matchCert = certificates?.find((cert) => cert.id === cert_id);
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field]
    );
  };

  if (!application) return null;

  const rcDisplay =
    application.rc_id?.address?.company +
    " " +
    (application.model[0]?.LE_type === "E"
      ? `(REC no.: ${application.rc_id?.rc_number_escalator})`
      : `(RLC no.: ${application.rc_id?.rc_number_lift})`);

  return (
    <Document>
      <Page
        wrap
        style={{
          padding: "48px 61.64px 48px 80.44px",
          fontSize: "11px",
          fontFamily: "Frutiger_light",
          lineHeight: 1.6,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontFamily: "Frutiger_Ultra_bold",
            marginBottom: "10px",
          }}
          fixed
        >
          <Text>Application No.: {application.application_number}</Text>
          <Text>Appendix</Text>
        </View>


        {/* <View
          style={{
            paddingBottom: "20px",
            textAlign: "center",
            fontFamily: "Frutiger_Ultra_bold",
          }}
        >
          <Text>Technical Specifications for</Text>
          <Text>
            {appendix.title ||
              `${model_manufacturer[0].provided_name}, Model ${application.model[0].provided_model_name}`}
          </Text>
        </View> */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: "20px",
            textAlign: "center",
            fontFamily: "Frutiger_Ultra_bold",
          }}
        >
          <View style={{ margin: "0 auto"}}>
            <Text style={{ alignSelf: "center" }}>
              Technical Specifications for
            </Text>
            <Text style={{ alignSelf: "center" }}>
              {appendix.title ||
                `${model_manufacturer[0].provided_name}, Model ${application.model[0].provided_model_name}`}
            </Text>
          </View>

          {/* {process.env.REACT_APP_PHASE_TWO && qrcode_url && ( */}
          {qrcode_url && (
            <View>
              <Image style={{ width: 50, height: 50 }} src={QRCode.toDataURL(qrcode_url || "google.com")} />
            </View>
          )}
        </View>


        {/* --------------------------------------------------------SECTION A------------------------------------------------------------ */}

        <Text style={{ fontFamily: "Frutiger_bold", fontSize: "11px" }}>
          Registered {application.model[0]?.LE_type === "E" ? "Escalator" : "Lift"} Contractor:{" "}
          <Text style={{ textDecoration: "underline" }}>{rcDisplay}</Text>
        </Text>

        <View>
          <Text
            style={{
              padding: "20px 0px 0px 0px",
              fontFamily: "Frutiger_Ultra_bold",
            }}
          >
            Overview
          </Text>
          {renderContent(appendix?.overviews || [], "pdf", application.model)}
        </View>

        <View>
          <Text
            style={{
              paddingBottom: "5px",
              fontFamily: "Frutiger_Ultra_bold",
            }}
          >
            A. Conditions
          </Text>
          {renderIndexedContent(
            appendix?.conditions || [],
            "pdf",
            application.model
          )}
        </View>

        {/* --------------------------------------------------------SECTION B------------------------------------------------------------ */}

        <View break>
          <Text style={{ fontFamily: "Frutiger_Ultra_bold" }}>
            B. General Operating Parameters and Characteristics
          </Text>

          {application?.model.map((model, i) => {
            return (
              <View key={i} style={{ marginRight: "-45px" }}>
                <PartBTable
                  application={application}
                  appendix={appendix}
                  model={model}
                  manu_code={"A"} //placeholder, this might be incorrect and there will be user input
                  certificates={certificates}
                  simpleDisplay={simpleDisplay}
                />
              </View>
            );
          })}
          {appendix?.remarks?.length > 0 && (
            <View>
              <Text style={{ fontFamily: "Frutiger_bold", fontSize: "11px" }}>
                Remarks
              </Text>
              {renderIndexedContent(
                appendix?.remarks || [],
                "pdf",
                application.model
              )}
            </View>
          )}
          <Text
            style={{
              paddingTop: "20px",
              textDecoration: "underline",
              fontFamily: "Frutiger_bold",
              fontSize: "11px",
            }}
          >
            Manufacturer Code
          </Text>

          {appendix.manufacturer_code?.map((manufacturer, i) => {
            return (
              <View
                style={{
                  paddingTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
                key={i}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Frutiger_bold",
                      fontSize: "11px",
                    }}
                  >
                    {String.fromCharCode(65 + i)}.{" "}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Frutiger_bold",
                      fontSize: "11px",
                      ...renderChinese(manufacturer.name),
                    }}
                  >
                    {manufacturer.name}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Frutiger_bold",
                      fontSize: "11px",
                      margin: "0px 3px",
                    }}
                  >
                    -
                  </Text>
                  <Text style={renderChinese(manufacturer.name)}>
                    {manufacturer.address}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>


        {appendix?.manufacturer_code.length === 0 && model_manufacturer?.map((manufacturer, i) => {
          const iso_manufacturer_address = simpleDisplay(
            manufacturer.iso_certificate_id,
            "iso_manufacturer_address"
          );
          return (
            <View
              style={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
              key={i}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Frutiger_bold",
                    fontSize: "11px",
                  }}
                >
                  {String.fromCharCode(65 + i)}.{" "}
                </Text>
                <Text
                  style={{
                    fontFamily: "Frutiger_bold",
                    fontSize: "11px",
                    ...renderChinese(manufacturer.provided_name),
                  }}
                >
                  {manufacturer?.short_name || manufacturer?.provided_name}
                </Text>
                <Text
                  style={{
                    fontFamily: "Frutiger_bold",
                    fontSize: "11px",
                    margin: "0px 3px",
                  }}
                >
                  -
                </Text>
                <Text style={renderChinese(iso_manufacturer_address)}>
                  {iso_manufacturer_address}
                </Text>
              </View>
            </View>
          )
        })
        }


        {/* --------------------------------------------------------SECTION C------------------------------------------------------------ */}

        <View style={{ pageBreakBefore: "always" }} break>
          <Text style={{ fontFamily: "Frutiger_Ultra_bold" }}>
            C. List of Applicable Type Examination Certificates
          </Text>
          {appendix?.remarks2?.length > 0 && (
            <View style={{ marginTop: "25px" }}>
              <Text style={{ fontFamily: "Frutiger_bold", fontSize: "11px" }}>
                Remarks
              </Text>
              {renderIndexedContent(
                appendix?.remarks2 || [],
                "pdf",
                application.model
              )}
            </View>
          )}

          {application?.model.map((model, i) => {
            return (
              <View
                key={`${model._id}-C`}
                style={{ boxShadow: "unset !important" }}
              >
                <PartCModelTable
                  appendix={appendix}
                  application={application}
                  model={model}
                  manu_code={"A"} //placeholder, this might be incorrect and there will be user input
                  romanize={romanize}
                  index={i}
                  certificates={certificates}
                  simpleDisplay={simpleDisplay}
                />
              </View>
            );
          })}

          {Object.keys(application.app_type?.components || {})
            .filter((component_type) => {
              if (["TractionMachine", "Controller"].includes(component_type)) return false;
              return (
                application.app_type?.components[component_type]?.length > 0
              );
            })
            .sort((a, b) => {
              const order = [
                "Buffer",
                "OverspeedGovernor",
                "LandingDoorLockingDevice",
                "CarDoorLockingDevice",
                "SafetyGear",
                "ACOP",
                "UCMP",
                "SafetyCircuitElectricalComponent",
              ];
              function customSort(item) {
                const index = order.indexOf(item);
                return index !== -1 ? index : order.length;
              }
              return customSort(a) - customSort(b);
            })
            .map((component_type, j) => {
              return (
                <View
                  key={`${component_type}-C`}
                  style={{ boxShadow: "unset !important" }}
                >
                  <PartCCompTable
                    appendix={appendix}
                    application={application}
                    component_type={component_type}
                    romanize={romanize}
                    index={j}
                    manufacturers={application?.manufacturer}
                    liftModels={application.model}
                    components={application?.app_type?.components}
                    certificates={certificates}
                    simpleDisplay={simpleDisplay}
                  />
                </View>
              );
            })}
        </View>

        <PartD application={application} appendix={appendix} format="pdf" />

        <Text
          style={{
            position: "absolute",
            fontSize: 10,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />

      </Page>
    </Document>
  );
};

export default Appendix;
