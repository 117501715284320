import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  InputLabel,
  Pagination,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Button
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { changeRcsPage, changeRcsItemsPerPage } from '../../redux/revoke/revokeSlice';
import { useTranslation } from 'react-i18next';


const SelectRC = ({ handleSelection, setUpload, upload }) => {
  const { t : itranslate } = useTranslation();
  const { rcs, currentPage, itemsPerPage, totalItems } = useSelector((state) => state.revoke.rcs);

  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [searchQueryResult, setSearchQueryResult] = useState('');

  const [selectedRC, setSelectedRC] = useState(upload?.rc || '');

  const totalRCs = useRef(totalItems);
  const [totalPages, setTotalPages] = useState(Math.ceil(totalRCs.current / itemsPerPage));

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  let allRCs = searchQueryResult !== "" ? searchQueryResult : rcs;
  let sortedRCs = allRCs && [...allRCs].sort((a, b) => a.name.localeCompare(b.name));  // sort by name (rc code)
  const paginatedRCs = sortedRCs?.slice(startIndex, endIndex);


  useEffect(() => {
    setTotalPages(Math.ceil(totalRCs.current / itemsPerPage));
  }, [totalRCs, itemsPerPage]);


  useEffect(() => {
    setSelectedRC(upload.rc_number);
  }, [upload.rc_number]);


  useEffect(() => {
    dispatch(changeRcsPage(1));

    if (searchQueryResult.length === 0) {
      totalRCs.current = totalItems;
    } else if (searchQueryResult.length > 0 && searchQueryResult !== "") {
      totalRCs.current = searchQueryResult.length;
    } else {
      totalRCs.current = 0;
    }

    setTotalPages(Math.ceil(totalRCs.current / itemsPerPage)); // Update totalPages
  }, [searchQueryResult, totalItems, itemsPerPage]);


  const handleChange = useCallback((id) => {
    setUpload((prevState) => ({
      ...prevState,
      rc: id,
    }));
    setSelectedRC(id);
    handleSelection(id);
  }, [setUpload, handleSelection]);


  const handlePageChange = (e, page) => {
    dispatch(changeRcsPage(page));
  };


  const handleItemsPerPageChange = (newItemsPerPage) => {
    dispatch(changeRcsItemsPerPage(newItemsPerPage));
  };


  const handleSearchChange = () => {
    sortedRCs = rcs; // reset to all RCs when the user presses "enter" to search
  
    if (searchValue.trim() === '') {
      setSearchQueryResult(''); // Set the search query result to an empty string
    } else {
      let filteredRCs = sortedRCs.filter((rc) => {
        const { name, address, rc_number_lift, rc_number_escalator } = rc;
        const company = address?.company;
        return (
          (name && name.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (company && company.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (rc_number_lift && rc_number_lift.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (rc_number_escalator && rc_number_escalator.toLowerCase().includes(searchValue.toLowerCase().trim()))
        );
      });
  
      setSearchQueryResult(filteredRCs);
    }
  };


  const handleClearSearch = () => {
    setSearchValue(''); // Clear the search field value
  };

  return (
    <>
      <InputLabel sx={{ mt: 4 }} id="rc_number">
        {/* Select RC */}
        {itranslate("Select RC")}
      </InputLabel>

      <TextField
        sx={{ flex: 1, width: "40%" }}
        variant="filled"
        className="searchBox"
        // label="Search"
        label="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                sx={{
                  cursor: "pointer",
                  color: "#051B44",
                  fontSize: ".7em",
                  textTransform: "none",
                }}
                onClick={handleClearSearch}
              >
                {/* Clear */}
                {itranslate("Clear")}
              </Button>
              <Button
                onClick={handleSearchChange}
                aria-label="search"
                variant="contained"
                sx={{
                  backgroundColor: "#2A598F",
                  textTransform: "none",
                  '&:hover': {
                    backgroundColor: "#203C5B",
                  },
                  fontSize: ".7em",
                }}
              >
                {/* Search */}
                {itranslate("Search")}
              </Button>
            </InputAdornment>
          ),
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearchChange();
          }
      }}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{width: "12%"}}> {itranslate("RC Code")} </TableCell>
              <TableCell sx={{width: "50%"}}> {itranslate("Company")} </TableCell>
              <TableCell sx={{width: "20%"}}> {itranslate("RC Number (Lift)")} </TableCell>
              <TableCell sx={{width: "20%"}}> {itranslate("RC Number (Escalator)")} </TableCell>
              <TableCell sx={{width: "8%"}}> {itranslate("Select")} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRCs.map((rc) => (
              <TableRow key={rc.id}>
                <TableCell>{rc?.name}</TableCell>
                <TableCell>{rc?.address?.company}</TableCell>
                <TableCell>{rc?.rc_number_lift}</TableCell>
                <TableCell>{rc?.rc_number_escalator}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={selectedRC === rc.id}
                    onChange={() => handleChange(rc.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <p style={{ marginRight: "10px" }}> {itranslate("Rows per page:")} </p>
          <Select
            variant='outlined'
            id="select-items-per-page"
            value={itemsPerPage}
            onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
            sx={{ minWidth: '40px', height: '30px'}}
          >
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="20">20</MenuItem>              
          </Select>
        </div>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />

      </div>
    </>
  );
};

export default SelectRC;