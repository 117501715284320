import React, { useState } from "react";
import {
  Popper,
  IconButton,
  Paper,
  Typography,
  ClickAwayListener,
  Box,
  Button,
  Slider,
} from "@mui/material";
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { useTranslation } from 'react-i18next';


const HighlightInput = (props) => {
  const { certificates, setCertificates, cert, field, application, userInfo, userRoles } = props;

  const { t : itranslate } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSliderChange = (value, certificateId, field, coordinate) => {
    setCertificates(
      certificates.map((c) => {
        if (c.id === certificateId) {
          return {
            ...c,
            position_data: cert.position_data.map((highlight) => {
              const newHighlight = { ...highlight };
              if (newHighlight.comment.text === field) {
                newHighlight.position = {
                  ...newHighlight.position,
                  boundingRect: {
                    ...newHighlight.position.boundingRect,
                    [coordinate]: value,
                  },
                };
              }
              return newHighlight;
            }),
          };
        } else {
          // No changes
          return c;
        }
      })
    );
  };

  const highlight = cert.position_data.find(
    (highlight) => highlight.comment.text === field
  )?.position;

  if (!highlight) return null;

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role""
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <IconButton
          size="small"
          onClick={handleToggle}
          aria-describedby={anchorEl ? "highlightInput-popper" : undefined}
          disabled={isDisabled}
        >
          <EditLocationAltIcon />
          <Typography>
            {/* Edit Position */}
            {itranslate("Edit Position")}
          </Typography>
        </IconButton>
        <Popper
          sx={{ zIndex: 2 }}
          id="mouse-enter-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="right-start"
          disablePortal
        >
          {({ TransitionProps }) => (
            <Paper sx={{ p: 3, width: "800px" }}>
              {" "}
              <Box>
                <Typography>
                  {/* Left */}
                  {itranslate("Left")}
                </Typography>
                <Slider
                  value={highlight.boundingRect?.x1}
                  min={0}
                  max={highlight.boundingRect?.width}
                  onChange={(e, newValue) =>
                    handleSliderChange(newValue, cert.id, field, "x1")
                  }
                />
              </Box>
              <Box>
                <Typography>
                  {/* Right */}
                  {itranslate("Right")}
                </Typography>
                <Slider
                  value={highlight.boundingRect?.x2}
                  min={0}
                  max={highlight.boundingRect?.width}
                  onChange={(e, newValue) =>
                    handleSliderChange(newValue, cert.id, field, "x2")
                  }
                />
              </Box>
              <Box>
                <Typography>
                  {/* Top */}
                  {itranslate("Top")}
                </Typography>
                <Slider
                  value={highlight.boundingRect?.y1}
                  min={0}
                  max={highlight.boundingRect?.height}
                  onChange={(e, newValue) =>
                    handleSliderChange(newValue, cert.id, field, "y1")
                  }
                />
              </Box>
              <Box>
                <Typography>
                  {/* Bottom */}
                  {itranslate("Bottom")}
                </Typography>
                <Slider
                  value={highlight.boundingRect?.y2}
                  min={0}
                  max={highlight.boundingRect?.height}
                  onChange={(e, newValue) =>
                    handleSliderChange(newValue, cert.id, field, "y2")
                  }
                />
              </Box>
            </Paper>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default HighlightInput;
