import React, { useState } from "react";
import { Button, List, ListItem, ListItemText, Divider, Box } from "@mui/material";
import { useTranslation } from 'react-i18next';


const DerivedHideModels = ({ appendix, setAppendix, certificates, setHideModelCerts }) => {
  const { t : itranslate } = useTranslation();
  const [hideAll, setHideAll] = useState(false);


  const handleHide = (id) => {
    let section = structuredClone(appendix["hideCerts"]);
    if (!section.includes(id)) section.push(id);
    setAppendix({ ...appendix, hideCerts: section });
  };

  const handleUnhide = (id) => {
    let section = structuredClone(appendix["hideCerts"]);
    section = section.filter((cert) => cert !== id);
    setAppendix({ ...appendix, hideCerts: section });
  };



  const handleHideAll = () => {
    const allIds = certificates
      .filter((cert) => cert?.type === "ModelTypeCert")
      .map((cert) => cert?.id);
    setAppendix({ ...appendix, hideCerts: allIds });
    setHideAll(true);
  };

  const handleUnhideAll = () => {
    setAppendix({ ...appendix, hideCerts: [] });
    setHideAll(false);
  };


  if (!certificates || !appendix) return null;

  return (
    <>
    <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
      <Button onClick={hideAll ? handleUnhideAll : handleHideAll}>
        {hideAll ? itranslate("Unhide All Models") : itranslate("Hide All Models")}
      </Button> 
    </Box>
    
    <List>
      {certificates
        .filter((cert) => cert?.type === "ModelTypeCert")
        .sort((a, b) => a.reference - b.reference)
        .map((cert, i) => {
          const isHidden = appendix["hideCerts"].includes(cert?.id);
     
          return (
            <React.Fragment key={cert?.id}>
              <ListItem
                onMouseEnter={() => setHideModelCerts(cert?.id)}
                onMouseLeave={() => setHideModelCerts(null)}
                secondaryAction={
                  <Button
                    sx={{ marginRight: "7px" }}
                    onClick={() =>
                      isHidden ? handleUnhide(cert?.id) : handleHide(cert?.id)
                    }
                  >
                    {isHidden ? itranslate("Unhide") : itranslate("Hide")}
                  </Button>
                }
              >
                <ListItemText
                  sx={{ maxWidth: "500px" }}
                  primary={
                    cert?.cert_corrections?.tec_certificate_number ||
                    cert?.cert_type_metadata?.tec_certificate_number ||
                    cert?.cert_corrections?.tec_certificate_number ||
                    cert?.cert_type_metadata?.tec_certificate_number
                  }
                  // secondary={
                  //   cert.cert_corrections?.tec_issue_date ||
                  //   cert.cert_type_metadata?.tec_issue_date
                  // }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
    </List>
    </>
  );
};

export default DerivedHideModels;
