import axios from "axios";
import { useState } from "react";
import {
  TextField,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import getToken from "../../utils/getAuthToken";
import { useNotify } from "react-admin";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function SaveDraft(props) {
  const location = useLocation();
  const notify = useNotify();
  const {
    draftType,
    upload,
    setUpload,
    update,
    optional,
    isValidated,
    currentDraft,
    setCurrentDraft,
  } = props;
  const { t : itranslate } = useTranslation()
  const [draftTitleModal, setDraftTitleModal] = useState(false);
  const [draftTitle, setDraftTitle] = useState("draft");
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async (mode, title) => {
    if (isSaving) return;
    //2 mode : saveAs / save
    let draft = upload;
    draft.optional = optional;

    if (draftType === "update") {
      draft.update = update;
      const update_type = location.pathname
        .split("/")
        .find((item) => item.includes("applications_"));
      draft.update_type = update_type.split("_")[1];
    }

    draft.title = title ? title : draftTitle;
    try {
      setIsSaving(true);
      setDraftTitleModal(false); //close the modal early to prevent spam
      const res = await axios.post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/app_preSubmit?type=${draftType}&mode=${mode}`,
        draft,
        { headers: { authorization: getToken() } }
      );
      setCurrentDraft([res.data._id, res.data.title]);
      setUpload(res.data);
      notify(itranslate(`Successfully saved to draft`));
    } catch (err) {
      notify(itranslate(err.response.data.message));
      console.log(err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      {isSaving ? (
        <CircularProgress />
      ) : (
        <ButtonGroup color="error" variant="outlined">
          <Button
            onClick={() => {
              if (currentDraft.length > 0) handleSave("save", currentDraft[1]);
              else setDraftTitleModal(true);
            }}
          >
            {/* Save */}
            {itranslate("Save")}
          </Button>
          {!isValidated && (
            <Button
              disabled={isValidated}
              onClick={() => setDraftTitleModal(true)}
            >
              {/* Save As */}
              {itranslate("Save As")}
            </Button>
          )}
        </ButtonGroup>
      )}

      <Typography sx={{ pl: 1 }}>
        {isValidated ? itranslate("Validated application") : itranslate("Current Draft")}:{" "}
        {currentDraft.length !== 0 ? currentDraft[1] : itranslate("none")}
      </Typography>

      <Dialog
        open={draftTitleModal}
        onClose={() => setDraftTitleModal(false)}
        sx={{ textAlign: "center" }}
        aria-labelledby="picture-modal-title"
        aria-describedby="picture-modal-description"
      >
        <DialogTitle>
          {itranslate("Name your draft")}
        </DialogTitle>
        <DialogContent>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            defaultValue="draft"
            variant="filled"
            size="small"
            inputProps={{ maxLength: 20 }}
            onChange={(e) => setDraftTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            disabled={isSaving || !draftTitleModal}
            color="error"
            variant="outlined"
            onClick={() => handleSave("saveAs")}
          >
            {/* Save */}
            {itranslate("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SaveDraft;
