import jwtDecode from "jwt-decode";
import axios from "axios";
import getToken from "./getAuthToken";

// Internal Imports
import { setUserInfo } from "../redux/userInfoSlice";
import store from "../redux/store";

const path = window.location.pathname;
const portal = path === "/lift_rc" ? "rc" : path === "/lift_emsd" ? "emsd" : "";

const authProvider = {
  signup: ({ data, navigate }) => {
    return axios
      .post(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/auth/activate`, data, {
        headers: {
          "Content-Type": "application/json",
          portal: portal,
        },
      })
      .then((auth) => {
        const decodedUserInfo = jwtDecode(JSON.stringify(auth));
        store.dispatch(setUserInfo(decodedUserInfo));
        localStorage.setItem(
          `tapas_lift_${portal}_token`,
          JSON.stringify(auth.data)
        );
        navigate("/");
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  },

  login: ({ data }) => {
    return axios
      .post(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/auth/login`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            portal: portal,
          },
        }
      )
      .then((auth) => {
        const decodedUserInfo = jwtDecode(JSON.stringify(auth));
        store.dispatch(setUserInfo(decodedUserInfo));
        localStorage.setItem(
          `tapas_lift_${portal}_token`,
          JSON.stringify(auth.data)
        );
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  },

  logout: async () => {
    const path = window.location.pathname;
    const portal =
      path === "/lift_rc" ? "rc" : path === "/lift_emsd" ? "emsd" : "";

    const token =  getToken();
    if (token) {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/users/logout`,
          {},
          {
            headers: {
              authorization: getToken(),
            },
          }
        );
        if (data) localStorage.removeItem(`tapas_lift_${portal}_token`);
      } catch (err) {
        console.log(err);
      }
    }  

    // localStorage.removeItem(`tapas_lift_${portal}_token`);
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.response?.status;
    const path = window.location.pathname;
    const portal =
      path === "/lift_rc" ? "rc" : path === "/lift_emsd" ? "emsd" : "";
    if (status === 403) {
      localStorage.removeItem(`tapas_lift_${portal}_token`);
      return Promise.reject();
    } else if (status === 401) {
      localStorage.removeItem(`tapas_lift_${portal}_token`);
      return Promise.reject({
        message: "Your session has expired. Please log in again.",
      });
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const path = window.location.pathname;
    const portal =
      path === "/lift_rc" ? "rc" : path === "/lift_emsd" ? "emsd" : "";
    const token = localStorage.getItem(`tapas_lift_${portal}_token`);
    if (!token) return Promise.reject();

    let decodedUserInfo = jwtDecode(token);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedUserInfo.exp * 1000 < currentDate.getTime()) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  },
  getPermissions: () => {
    const path = window.location.pathname;
    const portal =
      path === "/lift_rc" ? "rc" : path === "/lift_emsd" ? "emsd" : "";
    return localStorage.getItem(`tapas_lift_${portal}_token`)
      ? Promise.resolve(true)
      : Promise.resolve(false);
  },
  /*   getIdentity: () => {
	try {
		const token = localStorage.getItem('auth');
		console.log(token)
		console.log((JSON.parse(atob(token.split('.')[1]))));
		return Promise.resolve({ });
	} catch (error) {
		return Promise.reject(error);
	}
} */
};

export default authProvider;
