import useSWR from 'swr'
import axios from 'axios'

import fetcher from '../../services/fetcher'


export default function useVerify(){
    const verifyAppNoHook = (appNoOrDaNo, encryptedDaNo) => (new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/apps/verifyAppNo`,
            data: {appNoOrDaNo, encryptedDaNo}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    }))

    const verifyModelNameHook = (modelName, appNo, encryptedDaNo) => (new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/models/verifyModelName`,
            data: {modelName, appNo, encryptedDaNo}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    }))

    const verifyManuHook = (modalName, appNo, encryptedDaNo, manu) => (new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/manufacturers/verifyManu`,
            data: {modalName, appNo, encryptedDaNo, manu}
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    }))

    return {
        verifyAppNoHook,
        verifyModelNameHook,
        verifyManuHook
    }
}