import { pdf } from "@react-pdf/renderer";

async function getBase64(document) {
  try {
    const blob = await pdf(document).toBlob();
    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    }
    const base64data = await blobToBase64(blob);
    return base64data.split(",")[1];
  } catch (e) {
    console.log(e);
  }
}

export default getBase64;
