import React from "react";
import keyToField from "../../../utils/keyToField";
import parseAppendixJson from "../../../utils/pdf/appendix_format_parser";
import { romanize, alphabet } from "../../../utils/romanize";
import "../../../styles/derivedAppendix/DerivedAppendixSC.css";
import reactStringReplace from "react-string-replace";
import { fixInvalidDates } from "../../../utils/misc/fixInvalidDates";
import {
  renderContent,
  renderIndexedContent,
} from "../../../utils/pdf/renderContent";
import _ from "lodash";

const displayedCertData = {
  ACOP: [
    "Manufacturer",
    "Model",
    "tec_car_load", 
    "tec_balancing_factor",
    "tec_rated_load",
    "tec_rated_speed",
    "tec_tripping_speed",
    "tec_rope_factor",
    "tec_certificate_number",
    "Approval Reference"
  ],
  Buffer: [
    "Manufacturer",
    "Model",
    "tec_total_mass",
    "tec_impact_speed",
    "tec_certificate_number",
    "Approval Reference"
  ],
  CarDoorLockingDevice: [
    "Manufacturer",
    "Model",
    "tec_door_type",
    "tec_contact_rating",
    "tec_certificate_number",
    "Approval Reference"
  ],
  LandingDoorLockingDevice: [
    "Manufacturer",
    "Model",
    "tec_door_type",
    "tec_contact_rating",
    "tec_certificate_number",
    "Approval Reference"
  ],
  OverspeedGovernor: [
    "Manufacturer",
    "Model",
    "tec_rated_speed",
    "tec_tripping_speed",
    "tec_certificate_number",
    "Approval Reference"
  ],
  Pallet: [
    "Manufacturer",
    "Model",
    "tec_rated_load",
    "tec_rated_speed",
    "tec_certificate_number",
    "Approval Reference"
  ],
  SafetyCircuitElectricalComponent: [
    "Manufacturer",
    "Model",
    "tec_circuit_description",
    "tec_certificate_number",
    "Approval Reference"
  ],
  SafetyGear: [
    "Manufacturer",
    "Model",
    "tec_total_mass",
    "tec_rated_speed",
    "tec_tripping_speed",
    "tec_certificate_number",
    "Approval Reference"
  ],
  Step: [
    "Manufacturer",
    "Model",
    "tec_rated_load",
    "tec_rated_speed",
    "tec_certificate_number",
    "Approval Reference"
  ],
  UCMP: [
    "Manufacturer",
    "Model",
    "tec_car_load",
    "tec_balancing_factor",
    "tec_rated_load",
    "tec_rated_speed",
    "tec_tripping_speed",
    "tec_rope_factor",
    "tec_certificate_number",
    "Approval Reference"
  ],
  TractionMachine: [
    "Manufacturer",
    "Model",
    "tec_rated_load",
    "tec_rated_speed",
    "tec_certificate_number",
    "Approval Reference"
  ],
  Controller: [
    "Manufacturer",
    "Model",
    "tec_rated_load",
    "tec_rated_speed",
    "tec_certificate_number",
    "Approval Reference"
  ],
};

  // for width of each cell in a row
  const tableFields = {
    ACOP: {
      Manufacturer: 10,
      Model: 8.571,
      tec_car_load: 8.571,
      tec_balancing_factor: 8.571,
      tec_rated_load: 8.571,
      tec_rated_speed: 8.571,
      tec_tripping_speed: 8.571,
      tec_rope_factor: 8.571,
      tec_certificate_number: 15,
      "Approval Reference": 15,
    },
    Buffer: {
      Manufacturer: 20,
      Model: 10,
      tec_total_mass: 15,
      tec_impact_speed: 20,
      tec_certificate_number: 15,
      "Approval Reference": 20,
    },
    CarDoorLockingDevice: {
      Manufacturer: 15,
      Model: 12.5,
      tec_door_type: 25,
      tec_contact_rating: 15,
      tec_certificate_number: 20,
      "Approval Reference": 12.5,
    },
    LandingDoorLockingDevice: {
      Manufacturer: 20,
      Model: 10,
      tec_door_type: 20,
      tec_contact_rating: 10,
      tec_certificate_number: 20,
      "Approval Reference": 20,
    },
    OverspeedGovernor: {
      Manufacturer: 23,
      Model: 8,
      tec_rated_speed: 13,
      tec_tripping_speed: 13,
      tec_certificate_number: 20,
      "Approval Reference": 23,
    },
    Pallet: {
      Manufacturer: 23,
      Model: 8,
      tec_rated_load: 13,
      tec_rated_speed: 13,
      tec_certificate_number: 20,
      "Approval Reference": 23,
    },
    SafetyCircuitElectricalComponent: {
      Manufacturer: 27,
      Model: 8,
      tec_circuit_description: 29,
      tec_certificate_number: 18,
      "Approval Reference": 18,
    },
    SafetyGear: {
      Manufacturer: 20,
      Model: 15,
      tec_total_mass: 15,
      tec_rated_speed: 15,
      tec_tripping_speed: 15,
      tec_certificate_number: 15,
      "Approval Reference": 20,
    },
    Step: {
      Manufacturer: 23,
      Model: 8,
      tec_rated_load: 13,
      tec_rated_speed: 13,
      tec_certificate_number: 20,
      "Approval Reference": 23,
    },
    UCMP: {
      Manufacturer: 10,
      Model: 8.571,
      tec_car_load: 8.571,
      tec_balancing_factor: 8.571,
      tec_rated_load: 8.571,
      tec_rated_speed: 8.571,
      tec_tripping_speed: 8.571,
      tec_rope_factor: 8.571,
      tec_certificate_number: 15,
      "Approval Reference": 15,
    },
    TractionMachine: {
      Manufacturer: 23,
      Model: 8,
      tec_rated_load: 13,
      tec_rated_speed: 13,
      tec_certificate_number: 20,
      "Approval Reference": 23,
    },
    Controller: {
      Manufacturer: 23,
      Model: 8,
      tec_rated_load: 13,
      tec_rated_speed: 13,
      tec_certificate_number: 20,
      "Approval Reference": 23,
    },
  };


const order = [
  "Buffer",
  "OverspeedGovernor",
  "LandingDoorLockingDevice",
  "CarDoorLockingDevice",
  "SafetyGear",
  "ACOP",
  "UCMP",
  "SafetyCircuitElectricalComponent",
];

function customSort(item) {
  const index = order.indexOf(item);
  return index !== -1 ? index : order.length;
}


function DerivedAppendixForSChtml({ application, appendix, certificates, standalones, compLevel}) {
// console.log("🚀 ~ DerivedAppendixForSChtml ~ standalones:", standalones)

  if (!application || !appendix || !standalones || !certificates) return null;

  const componentTypes = _.uniq([
    ...Object.keys(application.app_type?.components || {}),
    ...standalones.components.map((c) => c.type),
  ]);

  const comps = componentTypes.reduce((acc, type) => {
    const historicalComp = standalones.components.filter(
      (comp) => comp.type === type
    );
    const newComp = application.app_type?.components[type] || [];
    acc[type] = [...historicalComp, ...newComp];
    return acc;
  }, {});
  // console.log("🚀 ~ comps ~ comps:", comps)

  const combinedCertificates = standalones.certificates.concat(certificates);

  const combinedManufacturers = standalones.manufacturers.concat(application.manufacturer);
  // console.log("🚀 ~ DerivedAppendixForSChtml ~ combinedManufacturers:", combinedManufacturers)


  const simpleDisplay = (cert_id, field) => {
    const matchCert = combinedCertificates?.find(cert => (cert?._id || cert?.id) === cert_id);
    // console.log("🚀 ~ simpleDisplay ~ matchCert:", matchCert)
    if(matchCert._id === '6621e60022ddb711efb295f4') {
      console.log("🚀 ~ simpleDisplay ~ matchCert:", matchCert)
    }
    
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field] ||
      ""
    );
  };


  const simpleDisplay2 = (cert_id, field) => {
    const matchCert = combinedCertificates?.find(cert => (cert?._id || cert?.id) === cert_id);
    
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field] ||
      ""
    );
  };



  function getCertList(component, component_type) {
    const hideSCCerts = appendix.hideSCCerts;
    if (!hideSCCerts || hideSCCerts.length === 0) {
      return true; // Return true when hideSCCerts is undefined or empty
    }
  
    const isHidden = hideSCCerts.some(
      (item) =>
        item.cert_id === component._id && item.component_type === component_type
    );
  
    return !isHidden;
  }

  
  function getCompListCount(comps, component_type) {
    if (!component_type) {
      return 0; // Return 0 when component_type is undefined
    }
  
    const components = comps?.[component_type].filter((component) => {
      const list = getCertList(component, component_type);
      return list;
    });
  
    return components ? components.length : 0;
  }
  


  return (
    <div className="dappendixSC">
      <div>
        <div
          className="text-center py-[23px]"
        >
          <p className="bold header">Technical Specifications for</p>
          <p className="bold header">Various Safety Components</p>
        </div>

        <p className="bold">
          Registered Lift Contractor:{" "}
          <span className="bold underline">
            {application.rc_id.rc_number_lift || application.rc_id.name}
          </span>
        </p>

        <p className="bold py-[18px]">
          Specifications and Conditions
        </p>
        <p className="lightBold">Safety Components Specifications:</p>
  

        {componentTypes
          .sort((a, b) => customSort(a) - customSort(b))
          .map((type, i) => {
          // console.log("🚀 ~ .map ~ type:", type)

          function getCompList(manu) {
            return comps[type].filter((comp) => comp.manufacturer_id === manu._id);
          }

          //some mnaufacturers has no component, don't render them
          const manufacturers = combinedManufacturers.filter((manu) => getCompList(manu).length > 0);
          // console.log("🚀 ~ .map ~ manufacturers:", manufacturers)

          // remove the duplicate manufacturers
          // if duplicates exist, remove the one with the latest approval date (if approval date exists)
          const uniqueManufacturers = Array.from(new Set(manufacturers.map(obj => obj._id))).map(_id => {
            const objectsWithId = manufacturers.filter(obj => obj._id === _id);
            const sortedByDate = objectsWithId.sort((a, b) => {
              const dateA = a.approval_date ? new Date(a.approval_date) : new Date(0);
              const dateB = b.approval_date ? new Date(b.approval_date) : new Date(0);
              return dateB - dateA;
            });
            return sortedByDate[0];
          });
          // console.log("🚀 ~ uniqueManufacturers ~ uniqueManufacturers:", uniqueManufacturers)



          // let uniqueComps = Array.from(
          //   new Set(comps[type].map((item) => item.type_test_certificate_id[0]))
          // ).map((typeTestId) =>
          //   comps[type].find((item) => item.type_test_certificate_id[0] === typeTestId)
          // );
          // console.log("🚀 ~ .map ~ uniqueComps:", uniqueComps)


          
          return (
            <table key={type}>
              
              {
                (getCompListCount(comps, type)) > 0 && (
                  <thead>
                <tr>
                  <th colSpan="100%" className="tableHeader">
                    ({romanize(i + 1).toLowerCase()}){"       "}
                    {keyToField[type]}
                  </th>
                </tr>
                <tr>
                  {displayedCertData[type].map((field) => (
                    <th key={field}>
                      {field === "Model"
                        ? "Model"
                        : field === "tec_certificate_number"
                        ? "Type Exam. Cert. No."
                        : keyToField[field] ?? field}
                    </th>
                  ))}
                </tr>
              </thead>
                )
              }

              <tbody>
                {uniqueManufacturers.map((manu, manuIndex) => {
                  const matchCert = combinedCertificates.find((cert) => (cert?._id || cert?.id) === manu.iso_certificate_id);
          
                  let list = getCompList(manu);
          
                  let uniqueComponents = Array.from(
                    new Set(list.map((item) => item?.type_test_certificate_id[0]))
                  ).map((typeTestId) =>
                    list.find((item) => item?.type_test_certificate_id[0] === typeTestId)
                  );
          
                  let filteredUniqueComponents = uniqueComponents.filter((comp) =>
                    !appendix.hideSCCerts?.some((item) =>item.cert_id === comp._id && item.component_type === type)
                  );
          
                  return (
                    <React.Fragment key={`manu-${manuIndex}`}>
                      {filteredUniqueComponents?.map((comp, j) => (

                        <React.Fragment key={`comp-${manuIndex}-${j}`}>
                          {comp.type_test_certificate_id.map((cert, compIndex) => (
                            <tr key={`cert-${manuIndex}-${j}-${compIndex}`}>
                              {j === 0 && compIndex === 0 && (
                                <td
                                  rowSpan={filteredUniqueComponents.reduce(
                                    (acc, comp) =>
                                      acc + comp.type_test_certificate_id.length,
                                    0
                                  )}
                                  className={`w-[${tableFields[type]["Manufacturer"]}%]`}
                                >
                                  {manu.short_name || manu.provided_name}
                                </td>
                              )}

                              {compIndex === 0 && (
                                <td
                                  rowSpan={comp.type_test_certificate_id.length}
                                  className={`w-[${tableFields[type]["Model"]}%]`}
                                  style={{
                                    ...(compLevel?.cert_id === comp?._id &&
                                      compLevel.component_type === type && { backgroundColor: "lightcyan"}),
                                  }}
                                >
                                  {comp.provided_comp_name}
                                </td>
                              )}

                              {Object.keys(displayedCertData[type])
                                .filter((field) => !["Manufacturer", "Model"].includes(displayedCertData[type][field]))
                                .map((field) => (
                                  <td
                                    key={`field-${field}`}
                                    className={`w-[${tableFields[type][displayedCertData[type][field]]}%]`}
                                    style={{ ...(compLevel?.cert_id === comp?._id && compLevel.component_type === type && {backgroundColor: "lightcyan" }) }}
                                  >

                                    {displayedCertData[type][field] === "Approval Reference" ? (
                                      <>
                                        {simpleDisplay(
                                          cert,
                                          displayedCertData[type][field]
                                        ) ? (
                                          reactStringReplace(
                                            simpleDisplay(
                                              cert,
                                              displayedCertData[type][field]
                                            ),
                                            "[Ƨ]",
                                            (match, i) => (
                                              <span
                                                key={`match-${i}`}
                                                style={{
                                                  fontFamily: "DejaVu Sans",
                                                  display: "inline",
                                                  border: "none",
                                                }}
                                              >
                                                {match}
                                              </span>
                                            )
                                          )
                                        ) : (
                                          `${application.application_number} dated ${
                                            (
                                              (application.approval_date &&
                                                fixInvalidDates(
                                                  (application.approval_date || "").replace(
                                                    /–/g,
                                                    "-"
                                                  )
                                                )) 
                                              //   ||
                                              // (application.updatedAt &&
                                              //   fixInvalidDates(
                                              //     (application.updatedAt || "").replace(
                                              //       /–/g,
                                              //       "-"
                                              //     )
                                              //   ))
                                            ).split("T")[0]
                                          }`
                                        )}
                                        {matchCert?.comply_standard?.lift && (
                                          <span
                                            style={{
                                              fontFamily: "DejaVu Sans",
                                            }}
                                          >
                                            {" [Ƨ]"}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      simpleDisplay(cert, displayedCertData[type][field]) ?
                                      simpleDisplay(cert, displayedCertData[type][field]) :
                                      "N/A"
                                    )}

{/* 
                                    {displayedCertData[type][field] === "Approval Reference" ? (
                                      <>
                                        {simpleDisplay(cert, displayedCertData[type][field]) ? (
                                          <>
                                            {simpleDisplay(cert, displayedCertData[type][field])}
                                          </>
                                        ) : (
                                          
                                              (application.approval_date &&
                                                fixInvalidDates(
                                                  (application.approval_date || "").replace(/–/g, "-")
                                                )) ||
                                              (application.updatedAt &&
                                                fixInvalidDates(
                                                  (application.updatedAt || "").replace(/–/g, "-")
                                                ))
                                            ).split("T")[0]
                                        }

                          

                                        {matchCert?.comply_standard?.lift && (
                                          <span style={{ fontFamily: "DejaVu Sans" }}>{" [Ƨ]"}</span>
                                        )}
                                      </>
                                    ) : displayedCertData[type][field] === "tec_certificate_number" ? (
                                      <>
                                        {simpleDisplay(cert, displayedCertData[type][field]) ? (
                                          <>
                                            {simpleDisplay(cert, displayedCertData[type][field])} issued on{" "} 
                                            {simpleDisplay(cert, "tec_issue_date") }
                                          </>
                                        ) : (
                                          "N/A"
                                        )}
                                      </>
                                    ) : (
                                      simpleDisplay(cert, displayedCertData[type][field]) ? (
                                        simpleDisplay(cert, displayedCertData[type][field]) 
                                      ) : (
                                        "N/A"
                                      )
                                    )} */}

                                  </td>
                                ))}
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          );

          
        })}

        {appendix?.remarks?.length > 0 && (
          <div 
            className="mb-2.5"
          >
            <p style={{ fontFamily: "Frutiger_light", fontWeight: "bold" }}>
              Remarks:
            </p>
            {renderIndexedContent(
              appendix?.remarks,
              "plainHtml",
              application.model,
              "scRemarks"
            )}
          </div>
        )}

        <div 
          className="mb-2.5"
        >
          <p
            style={{
              fontFamily: "Frutiger_light",
              fontWeight: "bold",
              marginTop: 10,
            }}
          >
            Conditions:
          </p>
          {renderIndexedContent(appendix?.conditions, "plainHtml", application.model)}
        </div>
      </div>


    </div>
  );
}




export default DerivedAppendixForSChtml;
