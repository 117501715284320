import {
  Button,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ModelInput from "./ModelInput";
import { useTranslation } from 'react-i18next';


const TableInput = (props) => {
  const {
    application,
    setUpload,
    appendix,
    deleteSection,
    setAppendix,
    models,
    setModels,
    userInfo,
    userRoles,
    setText,
  } = props;

  const { t : itranslate } = useTranslation();

  const handleText = (e, i, type) => {
    let section = structuredClone(appendix["table"]);
    if (type === "field" && section.some((row) => row.field === e.target.value))
      return;
    section[i][type] = e.target.value;
    setAppendix({ ...appendix, table: section });
  };

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role"
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))

  const handleSelect = (e, i) => {
    const selectedText = window.getSelection().toString();
    const text = {
      text: selectedText,
      range: [e.target.selectionStart, e.target.selectionEnd],
      index: i,
      subIndex: null,
      section: "table",
    };
    setText(text);
  };

  return (
    <Box>
      {application?.update_type === "Lift/escalator model range extension" && (
        <ModelInput
          models={models}
          setModels={setModels}
          upload={application}
          setUpload={setUpload}
          userInfo={userInfo}
          userRoles={userRoles}
        />
      )}
      <TableContainer sx={{ width: "100%" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="25%"> {itranslate("Field")} </TableCell>
              <TableCell width="70%"> {itranslate("Value")} </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appendix.table?.map((row, i) => (
              <TableRow key={i}>
                <TableCell>
                  <TextField
                    disabled={isDisabled} 
                    fullWidth
                    variant="outlined"
                    value={row.field}
                    onChange={(e) => handleText(e, i, "field")}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    disabled={isDisabled} 
                    fullWidth
                    variant="outlined"
                    value={row.value}
                    onSelect={(e) => handleSelect(e, i)}
                    onChange={(e) => handleText(e, i, "value")}
                  />
                </TableCell>
                <TableCell align="right">
                  <Button disabled={isDisabled} onClick={() => deleteSection(i, "table")}>
                    {/* Delete */}
                    {itranslate("Delete")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableInput;
