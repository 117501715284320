function handleDateConversion(date) {
    function dateWithoutTimezone(date) {
        const tzoffset = date?.getTimezoneOffset() * 60000;
        const withoutTimezone = new Date(date.valueOf() - tzoffset).toISOString()
        return withoutTimezone;
    };
    let expiry_date = dateWithoutTimezone(date);
    return expiry_date?.split("T")[0] + " " + expiry_date?.split("T")[1]?.split(".")[0]
}

export default handleDateConversion