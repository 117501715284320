import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  EditBase,
  useEditController,
  Title,
  useGetList,
  useGetOne,
  Loading,
} from "react-admin";
import {
  Box,
  Button,
  StepButton,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ManufacturerDisplay } from "../../components/manufacturer/ManufacturerDisplay";
import { ModelDisplay } from "../../components/model/ModelDisplay";
import { DocumentStack } from "../../components/document/DocumentStack";
import { ComponentDisplay } from "../../components/component/ComponentDisplay";
import * as static_fields from "../../utils/static_fields.json";
import variable_fields from "../../utils/variable_fields.json";
import isGetOneData from "../../utils/applications/isGetOneData";
import { v4 as uuidv4 } from "uuid";
import RecordAppendixEdit from "./RecordAppendixEdit";
import RecordUpdateCheck from "./RecordUpdateCheck";
import { useSelector, useDispatch } from "react-redux";
import { clearComponents } from "../../redux/componentsSlice";
import { useTranslation } from 'react-i18next';


const RecordEdit = (props) => {
  const { t : itranslate } = useTranslation();

  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useEditController({ resource: "applications" });

  const location = useLocation();
  const dispatch = useDispatch();
  const { mode } = props;
  const { id } = useParams();
  const [certificates, setCertificates] = useState(null);
  const chosenComponents = useSelector((state) => state.components.value);

  const { data: originalCertificates } = useGetList("certificates", {
    filter: {
      application: id,
    },
    pagination: { page: null, perPage: null },
  });


  // PerPage: 0 result in .limit(0) in backend, which returns all data instead of the default range (0-25)
  const { data: manufacturersLoad, isLoading: isLoadingManu } = useGetList(
    "manufacturers",
    {
      filter: {
        rc_id: record?.rc_id?._id || record?.rc_id,
      },
      pagination: { page: null, perPage: null },
    },
    { enabled: Boolean(record) }
  );


  const { data: modelsLoad, isLoading: isLoadingModel } = useGetList(
    "models",
    {
      filter: {
        rc_id: record?.rc_id?._id || record?.rc_id,
      },
      pagination: { page: null, perPage: null },
    },
    { enabled: Boolean(record) }
  );


  const { data: componentsLoad, isLoading: isLoadingComponent } = useGetList(
    "components",
    {
      filter: {
        rc_id: record?.rc_id?._id || record?.rc_id,
      },
      pagination: { page: null, perPage: null },
    },
    { enabled: Boolean(record) }
  );


  const { data: filenames } = useGetOne("files/filenames", { id: id });
  const [options, setOptions] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [optional, setOptional] = useState({});
  const [upload, setUpload] = useState(null);

  const [update, setUpdate] = useState({
    file_code: uuidv4(),
    manufacturer: [],
    model: [],
    app_type: {
      type: "",
      components: {},
    },
    other_documents: [],
    dialog: [],
  });


  const getPatchedUpload = (record) => {
    //patch the data from useGetOne to fix various bugs / limit the data to the standAlone SC/ load draft
    let updatedComponents = record.app_type.components;
    let updatedManufacturers = record.manufacturer;

    const query = new URLSearchParams(location.search);
    const manufacturerQuery = query.get("manufacturer");

    if (chosenComponents.length > 0) {
      // For updating standalone SC, we need to filter out the component that is not chosen
      const types = Array.from(
        new Set(chosenComponents.map((item) => item.type))
      );

      updatedComponents = types.reduce((acc, type) => {
        acc[type] = chosenComponents
          .filter((item) => item.type === type)
          .map((item) => {
            const { id, manufacturer, ...rest } = item;
            return {
              ...rest,
              _id: id,
              manufacturer_id: manufacturer,
            };
          });
        return acc;
      }, {});
      const manufacturers = chosenComponents.map((item) => {
        return { ...item.manufacturersPopulated[0] };
      });
      updatedManufacturers = _.uniqBy(manufacturers, "_id");
    } else if (mode === "f") {
      updatedComponents = Object.keys(
        variable_fields.app_type[record.app_type.type] || {}
      ).reduce((acc, compType) => {
        //For new application, we need to set the confirmed of component to true, and require user reconfirm if they upload changes
        if (record.app_type.components[compType]) {
          acc[compType] = record.app_type.components[compType].map((item) => {
            return {
              ...item,
              confirmed: true,
            };
          });
        } else {
          //We need empty array so that user can add new components
          acc[compType] = [];
        }
        return acc;
      }, {});
    }

    const updatedData = {
      ...record,
      app_type: {
        ...record.app_type,
        components: updatedComponents,
      },
      manufacturer: updatedManufacturers,
      ...(manufacturerQuery && { changedManufacturer: manufacturerQuery }),
    };

    return updatedData;
  };


  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.addEventListener("popstate", unloadCallback);
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);
  

  useEffect(() => {
    if (!upload && record && isGetOneData(record, "applications")) {
      const patchedUpload = getPatchedUpload(record);
      setUpload(_.cloneDeep(patchedUpload));
      dispatch(clearComponents()); //clean up when user make a new app for update cert for standaloneSC (don't put it in return as Strict mode will call the unmount immediately on mount)
    }
  }, [record]);


  useEffect(() => {
    if (componentsLoad) {
      setOptions(
        componentsLoad?.map((object) => {
          return {
            category: "Old",
            ...object,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentsLoad]);
  

  useEffect(() => {
    if (originalCertificates) {
      setCertificates(originalCertificates);
    }
  }, [originalCertificates]);


  //shown as edit.mode / edit.clarifying in XXXXXXDisplay.js
  const modeMap = {
    a: { mode: "a" },
    b: { mode: "b" },
    c: { mode: "c" },
    d: { mode: "d" },
    e: { mode: "e" },
    f: { mode: "f" },
    record: { clarifying: "record" },
  };

  const steps = [
    "Edit manufacturers",
    "Edit models",
    "Edit components",
    "Additional documents",
    "Confirmation",
  ];

  const stepDisplayMap = {
    a: steps.filter((s, i) => ![2, 3].includes(i)),
    b: steps.filter((s, i) => ![0, 2, 3].includes(i)),
    c: steps.filter((s, i) => ![0, 3].includes(i)),
    d: steps.filter((s, i) => ![0, 1, 3].includes(i)),
    e: steps.filter((s, i) => ![0, 1, 3].includes(i)),
    f: steps.filter((s, i) => ![3].includes(i)),
    record: steps,
  };

  if (!upload) return <Loading />;

  return (
    <EditBase {...props}>
      <Title title={itranslate("- Edit Record")} />
      <>
        <Stepper nonLinear activeStep={activeStep} sx={{ m: 5 }}>
          {stepDisplayMap[mode].map((step, index) => {
            return (
              <Step key={step}>
                <StepButton
                  color="inherit"
                  onClick={(e) => setActiveStep(index)}
                >
                  <StepLabel>{itranslate(step)}</StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>

        {stepDisplayMap[mode].map((step, index) => {
          const stepComponent = {
            "Edit manufacturers": (
              <ManufacturerDisplay
                show={false}
                edit={modeMap[mode]}
                add={false}
                title={"Edit Manufacturer(s)"}
                existing={manufacturersLoad || []}
                upload={upload}
                setUpload={setUpload}
                update={update}
                setUpdate={setUpdate}
                template={static_fields}
                variable={variable_fields}
                filenames={filenames}
              />
            ),
            "Edit models": (
              <ModelDisplay
                show={false}
                edit={modeMap[mode]}
                add={false}
                title={"Edit Model(s)"}
                existing={modelsLoad || []}
                upload={upload}
                setUpload={setUpload}
                update={update}
                setUpdate={setUpdate}
                template={static_fields}
                variable={variable_fields}
                filenames={filenames}
              />
            ),
            "Edit components": (
              <ComponentDisplay
                show={false}
                edit={modeMap[mode]}
                add={false}
                title={"Edit Safety Component(s)"}
                options={options}
                setOptions={setOptions}
                upload={upload}
                setUpload={setUpload}
                update={update}
                setUpdate={setUpdate}
                optional={optional}
                setOptional={setOptional}
                template={static_fields}
                variable={variable_fields}
                filenames={filenames}
              />
            ),
            "Additional documents": (
              <DocumentStack
                show={false}
                edit={modeMap[mode]}
                add={false}
                title={"Edit Additional Documents"}
                upload={upload}
                setUpload={setUpload}
                update={update}
                setUpdate={setUpdate}
                variable={variable_fields}
              />
            ),
            Confirmation:
              mode === "record" ? (
                <RecordAppendixEdit
                  upload={upload}
                  setUpload={setUpload}
                  update={update}
                  certificates={certificates}
                  setCertificates={setCertificates}
                  id={id}
                />
              ) : (
                <RecordUpdateCheck
                  mode={mode}
                  upload={upload}
                  update={update}
                  setUpload={setUpload}
                  filterCert={true}
                  id={id}
                />
              ),
          };
          if (activeStep === index)
            return <Box key={step}>{stepComponent[step]}</Box>;
        })}
      </>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button
          disabled={activeStep === 0}
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
          onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        >
          {itranslate("Back")}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        {!(activeStep === stepDisplayMap[mode].length - 1) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
          >
            {itranslate("Next")}
          </Button>
        )}
      </Box>
    </EditBase>
  );
};

export default RecordEdit;
