import { createSlice } from "@reduxjs/toolkit";


export const revokeSlice = createSlice({ 
  name: "revoke",
  initialState: {
    components: {
      comps: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    },
    models: {
      models: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    },
    manufacturers: {
      manufacturers: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    },
    rcs: {
      rcs: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    }
  },
  reducers: { 
    setComponents: (state, action) => {
      state.components.comps = action.payload;
      state.components.totalItems = action.payload.length;
    },
    changeComponentsPage(state, action) {
      state.components.currentPage = action.payload;
    },
    changeComponentsItemsPerPage(state, action) {
      state.components.itemsPerPage = action.payload;
      state.components.currentPage = 1; // Reset the current page to 1 when changing items per page
    },
    setModels: (state, action) => {
      state.models.models = action.payload;
      state.models.totalItems = action.payload.length;
    },
    changeModelsPage(state, action) {
      state.models.currentPage = action.payload;
    },
    changeModelstemsPerPage(state, action) {
      state.models.itemsPerPage = action.payload;
      state.models.currentPage = 1; // Reset the current page to 1 when changing items per page
    },
    setManufacturers: (state, action) => {
      state.manufacturers.manufacturers = action.payload;
      state.manufacturers.totalItems = action.payload.length;
    },
    changeManufacturersPage(state, action) {
      state.manufacturers.currentPage = action.payload;
    },
    changeManufacturersItemsPerPage(state, action) {
      state.manufacturers.itemsPerPage = action.payload;
      state.manufacturers.currentPage = 1; // Reset the current page to 1 when changing items per page
    },
    setRcs: (state, action) => {
      state.rcs.rcs = action.payload;
      state.rcs.totalItems = action.payload.length;
    },
    changeRcsPage(state, action) {
      state.rcs.currentPage = action.payload;
    },
    changeRcsItemsPerPage(state, action) {
      state.rcs.itemsPerPage = action.payload;
      state.rcs.currentPage = 1; // Reset the current page to 1 when changing items per page
    },
  }
})


export const { 
  setComponents, 
  changeComponentsPage, 
  changeComponentsItemsPerPage,
  setModels,
  changeModelsPage,
  changeModelstemsPerPage,
  setManufacturers,
  changeManufacturersPage,
  changeManufacturersItemsPerPage,
  setRcs,
  changeRcsPage,
  changeRcsItemsPerPage
} = revokeSlice.actions

export default revokeSlice.reducer;