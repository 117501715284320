import { Text } from "@react-pdf/renderer";


const formatParser = (text, format) => {
  const TextComponent = format === "plainHtml" ? "span" : Text;

  function traditionalChinese(textOutsideSymbols, textArray) {
    const arr4 = textOutsideSymbols.split("##");
    arr4.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        styledText =
          format === "plainHtml" ? (
            <span key={Math.random()}>{subText}</span>
          ) : (
            // We replace all white space after a new line(\n) with \u00A0 for non-breaking space in pdf, in html, it is handled by css "white-space: pre-wrap"
            <Text key={Math.random()}>
              {subText.replace(/(?<=\n)\s+/g, (match) =>
                "\u00A0".repeat(match.length)
              )}
            </Text>
          );
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ fontFamily: "mYuenLight"}}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  function switchFontFamily(textOutsideUnderline, textArray) {
    const arr3 = textOutsideUnderline.split("`");
    arr3.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        traditionalChinese(subText, textArray);
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ fontFamily: "DejaVu Sans" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  function checkUnderline(textOutsideBold, textArray) {
    const arr2 = textOutsideBold.split("__");
    arr2.forEach((subText, index) => {
      let styledText;
      if (index % 2 === 0) {
        switchFontFamily(subText, textArray);
      } else {
        styledText = (
          <TextComponent
            key={Math.random()}
            style={{ textDecoration: "underline" }}
          >
            {subText}
          </TextComponent>
        );
      }
      textArray.push(styledText);
    });
  }

  if (text) {
    const arr = text.split("**");
    const newTextArr = [];
    arr.forEach((text, index) => {
      if (index % 2 === 0) {
        checkUnderline(text, newTextArr);
      } else if (index % 2 !== 0) {
        let styledText;
        if (text.startsWith("__") && text.endsWith("__")) {
          styledText = (
            <Text
              key={Math.random()}
              style={{
                textDecoration: "underline",
                fontFamily: "Frutiger_bold",
              }}
            >
              {text
                .slice(2, -2)
                .replace(/(?<=\n)\s+/g, (match) =>
                  "\u00A0".repeat(match.length)
                )}
            </Text>
          );
        } else if (text.startsWith("~") && text.endsWith("~")) {
          styledText = (
            <Text key={Math.random()} style={{ fontFamily: "Frutiger_bold" }}>
              {text
                .slice(1, -1)
                .replace(/(?<=\n)\s+/g, (match) =>
                  "\u00A0".repeat(match.length)
                )}
            </Text>
          );
        } else {
          // Wrap with bold text style
          styledText = (
            <Text
              key={Math.random()}
              style={{ fontFamily: "Frutiger_Ultra_bold" }}
            >
              {text.replace(/(?<=\n)\s+/g, (match) =>
                "\u00A0".repeat(match.length)
              )}
            </Text>
          );
        }
        if (styledText) {
          newTextArr.push(styledText);
        }
      }
    });
    return newTextArr;
  }
};




const isChineseChar = (char) => /[\u4e00-\u9fa5]/.test(char);


const wrapChineseWords = (input) => {
  let output = '';
  let isPrevCharChinese = false;

  for (const char of input) {
    const isChinese = isChineseChar(char);
    if (isChinese) {
      if (!isPrevCharChinese) {
        output += '##';
      }
      output += char;
    } else {
      if (isPrevCharChinese) {
        output += '##';
      }
      output += char;
    }

    isPrevCharChinese = isChinese;
  }

  if (isPrevCharChinese) {
    output += '##';
  }

  return output;
};



const parseDerivedAppendixPDF = (input) => {
  const wrappedInput = wrapChineseWords(input);
  const parsedFormat = formatParser(wrappedInput, 'pdf');
  
  return parsedFormat;
};

export default parseDerivedAppendixPDF;