import { configureStore } from "@reduxjs/toolkit";
import uploadReducer from "./uploadSlice";
import userInfoReducer from "./userInfoSlice";
import componentsReducer from "./componentsSlice";
import pdfReducer from "./pdfSlice";
import daPdfReducer from "./daPdfSlice"
import revokeSlice  from "./revoke/revokeSlice";

// for ru create
import stageReducer from "./stageSlice";
import optionReducer from "./optionSlice";

// parse value
import dasReducer from "./dasSlice";
import appReducer from "./appSlice"
import loadingReducer from "./loadingSlice"
import certsReducer from "./certsSlice"
import saveDaHookReducer from "./saveDaHookSlice"

import { apiSlice } from './api/apiSlice';

// ru create
import ruPageDataReducer from './ruCreate/ruPageDataSlice';

export default configureStore({
  reducer: {
    upload: uploadReducer,
    userInfo: userInfoReducer,
    pdf: pdfReducer,
    das: dasReducer,
    app: appReducer,
    loading: loadingReducer,
    certs: certsReducer,
    saveDaHook: saveDaHookReducer,
    daPdf: daPdfReducer,
    stage: stageReducer,
    option: optionReducer,
    revoke: revokeSlice,
    components: componentsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    // ru create
    ruPageData: ruPageDataReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.REACT_APP_UAT === "true",
});
