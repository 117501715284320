export const headCells = {
  models: [
    {
      id: 'model_code',
      numeric: false,
      disablePadding: false,
      label: 'Model Code',
    },
    {
      id: 'provided_model_name',
      numeric: false,
      disablePadding: false,
      label: 'Model Name',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
    },
    {
      id: 'manufacturersPopulated.provided_name',
      numeric: false,
      disablePadding: false,
      label: 'Manufacturer Name',
    },
    {
      id: 'documents',
      numeric: false,
      disablePadding: false,
      label: 'Documents',
    }
  ],
  manufacturers: [
    {
      id: 'manufacturerCode',
      numeric: false,
      disablePadding: false,
      label: 'Manufacturer Code',
    },
    {
      id: 'provided_name',
      numeric: false,
      disablePadding: false,
      label: 'Manufacturer Name',
    },
    {
      id: 'documents',
      numeric: false,
      disablePadding: false,
      label: 'Documents',
    },
  ],
  components: [
    {
      id: 'component_code',
      numeric: false,
      disablePadding: false,
      label: 'Component Code'
    },
    {
      id: 'provided_comp_name',
      numeric: false,
      disablePadding: false,
      label: 'Component Name'
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type'
    },
    {
      id: 'documents',
      numeric: false,
      disablePadding: false,
      label: 'Documents',
    }
  ]

}