import React, {useEffect, useState} from "react";
import {
  Document,
  Page,
  Text,
  View,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import fontRegister from "../../../utils/pdf/fontRegister.js";
import ReactDOMServer from "react-dom/server";

import parseContentJson from "./pdf/revocationLetter_format_parser.js";
import parseContentBodyChineseJson from "./pdf/revocationLetter_format_parser_body_chinese.js";
import parseContentHeadingChinesePDFJson from "./pdf/revocationLetter_format_parser_heading_chinese.js";

import emsdLogo from "../../../styles/logo/emsd_coverLetter.jpeg";
// import formatDate from "../../../utils/misc/formatDate.js";
import getToken from "../../../utils/getAuthToken.js";
// import renderChinese from "../../../utils/pdf/renderChinese.js";

import { fixInvalidDates } from "../../../utils/misc/fixInvalidDates.js";
import useRevokeHook from "../../../useHooks/useRevokeHook.jsx";  
import { useTranslation } from 'react-i18next'


export const RevocationLetterPDF = ({ application, appendix, rcName, rcAddress, rcItem, format, inputs, setInputs }) => {  
const [signatureResponse, setSignatureResponse] = useState("");
const { getSignature } = useRevokeHook();
const [isFocused, setIsFocused] = useState(false);


const handleInputFocus = () => {
  setIsFocused(true);
};

const handleInputBlur = () => {
  setIsFocused(false);
};


useEffect(() => {
  const fetchSignature = async () => {
    try {
      const response = await getSignature();
      setSignatureResponse(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchSignature();
},[])


  fontRegister();

  if (!application) return null;


  const stylesheet = {
    "*": {
      fontFamily: "Frutiger_light",
      fontSize: "11px",
    },
    p: {
      margin: 0,
      fontFamily: "Frutiger_light",
    },
    ".rl-column": {
      display: "flex",
      flexDirection: "column",
    },
    ".rl-approval": {
      marginLeft: "50px",
      // paddingLeft: "100px",
    },
    ".rl-row": {
      display: "flex",
      flexDirection: "row",
    },
    ".rl-bold, .rl-bold *": {
      fontFamily: "Frutiger_bold",
    },
    ".rl-logo": {
      position: "relative",
      width: "210px",
      left: "45%",
      marginTop: "30px",
      marginBottom: "20px",
    },
    ".rl-address": {
      marginRight: "19px",
      marginBottom: "3px",
      alignSelf: "flex-end",
    },
    ".rl-address *": {
      lineHeight: 1.1,
      fontSize: "10px",
      fontFamily: "mYuenLight",
    },
    ".rl-address_eng": {
      marginRight: "2px",
      alignSelf: "flex-end",
      transform: "scale(.5, 1)",
      transformOrigin: "right",
      lineHeight: 1.1,
    },
    ".rl-letterHeader": {
      justifyContent: "space-between",
      padding: "0 52px 0px 26px",
    },
    ".rl-letterHeaderRight": {
      flexBasis: "43%",
      paddingTop: "10px",
    },
    ".rl-reference": {
      alignItems: "flex-start",
      marginBottom: "5px",
    },
    ".rl-reference_no"  : {
      marginTop: "5px",
      display: "flex",
      flexDirection: "row",
    },
    ".rl-reference_no_2"  : { 
      marginTop: "5px"
    },
    ".rl-thinFont": {
      transform: "scale(.5, 1)",
      transformOrigin: "right",
      marginRight: "8px",
    },
    ".rl-chinese": {
      fontFamily: "mYuenLight",
      fontSize: "10px",
      paddingTop: "2px",
      lineHeight: 1.3,
    },
    ".rl-sendAddress": {
      marginTop: "7px",
      marginBottom: "15px",
      lineHeight: 1.6,
    },
    ".rl-phoneNumber p": {
      fontSize: "12px",
    },
    ".rl-phoneNumber p:first-child": {
      marginTop: "0.5px",
      marginLeft: '5px'
    },
    ".rl-phoneNumber p:nth-child(2)": {
      marginTop: "3px",
      marginLeft: '5px'
    },
    ".rl-facsimile": {
      marginLeft: "6px",
      marginBottom: "0px",
    },
    ".rl-padding": {
      // padding: "0 105px",
      marginTop: "15px",
    },
    ".rl-content *": {
      lineHeight: 1.6,
    },
    ".rl-title": {
      fontFamily: "Frutiger_Ultra_bold",
      padding: "15px 0px",
      borderBottom: "1px solid black",
    },
    ".rl-title *": {
      fontFamily: "Frutiger_Ultra_bold",
    },
    ".rl-main": {
      paddingTop: "15px",
    },
    ".rl-main p": {
      marginBottom: "15px",
    },
    ".rl-sign": {
      marginTop: "150px",
      marginLeft: "50px",
    },
    ".rl-signature": {
      width: "215px",
      padding: "0px 0px 10px 0px",
      borderBottom: "1px solid black",
      marginBottom: "5px",
    },
    ".rl-date_padding": {
      paddingTop: "10px",
      paddingLeft: "-10px",
    },
    "tytytyt": {
      fontFamily: "mYuenLight",
    }
  };

 
  const approvalLetter = (
    <div className="rl-column">
      <img src={emsdLogo} className="rl-logo" alt="emsdLogo" />
      
        <div className="rl-address">
          <p>香港特別行政區政府 機電工程署</p>
          <p>香港九龍啟成街3號</p>
        </div>
        <div className="rl-address_eng rl-bold">
          <p>Electrical and Mechanical Services Department</p>
          <p>Government of the Hong Kong Special Administrative Region</p>
          <p>3 Kai Shing Street, Kowloon, Hong Kong</p>
          <p>www.emsd.gov.hk</p>
        </div>
      

      <div className="rl-letterHeader rl-row">
        <div>
        </div>

        <div className="rl-letterHeaderRight rl-row">
          <div>
            <div className="rl-reference rl-row">
              <p className="rl-thinFont rl-bold">Telephone</p>
              <p className="rl-chinese">電話號碼 :</p>
            </div>
            <div className="rl-reference rl-facsimile rl-row">
              <p className="rl-thinFont rl-bold">Facsimile</p>
              <p className="rl-chinese">圖文傳真 :</p>
            </div>
          </div>

          <div className="rl-phoneNumber">
            <p> 28083861</p>
            <p className="rl-fax"> 25045970</p>
          </div>
        </div>
      </div>


      <div className="rl-approval">

        <div className="rl-reference_no rl-padding">
          <p>Our reference </p>
          <p className="rl-chinese">本署檔案 : </p>
          <input 
            value={inputs.our_reference}
            onChange={(e) => {
              setInputs({
                ...inputs,
                our_reference: e.target.value
              })
            }}
          />
          <p>{ `${format === "pdf" ? inputs.our_reference : ""}` }</p>
        </div>

        <div className="rl-reference_no rl-yourRef_padding">
          <p >Your reference </p>
          <p className="rl-chinese">來函檔號 : </p>
          <input 
            value={inputs.your_reference}
            onChange={(e) => {
              setInputs({
                ...inputs,
                your_reference: e.target.value
              })
            }}
          />
          <p>{ `${format === "pdf" ? inputs.your_reference : ""}` }</p>
        </div>

        <div className="rl-date_padding">
          <input 
              value={isFocused ? inputs.reference_date : inputs.reference_date ? fixInvalidDates(inputs.reference_date) : ""}
              onChange={(e) => {
                setInputs({
                  ...inputs,
                  reference_date: e.target.value
                })
              }}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              placeholder="DD-MM-YYYY"
            />
            <p>{ `${format === "pdf" ? fixInvalidDates(inputs.reference_date) : ""}` }</p>
        </div>

        <div className="rl-padding">
          <div className="rl-sendAddress">
            {rcAddress && (
              <div>
                <p>{rcAddress.company}</p>
                <p>
                  {rcAddress.floor && `${rcAddress.floor}/F, `}
                  {rcAddress.flat && `${rcAddress.flat}, `}
                  {rcAddress.block && `Block ${rcAddress.block}, `}
                  {rcAddress.building && `${rcAddress?.building}, `}
                  {rcAddress.estate && `${rcAddress?.estate}, `}
                </p>
                <p>
                  {rcAddress.street_start_no && rcAddress.street_end_no
                    ? `${rcAddress.street_start_no} - ${rcAddress.street_end_no} `
                    : `${rcAddress.street_start_no} `}
                  {rcAddress.street},
                </p>
                <p>{rcAddress.district_name}</p>
                
              </div>
            )}
          </div>

          <div className="rl-rcname">
            <p className="rl-dear">
              {`${ format === "pdf" ? (inputs.rc_name !== "" ? `Dear ${inputs.rc_name}` : ((rcName && rcName !== 'undefined') ? `Dear ${rcName},` : "Dear Sir/Madam,")) : "Dear" }`}
            </p>
            <input 
              value={isFocused ? inputs.rc_name : inputs.rc_name !== "" ? inputs.rc_name : (rcName && rcName !== 'undefined' ? rcName : "Sir/Madam") }
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onChange={(e) => {
                setInputs({
                  ...inputs,
                  rc_name: e.target.value
                });
              }}
            />
          </div>

        </div>
      </div>

    </div>
  );


  const approvalLetterSign = (
    <div className="rl-sign rl-content">
    <p>Yours faithfully,</p>
      <img
        crossOrigin="anonymous"
        src={`${process.env.REACT_APP_ENV_ENDPOINT}/lift/options/signature_image?token=${getToken()}`}

        className="rl-signature"
        alt="signature"
      />

    <p>( {signatureResponse && signatureResponse?.[0]?.signature} )</p>
    <p>for Director of Electrical and Mechanical Services</p>

  </div>
  );


  const approvalLetterHtml = ReactDOMServer.renderToStaticMarkup(
    <html>
      <body className="revoke">{approvalLetter}</body>
    </html>
  );


  const approvalLetterSignHtml = ReactDOMServer.renderToStaticMarkup(
    <html>
      <body className="revoke">{approvalLetterSign}</body>
    </html>
  );


  const manufacturerName = "{manufacturer_name}";
  const manufacturerAddress = "{manufacturer_address}";


  return (
    <Document>
      <Page
        wrap
        style={{
          // padding: "48px 61.64px 48px 80.44px",
          padding: "15px 36px 15px 15px",
          fontSize: "11px",
          fontFamily: "Frutiger_light",
          lineHeight: 1.6,
        }}
      >
        <Html stylesheet={stylesheet}>{approvalLetterHtml}</Html>

        <View
          style={{
            marginLeft: "58px",
          }}
        >
          {appendix?.revocation_letter?.heading.map((section, i) => {
            return (
              <>
                {section && section.includes(manufacturerName) ? (
                  <Text
                    style={{
                      paddingBottom: "15px",
                      padding: "15px 0px",
                      borderBottom: "1px solid black",
                      fontFamily: "Frutiger_bold", 
                      // ...(section.length > 0 &&
                      //   i !== section.length - 1 && { paddingBottom: "0px" }),
                    }}
                    key={i}
                  >
                    {parseContentHeadingChinesePDFJson(section, rcItem)}
                  </Text>
                ) : (
                  <Text
                    style={{
                      paddingBottom: "15px",
                      padding: "15px 0px",
                      borderBottom: "1px solid black",
                      fontFamily: "Frutiger_bold", 
                      // ...(section.length > 0 &&
                      //   i !== section.length - 1 && { paddingBottom: "0px" }),
                    }}
                    key={i}
                  >
                    {parseContentJson(section, rcItem)}
                  </Text>
                )}
              </>
            );
          })}
        </View>

        <View
          style={{
            lineHeight: 1.6,
            paddingTop: "15px",
            marginLeft: "58px",
          }}
        >
          {appendix?.revocation_letter?.body.map((section, i) => {
            return (
              <>
                {section &&
                section.includes(manufacturerName || manufacturerAddress) ? (
                  <Text>{parseContentBodyChineseJson(section, rcItem)}</Text>
                ) : (
                  <Text
                    style={{
                      ...(section.length > 0 &&
                        i !== section.length - 1 && {
                          paddingBottom: "0px",
                          paddingTop: "15px",
                        }),
                    }}
                    key={i}
                  >
                    {parseContentJson(section, rcItem)}
                  </Text>
                )}
              </>
            );
          })}
        </View>

        <Text
          style={{
            fontFamily: "Frutiger_light",
            position: "absolute",
            fontSize: 11,
            bottom: 35,
            right: 60,
          }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />

        <Text
          style={{
            fontFamily: "mYuenLight",
            position: "absolute",
            fontSize: 9,
            bottom: 10,
            left: 30,
            right: 0,
          }}
        >
          凡寄來之文件請註明為機電工程署所收，而非個別職員者。
        </Text>

        <Text
          style={{
            transform: "scale(0.5, 1)",
            transformOrigin: "right",
            fontFamily: "Frutiger_bold",
            position: "absolute",
            fontSize: 9,
            bottom: 12,
            right: 50,
          }}
        >
          Please address your correspondence to the Electrical and Mechanical
          Services Department and not to individual officiers by name.
        </Text>

        {/* 2nd page */}
        <View break>
          <Html stylesheet={stylesheet}>{approvalLetterSignHtml}</Html>
        </View>
      </Page>
    </Document>
  ); 
};



const RevocationLetterDownload = (props) => {
  const { t : itranslate } = useTranslation();
  const { rcAddress } = props;

  const fileName = rcAddress?.company ? rcAddress.company + " - revocation_letter.pdf" : "revocation_letter.pdf";

  return (
    <PDFDownloadLink
      style={{ 
        textDecoration: "none" 
      }}
      document={<RevocationLetterPDF {...props} />}
      fileName={fileName}
    >
      {({ blob, url, loading, error }) =>
        // loading ? "Loading document..." : "Download Letter"
        loading ? itranslate("Loading document...") : itranslate("Download Letter")
      }
    </PDFDownloadLink>
  )
}


export default RevocationLetterDownload