const mandatoryCertData = {
  iso: [
    "iso_manufacturer_name",
    "iso_manufacturer_address",
    "iso_standard",
    "iso_cert_number",
    "iso_issue_date",
    "iso_expiry_date",
  ],
  general: [
    "tec_manufacturer_name",
    "tec_manufacturer_address",
    "tec_cert_type",
    "tec_standard",
    "tec_certificate_number",
    "tec_certificate_body",
    "tec_issue_date",
    "tec_expiry_date",
    "tec_report_no",
    "tec_test_item",
    "tec_model_no",
  ],
  lift: [
    "tec_lift_type",
    "tec_rated_speed_max",
    "tec_rated_load_max",
    "controller",
    "tec_travel_height_max",
    "tec_roping_factor",
    "tec_balancing_factor",
  ],
  escalator: [
    "tec_lift_type",
    "tec_rated_speed_max",
    "tec_inclination_angle",
    "tec_max_rise",
    "tec_material",
  ],
  ElectricTractionLiftMachineRoom: ["traction_machine"],
  ElectricTractionLiftRoomless: ["traction_machine"],
  HydraulicLift: ["tec_actuation"],
  StairLift: ["tec_inclination_angle"],
  ServiceLift: ["traction_machine"],
  Escalator: [],
  PassengerConveyor: [],
  MVPS: ["traction_machine"],
  ElectricalVerticalLiftingPlatform: [],
  InclinedLift: ["tec_inclination_angle", "traction_machine"],
  Others: [],
  Buffer: ["tec_buffer", "tec_rated_speed_max"],
  OverspeedGovernor: [
    "tec_overspeed_governor",
    "tec_rated_speed_max",
    "tec_tripping_speed_max",
  ],
  LandingDoorLockingDevice: ["tec_landing_door_locking_device"],
  CarDoorLockingDevice: ["tec_car_door_locking_device"],
  SafetyGear: ["tec_safety_gear", "tec_rated_speed_max", "tec_rated_speed_min"],
  ACOP: ["tec_acop"],
  UCMP: ["tec_ucmp"],
  SafetyCircuitElectricalComponent: [
    "tec_circuit_electronic",
    "tec_description",
  ],
  RaptureValve: ["tec_rapture_valve"],
  OneWayValve: ["tec_one_way_valve"],
  Step: ["tec_step"],
  Pallet: ["tec_pallet"],
  TractionMachine: [],
  Controller: [],
};

export default mandatoryCertData;
