import { useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import keyToField from "../../utils/keyToField";
import { useTranslation } from 'react-i18next';


function TapasRcCheckingResult(props) {
  const { t : itranslate } = useTranslation();
  const { aiFeedBack, certificates, userInfo_Role } = props;
  const [expanded, setExpanded] = useState(!userInfo_Role);

  if (!aiFeedBack || !certificates) return null;
  const checkedFile = aiFeedBack.map((item) => item.fileId);

  const handleToggle = () => {setExpanded(!expanded);};

  return (
    <Box sx={{ marginBottom: "30px", width: "60vw" }}>
      <Typography variant="h6" sx={{ fontFamily: "Frutiger_bold" }}>
        {/* TAPAS RC AI Checking */}
        {itranslate("TAPAS RC AI Checking")}
      </Typography>
      <Accordion expanded={expanded}>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: "lightgray" }}
          onClick={handleToggle}>
          <Typography >
            {/* Toggle Content */}
            {itranslate("Toggle Content")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer sx={{ my: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "13.5%", fontWeight: "bold" }}>
                    {/* Certificate Number */}
                    {itranslate("Certificate Number")}
                  </TableCell>
                  <TableCell sx={{ width: "13.5%", fontWeight: "bold" }}>
                    {/* Field */}
                    {itranslate("Field")}
                  </TableCell>
                  <TableCell sx={{ width: "23.5%", fontWeight: "bold" }}>
                    {/* Data checked */}
                    {itranslate("Data checked")}
                  </TableCell>
                  <TableCell sx={{ width: "22.5%", fontWeight: "bold" }}>
                    {/* Closest AI result */}
                    {itranslate("Closest AI result")}
                  </TableCell>
                  <TableCell sx={{ width: "10%", fontWeight: "bold" }}>
                    {/* AI's fault */}
                    {itranslate("AI's fault")}
                  </TableCell>
                  <TableCell sx={{ width: "19.5%", fontWeight: "bold" }}>
                    {/* RC's comment */}
                    {itranslate("RC's comment")}
                  </TableCell>
                  <TableCell sx={{ width: "10%", fontWeight: "bold" }}>
                    {/* Status */}
                    {itranslate("Status")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {checkedFile &&
                  certificates
                    .filter((cert) => checkedFile.includes(cert.file))
                    .map((cert) => {
                      return aiFeedBack
                        .filter((item) => item.fileId === cert.file)
                        .map((item, index, array) => {
                          return (
                            <TableRow key={item.certField}>
                              {index === 0 && (
                                <TableCell rowSpan={array.length}>
                                  {cert?.cert_corrections?.tec_certificate_number ||
                                    cert?.cert_corrections?.iso_cert_number ||
                                    cert?.cert_type_metadata?.tec_certificate_number ||
                                    cert?.cert_type_metadata?.iso_cert_number ||
                                    itranslate("Cert number not found")
                                  }
                                </TableCell>
                              )}
                              <TableCell>
                                {itranslate(keyToField[item.certField]) ?? itranslate(item.certField)}
                              </TableCell>
                              <TableCell>{item.comparison[0]}</TableCell>
                              <TableCell>{item.comparison[1]}</TableCell>
                              <TableCell>{item.fault ? itranslate("Yes") : itranslate("No")}</TableCell>
                              <TableCell>{item.comment}</TableCell>
                              <TableCell
                                sx={
                                  item.failed
                                    ? { color: "red" }
                                    : { color: "green" }
                                }
                              >
                                {item.failed ? itranslate("Failed") : itranslate("Passed")}
                              </TableCell>
                            </TableRow>
                          );
                        });
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default TapasRcCheckingResult;
