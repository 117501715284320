import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  Grid,
} from "@mui/material";
import palette from "../../styles/palette";
import { useState } from "react";
import { useTranslation } from 'react-i18next';


function DocumentList(props) {
  const { certificates, cert, setCert } = props;

  const { t : itranslate } = useTranslation();
  const [value, setValue] = useState("all");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ position: "sticky", width: "100%", top: 0, zIndex: 1 }}>
        <Tabs
          sx={{
            fontFamily: "Frutiger_bold",
            backgroundColor: palette.shadow,
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{ textTransform: "none" }}
            // label="Documents List"
            label={itranslate("Documents List")}
            value="all"
          />
          <Tab sx={{ textTransform: "none" }} 
            // label="ISO" 
            label={itranslate("ISO")}
            value="ISO" 
          />
          <Tab
            sx={{ textTransform: "none" }}
            // label="Model TEC"
            label={itranslate("Model TEC")}
            value="ModelTypeCert"
          />
          <Tab
            sx={{ textTransform: "none" }}
            // label="Model Full Quality"
            label={itranslate("Model Full Quality")}
            value="FullQualityCert"
          />
          <Tab
            sx={{ textTransform: "none" }}
            // label="Component TEC"
            label={itranslate("Component TEC")}
            value="ComponentTypeCert"
          />
          <Tab
            sx={{ textTransform: "none" }}
            // label="Others"
            label={itranslate("Others")}
            value="Compatibility"
          />
        </Tabs>
      </Box>
      <Grid
        container
        sx={{
          width: "100%",
          "--Grid-borderWidth": "1px",
          borderTop: "var(--Grid-borderWidth) solid",
          borderLeft: "var(--Grid-borderWidth) solid",
          borderColor: "divider",
          "& > div": {
            borderRight: "var(--Grid-borderWidth) solid",
            borderBottom: "var(--Grid-borderWidth) solid",
            borderColor: "divider",
          },
        }}
      >
        {certificates?.length > 0 &&
          certificates
            .filter((cert) => !cert.historical && (value === "all" || cert.type === value))
            .map((certificate) => {
              return (
                <Grid item xs={4} key={certificate.id}>
                  <ListItemButton
                    onClick={() => setCert(certificate)}
                    sx={{
                      height: "100%",
                      ...(cert?.id === certificate.id && {
                        backgroundColor: palette.selected,
                      }),
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "0.8rem" }}
                    >
                      {certificate.description} - {certificate.filename}
                    </ListItemText>
                  </ListItemButton>
                </Grid>
              );
            })}
      </Grid>
    </Box>
  );
}

export default DocumentList;
