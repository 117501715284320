import axios from 'axios'

import getToken from "../../utils/getAuthToken";

var token

export const getDataByDateTime = (filter, startDate, endDate) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/dataByDateTime/getDataByDateAndQuery`,
        headers: {authorization: token},
        params: {
            filter: filter,
            startDate: startDate,
            endDate: endDate
        },
        responseType: 'json'
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))

export const getGraphByPeriod = (graphRange) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/dataByDateTime/getGraphByPeriod`,
        params:{
            graphRange
        },
        headers: {authorization: token}
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))

export const getRankByEntityPeriodChannel = (entity=null, rankRange=null, channel=null) => (new Promise((resolve, reject) => {
    token = getToken()
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/dataByDateTime/getRankByEntityPeriodChannel`,
        params:{
            entity,
            rankRange,
            channel
        },
        headers: {authorization: token}
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))