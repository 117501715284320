import { Container, Typography, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
    Button,
    Box
} from "@mui/material";

import React, { useEffect, useState } from 'react';

import { fixInvalidDates } from '../../../../../../admin/utils/misc/fixInvalidDates';
import { ShowFileBtn } from "../../../../../../_hoc/Buttons/ShowFileBtn";
import { useTranslation } from "react-i18next";
// mapping component types
const component_types = {
    ACOP: "Ascending car overspeed protection means",
    Buffer: "Buffer" ,
    CarDoorLockingDevice: "Car door locking device" ,
    LandingDoorLockingDevice: "Landing door locking device" ,
    OverspeedGovernor: "Overspeed Governor" ,
    Pallet: "Pallet" ,
    SafetyCircuitElectricalComponent: "Safety circuit containing electrical components" ,
    SafetyGear: "Safety Gear" ,
    Step: "Step" ,
    UCMP: "Unintended car movement protection means" ,
    RaptureValve: "Rapture Valve" ,
    TractionMachine: "Traction Machine" ,
    Controller: "Controller" ,
    Others: "Others",
}


export default function ModelPerspectiveShow({modelPerspectiveShowData, ruResult, handleGetPDFFile}){
    // const { t } = useTranslation("pages", {keyPrefix: "typeApprovalRecord"});
    const { t : itranslate } = useTranslation()
    const [ scTypes, setScTypes] = useState([]);
    const [ scs, setScs] = useState([]);

    useEffect(() => {

        // sorted and filtered scTypes based on one model
        setScTypes(
            [...new Set(ruResult?.model_manu_sc_id_arr?.filter(v => v.model._id === modelPerspectiveShowData._id)[0]?.relatedScChosen?.sort((a,b) => a.type - b.type)?.map(sc => sc.type))]
        )

        // sorted and filtered scs based on one model
        setScs(
            [...new Set(ruResult?.model_manu_sc_id_arr?.filter(v => v.model._id === modelPerspectiveShowData._id)[0]?.relatedScChosen?.sort((a,b) => a.type - b.type))]
        )
    }, [ruResult])


    return (
        <Container style={{ maxWidth: '100%' }}>
            {
                    <Card variant="outlined" sx={{ marginTop: '50px', marginBottom: '50px', border: '1px solid black' }}>
                        {/* General Info */}
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold'}}>{itranslate("Model Code")}</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold'}}>{itranslate("Model Name")}</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold'}}>{itranslate("Approval Date")}</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold'}}>{itranslate("Type")}</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold'}}>{itranslate("Rated Speed")}</TableCell>

                                        {modelPerspectiveShowData?.LE_type === 'L' && 
                                            <>
                                                <TableCell sx={{ fontWeight: 'bold'}}>{itranslate("Rated Load")}</TableCell>
                                                <TableCell sx={{fontWeight: 'bold'}}>{itranslate("Max. Travel of Height")}</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold'}}>{itranslate("Roping")}</TableCell>
                                                <TableCell sx={{fontWeight: 'bold'}}>{itranslate("Balancing Factor")}</TableCell>
                                            </>
                                        }

                                        {modelPerspectiveShowData?.LE_type === 'E' && 
                                            <>
                                                <TableCell sx={{ fontWeight: 'bold'}}>{itranslate("Max. Rise")}</TableCell>
                                                <TableCell sx={{fontWeight: 'bold'}}>{itranslate("Angle of Inclination")}</TableCell>
                                            </>
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ whiteSpace: 'nowrap' }}>{modelPerspectiveShowData.model_code}</TableCell>
                                        <TableCell>{modelPerspectiveShowData.provided_model_name}</TableCell>
                                        <TableCell>{fixInvalidDates(new Date(modelPerspectiveShowData.approval_date).toLocaleDateString())}</TableCell>
                                        <TableCell>{ itranslate(modelPerspectiveShowData?.type?.split(/(?=[A-Z])/).join(' ')) || itranslate(modelPerspectiveShowData?.type?.split(/(?=[A-Z])/)) }</TableCell>
                                        <TableCell>{modelPerspectiveShowData.rated_speed}</TableCell>

                                        {modelPerspectiveShowData?.LE_type === 'L' && 
                                            <>
                                                <TableCell>{modelPerspectiveShowData.rated_load}</TableCell>
                                                <TableCell>{modelPerspectiveShowData.rope_factor[0]}</TableCell>
                                                <TableCell>{modelPerspectiveShowData.maximum_travel_of_height}</TableCell>
                                                <TableCell>{modelPerspectiveShowData.balancing_factor}</TableCell>
                                            </>
                                        }
                                        
                                        {modelPerspectiveShowData?.LE_type === 'E' && 
                                            <>
                                                <TableCell>{modelPerspectiveShowData.maximum_rise}</TableCell>
                                                <TableCell>{modelPerspectiveShowData.angle_of_inclination}</TableCell>
                                            </>
                                        }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                        
                        {/* unique models loop */}

                        <TableContainer component={Paper}>
                        {
                            modelPerspectiveShowData.filtered_model_manu_files.length > 0 && (
                                <>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Code")}</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Model Name")}</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Type Examination Certificate NO.")}</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Type Examination Certificate Issue Date")}</TableCell>
                                                {ruResult?.certsChosenForModelPerspective?.tec?.length > 0 && <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Document")}</TableCell>}
                                            </TableRow>
                                        </TableHead>

                                        {/* loop model manu file & tec */}
                                        <TableBody>
                                            {
                                                modelPerspectiveShowData.filtered_model_manu_files.map((uniqueModel, i) => {
                                                    return (

                                                        <TableRow key={`${i}-uniqueModel`}>
                                                            {/* Code */}
                                                            <TableCell>{uniqueModel.unique_model_code}</TableCell>
                                                            {/* Model Name */}
                                                            <TableCell>{modelPerspectiveShowData.provided_model_name}</TableCell>
                                                            {/* Type Examination Certificate Number */}
                                                            <TableCell align="left">
                                                                {uniqueModel.type_test_certificate_id.map((id, i) => {
                                                                    return  (
                                                                        <div key={`${i}-1`}>
                                                                            {
                                                                                modelPerspectiveShowData.certsPopulated.filter(v => v._id === id).map((cert, i) => {
                                                                                    return <div key={`${i}-tec-no`}>
                                                                                        {cert.cert_type_metadata.tec_certificate_number || itranslate("N/A") }
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </TableCell>
                                                            {/* Type Examination Certificate Issue Date */}
                                                            <TableCell align="left">
                                                                {uniqueModel.type_test_certificate_id.map((id, i) => {
                                                                    return  (
                                                                        <div key={`${i}-2`}>
                                                                            {
                                                                                modelPerspectiveShowData.certsPopulated.filter(v => v._id === id).map((cert, i) => {
                                                                                    return <div key={`${i}-tec-issue-date}`}>
                                                                                        {(cert.cert_type_metadata.tec_issue_date && fixInvalidDates(cert.cert_type_metadata.tec_issue_date)) || cert.cert_type_metadata.tec_issue_date || itranslate("N/A")}
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </TableCell>
                                                            {/* Type Examination Certificate */}
                                                            <TableCell align="left">
                                                                {uniqueModel.type_test_certificate_id.map((id, i) => {
                                                                    return  (
                                                                        <div key={`${i}-3}`}
                                                                            className="h-[50px] flex flex-col justify-center"
                                                                        >
                                                                            {ruResult.certsChosenForModelPerspective.tec.filter(v => v._id === id.toString()).map((cert, i) => {
                                                                                return (
                                                                                    <ShowFileBtn 
                                                                                        handleGetPDFFile={handleGetPDFFile} 
                                                                                        id={cert._id}
                                                                                        key={`${i}-model-tec-issue-date`} 
                                                                                    />
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </TableCell>
                                                        </TableRow>
                                                    )

                                                })
                                                
                                            }
                                        </TableBody>
                                    </Table>
                                    {/* model manu populated iso */}
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Code")}</TableCell>

                                                <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Manufacturer Name")}</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("ISO Certificate Issue Date")} </TableCell>
                                                {ruResult.certsChosenForModelPerspective.iso.length > 0 && <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("ISO Certificate")}</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        {
                                            modelPerspectiveShowData?.manufacturersPopulated?.length > 0 && modelPerspectiveShowData?.manufacturersPopulated.map((manuf, i) => {
                                                return (
                                                    <TableBody key={`${i}-manu`}>
                                                        <TableRow>
                                                            <TableCell>
                                                                {
                                                                    // always get 1
                                                                    modelPerspectiveShowData.filtered_model_manu_files.filter(v => v.manufacturer_id === manuf._id).map((v, i) => {
                                                                        return (
                                                                            <div key={`${i}-unique_model_code`}>
                                                                                {v.unique_model_code}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </TableCell>
                                                            <TableCell>{manuf.provided_name}</TableCell>
                                                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                                {
                                                                    // filter the iso with ruResult and always get 1
                                                                    manuf.iso_certificate_id?.cert_type_metadata?.iso_issue_date ? (
                                                                        <div>
                                                                            {fixInvalidDates(manuf.iso_certificate_id?.cert_type_metadata?.iso_issue_date)}
                                                                        </div>
                                                                    ) : (
                                                                        <div key={`${i}-iso-issue-date-N/A`}>{itranslate("N/A")}</div>
                                                                    )
                                                                }
                                                            </TableCell>  
                                                            
                                                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                                {
                                                                    // filter the iso with ruResult and always get 1
                                                                    manuf.iso_certificate_id && ruResult.certsChosenForModelPerspective.iso.filter(v => v._id === manuf.iso_certificate_id._id)?.map((v, i) => {
                                                                            return manuf.iso_certificate_id.file || manuf.iso_certificate_id.id ? (
                                                                                <ShowFileBtn 
                                                                                    handleGetPDFFile={handleGetPDFFile} 
                                                                                    id={manuf.iso_certificate_id.file || manuf.iso_certificate_id.id}
                                                                                    key={`${i}-iso-issue-date`} 
                                                                                />
                                                                            ) : (
                                                                                <div key={`${i}-iso-issue-date-N/A`}>
                                                                                    {itranslate("N/A")}
                                                                                </div>
                                                                            ) 
                                                                    })
                                                                }
                                                            </TableCell>
                                                            
                                                        </TableRow>

                                                    </TableBody>
                                                )
                                            })
                                        }
                                    </Table>
                                </>
                            )
                        }
                        </TableContainer>

                        {/* Loop through rowsScType (Safety Components) */}
                        <Typography variant="h6" gutterBottom style={{ marginTop: '40px', marginBottom: '30px', marginLeft: '15px', textDecoration: 'underline' }}>
                            {itranslate("Safety Components")}
                        </Typography>
                        {
                            scTypes && scTypes.map((scType, i) => {
                                return (
                                    <div key={`${i}`}>
                                        {
                                            <Typography style={{ marginTop: '20px', marginLeft: '15px', textDecoration: 'underline'}}>
                                                Component Type: {component_types[scType]}
                                            </Typography>
                                        }

                                        {/* Table for rowsScType (Safety Components) */}
                                        <TableContainer component={Paper}>
                                        <Table>
                                            {
                                                scs && scs.filter(sc => sc.type === scType).map((sc, rowIndex) => {
                                                    
                                                    return (
                                                        <React.Fragment key={rowIndex}>
                                                            {/* Check if Component Type is not empty before rendering TableHead */}
                                                            {
                                                                rowIndex === 0 && sc &&(
                                                                    <TableHead>
                                                                        <TableRow>
                                                                        <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Manufacturer Name")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Component Name")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Component Code")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Approval Date")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Type Examination Certificate Number")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Issue Date")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold'}} align="left">{itranslate("Type Examination Certificate")}</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                )
                                                            }
                                                            <TableBody>
                                                            {
                                                                sc && (
                                                                    <TableRow>
                                                                    <TableCell align="left">{sc.manufacturer_id.provided_name}</TableCell>
                                                                    <TableCell align="left">{sc.provided_comp_name}</TableCell>
                                                                    <TableCell align="left">{sc.component_code}</TableCell>
                                                                    <TableCell align="left">{(sc.approval_date && fixInvalidDates(sc?.approval_date)) || sc.approval_date}</TableCell>

                                                                    {/* Type Examination Certificate Number  */}
                                                                    <TableCell align="left">
                                                                        {
                                                                            sc.type_test_certificate_id.map((v, i) => {
                                                                                return (
                                                                                    <div 
                                                                                        key={i}
                                                                                        className="h-[50px] flex flex-col justify-center"
                                                                                    >
                                                                                        {v.cert_type_metadata.tec_certificate_number}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TableCell>

                                                                    {/* Issue Date */}
                                                                    <TableCell align="left">
                                                                        {
                                                                            sc.type_test_certificate_id.map((v, i) => {
                                                                                return (
                                                                                    <div 
                                                                                        key={i}
                                                                                        className="h-[50px] flex flex-col justify-center"
                                                                                    >
                                                                                        {(v.cert_type_metadata.tec_issue_date && fixInvalidDates(v.cert_type_metadata.tec_issue_date)) || v.cert_type_metadata.tec_issue_date}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TableCell>
                                                                    
                                                                    {/* file */}
                                                                    <TableCell style={{ width: '10%' }}>
                                                                        {
                                                                            sc.type_test_certificate_id.map((v, i) => {
                                                                                return (
                                                                                    <div
                                                                                        key={i}
                                                                                        className="h-[50px] flex flex-col justify-center"
                                                                                    >
                                                                                        <ShowFileBtn handleGetPDFFile={handleGetPDFFile} id={v.id || v.file} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        
                                                                    </TableCell>
                                                                    
                                                                    </TableRow>
                                                                )
                                                            }
                                                            </TableBody>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </Table>
                                        </TableContainer>
                                    </div>
                                    
                                )

                            })
                        }  

                    </Card >
            }



        </Container>
    )
}