// in src/Dashboard.js
import React, { useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import emsdLogo from "../../styles/logo/emsd_appendix.jpg";
import keyToField from "../../utils/keyToField";
import parseAppendixJson from "../../utils/pdf/appendix_format_parser";
import { romanize, alphabet } from "../../utils/romanize";
import fontRegister from "../../utils/pdf/fontRegister";
import renderChinese from "../../utils/pdf/renderChinese";
import QRCode from "qrcode";
import renderSymbol from "../../utils/pdf/renderSymbol";
import {
  renderContent,
  renderIndexedContent,
} from "../../utils/pdf/renderContent";
import _ from "lodash";
import moment from "moment";

import reactStringReplace from "react-string-replace";
import { fixInvalidDates } from "../../utils/misc/fixInvalidDates";




const AppendixForSC = ({ application, appendix, standalones, certificates, qrcode_url, onResults = () => {} }) => {
// console.log("🚀 ~ standalones: ", standalones)
// console.log("application.app_type?.components: ", application.app_type?.components)

  let renderedCertificates;

  // Pass results back through the callback
    useEffect(() => {
      onResults(renderedCertificates);
    }, [renderedCertificates, onResults]);
  

  fontRegister();
  Font.registerHyphenationCallback((word) => {
    //https://github.com/diegomura/react-pdf/issues/419
    //Ensure the certificate number wrap and do not go out of the cell, as react PDF do not wrap number
    return word.includes("-")
      ? Array.from(word).flatMap((char) => [char, ""])
      : Array.from(word);
  });
  // const { application, appendix, standalones, certificates, qrcode_url, onResults } = props;

  if (!application || !appendix || !standalones || !certificates) return null;

  const componentTypes = _.uniq([
    ...Object.keys(application.app_type?.components || {}),
    ...standalones.components.map((c) => c.type),
  ]);

  const components = componentTypes.reduce((acc, type) => {
    const historicalComp = standalones.components.filter((comp) => comp.type === type);
    const newComp = application.app_type?.components[type] || [];
    acc[type] = [...historicalComp, ...newComp];
    return acc;
  }, {});
  // console.log("🚀 ~ components ~ components:", components)

  const combinedCertificates = standalones.certificates.concat(certificates);
  // console.log("🚀 ~ combinedCertificates:", combinedCertificates)



  // get all the type test certificate ids from the new application
  const typeTestCertificateIds = Object.values(application?.app_type.components).flatMap(typeArray =>
    typeArray.flatMap(item => item.type_test_certificate_id)
  );
  // console.log("🚀 ~ typeTestCertificateIds:", typeTestCertificateIds)
  
  const matchedCertificates = typeTestCertificateIds.map(certId => {
    return combinedCertificates.find(cert => cert._id === certId || cert.id === certId);
  });
  
  // Filter out undefined values (if any)
  const filteredMatchedCertificates = matchedCertificates.filter(cert => cert !== undefined);
  


  // Define renderedCertificates
  renderedCertificates = filteredMatchedCertificates.map(certObj => ({
    id: certObj._id || certObj.id,
    approval_reference: `${application.application_number} dated ${moment().format("DD-MM-YYYY")}`,
  }));
  // console.log("🚀 ~ renderedCertificates ~ renderedCertificates:", renderedCertificates)


  const combinedManufacturers = standalones.manufacturers.concat(application.manufacturer);
  // console.log("🚀 ~ combinedManufacturers:", combinedManufacturers)


  const styles = StyleSheet.create({
    pageNumber: {
      position: "absolute",
      fontSize: 9,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
    },
    logo: {
      position: "absolute",
      bottom: 30,
      left: 720,
      width: "66.4px",
      height: "21.25px",
    },
    lightBold: {
      fontFamily: "Frutiger_bold",
    },
    tableContainer: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "no-wrap",
      borderLeft: "1px solid black",
      borderTop: "1px solid black",
      margin: "0px 3px 18px 0px",
    },
    tableCell: {
      textAlign: "center",
      padding: "3px",
      fontSize: 10,
      minHeight: 19,
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    },
    tableCell_header2: {
      textAlign: "center",
      fontFamily: "Frutiger_bold",
    },
    tableCell_header: {
      textAlign: "left",
      backgroundColor: "#f1f1f1",
      fontFamily: "Frutiger_bold",
    },
    tableCell_multipleRow: {
      display: "flex",
      flexDirection: "column",
    },
    tableCell_multipleRowItem: {
      flexGrow: 1,
      width: "100%",
    },
    row: {
      flexDirection: "row",
      flexGrow: 1,
    },
    address: {
      fontSize: 9,
      paddingTop: "10px",
    },
  });

  const simpleDisplay = (cert_id, field, checkHistorical) => {
    const matchCert = combinedCertificates?.find((cert) => (cert._id || cert.id) === cert_id);

    if (checkHistorical) return matchCert.historical;

    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field] ||
      ""
    );
  };

  const tableFields = {
    ACOP: {
      Manufacturer: 10,
      Model: 8.571,
      tec_car_load: 8.571,
      tec_balancing_factor: 8.571,
      tec_rated_load: 8.571,
      tec_rated_speed: 8.571,
      tec_tripping_speed: 8.571,
      tec_rope_factor: 8.571,
      tec_certificate_number: 15,
      "Approval Reference": 15,
    },
    Buffer: {
      Manufacturer: 20,
      Model: 10,
      tec_total_mass: 15,
      tec_impact_speed: 20,
      tec_certificate_number: 15,
      "Approval Reference": 20,
    },
    CarDoorLockingDevice: {
      Manufacturer: 15,
      Model: 12.5,
      tec_door_type: 25,
      tec_contact_rating: 15,
      tec_certificate_number: 20,
      "Approval Reference": 12.5,
    },
    LandingDoorLockingDevice: {
      Manufacturer: 20,
      Model: 10,
      tec_door_type: 20,
      tec_contact_rating: 10,
      tec_certificate_number: 20,
      "Approval Reference": 20,
    },
    OverspeedGovernor: {
      Manufacturer: 23,
      Model: 8,
      tec_rated_speed: 13,
      tec_tripping_speed: 13,
      tec_certificate_number: 20,
      "Approval Reference": 23,
    },
    SafetyCircuitElectricalComponent: {
      Manufacturer: 27,
      Model: 8,
      tec_circuit_description: 29,
      tec_certificate_number: 18,
      "Approval Reference": 18,
    },
    SafetyGear: {
      Manufacturer: 18,
      Model: 13,
      tec_total_mass: 13,
      tec_rated_speed: 13,
      tec_tripping_speed: 13,
      tec_certificate_number: 13,
      "Approval Reference": 17,
    },
    Step: {
      Manufacturer: 18,
      Model: 13,
      tec_width: 13,
      tec_depth: 13,
      tec_inclination_angle: 13,
      tec_certificate_number: 13,
      "Approval Reference": 17,
    },
    Pallet: {
      Manufacturer: 18,
      Model: 13,
      tec_width: 13,
      tec_depth: 13,
      tec_inclination_angle: 13,
      tec_certificate_number: 13,
      "Approval Reference": 17,
    },
    UCMP: {
      Manufacturer: 10,
      Model: 8.571,
      tec_car_load: 8.571,
      tec_balancing_factor: 8.571,
      tec_rated_load: 8.571,
      tec_rated_speed: 8.571,
      tec_tripping_speed: 8.571,
      tec_rope_factor: 8.571,
      tec_certificate_number: 15,
      "Approval Reference": 15,
    },
    TractionMachine: {
      Manufacturer: 23,
      Model: 8,
      tec_rated_load: 13,
      tec_rated_speed: 13,
      tec_certificate_number: 20,
      "Approval Reference": 23,
    },
    Controller: {
      Manufacturer: 23,
      Model: 8,
      tec_rated_load: 13,
      tec_rated_speed: 13,
      tec_certificate_number: 20,
      "Approval Reference": 23,
    },
  };

  return (
    <Document>
      <Page
        orientation="landscape"
        wrap
        style={{
          padding: "34.156px 43.037px 67.13px 40.3px",
          fontSize: "11px",
          fontFamily: "Frutiger_light",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontFamily: "Frutiger_Ultra_bold",
          }}
          fixed
        >
          <Text>{`Application No.: ${application.application_number}`}</Text>
          <Text>Appendix</Text>
        </View>

        {/* <View
          style={{
            padding: "23px 0px",
            textAlign: "center",
            fontFamily: "Frutiger_Ultra_bold",
            fontSize: "14px",
          }}
        >
          <Text>Technical Specifications for</Text>
          <Text>Various Safety Components</Text>
        </View> */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <View
            style={{
              margin: "0 auto",
              padding: "23px 0px",
              textAlign: "center",
              fontFamily: "Frutiger_Ultra_bold",
              fontSize: "14px",
            }}
          >
            <Text>Technical Specifications for</Text>
            <Text>Various Safety Components</Text>
          </View>
{/* 
          {process.env.REACT_APP_PHASE_TWO && qrcode_url && ( */}
          {qrcode_url && (
            <View>
              <Image
                style={{ width: 50, height: 50 }}
                src={QRCode.toDataURL(qrcode_url || "google.com")}
              />
            </View>
          )}
        </View>

        <Text style={{ fontFamily: "Frutiger_Ultra_bold" }}>
          Registered Lift Contractor:{" "}
          <Text style={{ textDecoration: "underline" }}>
            {application.rc_id?.address?.company} (RLC no.:
            {application.rc_id.rc_number_lift})
          </Text>
        </Text>

        <Text
          style={{ padding: "18px 0px", fontFamily: "Frutiger_Ultra_bold" }}
        >
          Specifications and Conditions
        </Text>
        <Text style={{ fontFamily: "Frutiger_bold" }}>
          Safety Components Specifications:
        </Text>

        {/* 1 component type 1 table */}
        {componentTypes
          .sort((a, b) => {
            const order = [
              "Buffer",
              "OverspeedGovernor",
              "LandingDoorLockingDevice",
              "CarDoorLockingDevice",
              "SafetyGear",
              "ACOP",
              "UCMP",
              "SafetyCircuitElectricalComponent",
            ];
            function customSort(item) {
              const index = order.indexOf(item);
              return index !== -1 ? index : order.length;
            }
            return customSort(a) - customSort(b);
          })
          .map((type, i) => {
            // console.log("🚀 ~ .map ~ type:", type)
            if (!tableFields[type]) return null; //some comp type like rupturevalve doesn't have table


            function getCompList(manu) {
              // return components[type].filter((comp) => comp.manufacturer_id === manu._id);
              return components[type].filter((comp) => comp.manufacturer_code === manu.manufacturer_code);
            }


            //some mnaufacturers has no component, don't render them
            const manufacturers = combinedManufacturers.filter((manu) => getCompList(manu).length > 0);
            // console.log("🚀 ~ .map ~ manufacturers:", manufacturers)

            // remove the duplicate manufacturers
            // if duplicates exist, remove the one with the latest approval date (if approval date exists)

            // let uniqueManufacturers = Array.from(new Set(manufacturers.map((obj) => obj._id))).map((_id) => {
            //   const objectsWithId = manufacturers.filter((obj) => obj._id === _id);

            //   const sortedByDate = objectsWithId.sort((a, b) => {
            //     const dateA = a.approval_date
            //       ? new Date(a.approval_date)
            //       : new Date(0);
            //     const dateB = b.approval_date
            //       ? new Date(b.approval_date)
            //       : new Date(0);
            //     return dateB - dateA;
            //   });
            //   return sortedByDate[0];
            // });   

          //   let uniqueManufacturers = Array.from(new Set(manufacturers.map((obj) => obj.manufacturer_code))).map((manufacturer_code) => {
          //     const objectsWithCode = manufacturers.filter((obj) => obj.manufacturer_code === manufacturer_code);
          
          //     const sortedByDate = objectsWithCode.sort((a, b) => {
          //       const dateA = a.approval_date ? new Date(a.approval_date) : new Date(0);
          //       const dateB = b.approval_date ? new Date(b.approval_date) : new Date(0);
          //       return dateB - dateA;
          //     });
          //     return sortedByDate[0];
          //  });
            
            
          //  //sort in lexicographical order 
          //  uniqueManufacturers.sort((a, b) => {
          //   if (a.provided_name < b.provided_name) {
          //     return -1; // a comes before b
          //   }
          //   if (a.provided_name > b.provided_name) {
          //     return 1; // a comes after b
          //   }
          //   return 0; // a and b are equal
          //  });


          // remove the duplicate manufacturers. if duplicates exist, remove the one with the latest approval date (if approval date exists)
          // then sort in lexicographical order 
          let uniqueManufacturers = Array.from(new Set(manufacturers.map(obj => obj.manufacturer_code)))
            .map(manufacturer_code => {
              const objectsWithCode = manufacturers.filter(obj => obj.manufacturer_code === manufacturer_code);
              
              return objectsWithCode.reduce((latest, current) => {
                const dateLatest = latest.approval_date ? new Date(latest.approval_date) : new Date(0);
                const dateCurrent = current.approval_date ? new Date(current.approval_date) : new Date(0);
                return dateCurrent > dateLatest ? current : latest;
              });
            })
            .sort((a, b) => a.provided_name.localeCompare(b.provided_name));

     

            return (
              <View
                style={{
                  ...styles.tableContainer,
                  ...(appendix["SCLineBreak"]?.[type] && {
                    marginBottom: appendix["SCLineBreak"][type],
                  }),
                }}
                key={type}
              >
                {/* ----------------------------------------TABLE TITLE -------------------------------------------------*/}
                <View style={{ minHeight: "19px" }}>
                  <Text
                    style={{
                      ...styles.tableCell,
                      ...styles.tableCell_header,
                    }}
                  >
                    ({romanize(i + 1).toLowerCase()}){"       "}
                    {keyToField[type]}
                  </Text>
                </View>

                {/* ----------------------------------------TABLE HEAD -------------------------------------------------*/}
                <View style={styles.row}>
                  {Object.keys(tableFields[type])
                    .filter(
                      (field) =>
                        !appendix?.SCHideColumns?.[type]?.includes(field)
                    )
                    .map((field) => {
                      return (
                        <Text
                          style={{
                            ...styles.tableCell,
                            ...styles.tableCell_header2,
                            width: `${tableFields[type][field]}%`,
                            ...field === "Approval Reference" && {flex: 1}, //If user hide other cells, make Approval Reference take up the remaining space
                          }}
                          key={field}
                        >
                          {field === "Model"
                            ? "Model"
                            : field === "tec_certificate_number"
                            ? "Type Exam. Cert. No."
                            : keyToField[field] ?? field}
                        </Text>
                      );
                    })}
                </View>

                {/* ----------------------------------------TABLE BODY -------------------------------------------------*/}
                
                {uniqueManufacturers.map((manu) => {
                
                  const widthExceptFirst = Object.keys(tableFields[type]).reduce((acc, field) => {
                    if (!["Manufacturer"].includes(field)) return acc + tableFields[type][field];
                    else return acc;
                  }, 0);

                  const matchCert = combinedCertificates.find((cert) => (cert._id || cert.id) === manu.iso_certificate_id);
                  
                  const manufacturerAddress = matchCert?.cert_corrections?.iso_manufacturer_address || matchCert?.cert_type_metadata?.iso_manufacturer_address;


                  let list = getCompList(manu);

                  // // filter out duplicate components based on "type_test_certificate_id"
                  // let uniqueComponents = Array.from(new Set(list.map((item) => item.type_test_certificate_id[0]))
                  // ).map((typeTestId) => 
                  //   list.find((item) => item.type_test_certificate_id[0] === typeTestId)
                  // );
                  // // console.log("🚀 ~ {uniqueManufacturers.map ~ uniqueComponents:", uniqueComponents)


                  // // sort in lexicographical order 
                  // uniqueComponents.sort((a, b) => {
                  //   if (a.provided_comp_name < b.provided_comp_name) {
                  //     return -1; // a comes before b
                  //   }
                  //   if (a.provided_comp_name > b.provided_comp_name) {
                  //     return 1; // a comes after b
                  //   }
                  //   return 0; // a and b are equal
                  // });


                  // filter out duplicate components based on "type_test_certificate_id"
                  // then sort in lexicographical order 
                  let uniqueComponents = Array.from(new Set(list.map(item => item.type_test_certificate_id[0])))
                    .map(typeTestId => list.find(item => item.type_test_certificate_id[0] === typeTestId))
                    .sort((a, b) => a.provided_comp_name.localeCompare(b.provided_comp_name));


                  let uniqueComponentsCombined = [];
                  uniqueComponents.forEach(comp => {
                      const itemKey = `${comp.type}-${comp.manufacturer_code}-${comp.component_code}`;
                      let existingItem = uniqueComponentsCombined.find(item => item.key === itemKey);

                      if (!existingItem) {
                          // If it doesn't exist, add it to the new array
                          uniqueComponentsCombined.push({
                              key: itemKey,
                              ...comp,
                              type_test_certificate_id: [...comp.type_test_certificate_id] // Initialize with current IDs
                          });
                      } else {
                          // If it exists, combine the type_test_certificate_id arrays
                          existingItem.type_test_certificate_id = Array.from(new Set([
                              ...existingItem.type_test_certificate_id,
                              ...comp.type_test_certificate_id
                          ]));
                      }
                  });

                  // Resulting array with combined type_test_certificate_id
                  // console.log("🚀 ~ {uniqueManufacturers.map ~ uniqueComponentsCombined:", uniqueComponentsCombined)


                  return (
                    <View style={styles.row} key={manu._id}>
                      {/* Manufacturer section*/}
                      <View
                        style={{
                          ...styles.tableCell,
                          width: `${tableFields[type]["Manufacturer"]}%`,
                          textAlign: "left",
                        }}
                      >
                        <Text
                          style={{
                            ...styles.lightBold,
                            ...renderChinese(manu.short_name || manu.provided_name),
                          }}
                        >
                          {renderSymbol(manu.short_name || manu.provided_name)}{" "}
                          {"\n"}
                        </Text>
                        <Text
                          style={{
                            ...styles.address,
                            ...renderChinese(manufacturerAddress),
                          }}
                        >
                          {manufacturerAddress}
                        </Text>

                        {/* <Text>
                          {manu._id} - {manu.iso_certificate_id} - {manu.manufacturer_code}
                        </Text> */}

                      </View>

                      <View style={{ width: `${widthExceptFirst}%` }}>
                        {/* Since we're inside the a div with width of widthExceptFirst, we need to multiply the cell ratio
                        to get the cell width inside this div */}
                        
                        {/* {uniqueComponents.map((comp) => { */}
                        {uniqueComponentsCombined.map((comp) => {
                          const cellRatio = 100 / widthExceptFirst;                     
                          const fieldWidth = widthExceptFirst - tableFields[type]["Model"];
                          const isNewCert = (cert) => !simpleDisplay(cert, undefined, true);
                          const hasNewCert = comp.type_test_certificate_id.some((cert) => isNewCert(cert));

                          return (
                            <View
                              style={{ ...styles.row }}
                              key={comp._id.component_code || comp._id}
                            >
                              <Text
                                style={{
                                  ...styles.tableCell,
                                  width: `${tableFields[type]["Model"] * cellRatio}%`,
                                  ...(hasNewCert && {fontFamily: "Frutiger_italic"}),
                                }}
                              >
                                {/* {comp.provided_comp_name} - {comp._id} - {comp.component_code} */}
                                {comp.provided_comp_name}
                              </Text>

                              {/* Spanning multiple rows of certificate data */}
                              <View style={{ width: `${fieldWidth * cellRatio}%` }}>

                                {/* this div includes all field except the first (manufacturer) */}
                                {comp.type_test_certificate_id.map((cert) => {

                                  const matchCert = combinedCertificates.find((c) => (c._id || c.id) === cert); 

                                  const cellRatio2 = 100 / fieldWidth;
                                  /* row of certificate values */
                                  /* Again since we're in a div with width of fieldWidth, we need to multiply another cell ratio */
                                  return (
                                    <View
                                      style={{ ...styles.row }}
                                      key={cert}
                                    >

                                    {Object.keys(tableFields[type])
                                      .filter((field) => !["Manufacturer", "Model"].includes(field) && !appendix?.SCHideColumns?.[type]?.includes(field))
                                      .map((field) => {
                                        return (
                                          <Text
                                            key={field}
                                            style={{
                                              ...styles.tableCell,
                                              ...styles.tableCell_multipleRowItem,
                                              width: `${tableFields[type][field] * cellRatio2}%`,
                                              // ...(certificates.find((c) => c.id === cert) && 
                                              //   (field === "tec_certificate_number" || field === "Approval Reference") && { fontFamily: "Frutiger_bold" }),
                                              // ...field === "Approval Reference" && {flex: 1}, //If user hide other cells, make Approval Reference take up the remaining space
                                              //simpleDisplay to find cert.historical, which comes from backend (certifcateController and getStandalones)
                                              ...(isNewCert(cert) && {
                                                fontFamily: "Frutiger_italic",
                                              }),
                                            }}
                                          >
                                            {field === "Approval Reference" ? (
                                              filteredMatchedCertificates.some(certObj => certObj._id === cert || certObj.id === cert) ? (
                                                filteredMatchedCertificates.map(certObj => {
                                                  if (certObj._id === cert || certObj.id === cert) {
                                                    return (
                                                      <Text key={certObj._id} 
                                                        style={{ ...styles.tableCell }}>
                                                        {`${application.application_number} dated ${moment().format("DD-MM-YYYY")}`}
      
                                                        {certObj?.comply_standard?.lift && (
                                                          <>
                                                            {" "}[<Text style={{ fontFamily: "DejaVu Sans" }}>Ƨ</Text>]
                                                          </>
                                                        )}
                                                      </Text>
                                                    );
                                                  }
                                                  return null; // Return null if no match
                                                })
                                              ) : (
                                                <>
                                                  {simpleDisplay(cert, field) ? (
                                                    renderSymbol(simpleDisplay(cert, field))
                                                  ) : (
                                                    `${application.application_number} dated ${application.approval_date ? 
                                                      fixInvalidDates(application.approval_date.replace(/–/g, "-")).split("T")[0] : 
                                                      ""}`
                                                  )}
             
                                                  {matchCert?.comply_standard?.lift && (
                                                    <span style={{ fontFamily: "DejaVu Sans" }}>
                                                      {" [Ƨ]"}
                                                    </span>
                                                  )}
                                                </>
                                              )
                                            ) : field === "tec_certificate_number" ? (
                                              <>
                                                {renderSymbol(simpleDisplay(cert, field))} issued on {renderSymbol(simpleDisplay(cert, "tec_issue_date"))}
                                              </>
                                            ) : (
                                              simpleDisplay(cert, field) ? renderSymbol(simpleDisplay(cert, field)) : "N/A"
                                            )}
                                          </Text>
                                        );
                                      })}


                                    </View>
                                  );
                                })}
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  );
                })}
              </View>
            );
          })}

        {/* -------------------------------------------------TABLE END------------------------------------------------- */}

        <View break>
          {appendix?.remarks?.length > 0 && (
            <View>
              <Text style={{ fontFamily: "Frutiger_bold" }}>Remarks:</Text>
              {renderIndexedContent(
                appendix?.remarks,
                "pdf",
                application.model,
                "scRemarks"
              )}
            </View>
          )}

          <Text style={{ fontFamily: "Frutiger_bold", marginTop: 10 }}>
            Conditions:
          </Text>
          {renderIndexedContent(appendix?.conditions, "pdf", application.model)}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
        <Image src={emsdLogo} style={styles.logo} fixed />

      </Page>
    </Document>
  );
};

export default AppendixForSC;
