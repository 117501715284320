import { useEffect, useState } from "react";
import {
  useShowController,
  Show,
  SimpleShowLayout,
  Datagrid,
  List,
  ReferenceField,
  TextField,
  FunctionField,
  Create,
  useRecordContext,
  ShowButton,
} from "react-admin";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import static_fields from "../utils/static_fields.json";
import { ComponentShowOne } from "../components/component/ComponentShowOne";
import ComponentFilter from "./filters/ComponentFilter";
import componentsExporter from "./exporters/componentsExporter";
import keyToField from "../utils/keyToField.js";
import isGetOneData from "../utils/applications/isGetOneData.js";
import { useTranslation } from 'react-i18next';


export const ComponentShow = () => {
  const { error, isLoading, record } = useShowController();

  const [update, setUpdate] = useState(null);

  useEffect(() => {
    if (record && isGetOneData(record, "components")) setUpdate(record);
  }, [record]);

  return (
    <Show title={<ComponentTitle />}>
      <SimpleShowLayout>
        {update && (
          <ComponentShowOne upload={update} template={static_fields} />
        )}
      </SimpleShowLayout>
    </Show>
  );
};

export const ComponentList = () => {
  const { t : itranslate } = useTranslation()
  const [isStandalone, setIsStandalone] = useState(false);
  return (
    <List
      filters=<ComponentFilter />
      exporter={componentsExporter}
      title={`- ${itranslate('Components')}`}
      sort={{ field: "approval_date", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false} isRowSelectable={(x) => false}>
        <TextField 
          label={itranslate('Component Code')}
          source="component_code" 
        />

        {/* <TextField 
          label={itranslate('Component Name')}
          source="provided_comp_name"
          render={(record) => {
            return (
              <div style={{ maxWidth: "50px"}}>
                {record && record.provided_comp_name}
              </div>
            )
          }}
        /> */}


        <FunctionField 
          label={itranslate('Component Name')}
          source="provided_comp_name"
          render={(record) => {
            return (
              <div style={{ 
                maxWidth: "150px", 
                fontFamily: "Frutiger_Light",
                fontWeight: '400',
                fontSize: "1.09375rem",
              }}>
                {record && record.provided_comp_name}
              </div>
            )
          }}
        />


        <FunctionField
          // label="Type"
          label={itranslate('Type')}
          source="type"
          // render={(record) => keyToField[record.type] ?? record.type}
          render={(record) => {
            const translationKey = `${keyToField[record.type]}`;
            const translatedField = itranslate(translationKey);
            const fallbackField = keyToField[record.type] ?? record.type;
            return (
              // translatedField && translatedField !== translationKey ? translatedField : fallbackField
              <div style={{ minWidth: "100px"}}>
                {translatedField && translatedField !== translationKey ? translatedField : fallbackField}
              </div>
            )
          }
          }
        />


        <ReferenceField
          source="model_id"
          reference="models"
          label={itranslate('Lift/Escalator Model Type')}
          link={false}
          emptyText="N/A"
        >
          <FunctionField
            source="type"
            render={(record) => itranslate(`${keyToField[record.type]}`) }
            // render={(record) => {
            //   return (
            //     <div style={{ minWidth: "100px"}}>
            //       {/* {record && itranslate(`${keyToField[record.type]}`) } */}
            //     </div>
            //   )
            // }}
          />
        </ReferenceField>


        <ReferenceField
          source="model_id"
          reference="models"
          label={itranslate('Lift/Escalator Model')}
          link="show"
          emptyText="None"
        >
          <FunctionField render={(record) => {
            return record.provided_model_name
          }} />
          {/* <TextField source="provided_model_name" /> */}
        </ReferenceField>


        {/* if the component is standalone, display "Yes", else "No" in the standalone column */}
        <ReferenceField
          source="model_id"
          reference="models"
          label={itranslate('Standalone')}
          link="show"
          emptyText={itranslate('Yes')}
        >
          <FunctionField render={() => {
            return (
              itranslate('No')
            )
          }} />
        </ReferenceField>


        {window.location.pathname === `/lift_emsd` && (
          <ReferenceField 
            label={itranslate('RC')}
            source="rc_id" reference="rcs" link="show"
          >
            <FunctionField
              render={(record) => {
                let rcDisplay = "";
                rcDisplay = record?.rc_number_lift;
                if (record?.rc_number_escalator)
                  rcDisplay += " / " + record?.rc_number_escalator;
                if (record?.address?.company)
                  rcDisplay += " / " + record?.address?.company;
                return rcDisplay || record.name;
              }}
            />
          </ReferenceField>
        )}


        <ReferenceField
          label={itranslate('Manufacturer')}
          source="manufacturer"
          reference="manufacturers"
          link="show"
        >
          <div style={{ maxWidth: "800px" }}>
            <TextField 
              source="provided_name" 
            />
          </div>
        </ReferenceField>

        <ShowButton sx={{ padding: '0' }}/>
        
      </Datagrid>
    </List>
  )
};


const ComponentTitle = () => {
  const { t : itranslate } = useTranslation()
  const record = useRecordContext();
  return <span>
      {itranslate("Component")} {record?.component_code}
      </span>;
};


export const ComponentEdit = () => (
  <Edit title={<ComponentTitle />}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>

      <TextInput source="title" />
      <TextInput multiline source="body" />
    </SimpleForm>
  </Edit>
);


export const ComponentCreate = (props) => (
  <Create {...props} resource="components">
    <SimpleForm>
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="title" />
      <TextInput multiline source="body" />
    </SimpleForm>
  </Create>
);
