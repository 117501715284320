import { createSlice } from "@reduxjs/toolkit";

const pdfSlice = createSlice({
  name: "pdf",
  initialState: {
    value: {
      appendixInfo: null,
      approvalLetterInfo: null,
    },
    daAppendice: {
      url: null,
      encoded: null,
    },
  },
  reducers: {
    setAppendixInfo: (state, action) => {
      state.value.appendixInfo = action.payload;
    },
    setApprovalLetterInfo: (state, action) => {
      state.value.approvalLetterInfo = action.payload;
    },
    setAappendice: (state, action) => {
      state.daAppendice.url = action.payload.url;
      state.daAppendice.encoded = action.payload.encoded;
    },
    clear: (state) => {
      state.value.appendixInfo = null;
      state.value.approvalLetterInfo = null;
    },
    clearAappendice: (state) => {
      state.daAppendice = {
        url: null,
        encoded: null,
      };
    },
  },
});

export const {
  setAppendixInfo,
  setApprovalLetterInfo,
  setAappendice,
  clear,
  clearAappendice,
} = pdfSlice.actions;
export const getdaAppendice = (state) => state.pdf.daAppendice;
export default pdfSlice.reducer;
