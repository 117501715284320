import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

// import {useTranslate} from 'react-admin';
import { useTranslation } from 'react-i18next';

// hoc
import DateRangeComponent from "../../../_hoc/DateRangePicker/index";

// react hooks
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";

const fields = [
  {
    value: 'remark',
    label: 'Remark',
  },
  {
    value: 'createdAt',
    label: 'Creation Date',
  },
  {
    value: 'client_user',
    label: 'Client User',
  },
];


function EnhancedTableToolbar({ selected, numSelected, handleDelete, setFilter, range, sort }) {
  // const translate = useTranslate();
  const { t : itranslate } = useTranslation();
  const [filterField, setFilterField] = useState("client_user");
  const [filterValue, setFilterValue] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const matches = useMediaQuery('(max-width: 600px)');
  
  useEffect(() => {
    setFilterValue("")
  }, [filterField])

  // setFilter when changes in date range.
  useEffect(() => {
    if(filterField === "createdAt"){
      setFilterValue(`${startDate},${endDate}`)
    }
  }, [startDate, endDate])
  
  const handleTextSearch = (e) => {
    e.preventDefault()
    setFilter(`{"${filterField}":"${filterValue}"}`)
  }

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <>
      
      <Toolbar
        sx={{
          mt: 5,
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <>
            <Typography
              sx={{ flex: '1 1 100%'}}
              id="tableTitle"
              component="div"
            >
              {/* {numSelected} {translate("phase2.ru.selected")} */}
              {numSelected} {itranslate("selected")}
            </Typography>
          </>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%', mt: 1}}
            id="tableTitle"
            component="div"
          >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { width: '100%' },
                flexGrow: 1,
                m: 0
              }}
              noValidate
              autoComplete="off"
            >

              <Grid 
                container spacing={{xs: 1, sm: 1, md: 2}} columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  // center
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: "row",
                  m: 0
                }}
              >
                    {
                      filterField !== 'createdAt' && (
                        <Grid item xs={12}>
                          <TextField
                            id="standard-basic" variant="outlined" size='big' 
                            // placeholder="search by remark or client user"
                            placeholder={itranslate("search by remark or client user")}
                            value={filterValue}
                            sx={{
                              boxShadow: "0 0 0 1rem rgba(42,89,143,.25)",
                              borderRadius: "2px",
                            }}
                            InputProps={{
                              startAdornment: 
                                <InputAdornment position="start">
                                  <TextField
                                    id="filterField"
                                    select
                                    // label="Filter"
                                    label={itranslate("Filter")}
                                    defaultValue="client_user"
                                    variant="filled"
                                    onChange={(e) => setFilterField(e.target.value)}
                                    value={filterField}
                                    size="small"
                                  >
                                    {fields.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {/* {translate(`phase2.ru.enhancedTableHead.${option.label}`)} */}
                                        {itranslate(`${option.label}`)}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </InputAdornment>,
                              endAdornment: 
                                <InputAdornment position="end">
                                  <Button
                                    sx={!matches ? {
                                      cursor: "pointer",
                                      color: "#051B44",
                                    } : {
                                      cursor: "pointer",
                                      color: "#051B44",
                                      fontSize: "8px",
                                    }}
                                    onClick={(e) => setFilterValue("")}
                                  >
                                    {/* {translate("phase2.ru.enhancedTableToolbar.clear")} */}
                                    {itranslate("clear")}
                                  </Button>
                                  
                                  <Button
                                    onClick={handleTextSearch}
                                    aria-label='search'
                                    variant="contained"
                                    sx={!matches ? {
                                      backgroundColor: "#2A598F",
                                      '&:hover': {
                                        backgroundColor: "#203C5B",
                                      },
                                    } : {
                                      backgroundColor: "#2A598F",
                                      '&:hover': {
                                        backgroundColor: "#203C5B",
                                      },
                                      fontSize: "8px",
                                    }}
                                  >
                                    {/* {translate("phase2.ru.enhancedTableToolbar.search")} */}
                                    {itranslate("search")}
                                  </Button>
                                </InputAdornment>,
                            }}
                            onChange={(e) => setFilterValue(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleTextSearch(e)
                              }
                            }}
                          />
                        </Grid>
                      )
                    }

                    {
                      filterField === 'createdAt' && (
                        <Grid item xs={12}>
                          <TextField
                            id="standard-basic" variant="outlined" size='big' disabled
                            sx={{
                              boxShadow: "0 0 0 1rem rgba(42,89,143,.25)",
                              borderRadius: "2px",
                              mb:2
                            }}
                            InputProps={{
                              startAdornment:
                                <InputAdornment position="start">
                                  <TextField
                                    id="filterField"
                                    select
                                    // label="Filter"
                                    label={itranslate("Filter")}
                                    defaultValue="client_user"
                                    variant="filled"
                                    onChange={(e) => setFilterField(e.target.value)}
                                    value={filterField}
                                    size="small"
                                  >
                                    {fields.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {/* {translate(`phase2.ru.enhancedTableHead.${option.label}`)} */}
                                        {itranslate(`${option.label}`)}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <span className='mx-3'></span>

                                  <DateRangeComponent 
                                    onDateChange={handleDateChange} 
                                    date1={startDate}
                                    date2={endDate}
                                    useCaseType="ru listing"
                                  />
                                </InputAdornment>,

                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    onClick={handleTextSearch}
                                    aria-label='search'
                                    variant="contained"
                                    sx={!matches ? {
                                      backgroundColor: "#2A598F",
                                      '&:hover': {
                                        backgroundColor: "#203C5B",
                                      },
                                    } : {
                                      backgroundColor: "#2A598F",
                                      '&:hover': {
                                        backgroundColor: "#203C5B",
                                      },
                                      fontSize: "8px",
                                    }}
                                  >
                                    {/* {translate("phase2.ru.enhancedTableToolbar.search")} */}
                                    {itranslate("search")}
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleTextSearch(e)
                              }
                            }}
                          />
                        </Grid>
                      )
                    }

              </Grid>

            </Box>
          </Typography>
        )}

        {numSelected > 0 && (
          <>
            <Tooltip title={itranslate("Delete")}>
              <IconButton
                onClick={() => handleDelete(selected)}
                aria-label='delete'
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Toolbar>
      {
        numSelected > 0 && (
          <Box
            component={"div"}
            sx={{
              mb: 3 
            }}
          >
            {/* placeholder to fix the space */}
          </Box>
        )
      }
    </>

  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default EnhancedTableToolbar