import React from "react";
import { Button, List, ListItem, ListItemText, Divider } from "@mui/material";
import { useTranslation } from 'react-i18next';


const DerivedHideTableB = ({
  appendix,
  setAppendix,
  application,
  model,
  setHideTableProps
}) => {
  // console.log("🚀 ~ appendix:", appendix)
  const { t : itranslate } = useTranslation();


  const fieldName = (model, field) => {
    switch (field) {
      case "type":
        return "Type";
      case "provided_model_name":
        return model.LE_type === "E" ? "Escalator Model" : "Lift Model";
      case "rated_speed":
        return "Rated Speed";
      case "rated_load":
        return "Rated Load";
      case "maximum_travel_of_height":
        return "Travel of Height";
      case "rope_factor":
        return "Roping";
      case "balancing_factor":
        return "Balancing Factor";
      case "maximum_rise":
        return "Maximum Rise";
      case "angle_of_inclination":
        return "Inclination";
      case "manufacturer_files":
        return "Type Exam. Certificate No./ Manufacturer Code/ [EMSD Approval Ref.]";
      default:
        return field;
    }
  };

  const fieldShownForLift = [
    "provided_model_name",
    "type",
    "rated_load",
    "rated_speed",
    "maximum_travel_of_height",
    "rope_factor",
    "balancing_factor",
    "manufacturer_files",
  ].filter((field) => {
    const typeOfMachine = application.app_type?.type;
    switch (typeOfMachine) {
      case "HydraulicLift":
        return field !== "rope_factor" && field !== "balancing_factor";
      case "ElectricalVerticalLiftingPlatform":
        return field !== "balancing_factor";
      default:
        return field;
    }
  });
  

  const fieldShownForEscalator = [
    "provided_model_name",
    "type",
    "angle_of_inclination",
    "rated_speed",
    "maximum_rise",
    "manufacturer_files",
  ];
  //manufacturer files is for displaying type test cert
  let modelFields = model.LE_type === "L" ? fieldShownForLift : fieldShownForEscalator;

  if (appendix.table?.length > 0) {
    const addedField = appendix.table.map((row) => row.field);
    modelFields.splice(-1, 0, ...addedField);
  } 
    // console.log("🚀 ~ modelFields:", modelFields)


    const handleHide = (field) => {
      let section = structuredClone(appendix["hideTableProps"]);
      if (!section.includes(field)) section.push(field);
      setAppendix({ ...appendix, hideTableProps: section });
    };
    
    const handleUnhide = (field) => {
      let section = structuredClone(appendix["hideTableProps"]);
      section = section.filter((f) => f !== field);
      setAppendix({ ...appendix, hideTableProps: section });
    };


    const compulsoryLst = [
      "provided_model_name",
      "type",
      "rated_load",
      "rated_speed",
      "maximum_travel_of_height",
      "rope_factor",
      "balancing_factor",
      "maximum_rise",
      "angle_of_inclination",
      "manufacturer_files",
    ];

    modelFields = modelFields.filter((field) => !compulsoryLst.includes(field));

  
    return (
      <List>
        {modelFields.map((field, i) => {
          const isHiddenCert = appendix["hideTableProps"].includes(field);

          const addedRow = appendix.table?.find((row) => row.field === field);
          if (
            (model[field] && !Array.isArray(model[field])) ||
            (Array.isArray(model[field]) && model[field].length > 0) ||
            addedRow
          )
          
            return (
              <React.Fragment key={i}>
                <ListItem
                   onMouseEnter={() => setHideTableProps(field)}
                   onMouseLeave={() => setHideTableProps(null)}
                   secondaryAction={
                    <Button
                      onClick={() =>
                        isHiddenCert ? handleUnhide(field) : handleHide(field)
                      }
                    >
                      {isHiddenCert ? itranslate("Unhide") : itranslate("Hide")}
                    </Button>
                  }
                >
                  <ListItemText
                    sx={{ maxWidth: "500px" }}
                    primary={fieldName(model, field)}
                    // secondary={field}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          return null;
        })}
      </List>
    );
}

export default DerivedHideTableB