import React, { useState, useEffect } from "react";
import { Box, Divider, Button, Typography } from "@mui/material";
import DerivedHideCerts from "./DerivedHideCerts";
import { useTranslation } from 'react-i18next';


const DerivedAppendixEditInput = ({
  application,
  appendix,
  setAppendix,
  certificates,
  section,
  simpleDisplay,
  manufacturers,
  components,
  setCompLevel,
  setCertLevel,
}) => {
  const { t : itranslate } = useTranslation();

  const [hideAll, setHideAll] = useState(false);
  // const [hideIndividualCertsAll, setHideIndividualCertsAll] = useState(false)
  const [allComponentTypes, setAllComponentTypes] = useState([]) ;


  useEffect(() => {
    function getComponentTypes() {
      let CompTypes = [];

      Object.keys(application.app_type?.components || {})
        .filter(
          (component_type) =>
            !["TractionMachine", "Controller"].includes(component_type)
        )
        .sort((a, b) => {
          const order = [
            "Buffer",
            "OverspeedGovernor",
            "LandingDoorLockingDevice",
            "CarDoorLockingDevice",
            "SafetyGear",
            "ACOP",
            "UCMP",
            "SafetyCircuitElectricalComponent",
          ];
          function customSort(item) {
            const index = order.indexOf(item);
            return index !== -1 ? index : order.length;
          }
          return customSort(a) - customSort(b);
        })
        .forEach((component_type, j) => {
          CompTypes.push(component_type);
        });

      setAllComponentTypes(CompTypes);
    }

    getComponentTypes();
  }, []); 




//  const handleHideAll = () => {
//     let section = structuredClone(appendix["hideComponentCodes"]);
//     const allIds = certificates
//       .filter((cert) => cert.type === "ComponentTypeCert")
//       .map((cert) => cert.reference);

//     section = allIds;

//     let unique = [];
//     section.forEach((element) => {
//       if (!unique.includes(element)) {
//         unique.push(element);
//       }
//     });

//     setAppendix({ ...appendix, hideComponentCodes: unique });
//     setHideAll(true);
//   };


  const handleHideAll = () => {
    const all_component_codes = certificates
      .filter((cert) => cert.type === "ComponentTypeCert")
      .map((cert) => cert.reference);
  
    const unique = [];
  
    all_component_codes.forEach((component_code) => {
      allComponentTypes.forEach((component_type) => {
        unique.push({
          component_type: component_type,
          component_code: component_code,
        });
      });
    });
  
    setAppendix({ ...appendix, hideComponentCodes2: unique });
    setHideAll(true);
  };


  const handleUnhideAll = () => {
    let section = structuredClone(appendix["hideComponentCodes2"]);
    section = [];
    setAppendix({ ...appendix, hideComponentCodes2: section });
    setHideAll(false);
  };




  // const handleIndividualCertsHideAll = () => {
  //   const allIds = certificates
  //     .filter((cert) => cert.type === "ComponentTypeCert")
  //     .map((cert) => cert.id);

  //   const unique = [];
  
  //   allIds.forEach((cert_id) => {
  //     allComponentTypes.forEach((component_type) => {
  //       unique.push({
  //         component_type: component_type,
  //         cert_id: cert_id,
  //       });
  //     });
  //   });

  //   setAppendix({ ...appendix, hideIndividualCerts2: unique });
  //   setHideIndividualCertsAll(true);
  // };


  // const handleIndividualCertsUnhideAll = () => {
  //   let section = structuredClone(appendix["hideIndividualCerts2"]);
  //   section = [];
  //   setAppendix({ ...appendix, hideIndividualCerts2: section });
  //   setHideIndividualCertsAll(false);
  // };




  return (
    <>
      <Typography
        style={{
          width: "100%",
          fontFamily: "Frutiger_bold",
          fontSize: "16px",
          marginTop: "30px",
          marginLeft: "9px",
        }}
      >
        {/* Component(s) */}
        {itranslate("Component(s)")}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
        <Button onClick={hideAll ? handleUnhideAll : handleHideAll}>
          {hideAll ? itranslate("Unhide All Components") : itranslate("Hide All Components")}
        </Button>
      </Box>
{/* 
      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
        <Button onClick={hideIndividualCertsAll ? handleIndividualCertsUnhideAll : handleIndividualCertsHideAll}>
          {hideIndividualCertsAll ? "Unhide All Certificates" : "Hide All Certificates"}
        </Button>
      </Box> */}

      <Box sx={{ px: 1, my: 1 }}>
        {section === "hideCertificates" &&
          Object.keys(application.app_type?.components || {})
            .filter(
              (component_type) =>
                !["TractionMachine", "Controller"].includes(component_type)
            )
            .sort((a, b) => {
              const order = [
                "Buffer",
                "OverspeedGovernor",
                "LandingDoorLockingDevice",
                "CarDoorLockingDevice",
                "SafetyGear",
                "ACOP",
                "UCMP",
                "SafetyCircuitElectricalComponent",
              ];
              function customSort(item) {
                const index = order.indexOf(item);
                return index !== -1 ? index : order.length;
              }
              return customSort(a) - customSort(b);
            })
            .map((component_type, j) => {

              return (
                <div key={`${component_type}-C`}>
                  <DerivedHideCerts
                    application={application}
                    appendix={appendix}
                    setAppendix={setAppendix}
                    certificates={certificates}
                    section={section}
                    simpleDisplay={simpleDisplay}
                    manufacturers={manufacturers}
                    components={components}
                    component_type={component_type}
                    setCompLevel={setCompLevel}
                    // setCertLevel={setCertLevel}
                  />
                  <Divider />
                </div>
              );
            })}
      </Box>
    </>
  );
};

export default DerivedAppendixEditInput;
