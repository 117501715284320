// in src/users.js
import * as React from "react";

import { Datagrid, List, ReferenceField, TextField, DateField } from 'react-admin';


export const ActionList = () => (
	<List>
		<Datagrid rowClick="edit">
			<DateField source="created_at" />
			<TextField source="id" />
			{/* <TextField source="name" /> */}
			{/* <TextField source="username" /> */}
			<ReferenceField source="user_id" reference="users">
				<TextField source="email" />
			</ReferenceField>
			<TextField source="type" />
			{/* <TextField source="address.street" /> */}
			{/* <TextField source="phone" /> */}
			{/* <MyUrlField source="website" /> */}
			{/* <TextField source="company.name" /> */}
		</Datagrid>
	</List>
);

