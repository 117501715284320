//In react admin, there's a weird behaviour that if you use useGetOne, the data might not actually the data from getOne, but the data from getList.
//This is because react admin expect getOne and getList to return the same data for every document, so it will use the data from getList if it's available.
//This might causes bug, therefore needs to check if the data is actually from getOne.
function isGetOneData(data, type) {
  try {
    if (
      type !== "applications" &&
      type !== "manufacturers" &&
      type !== "components" &&
      type !== "models"
    )
      return false;

    let result = true;

    if (type === "applications") {
      if (
        data.manufacturer.length > 0 &&
        !data.manufacturer.every((manu) => typeof manu === "object")
      )
        result = false;

      if (
        data.model.length > 0 &&
        !data.model.every((manu) => typeof manu === "object")
      )
        result = false;

      if (
        !Object.values(data.app_type.components)
          .flat()
          .every((comp) => typeof comp === "object")
      )
        result = false;
    } else if (type === "manufacturers") {
      if (
        !data.componentsPopulated ||
        !data.modelsPopulated ||
        !data.applicationsPopulated
      )
        result = false;
    } else if (type === "components") {
      if (
        !data.certsPopulated ||
        !data.manufacturersPopulated ||
        !data.modelsPopulated ||
        !data.applicationsPopulated
      )
        result = false;
    } else if (type === "models") {
      if (
        !data.application_id ||
        !data.componentsPopulated ||
        !data.applicationsPopulated ||
        !data.certsPopulated ||
        !data.manufacturersPopulated
      )
        result = false;
    }
    return result;
  } catch (err) {
    return false;
  }
}

export default isGetOneData;
