import { defaultTheme } from "react-admin";
import { createTheme } from "@mui/material/styles";
import merge from "lodash/merge";

// later I would redux a nonce in order to use it globally.
// and then assign it to window.__CSP_NONCE__

// then, I can parse it to sx prop in MUI

//  or instead of parsing it to component's prop one by one, I can just do the following

// const divElement = document.getElementById('myDiv'); // Replace 'myDiv' with the actual ID of your <div> element

// if (divElement) {
//   divElement.style.setProperty('margin-top', '1rem', 'important');
//   divElement.setAttribute('nonce', nonce);
// }


const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      primary: {
        main: "#2a598f",
        contrastText: "#fff",
      },
    },

    typography: {
      //font defined in Appendix.css
      fontFamily: "Frutiger_light",
      fontSize: 17.5,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      RaLayout: {
        styleOverrides: {
          root: {
            "& .RaLayout-content": {
              backgroundColor: "#ffffff",
            },
            "& .RaLayout-contentWithSidebar": {
              backgroundColor: "#ffffff",
            },
          },
        },
      },
      RaMenuItemLink: {
        styleOverrides: {
          root: {
            "&.RaMenuItemLink-active": {
              backgroundColor: "#eaecf0",
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            "&.Mui-active": {
              color: "#2a598f",
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  })
);

export default theme;
