import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
} from "@mui/material";
import keyToField from "../../utils/keyToField";
import { Link } from "react-router-dom";
import variable_fields from "../../utils/variable_fields.json";
import { fixInvalidDates } from "../../utils/misc/fixInvalidDates";
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { useTranslation } from 'react-i18next';


function ManufacturerExtraDetail(props) {
  const { manufacturer } = props;
  const { t : itranslate } = useTranslation();

  const [hoveredCert, setHoveredCert] = React.useState("");

  const [ascOrderedRcs, setAscOrderedRcs] = React.useState([]);
  const [ascOrderedComps, setAscOrderedComps] = React.useState([]);
  const [ascOrderedModels, setAscOrderedModels] = React.useState([]);
  const [ascOrderedApps, setAscOrderedApps] = React.useState([]);


  useEffect(() => {
    // sort rcs by rc_name, group by RC
    setAscOrderedRcs(manufacturer.rcsComps.sort((a, b) => {
      return Number(a.name.split("C")[1]) - Number(b.name.split("C")[1])
    }))

    // sort componentsPopulated by component_code, group by RC
    setAscOrderedComps(manufacturer.componentsPopulated.sort((a, b) => {
      return Number(a.component_code.split("RC")[1]) - Number(b.component_code.split("RC")[1])
    }))
    // sort modelsPopulated by model_code
    setAscOrderedModels(manufacturer.modelsPopulated.sort((a, b) => {
      return Number(a.model_code.split("RM")[1]) - Number(b.model_code.split("RM")[1])
    }))
    // sort applicationsPopulated by application_number
    setAscOrderedApps(manufacturer.applicationsPopulated.sort((a, b) => {
      return Number(a.application_number.split("BM")[1]) - Number(b.application_number.split("BM")[1])
    }))
  }, [])


  return (
    <Box sx={{ my: 3 }}>

      {
        window.location.pathname === "/lift_emsd" && (
          <TableContainer sx={{ width: "800px" }}>
            <Typography sx={{ fontWeight: "bold", mt: 3 }}>
              {itranslate("Registered Lift Contractors")}
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {itranslate("RC number")}
                  </TableCell>
                  <TableCell>
                    {itranslate("RC company name")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow >

                  <TableCell>
                    {manufacturer?.rcsFromAppPopulated?.map((rc) => {
                      return (
                        <div key={uuid()} className="my-1">
                          <Link to={`/rcs/${rc._id}/show`} target="_blank">
                            {rc.rc_number_lift}
                          </Link>
                        </div>
                      );
                    })}
                  </TableCell>

                  <TableCell>
                    {manufacturer?.rcsFromAppPopulated?.map((rc) => {
                      return (
                        <div key={uuid()} className="my-1">
                          <Link to={`/rcs/${rc._id}/show`} target="_blank">
                            {rc?.address?.company}
                          </Link>
                        </div>
                      );
                    })}
                  </TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
      <TableContainer sx={{ width: "800px" }}>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          {itranslate("Certification of Quality Assurance Scheme")}
        </Typography>

        <Table size="small">
          <TableBody>
            {Object.keys(manufacturer?.certsPopulated?.cert_type_metadata || {})
              .concat(Object.keys(manufacturer?.certsPopulated?.cert_corrections || {}))
              .filter((field) => window.location.pathname === "/lift_rc" ? field !== "iso_expiry_date" : field)
              .map((field) => {
                const translationKey = `${keyToField[field]}`;
                const translatedField = itranslate(translationKey);
                const fallbackField = keyToField[field] ?? field;

                return (
                  <TableRow key={field}>
                    <TableCell sx={{ fontWeight: "bold" }} variant="head">
                      {/* {keyToField[field] ?? field} */}
                      {/* {itranslate(`manufacturer.${keyToField[field]}`) } */}
                        {translatedField && translatedField !== translationKey ? translatedField : fallbackField} {/* Check for translation */}
                    </TableCell>
                    
                    <TableCell>
                      {manufacturer?.certsPopulated?.cert_corrections?.[field] || manufacturer?.certsPopulated?.cert_type_metadata?.[field]}
                    </TableCell>
                    
                    {/* {field === "iso_issue_date" || field === "iso_expiry_date" ? (

                      fixInvalidDates((manufacturer?.certsPopulated?.cert_corrections?.[field]?.split("T")[0] || "").replace(/–/g, "-")) || 

                      fixInvalidDates((manufacturer?.certsPopulated?.cert_type_metadata?.[field]?.split("T")[0] || "").replace(/–/g, "-"))
                    ) :
                      (
                        manufacturer?.certsPopulated?.cert_corrections?.[
                          field
                        ] ||
                          manufacturer?.certsPopulated?.cert_type_metadata?.[
                            field
                          ]
                      )
                    } */}

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer sx={{ width: "800px" }}>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          {itranslate("Manufactured lift/escalator models")}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                {itranslate("Model Code")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {itranslate("Model Name")}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {ascOrderedModels.map((model) => {
              return (
                <TableRow key={model._id}>
                  <TableCell>{model.model_code}</TableCell>
                  <TableCell>
                    <Link to={`/models/${model._id}/show`}>
                      {model.provided_model_name}
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

        </Table>
      </TableContainer>
      

      {/* manufactured comps */}
      {
        window.location.pathname === "/lift_emsd" && (
          <TableContainer>
            <Typography sx={{ fontWeight: "bold", mt: 3 }}>
              {itranslate("Manufactured Components")}
            </Typography>
            {
              ascOrderedRcs.map((rc) => {
                return (
                  <React.Fragment key={uuid()}>
                    <Typography sx={{ fontWeight: "bold", fontSize: 18, mt: 3, ml: 2 }}>
                      {rc.rc_number_lift || 'N/A'} - {rc.address.company || 'N/A'}
                    </Typography>
                    <Table size="small" key={uuid()}>

                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Type")}</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Component Code")}</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Component Name")}</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Cert No.")}</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Issue date")}</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {Object.keys(variable_fields.component)
                          .sort((a, b) => {
                            const order = [
                              "SafetyGear",
                              "Buffer",
                              "OverspeedGovernor",
                              "LandingDoorLockingDevice",
                              "CarDoorLockingDevice",
                              "ACOP",
                              "UCMP",
                              "SafetyCircuitElectricalComponent",
                              "OneWayValve",
                              "RaptureValve",
                              "Buffer",
                            ];
                            function customSort(item) {
                              const index = order.indexOf(item);
                              return index !== -1 ? index : order.length;
                            }
                            return customSort(a) - customSort(b);
                          })
                          .map((type) => {
                            return rc.componentsPopulated
                              .filter((comp) => comp.type === type)
                              .map((comp, compIndex, compList) => {
                                return (
                                  <TableRow key={comp._id}>

                                    {compIndex === 0 && (
                                      <TableCell
                                        rowSpan={compList.length}
                                        sx={{ verticalAlign: "text-top" }}
                                      >
                                        {itranslate(`${keyToField[type]}`)}
                                      </TableCell>
                                    )}

                                    <TableCell>{comp.component_code}</TableCell>

                                    <TableCell>
                                      <Link to={`/components/${comp._id}/show`}>
                                        {comp.provided_comp_name}
                                      </Link>
                                    </TableCell>

                                    <TableCell>
                                      {comp.certsPopulated?.map((cert) => {
                                          return <div
                                            key={cert._id}
                                            className={(hoveredCert === cert._id) ? "bg-sky-200" : ""}
                                            onMouseEnter={() => {setHoveredCert(cert._id)}}
                                            onMouseLeave={() => {setHoveredCert(null)}}
                                          >
                                            {cert?.cert_corrections?.tec_certificate_number || cert?.cert_type_metadata?.tec_certificate_number}
                                          </div>
                                        })
                                      }
                                    </TableCell>

                                    <TableCell>
                                      {comp.certsPopulated
                                        ?.map(
                                          (cert) => {
                                            const tecIssueDateCorrection = cert?.cert_corrections?.tec_issue_date || "";
                                            const tecIssueDateMetadata = cert?.cert_type_metadata?.tec_issue_date || "";

                                            // Regex to check if the string ends with "(Ƨ)"
                                            const isSpecialCase = (dateString) => /\s*\(Ƨ\)$/.test(dateString);

                                            // Function to determine the display date
                                            const getDisplayDate = (dateString) => {
                                              const cleanedDate = dateString.replace(/–/g, "-");
                                              return isSpecialCase(cleanedDate) ? cleanedDate : fixInvalidDates(cleanedDate);
                                            };

                                            // Using the function to display the date
                                            const displayDate = 
                                            (tecIssueDateCorrection && getDisplayDate(tecIssueDateCorrection)) ||
                                            (tecIssueDateMetadata && getDisplayDate(tecIssueDateMetadata));

                                            return (
                                              <div
                                                key={cert._id}
                                                className={(hoveredCert === cert._id) ? "bg-sky-200" : ""}
                                                onMouseEnter={() => setHoveredCert(cert._id)}
                                                onMouseLeave={() => setHoveredCert(null)}
                                              >
                                                {/* {(cert?.cert_corrections?.tec_issue_date && fixInvalidDates((cert?.cert_corrections?.tec_issue_date || "").replace(/–/g, "-"))) ||
                                                  (cert?.cert_type_metadata?.tec_issue_date && fixInvalidDates((cert?.cert_type_metadata?.tec_issue_date || "").replace(/–/g, "-")))} */}
                                                  {displayDate}
                                              </div>
                                            )
                                          }

                                        )
                                      }
                                    </TableCell>

                                  </TableRow>
                                );
                              });
                          })}
                      </TableBody>
                    </Table>
                  </React.Fragment>

                )
              })
            }
          </TableContainer>

        )
      }

      {
        window.location.pathname === "/lift_rc" && (
          <TableContainer>
            <Typography sx={{ fontWeight: "bold", mt: 3 }}>
              {itranslate("Manufactured Components")}
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Type")}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Component Code")}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Component Name")}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Cert No.")}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Issue date")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(variable_fields.component)
                  .sort((a, b) => {
                    const order = [
                      "SafetyGear",
                      "Buffer",
                      "OverspeedGovernor",
                      "LandingDoorLockingDevice",
                      "CarDoorLockingDevice",
                      "ACOP",
                      "UCMP",
                      "SafetyCircuitElectricalComponent",
                      "OneWayValve",
                      "RaptureValve",
                      "Buffer",
                    ];
                    function customSort(item) {
                      const index = order.indexOf(item);
                      return index !== -1 ? index : order.length;
                    }
                    return customSort(a) - customSort(b);
                  })
                  .map((type) => {
                    return ascOrderedComps
                      .filter((comp) => comp.type === type)
                      .map((comp, compIndex, compList) => {
                        return (
                          <TableRow key={comp._id}>

                            {compIndex === 0 && (
                              <TableCell
                                rowSpan={compList.length}
                                sx={{ verticalAlign: "text-top" }}
                              >
                                {itranslate(`${keyToField[type]}`)}
                              </TableCell>
                            )}

                            <TableCell>{comp.component_code}</TableCell>

                            <TableCell>
                              <Link to={`/components/${comp._id}/show`}>
                                {comp.provided_comp_name}
                              </Link>
                            </TableCell>

                            <TableCell>
                              {comp.certsPopulated?.map((cert) => {
                                  return <div
                                    key={cert._id}
                                    className={(hoveredCert === cert._id) ? "bg-sky-200" : ""}
                                    onMouseEnter={() => {setHoveredCert(cert._id)}}
                                    onMouseLeave={() => {setHoveredCert(null)}}
                                  >
                                    {cert?.cert_corrections?.tec_certificate_number || cert?.cert_type_metadata?.tec_certificate_number}
                                  </div>
                                })
                              }
                            </TableCell>

                            <TableCell>
                              {comp.certsPopulated?.map((cert) => 
                                  { 
                                    const tecIssueDateCorrection = cert?.cert_corrections?.tec_issue_date || "";
                                    const tecIssueDateMetadata = cert?.cert_type_metadata?.tec_issue_date || "";

                                    // Regex to check if the string ends with "(Ƨ)"
                                    const isSpecialCase = (dateString) => /\s*\(Ƨ\)$/.test(dateString);

                                    // Function to determine the display date
                                    const getDisplayDate = (dateString) => {
                                      const cleanedDate = dateString.replace(/–/g, "-");
                                      return isSpecialCase(cleanedDate) ? cleanedDate : fixInvalidDates(cleanedDate);
                                    };

                                    // Using the function to display the date
                                    const displayDate = 
                                    (tecIssueDateCorrection && getDisplayDate(tecIssueDateCorrection)) ||
                                    (tecIssueDateMetadata && getDisplayDate(tecIssueDateMetadata));

                                    return (
                                      <div
                                        key={cert._id}
                                        className={(hoveredCert === cert._id) ? "bg-sky-200" : ""}
                                        onMouseEnter={() => {setHoveredCert(cert._id)}}
                                        onMouseLeave={() => {setHoveredCert(null)}}
                                      >
                                        {/* {(cert?.cert_corrections?.tec_issue_date && fixInvalidDates((cert?.cert_corrections?.tec_issue_date || "").replace(/–/g, "-"))) ||
                                          (cert?.cert_type_metadata?.tec_issue_date && fixInvalidDates((cert?.cert_type_metadata?.tec_issue_date || "").replace(/–/g, "-")))} */}

                                        {displayDate}
                                      </div>
                                    )
                                  }

                                )
                              }
                            </TableCell>
                          </TableRow>
                        );
                      });
                  })}
              </TableBody>
            </Table>
          </TableContainer>

        )
      }

      <List
        subheader={
          <Typography sx={{ fontWeight: "bold", mt: 3 }}>
            {itranslate("Related Applications")}
          </Typography>
        }
      >
        {ascOrderedApps.map((app) => {
          return (
            <ListItem
              key={app._id}
              sx={{ pl: 0, pr: 0, fontFamily: "Frutiger_light" }}
            >
              <Link to={`${window.location.pathname === "/lift_emsd" ? "/v2/applications" : "/applications"}/${app._id}/show`}>
                {app.application_number}
              </Link>
            </ListItem>
          );
        })}
      </List>

      {/* manufacturer name change history */}
      {manufacturer?.manufsNameChangeHistory?.length > 0 && 
          <TableContainer>
            <Typography sx={{ fontWeight: "bold", mt: 3 }}>
              {itranslate("Name Change History")}
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Application Number")}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Name")}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Previous Name")}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{itranslate("Approval Date")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  manufacturer.manufsNameChangeHistory?.map((history) => {
                    return (
                      <TableRow key={history._id}>
                        <TableCell>
                          <Link to={`${window.location.pathname === "/lift_emsd" ? "/v2/applications" : "/applications"}/${history.application_id}/show`}>
                            {history.application_number}
                          </Link>
                        </TableCell>
                        <TableCell>{history.name}</TableCell>
                        <TableCell>{history.previous_name}</TableCell>
                        <TableCell>{fixInvalidDates((history.approval_date || "").replace(/–/g, "-"))}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
      }
      <Box>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          {itranslate("Approved Date")}
        </Typography>
        {manufacturer.approval_date.split("T")[0] && fixInvalidDates((manufacturer.approval_date || "").replace(/–/g, "-"))}
      </Box>
    </Box>
  );
}

export default ManufacturerExtraDetail;
