// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import enJSON from './locale/en.json'
// import chTraditionalJSON from './locale/ch-traditional.json'

// // i18n.use(initReactI18next).init({
// //     resources: {
// //         en:{...enJSON},
// //         chTraditional: {...chTraditionalJSON},
// //     }, // Where we're gonna put translations' files
// //     lng: "en",     // Set the initial language of the App
// // });

// const resources = {
//     en: {
//       translation: enJSON
//     },
//     ch: {
//       translation: chTraditionalJSON
//     },
// };

// // Add this console.log statement
// i18n.on('languageChanged', (lng) => {
//     console.log(`Language changed to: ${lng}`);
//   });

// i18n
//     .use(initReactI18next)
//     .init({
//         resources,
//         lng: "en",
//         fallbackLng: "en",
//         interpolation: {
//             escapeValue: false,
//         },
//     });

// export default i18n;

// // src/i18n.js
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import enJSON from './locale/en.json';
// import chTraditionalJSON from './locale/ch-traditional.json';

// i18n.use(initReactI18next).init({
//     resources: {
//         en: { translation: enJSON },
//         ch: { translation: chTraditionalJSON },
//     },
//     lng: "en", // Set the initial language of the App
//     fallbackLng: "en",
//     interpolation: {
//         escapeValue: false, // React already escapes values
//     },
// });

// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enCommon from "./locale/en/common.json"; 
import entapas from "./locale/en/tapas.json";
import chtapas from "./locale/ch/tapas.json";
import chCommon from "./locale/ch/common.json";

// Define resources
const resources = {
  en: {
    translation: {
      ...entapas,
      ...enCommon
    },
  },
  ch: {
    translation: {
      ...chtapas,
      ...chCommon
    },
  },
};

// Language change event
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("selectedLanguage", lng); // Update localStorage
  console.log(`Language changed to: ${lng}`);
});

// Initialize i18next
i18n.use(initReactI18next).init({
  resources,
  // lng: "en", // Default language
  lng: localStorage.getItem("selectedLanguage") || "en", // Default language from localStorage
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
