import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t : itranslate } = useTranslation()
  return (
    <div
      className="flex items-center justify-center bg-white h-20 text-black font-light text-sm leading-none emsdFontLight_global"
    >
      2023 ©
      <span
        className="px-5"
      >
        |
      </span>
      <Link
        className="text-black font-light text-sm leading-none emsdFontLight_global no-underline"
        to="/footer/important_notices"
      >
        {/* Important Notices */}
        {itranslate('Important Notices')}
      </Link>

      <span
        className="px-5"
      >
        |
      </span>
      <Link
        className="text-black font-light text-sm leading-none emsdFontLight_global no-underline"
        to="/footer/privacy_policy"
      >
        {/* Privacy Policy */}
        {itranslate('Privacy Policy')}
      </Link>

      <span
        className="px-5"
      >
        |
      </span>
      <Link
        className="text-black font-light text-sm leading-none emsdFontLight_global no-underline"
        to="/footer/about_us"
      >
        {/* About Us */}
        {itranslate('About Us')}
      </Link>

      <span
        className="px-5"
      >
        |
      </span>
      <Link
        className="text-black font-light text-sm leading-none emsdFontLight_global no-underline"
        to="/footer/contact_us"
      >
        {/* Contact Us */}
        {itranslate('Contact Us')}
      </Link>

      <span
        className="px-5"
      >
        |
      </span>
      <span className="text-black font-light text-sm leading-none emsdFontLight_global no-underline"
      // >&nbsp;Last Revision Date: 04 Mar 2024</span>
      // >&nbsp;{itranslate('Last Revision Date')}: 04 {itranslate('Mar')} 2024</span>
      >&nbsp;{itranslate('Last Revision Date')}: 04 Mar 2024</span>
    </div>
  );
}

export default Footer;
