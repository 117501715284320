import React, { useState } from 'react'
import { Button, List, ListItem, ListItemText, Divider, Box } from "@mui/material";
import { useTranslation } from 'react-i18next';


const DerivedHideIsoCert = ({ application, appendix, setAppendix, certificates, setHideManufs }) => {
  const { t : itranslate } = useTranslation();
  const [hideAll, setHideAll] = useState(false);

    
    const handleHide = (id) => {
      let section = structuredClone(appendix["hideCerts"]);
      if (!section.includes(id)) section.push(id);
      setAppendix({ ...appendix, hideCerts: section });
    };
  
    const handleUnhide = (id) => {
      let section = structuredClone(appendix["hideCerts"]);
      section = section.filter((cert) => cert !== id);
      setAppendix({ ...appendix, hideCerts: section });
    };


  
    if (!certificates || !appendix) return null;



    const model_manufacturer = application?.model
    .flatMap((model) => model?.manufacturer_files)
    .map((file) =>
      application.manufacturer.find((manu) => manu?._id === file?.manufacturer_id)
    );

    // Need to find out which certificates match the manufacturer(s)
    const matchingCertificates = [];
    for (const manufacturer of model_manufacturer) {
      const isoCertificateId = manufacturer?.iso_certificate_id;
      for (const certificate of certificates) {
        if (certificate?.id === isoCertificateId) {
          matchingCertificates.push(certificate);
        }
      }
    }



    const handleHideAll = () => {
      const allIds = matchingCertificates.map((cert) => cert?.id);
      setAppendix({ ...appendix, hideCerts: allIds });
      setHideAll(true);
    };
    
    const handleUnhideAll = () => {
      setAppendix({ ...appendix, hideCerts: [] });
      setHideAll(false);
    };



    const handleHideAll2 = () => {
      const allManufacturers = appendix["manufacturer_code"].map((manufacturer) => manufacturer.name + manufacturer.address);
      setAppendix({ ...appendix, hideCerts: allManufacturers });
      setHideAll(true);
    };
    
    const handleUnhideAll2 = () => {
      setAppendix({ ...appendix, hideCerts: [] });
      setHideAll(false);
    };



  
    return (
      <>
        {appendix?.manufacturer_code.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button onClick={hideAll ? handleUnhideAll2 : handleHideAll2}>
                {hideAll
                  ? itranslate("Unhide All Manufacturers")
                  : itranslate("Hide All Manufacturers")}
              </Button>
            </Box>

            <List>
              {appendix.manufacturer_code?.map((manufacturer, i) => {
                const isHidden2 = appendix.hideCerts.includes(
                  manufacturer.name + manufacturer.address
                );

                return (
                  <React.Fragment key={i}>
                    <ListItem
                      onMouseEnter={() =>
                        setHideManufs(manufacturer.name + manufacturer.address)
                      }
                      onMouseLeave={() => setHideManufs(null)}
                      secondaryAction={
                        <Button
                          sx={{ marginRight: "7px" }}
                          onClick={() =>
                            isHidden2
                              ? handleUnhide(
                                  manufacturer.name + manufacturer.address
                                )
                              : handleHide(
                                  manufacturer.name + manufacturer.address
                                )
                          }
                        >
                          {isHidden2 ? itranslate("Unhide") : itranslate("Hide")}
                        </Button>
                      }
                    >
                      <ListItemText
                        primary={manufacturer.name}
                        secondary={manufacturer.address}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button onClick={hideAll ? handleUnhideAll : handleHideAll}>
                {hideAll
                  ? itranslate("Unhide All Manufacturers")
                  : itranslate("Hide All Manufacturers")}
              </Button>
            </Box>

            <List>
              {matchingCertificates
                .filter((cert) => cert?.type === "ISO")
                .sort((a, b) => a.reference - b.reference)
                .map((cert, i) => {
                  const isHidden = appendix["hideCerts"].includes(cert?.id);
                  let manufacturer_name;
                  let manufacturer_address;

                  if (
                    cert.cert_corrections?.iso_manufacturer_name !== undefined
                  ) {
                    manufacturer_name =
                      cert.cert_corrections?.iso_manufacturer_name;
                  } else {
                    manufacturer_name =
                      cert.cert_type_metadata?.iso_manufacturer_name;
                  }

                  if (
                    cert.cert_corrections?.iso_manufacturer_address !==
                    undefined
                  ) {
                    manufacturer_address =
                      cert.cert_corrections?.iso_manufacturer_address;
                  } else {
                    manufacturer_address =
                      cert.cert_type_metadata?.iso_manufacturer_address;
                  }

                  return (
                    <React.Fragment key={cert.id}>
                      <ListItem
                        onMouseEnter={() => setHideManufs(cert.id)}
                        onMouseLeave={() => setHideManufs(null)}
                        secondaryAction={
                          <Button
                            sx={{ marginRight: "7px" }}
                            onClick={() =>
                              isHidden
                                ? handleUnhide(cert.id)
                                : handleHide(cert.id)
                            }
                          >
                            {isHidden ? itranslate("Unhide") : itranslate("Hide")}
                          </Button>
                        }
                      >
                        <ListItemText
                          primary={manufacturer_name}
                          secondary={manufacturer_address}
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })}
            </List>
          </>
        )}
      </>
    );
  };
  


export default DerivedHideIsoCert