import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import keyToField from "../../utils/keyToField";
import renderChinese from "../../utils/pdf/renderChinese";
import moment from "moment";
import { fixInvalidDates } from "../../utils/misc/fixInvalidDates";
import renderSymbol from "../../utils/pdf/renderSymbol";

const styles = StyleSheet.create({
  tableContainer: {
    padding: "5px 0px",
    marginRight: "-15px",
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "none",
    fontFamily: "Frutiger_bold",
    fontSize: "11px",
    marginRight: "-45px",
    marginBottom: "15px",
  },
});

const PartCCompTable = ({
  appendix,
  application,
  component_type,
  romanize,
  index,
  manufacturers,
  liftModels,
  components,
  certificates,
  simpleDisplay,
}) => {

  if (!certificates || !appendix || !application) return null;

  
  // get unique manufacturers by manufacturer code
  let uniqueManufacturers = [];
  const manufacturerCodes = new Set();
  
  for (const manufacturer of manufacturers) {
    if (!manufacturerCodes.has(manufacturer.manufacturer_code)) {
      manufacturerCodes.add(manufacturer.manufacturer_code);
      uniqueManufacturers.push(manufacturer);
    }
  };
  // sort the manufacturers alphabetically
  uniqueManufacturers.sort((a, b) => a.provided_name.localeCompare(b.provided_name));
  // console.log("🚀 ~ uniqueManufacturers:", uniqueManufacturers)



  const checkLiftStandard = [
    "HydraulicLift",
    "ElectricTractionLiftMachineRoom",
    "ElectricTractionLiftRoomless",
  ].includes(application.app_type?.type);

  const checkEscalatorStandard = ["Escalator", "PassengerConveyor"].includes(application.app_type?.type);


  // used later to store the unique keys to remove duplicates
  const unique_Keys = new Set();


  return (
    <View style={styles.tableContainer}>
      <Text
        style={{
          textDecoration: "underline",
          fontFamily: "Frutiger_bold",
          fontSize: "11px",
        }}
      >{`${romanize(liftModels.length + index + 1)}. Safety Components - ${
        keyToField[component_type]
      }`}</Text>
      <View style={styles.tableHead}>
        <Text style={{ width: "33.3333%", paddingLeft: "6px" }}>Model</Text>
        <Text style={{ width: "33.3333%" }}>Type Exam. Certificate</Text>
        <Text style={{ width: "33.3333%" }}>Issue Date</Text>
      </View>

        {/* group the components by manufacturer code */}
        {uniqueManufacturers?.map((manu, i) => {
          // {manufacturers?.map((manu, i) => {
            // const componentsOfManu = components[component_type].filter((compModel) => compModel.manufacturer_id === manu._id);
          const componentsOfManu = components[component_type].filter((compModel) => compModel.manufacturer_code === manu.manufacturer_code);


          function getCertList(component) {
            const certs = certificates.filter(
              (tec) =>
                tec?.reference === component.component_code &&
                tec.component_type === component_type &&
                tec.type === "ComponentTypeCert" &&
                !appendix.hideCerts?.includes(tec.id) &&
                !appendix.hideComponentCodes?.includes(tec.reference)
            );
            return certs;
          }

        
        
          const filteredComp = componentsOfManu.filter((component) => {
            const certs = getCertList(component);
            return certs.length > 0;
          });


          // remove duplicate components by component code
          const uniqueComponents = filteredComp.reduce((acc, current) => {
            const x = acc.find(item => item.component_code === current.component_code);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
          }, []);
  


          if (uniqueComponents.length > 0) {
            return (
              <View
                key={i}
                style={{ paddingBottom: "10px", marginRight: "-45px" }}
              >
                <Text
                  style={{
                    width: "40%",
                    fontFamily: "Frutiger_bold",
                    fontSize: "11px",
                    paddingLeft: "3px",
                    ...renderChinese(manu.provided_name),
                  }}
                  hyphenationCallback={(word) => [word]} // Prevents hyphenation
                >
                  {manu?.short_name || manu?.provided_name}
                </Text>

                {uniqueComponents.map((component) => {
                  const certs = getCertList(component);
  
                  return certs.map((cert, i) => {
                    const displayValue = simpleDisplay(cert.id, "tec_certificate_number")
                    
                    // Create a unique key from the specified fields
                    const uniqueKey = `${component_type}|${component.manufacturer_code}|${component.provided_comp_name}|${displayValue}`;

                    // Check for duplicates
                    if (unique_Keys.has(uniqueKey)) {
                      return null; // Skip rendering this duplicate
                    }

                    unique_Keys.add(uniqueKey); // Add the unique key to the Set


                    return (
                      <View
                      key={i}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        ...(!cert.historical && { fontFamily: "Frutiger_italic" }),
                      }}
                    >
                      <Text style={{ width: "33.3333%", paddingLeft: "6px" }}>
                        {component.provided_comp_name}
                      </Text>
                      <Text style={{ width: "33.3333%" }}>
                        {simpleDisplay(cert.id, "tec_type_exam") ||
                          simpleDisplay(cert.id, "tec_certificate_number")}
                      </Text>
                      <Text style={{ width: "33.3333%" }}>
                        {simpleDisplay(cert.id, "tec_issue_date") &&
                          renderSymbol(
                            fixInvalidDates(
                              simpleDisplay(cert.id, "tec_issue_date")
                            )
                          )}
                        {checkLiftStandard && cert.comply_standard?.lift && (
                          <>
                            {" "}
                            [<Text style={{ fontFamily: "DejaVu Sans" }}>Ƨ</Text>]
                          </>
                        )}
                        {checkEscalatorStandard &&
                          cert.comply_standard?.escalator &&
                          `[#]`}
                      </Text>
                    </View>
                    )

                  });

                })}
              </View>
            );
          }
      


      })}
    </View>
  );
};

export default PartCCompTable;
