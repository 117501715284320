import React, { useRef, useState, useEffect } from "react";
import "../../../styles/derivedAppendix/DerivedAppendix.css";
import keyToField from "../../../utils/keyToField";
import { alphabet, romanize } from "../../../utils/romanize";
import { fixInvalidDates } from "../../../utils/misc/fixInvalidDates";
import renderChinese from "../../../utils/pdf/renderChinese";
import {
  renderContent,
  renderIndexedContent,
} from "../../../utils/pdf/renderContent";
// import palette from "../../../styles/palette";




const MyComponent = ({
  application,
  appendix,
  certificates,
  hideModelCerts,
  compLevel,
  certLevel,
  hideTableProps,
  hideManufs
}) => {
  // console.log("🚀 ~ compLevel:", compLevel)
  // console.log("🚀 ~ appendix:", appendix)

  const simpleDisplay = (cert_id, field) => {
    const matchCert = certificates?.find((cert) => cert?.id === cert_id);
    return (
      matchCert?.cert_corrections?.[field] ||
      matchCert?.cert_type_metadata?.[field]
    );
  };


  const model_manufacturer = application?.model
    .flatMap((model) => model.manufacturer_files)
    .map((file) =>
      application.manufacturer?.find((manu) => manu?._id === file?.manufacturer_id)
    );

  function findManufacturerAlphabet(model_manufacturer, model) {
    const manufacturers = model.manufacturer_files.map((file) => {
      return application.manufacturer?.find(
        (manu) => manu?._id === file?.manufacturer_id
      );
    });
    let indices = [];
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    model_manufacturer.forEach((manu, i) => {
      if (manufacturers.includes(manu)) {
        indices.push(alphabet[i]);
      }
    });
    return indices.join(", ");
  }

  function getCertList(component, component_type) {
    const certs = certificates?.filter(
      (tec) =>
        tec?.reference === component.component_code &&
        tec?.type === "ComponentTypeCert" &&
        !appendix.hideCerts?.includes(tec?.id) &&
        !appendix.hideComponentCodes2?.some(
          (item) =>
              item.component_code === tec?.reference &&
              item.component_type === component_type
        ) 
        // !appendix.hideIndividualCerts2?.some(
        //   (item) => 
        //       item.cert_id === tec.id &&
        //       item.component_type === component_type
        // )
    );
    return certs;
  }

  function getCompListCount(component_type) {
    if (!component_type) {
      return 0; // Return 0 when component_type is undefined
    }
    const components = application?.app_type?.components?.[component_type].filter(component => {
      const certs = getCertList(component, component_type);
      if (certs) return certs.length > 0
    })
    return components.length
  }
  

  return (
    <div className="da_appendix">
      <div className="da_appendixContainer">
        <div className="da_appendixHeader">
          <p>
            {application &&
              `Application No.: ${application.application_number}`}
          </p>
          <p className="da_appendixHeader3">Appendix</p>
        </div>
        <div className="da_appendixSection">
          <p>Technical Specifications for</p>
          <p>
            {application && application.manufacturer[0].provided_name}, Model{" "}
            {application && application.model[0].provided_model_name}
          </p>
        </div>

        {/* --------------------------------------------------------SECTION A------------------------------------------------------------ */}

        <p className="da_appendixHeader2">
        Registered {application.model[0]?.LE_type === "E" ? "Escalator" : "Lift"} Contractor:{" "}
          <span className="underline">
            {application.rc_id[
              application.model[0]?.LE_type === "E"
                ? "rc_number_escalator"
                : "rc_number_lift"
            ] || application.rc_id.name}
          </span>
        </p>

        <div>
          <p className="da_appendixHeader">Overview</p>
          {renderContent(
            appendix?.overviews || [],
            "plainHtml",
            application.model
          )}
        </div>

        <p style={{ fontFamily: "Frutiger_Ultra_bold", marginTop: 10 }}>
          A. Conditions:
        </p>
        {renderIndexedContent(
          appendix?.conditions || [],
          "plainHtml",
          application.model
        )}
        {/* --------------------------------------------------------SECTION B------------------------------------------------------------ */}

        <p className="da_appendixSection">
          B. General Operating Parameters and Characteristics
        </p>
        {application &&
          application?.model.map((model, i) => {
            return (
              <TableB
                key={i}
                appendix={appendix}
                model={model}
                application={application}
                simpleDisplay={simpleDisplay}
                certificates={certificates}
                manu_code={findManufacturerAlphabet(
                  model_manufacturer,
                  model
                )}
                hideTableProps={hideTableProps}
                hideModelCerts={hideModelCerts}
              />
            );
          })}

        {appendix?.remarks?.length > 0 && (
          <div>
            <p style={{ fontFamily: "Frutiger_light", fontWeight: "bold" }}>
              Remarks:
            </p>
            {renderIndexedContent(
              appendix?.remarks || [],
              "plainHtml",
              application.model
            )}
          </div>
        )}

        <p className="da_appendixHeader2">Manufacturer Code</p>
        {application &&
          certificates && 
          
          appendix?.manufacturer_code.length > 0 ? (
            appendix.manufacturer_code?.map((manufacturer, i) => {
              if (!appendix.hideCerts?.includes(manufacturer.name + manufacturer.address) ) {
                return (
                    <div 
                      className="da_manufacturer_code" 
                      key={i}
                      style={{
                        ...(`${manufacturer.name+manufacturer.address}` === hideManufs && { backgroundColor: "lightcyan" } ),
                      }}
                    >
                      <p>{String.fromCharCode(65 + i)}. </p>
                      <div
                        className="da_manufacturer_name"
                        style={{
                          fontFamily: "Frutiger_bold",
                          fontSize: "11px",
                          ...renderChinese(manufacturer.name),
                        }}
                      >
                        {" "}
                        {manufacturer.name}
                      </div>
                      <div
                        className="da_manufacturer_address"
                        style={renderChinese(manufacturer.address)}
                      >
                        {manufacturer.address}{" "}
                      </div>
                    </div>
                )
              }
            })
            
          ) : (

          model_manufacturer.map((manufacturer, i) => {
            if (
              !appendix.hideCerts?.includes(manufacturer?.iso_certificate_id)
            ) {
              // const iso_manufacturer_name = simpleDisplay(
              //   manufacturer?.iso_certificate_id,
              //   "iso_manufacturer_name"
              // );
              const iso_manufacturer_address = simpleDisplay(
                manufacturer?.iso_certificate_id,
                "iso_manufacturer_address"
              );

              return (
                <div className="da_manufacturer_code" key={i}>
                  <p>{String.fromCharCode(65 + i)}. </p>
                  <div
                    className="da_manufacturer_name"
                    style={{
                      fontFamily: "Frutiger_bold",
                      fontSize: "11px",
                      ...renderChinese(manufacturer?.provided_name),
                      ...(manufacturer.iso_certificate_id === hideManufs && { backgroundColor: "lightcyan" } ),
                    }}
                  >
                    {" "}
                    {manufacturer?.short_name || manufacturer?.provided_name}
                  </div>
                  <div
                    className="da_manufacturer_address"
                    style={renderChinese(iso_manufacturer_address)}
                  >
                    {iso_manufacturer_address}{" "}
                  </div>
                </div>
              );
            }
          }))
          
        }

        {/* --------------------------------------------------------SECTION C------------------------------------------------------------ */}

        <p className="da_appendixSection">
          C. List of Applicable Type Examination Certificates
        </p>

        {appendix?.remarks2?.length > 0 && (
          <div>
            <p style={{ fontFamily: "Frutiger_light", fontWeight: "bold" }}>
              Remarks:
            </p>
            {renderIndexedContent(
              appendix?.remarks2 || [],
              "plainHtml",
              application.model
            )}
          </div>
        )}

        {application &&
          application?.model.map((model, i) => {
            return (
              <TableCModel
                appendix={appendix}
                application={application}
                model={model}
                model_manufacturer={model_manufacturer}
                romanize={romanize}
                index={i}
                key={i}
                simpleDisplay={simpleDisplay}
                certificates={certificates}
                findManufacturerAlphabet={findManufacturerAlphabet}
                hideModelCerts={hideModelCerts}
              />
            );
          })}

        {application &&
          Object.keys(application?.app_type?.components)
            .sort((a, b) => {
              const order = [
                "Buffer",
                "OverspeedGovernor",
                "LandingDoorLockingDevice",
                "CarDoorLockingDevice",
                "SafetyGear",
                "ACOP",
                "UCMP",
                "SafetyCircuitElectricalComponent",
              ];
              function customSort(item) {
                const index = order.indexOf(item);
                return index !== -1 ? index : order.length;
              }
              return customSort(a) - customSort(b);
            })
            .filter(
              (component_type) =>
                !["TractionMachine", "Controller"].includes(component_type)
            )
            // .filter((component_type) => {
            //   return getCompListCount(component_type) > 0
            // })
            .map((component_type, j) => {
              if (getCompListCount(component_type) > 0) {
                return (
                  <TableCComp
                    application={application}
                    component_type={component_type}
                    romanize={romanize}
                    key={j}
                    index={j}
                    manufacturers={application?.manufacturer}
                    components={application?.app_type?.components}
                    simpleDisplay={simpleDisplay}
                    certificates={certificates}
                    appendix={appendix}
                    compLevel={compLevel}
                    certLevel={certLevel}
                    getCertList={getCertList}
                  />
                );
              }

            })}
      </div>
    </div>
  );
};




const fieldName = (model, field) => {
  switch (field) {
    case "type":
      return "Type";
    case "provided_model_name":
      return model.LE_type === "E" ? "Escalator Model" : "Lift Model";
    case "rated_speed":
      return "Rated Speed";
    case "rated_load":
      return "Rated Load";
    case "maximum_travel_of_height":
      return "Travel of Height";
    case "rope_factor":
      return "Roping";
    case "balancing_factor":
      return "Balancing Factor";
    case "maximum_rise":
      return "Maximum Rise";
    case "angle_of_inclination":
      return "Inclination";
    case "manufacturer_files":
      return "Type Exam. Certificate No./ Manufacturer Code/ [EMSD Approval Ref.]";
    default:
      return field;
  }
};

const processedFieldData = (field, data) => {
  switch (field) {
    case "type":
      return keyToField[data];
    case "rated_speed":
      return (
        <>
          <span style={{ fontFamily: "DejaVu Sans" }}>≤</span> {data} m/s
        </>
      );
    case "rated_load":
      return (
        <>
          <span style={{ fontFamily: "DejaVu Sans" }}>≤</span> {data}
        </>
      );
    case "maximum_travel_of_height":
      return (
        <>
          <span style={{ fontFamily: "DejaVu Sans" }}>≤</span> {data} m
        </>
      );
    case "rope_factor":
      return data.join(", ");
    case "balancing_factor":
      return data + " %";
    case "maximum_rise":
      return data + " m";
    case "angle_of_inclination":
      return data + "\u00B0";
    default:
      return data;
  }
};

function TableB(props) {
  const {
    model,
    appendix,
    application,
    simpleDisplay,
    certificates,
    manu_code,
    hideTableProps,
    hideModelCerts
  } = props;
    // console.log("🚀 ~ TableB ~ model:", model)

  const fieldShownForLift = [
    "provided_model_name",
    "type",
    "rated_load",
    "rated_speed",
    "maximum_travel_of_height",
    "rope_factor",
    "balancing_factor",
    "manufacturer_files",
  ].filter((field) => {
    const typeOfMachine = application.app_type?.type;
    switch (typeOfMachine) {
      case "HydraulicLift":
        return field !== "rope_factor" && field !== "balancing_factor";
      case "ElectricalVerticalLiftingPlatform":
        return field !== "balancing_factor";
      default:
        return field;
    }
  });

  const fieldShownForEscalator = [
    "provided_model_name",
    "type",
    "angle_of_inclination",
    "rated_speed",
    "maximum_rise",
    "manufacturer_files",
  ];
  //manufacturer files is for displaying type test cert
  let modelFields = model.LE_type === "L" ? fieldShownForLift : fieldShownForEscalator;

  if (appendix.table?.length > 0) {
    const addedField = appendix.table.map((row) => row.field);
    modelFields.splice(-1, 0, ...addedField);
  }

    modelFields = modelFields.filter((field) => !appendix.hideTableProps.includes(field));
    // console.log("🚀 ~ TableB ~ modelFields:", modelFields)

  return (
    <table className="da_tableB">
      <tbody>
        {modelFields.map((field, i) => {
          const addedRow = appendix.table?.find((row) => row.field === field);
          if (
            (model[field] && !Array.isArray(model[field])) ||
            (Array.isArray(model[field]) && model[field].length > 0) ||
            addedRow
          )
            return (
              <tr 
                key={i}
                style={{
                  ...(field === hideTableProps && { backgroundColor: "lightcyan" } ),
                }}
              >
                {field !== "manufacturer_files" ? (
                  <>
                    <td className="da_tableHeader">
                      {" "}
                      {fieldName(model, field)}
                    </td>
                    <td
                      style={{
                        width: "70%",
                        height: "100%",
                        ...(field !== "manufacturer_files" && {
                          textAlign: "center",
                          padding: "2px",
                        }),
                        border: "1px solid black",
                        borderLeft: "0px",
                        borderTop: "0px",
                        ...(field === "provided_model_name" && {
                          fontFamily: "Frutiger_bold",
                        }),
                      }}
                      // className={`w-[70%] h-full ${field !== "manufacturer_files" && ("text-center p-2")} border border-black `}
                    >
                      {addedRow?.value ??
                        keyToField[model[field]] ??
                        processedFieldData(field, model[field])}
                    </td>
                  </>
                ) : (
                  <PartBTableRow
                    appendix={appendix}
                    application={application}
                    addedRow={addedRow}
                    model={model}
                    field={field}
                    key={`${model._id}-${field}`}
                    certificates={certificates}
                    simpleDisplay={simpleDisplay}
                    manu_code={manu_code}
                    hideModelCerts={hideModelCerts}
                  />
                )}
              </tr>
            );
        })}
      </tbody>
    </table>
  );
}

function PartBTableRow(props) {
  const {
    application,
    appendix,
    addedRow,
    model,
    field,
    certificates,
    simpleDisplay,
    manu_code,
    hideModelCerts
  } = props;
    // console.log("🚀 ~ PartBTableRow ~ field:", field)

  return field === "manufacturer_files" ? (
    <PartCModelTable
      application={application}
      appendix={appendix}
      model={model}
      manu_code={manu_code}
      romanize={romanize}
      certificates={certificates}
      simpleDisplay={simpleDisplay}
      partB={true}
      field={field}
      hideModelCerts={hideModelCerts}
      
    />
  ) : addedRow ? (
    <td>
      {addedRow.value?.replace(/{([^{}]*)}/g, function (match, field) {
        return (
          simpleDisplay(
            model?.manufacturer_files?.[0]?.type_test_certificate_id?.[0],
            field
          ) || ""
        );
      })}
    </td>
  ) : (
    <td>{processedFieldData(field, model[field])}</td>
  );
}

function PartCModelTable(props) {
  const {
    appendix,
    application,
    model,
    manu_code,
    romanize,
    simpleDisplay,
    certificates,
    partB,
    field,
    hideModelCerts
  } = props;

  if (!certificates || !appendix || !application) return null;
  
  // Table B
  const certs = certificates.filter(
    (tec) =>
      tec?.reference === model.model_code &&
      tec?.type === "ModelTypeCert" &&
      !appendix.hideCerts?.includes(tec?.id)
  );

  const checkLiftStandard = [
    "HydraulicLift",
    "ElectricTractionLiftMachineRoom",
    "ElectricTractionLiftRoomless",
  ].includes(application.app_type?.type);

  const checkEscalatorStandard = ["Escalator", "PassengerConveyor"].includes(
    application.app_type?.type
  );

  return (
    <>
      <td className="da_tableHeader">{fieldName(model, field)}</td>
      <td>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "Futura",
            justifyContent: "space-between",
            padding: "0px 3px",
          }}
        >
          <span
            style={{
              width: partB ? "40.3333%" : "33.3333%",
            }}
          >
            Type Exam. Certificate
          </span>
          <span style={{ width: partB ? "26.3333%" : "33.3333%" }}>
            Manufacturer Code
          </span>
          <span
            style={{ width: "33.3333%", ...(partB && { textAlign: "right" }) }}
          >
            EMSD Approval Ref.
          </span>
        </div>

        {certs.map((cert, certIndex) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "Futura",
                justifyContent: "space-between",
                padding: "0px 3px",
                ...(partB && { fontFamily: "Futura", padding: "0px 3px" }),
                ...(cert.id === hideModelCerts && { backgroundColor: "lightcyan"  }),
              }}
              key={certIndex}
            >
              <span
                style={{ width: "45%", ...(!partB && { paddingLeft: "6px" }) }}
              >
                {simpleDisplay(cert.id, "tec_certificate_number")} dated{" "}
                {fixInvalidDates(simpleDisplay(cert.id, "tec_issue_date"))}
              </span>
              <span style={{ width: "10%" }}>
                |{"     "}
                {manu_code}
                {"     "}|
              </span>
              <span style={{ width: "45%", textAlign: "right" }}>
                {`[${application.application_number} dated ${
                  application.updatedAt.split("T")[0]
                }]`}
                {checkLiftStandard && cert.comply_standard?.lift && (
                  <div style={{ fontFamily: "DejaVu Sans" }}>{"[Ƨ]"}</div>
                )}
                {checkEscalatorStandard &&
                  cert.comply_standard?.escalator &&
                  `[#]`}
              </span>
            </div>
          );
        })}
      </td>
    </>
  );
}

function TableCModel(props) {
  const {
    appendix,
    application,
    model,
    model_manufacturer,
    romanize,
    index,
    simpleDisplay,
    certificates,
    findManufacturerAlphabet,
    hideModelCerts
  } = props;

  if (!certificates || !application) return null;

  const certs = certificates.filter(
    (tec) =>
      tec?.reference === model.model_code &&
      tec?.type === "ModelTypeCert" &&
      !appendix.hideCerts?.includes(tec?.id)
  );

  const checkLiftStandard = [
    "HydraulicLift",
    "ElectricTractionLiftMachineRoom",
    "ElectricTractionLiftRoomless",
  ].includes(application?.app_type?.type);

  const checkEscalatorStandard = ["Escalator", "PassengerConveyor"].includes(
    application?.app_type?.type
  );

  return (
    <React.Fragment>
      <p className="da_romanIndex">
        {romanize(index + 1)}. {model.LE_type === "E" ? "Escalator" : "Lift"}{" "}
        Model - {model.provided_model_name}
      </p>

      <table className="da_tableCmodel">
        <thead>
          <tr>
            <th>Type Exam. Certificate</th>
            <th>Manufacturer Code</th>
            <th>EMSD Approval Ref./Issue Date</th>
          </tr>
        </thead>
        <tbody>
          {certs.map((type_test_cert, i) => {
            const type_test_cert_id = type_test_cert.id;
            return (
              <tr key={i} 
                // style={{ backgroundColor: "yellow" }}
                style={{
                
                  ...(type_test_cert.id === hideModelCerts && { backgroundColor: "lightcyan"  })
                }}
              >
                <td>
                  {simpleDisplay(type_test_cert_id, "tec_certificate_number")}
                </td>
                <td>{findManufacturerAlphabet(model_manufacturer, model)}</td>
                <td>
                  {`[${application.application_number} dated ${
                    application.updatedAt.split("T")[0]
                  }]`}
                  {checkLiftStandard &&
                    type_test_cert.comply_standard?.lift &&
                    "[Ƨ]"}
                  {checkEscalatorStandard &&
                    type_test_cert.comply_standard?.escalator &&
                    "[#]"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}

function TableCComp(props) {
  const {
    application,
    component_type,
    manufacturers,
    components,
    romanize,
    index,
    simpleDisplay,
    certificates,
    appendix,
    compLevel,
    certLevel,
    getCertList
  } = props;
      // console.log("🚀 ~ TableCComp ~ certificates:", certificates)

  if (!certificates || !application) return null;
  const checkLiftStandard = [
    "HydraulicLift",
    "ElectricTractionLiftMachineRoom",
    "ElectricTractionLiftRoomless",
  ].includes(application?.app_type?.type);

  const checkEscalatorStandard = ["Escalator", "PassengerConveyor"].includes(
    application?.app_type?.type
  );

  return (
    <React.Fragment>
      <p className="da_romanIndex">
        {romanize(manufacturers?.length + index + 1)}. Safety Components -{" "}
        {keyToField[component_type]}
      </p>

      <table className="da_tableCcomp">
        <thead>
          <tr>
            <th>Model</th>
            <th>Type Exam. Certificate</th>
            <th>Issue Date</th>
          </tr>
        </thead>
        <tbody>
          {manufacturers?.map((manufacturer, i) => {
            const componentsOfManu = components[component_type].filter(
              (compModel) => compModel.manufacturer_id === manufacturer._id
            );

            const filteredComp = componentsOfManu.filter((component) => {
              const certs = getCertList(component, component_type);
              return certs.length > 0;
            });

            if (filteredComp.length > 0) {
              return (
                <React.Fragment key={i}>
                  <tr style={{ fontWeight: "bold" }}>
                    <td style={{ paddingTop: "10px" }}>
                      {manufacturer?.short_name || manufacturer?.provided_name}
                    </td>
                  </tr>

                  {filteredComp.map((component) => {
                    const certs = certificates.filter(
                      (tec) =>
                        tec?.reference === component.component_code &&
                        tec?.type === "ComponentTypeCert" &&
                        !appendix.hideComponentCodes2?.some(
                          (item) =>
                              item.component_code === tec?.reference &&
                              item.component_type === component_type
                        ) 
                        // !appendix.hideIndividualCerts2?.some(
                        //   (item) => 
                        //       item.cert_id === tec.id &&
                        //       item.component_type === component_type
                        // )
                    );

                    return certs.map((type_test_cert, i) => {
                      const type_test_cert_id = type_test_cert.id;
                      return (
                        <tr
                          key={i}
                          style={{
                            ...(compLevel?.component_code === component.component_code && compLevel?.component_type === component_type && { backgroundColor: "lightcyan" }),  
                          }}
                        >
                          <td>{component.provided_comp_name}</td>
                          <td>
                            {simpleDisplay(
                              type_test_cert_id,
                              "tec_type_exam"
                            ) ||
                              simpleDisplay(
                                type_test_cert_id,
                                "tec_certificate_number"
                              )}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              fontStyle: "italic",
                              display: "inline",
                              border: "none",
                            }}
                          >
                            {simpleDisplay(
                              type_test_cert_id,
                              "tec_issue_date"
                            ) &&
                              fixInvalidDates(
                                (
                                  simpleDisplay(
                                    type_test_cert_id,
                                    "tec_issue_date"
                                  ) || ""
                                ).replace(/–/g, "-")
                              )}
                            {checkLiftStandard &&
                              type_test_cert.comply_standard?.lift &&
                              "[Ƨ]"}
                            {checkEscalatorStandard &&
                              type_test_cert.comply_standard?.escalator &&
                              "[#]"}
                          </td>
                        </tr>
                      );
                    });
                  })}
                </React.Fragment>
              );
            }
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}



export default MyComponent