import { useEffect, useState } from "react";
import {
  SelectInput,
  TextInput,
  ReferenceInput,
  Filter,
  DateInput,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import getToken from "../../utils/getAuthToken";
import { useTranslation } from 'react-i18next';


const ApplicationFilter = (props) => {
  const { t : itranslate } = useTranslation();
  const location = useLocation();
  let userInfo = useSelector((state) => state.userInfo.value); //team_role_id

  const [doubling_roles, setDoubling_roles] = useState([]);
  const [current_role_name, setCurrent_role_name] = useState("");
  const [concat_doubling_roles, setconcat_doubling_roles] = useState("");


  useEffect(() => {
    if (userInfo?.doubling_roles && userInfo?.doubling_roles.length > 0) {
      const getDoublingRoleNames = async () => {
        const res = await axios.post(
          `${process.env.REACT_APP_ENV_ENDPOINT}/lift/team/getDoublingRoleNames`,
          { doubling_roles: userInfo?.doubling_roles },
          { headers: { authorization: getToken() } }
        );
          setDoubling_roles(res.data.doubling_roles_with_role_names);
          setconcat_doubling_roles(res.data.concat_doubling_roles)
      };
      getDoublingRoleNames();
    }
  }, [userInfo]);

 
useEffect(() => {
    if (userInfo?.role === "EMSDGR") {
      setCurrent_role_name("GR");
    } else {
      const getCurrentRoleName = async () => {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_ENV_ENDPOINT}/lift/team/getCurrentRoleName`,
            { team: userInfo?.team, team_role_id: userInfo?.team_role_id },
            { headers: { authorization: getToken() } }
          );
          setCurrent_role_name(res.data.team_role_with_role_name);
        } catch (error) {
          // Handle the error here
          // console.error(
          //   "Error occurred while fetching current role name:",
          //   error.message
          // );

          // If no team role found, then set the appropriate current_role_name value
          if (
            userInfo?.doubling_roles?.length > 0 &&
            userInfo?.role === "EMSDEngineer"
          ) {
            setCurrent_role_name("Engineer");
          } else if (
            userInfo?.doubling_roles?.length > 0 &&
            userInfo?.role === "EMSDSE"
          ) {
            setCurrent_role_name("Senior Engineer");
          } else if (
            userInfo?.doubling_roles?.length > 0 &&
            userInfo?.role === "EMSDCE"
          ) {
            setCurrent_role_name("Chief Engineer");
          } 
        }
      };
      getCurrentRoleName();
    }
  }, [userInfo]);

  const emsdStatusFilterChoices = [
    {
      id: "Pending",
      name: itranslate("Pending")
    },
    {
      id: "Pending E",
      name: itranslate("Pending Engineer")
    },
    { 
      id: "Pending SE", 
      name: itranslate("Pending Senior Engineer")
    },
    { 
      id: "Pending CE", 
      name: itranslate("Pending Chief Engineer")
    },
    { 
      id: "Clarifying", 
      name: itranslate("Clarifying")
    },
    { 
      id: "ReturningClarifying", 
      name: itranslate("Returning Clarifying")
    },
    { 
      id: "Withdrawn", 
      name: itranslate("Withdrawn")
    },
  ];

  const rcStatusFilterChoices = [
    {
      id: "Pending",
      name: itranslate("Pending")
    },
    { 
      id: "Approved", 
      name: itranslate("Approved")
    },
    { 
      id: "Clarifying", 
      name: itranslate("Clarifying")
    },
    { 
      id: "Withdrawn", 
      name: itranslate("Withdrawn")
    },
  ];

  return (
    <Filter {...props}>
      <TextInput
        source="q"
        label={itranslate("Search application no. / type of machine / models / status / RC")}
        alwaysOn
        sx={{ width: "500px" }}
      />

      {/* adding "z" at the end of the 1st choice is to distinguish between "Show All Assigned" and other choices. Else React admin will treat them the same */}
      {window.location.pathname === "/lift_emsd" &&
        location.pathname === "/applications" && (
          <SelectInput
            alwaysOn
            label={itranslate("Require Action")}
            source="current_role_id"
            emptyText={itranslate("Show All")}
            choices={[
              {
                id: "#" + (userInfo.team_role_id || concat_doubling_roles ? `${userInfo.team_role_id + concat_doubling_roles}` : userInfo.user_id),
                name: itranslate("Show All Assigned")
              },
              {
                id: `${userInfo.team_role_id || userInfo.user_id}`,
                name: `${current_role_name}`
              },
              ...(doubling_roles.length > 0
                ? doubling_roles.map((role) => ({
                    id: role.team_role_id,
                    name: role.roleName
                  }))
                : [])
            ]}
          />
        )}


      <TextInput 
        label={itranslate("Application number")}
        source="application_number" 
      />

      <SelectInput
        label={itranslate("Type of Application")}
        source="update_type"
        // these choices comes from ApplicationEdit.js typeDeterminant()
        choices={[
          {
            id: "Brand New Application",
            name: itranslate("Brand New Application")
          },
          {
            id: "Manufacturer Name Change",
            name: itranslate("Manufacturer Name Change")
          },
          {
            id: "Update lift/escalator model certificate",
            name: itranslate("Update lift/escalator model certificate")
          },
          {
            id: "Lift/escalator model range extension",
            name: itranslate("Lift/escalator model range extension")
          },
          {
            id: "Update safety component certificate",
            name: itranslate("Update safety component certificate")
          },
          {
            id: "Safety component range extension",
            name: itranslate("Safety component range extension")
          },
          { 
            id: "Add new safety component", 
            name: itranslate("Add new safety component")
          },
          {
            id: "Add new standalone safety component",
            name: itranslate("Add new standalone safety component")
          },
        ]}
      />

      <SelectInput
        label={itranslate("Type of Machine")}
        source="app_type.type"
        choices={[
          {
            id: "ElectricTractionLiftMachineRoom",
            name: itranslate("Electric Traction Lift (Machine Room)")
          },
          {
            id: "ElectricTractionLiftRoomless",
            name: itranslate("Electric Traction Lift (Machine Roomless)")
          },
          { 
            id: "InclinedLift", 
            name: itranslate("Electric Inclined Lift")
          },
          { 
            id: "ServiceLift", 
            name: itranslate("Service Lift")
          },
          { 
            id: "StairLift", 
            name: itranslate("Stairlift")
          },
          { 
            id: "HydraulicLift", 
            name: itranslate("Hydraulic Lift")
          },
          {
            id: "ElectricalVerticalLiftingPlatform",
            name: itranslate("Vertical Lifting Platform")
          },
          { 
            id: "MVPS", 
            name: itranslate("Mechanized Vehicle Parking System (MVPS)")
          },
          { 
            id: "Escalator", 
            name: itranslate("Escalator")
          },
          { 
            id: "PassengerConveyor", 
            name: itranslate("Passenger Conveyor")
          },
          { 
            id: "SafetyComponent", 
            name: itranslate("Safety Component")
          },
          { 
            id: "Others",
            name: itranslate("Others")
          },
        ]}
      />

      {/* If the location is /v2/applications, it only shows approved applications, so there is no point adding the filter */}
      {location.pathname === "/applications" && (
        <SelectInput
          label={itranslate("Status")}
          source="status"
          choices={
            window.location.pathname === "/lift_emsd"
              ? emsdStatusFilterChoices
              : rcStatusFilterChoices
          }
        />
      )}

      <TextInput 
        label={itranslate("Model")}
        source="modelsPopulated.provided_model_name" 
      />

      {window.location.pathname === "/lift_emsd" && (
        <ReferenceInput 
          source="rc_id" 
          label={itranslate("RC")}
          reference="rcs" 
          perPage={100}
        >
          {/* <SelectInput optionText="name" /> */}
          <SelectInput
            label={itranslate("RC")}
            optionText={(choice) => {
              let displayText = "";
              displayText = choice?.rc_number_lift;
              if (choice?.rc_number_escalator) {
                displayText += ` / ${choice.rc_number_escalator}`;
              }
              if (choice?.address?.company) {
                displayText += ` / ${choice.address.company}`;
              }
              return displayText;
            }}
          />
        </ReferenceInput>
      )}
      <DateInput 
        label={itranslate("Application Date")}
        source="createdAt" 
      />
    </Filter>
  );
};

export default ApplicationFilter;
