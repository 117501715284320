import React from "react";
import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useGetList } from "react-admin";
import { useTranslation } from 'react-i18next';


const selectProps = {
  sx: { mt: 2 },
  fullWidth: true,
  label: "operation",
};

const SelectRC = (props) => {
  const { t : itranslate } = useTranslation();

  //For emsd GR
  const { upload, setUpload } = props;
  const { data: rcs } = useGetList("rcs", {
    pagination: { page: 1, perPage: 0 },
  });
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="rc">
        {itranslate("Choose RC")}
      </InputLabel>

      <Select
        {...selectProps}
        labelId="rc"
        id="rc"
        value={upload.rc_id ?? ""}
        onChange={(e) => {
          setUpload((prev) => ({ ...prev, rc_id: e.target.value }));
        }}
      >
        {rcs &&
          rcs.map((rc) => {
            let rcDisplay = "";
            if (rc.address?.company_chinese)
              rcDisplay += rc.address?.company_chinese + " ";
            if (rc?.rc_number_lift) rcDisplay += rc?.rc_number_lift;
            if (rc?.rc_number_escalator)
              rcDisplay += " / " + rc?.rc_number_escalator;
            return (
              <MenuItem value={rc.id} key={rc.id}>
                {rcDisplay || rc.name}
              </MenuItem>
            );
          })}
      </Select>
    </React.Fragment>
  );
};


const SelectAction = (props) => {
  const { t : itranslate } = useTranslation();
  const { handleApplicationFlow, action, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="action">
        {itranslate("Choose type of application")}
      </InputLabel>

      <Select
        {...selectProps}
        labelId="action"
        id="action"
        value={action}
        onChange={(e) => handleApplicationFlow(e.target.value)}
        disabled={disableRadio}
      >
        <MenuItem value={"new"}>
          {itranslate("Brand new application")}
        </MenuItem>
        <MenuItem value={"update"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>
              {itranslate("Update application")}
            </Typography>
          </div>
        </MenuItem>
      </Select>
    </React.Fragment>
  );
};


const SelectLEtype = (props) => {
  const { t : itranslate } = useTranslation();
  const { handleApplicationFlow, system_type, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="model_type">
        {itranslate("Choose type of model")}
      </InputLabel>
      
      <Select
        {...selectProps}
        labelId="model_type"
        value={system_type}
        onChange={(e) => handleApplicationFlow(undefined, e.target.value)}
        disabled={disableRadio}
      >
        <MenuItem value={"lift"}>
          {itranslate("Lift")}
        </MenuItem>
        <MenuItem value={"escalator"}>
          {itranslate("Escalator")}
        </MenuItem>
      </Select>
    </React.Fragment>
  );
};


const SelectMachine = (props) => {
  const { t : itranslate } = useTranslation();
  const { system_type, handleRadio, radio, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="machine_type">
        {itranslate("Choose type of system")}
      </InputLabel>

      <Select
        {...selectProps}
        labelId="machine_type"
        value={radio === "9" ? "" : radio}
        onChange={(e) => handleRadio(e.target.value)}
        disabled={disableRadio}
      >
        {system_type === "lift" && (
          <MenuItem value={"0"}>
            {itranslate("Electric Traction Lift (Machine Room)")}
          </MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"10"}>
            {itranslate("Electric Traction Lift (Machine Roomless)")}
          </MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"8"}>
            {itranslate("Electric Inclined Lift")}
          </MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"3"}>
            {itranslate("Service Lift")}
          </MenuItem>
        )}
        {system_type === "lift" && <MenuItem value={"2"}>
          {itranslate("Stairlift")}
        </MenuItem>}
        {system_type === "lift" && (
          <MenuItem value={"1"}>
            {itranslate("Hydraulic Lift")}
          </MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"7"}>
            {itranslate("Vertical Lifting Platform")}
          </MenuItem>
        )}
        {system_type === "lift" && (
          <MenuItem value={"6"}>
            {itranslate("Mechanized Vehicle Parking System (MVPS)")}
          </MenuItem>
        )}
        {system_type === "escalator" && (
          <MenuItem value={"4"}>
            {itranslate("Escalator")}
          </MenuItem>
        )}
        {system_type === "escalator" && (
          <MenuItem value={"5"}>
            {itranslate("Passenger Conveyor")}
          </MenuItem>
        )}
        {system_type === "lift" && <MenuItem value={"11"}>
          {itranslate("Others")}
        </MenuItem>}
      </Select>
    </React.Fragment>
  );
};


const SelectApplicationType = (props) => {
  const { t : itranslate } = useTranslation();
  const { application_type, handleApplicationFlow, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="application">
        {itranslate("Choose type of application")}
      </InputLabel>
      <Select
        {...selectProps}
        labelId="application"
        id="application"
        value={application_type}
        onChange={(e) => handleApplicationFlow(undefined, undefined, e.target.value)}
        disabled={disableRadio}
      >
        <MenuItem value={"add sc"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>
              {itranslate("Add Safety Component")}
             </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontStyle: "italic", ml: 5 }}
            >
              {itranslate("Add stand-alone safety component or safety component attached to lift/escalator model")}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem value={"manu name change"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>
              {itranslate("Manufacturer Name Change")}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontStyle: "italic", ml: 5 }}
            >
              {itranslate("Change the name of manufacturers of a lift/escalator model")}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem value={"update cert"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>
              {itranslate("Update Certificate")}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontStyle: "italic", ml: 5 }}
            >
              {itranslate("Update the type test certificates of a lift/escalator model or safety component")}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem value={"range ext"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>
              {itranslate("Range Extension")}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontStyle: "italic", ml: 5 }}
            >
              {itranslate("Range extension of a lift/escalator model or safety component")}
            </Typography>
          </div>
        </MenuItem>
      </Select>
    </React.Fragment>
  );
};


const SelectScType = (props) => {
  const { t : itranslate } = useTranslation();
  const { sc_type, handleApplicationFlow, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="modelOrStandAlone">
        {itranslate("Choose type of safety component")}
      </InputLabel>
      <Select
        {...selectProps}
        labelId="modelOrStandAlone"
        id="modelOrStandAlone"
        value={sc_type}
        onChange={(e) => handleApplicationFlow(undefined, undefined, undefined, e.target.value)}
        disabled={disableRadio}
      >
        <MenuItem value={"standAloneSc"}>
          {itranslate("Stand-alone safety component")}
        </MenuItem>
        <MenuItem value={"leModelSc"}>
          {itranslate("Safety component attached to lift/escalator model")}
        </MenuItem>
      </Select>
    </React.Fragment>
  );
};


const SelectSystemType = (props) => {
  const { t : itranslate } = useTranslation();
  const { system_type, handleApplicationFlow, disableRadio } = props;
  return (
    <React.Fragment>
      <InputLabel sx={{ mt: 4 }} id="liftOrSc">
        {itranslate("Choose type of model")}
      </InputLabel>
      <Select
        {...selectProps}
        labelId="liftOrSc"
        id="liftOrSc"
        value={system_type}
        onChange={(e) => handleApplicationFlow(undefined, e.target.value)}
        disabled={disableRadio}
      >
        <MenuItem value={"lift"}>
          {itranslate("Lift/escalator")}
        </MenuItem>
        <MenuItem value={"sc"}>
          {itranslate("Safety component")}
        </MenuItem>
      </Select>
    </React.Fragment>
  );
};

export {
  SelectRC,
  SelectAction,
  SelectLEtype,
  SelectMachine,
  SelectApplicationType,
  SelectScType,
  SelectSystemType,
};
