import { useState, useEffect } from "react"
import { getRuInfo } from "../../../admin/useHooks/restrictedURL/useRestrictedURL"
import BasicModal from "../../../_hoc/modals/BasicModal"

// mui
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import { useTranslate } from "react-admin";
import { useTranslation } from 'react-i18next';

const CompExpiryDate = ({ ru_id, open, setOpen, updateExpiryDate }) => {
    // const translate = useTranslate()
    const { t : itranslate } = useTranslation();
    const [ruInfo, setRuInfo] = useState({})
    const [date, setDate] = useState(null)
    const [errMsg, setErrMsg] = useState("")
    const [successMsg, setSuccessMsg] = useState("")

    const [expiryDate, setExpiryDate] = useState("")

    function dateWithoutTimezone(date) {
        const tzoffset = date?.getTimezoneOffset() * 60000;
        const withoutTimezone = new Date(date.valueOf() - tzoffset).toISOString()
        return withoutTimezone;
    };

    useEffect(() => {
        if (ru_id) {
            getRuInfo(ru_id).then(res => {
                setRuInfo(res?.result)
            })
        }
    }, [ru_id])

    useEffect(() => {
        if (ruInfo?.expiry_date) {
            let expiry_date = dateWithoutTimezone(new Date(ruInfo?.expiry_date));
            setExpiryDate(expiry_date)
        }
    }, [ruInfo?.expiry_date])


    useEffect(() => {
        if (ruInfo?.expiry_date && date) {

            // get current time
            let now = dateWithoutTimezone(new Date(Date.now()));

            if (date < expiryDate || date < now) {
                setErrMsg("Date should be greater than today's date and expiry date")
                setSuccessMsg("")
            } else {
                setErrMsg("")
                setSuccessMsg("You can extend the expiry date till this date.")
            }
        }
    }, [date])

    useEffect(() => {
        if (!open) {
            setDate(null)
            setErrMsg("")
            setSuccessMsg("")
        }
    }, [open])

    const handleExtendDate = (e) => {
        e.preventDefault();
        updateExpiryDate(ru_id, date).then((res) => {
            setExpiryDate(dateWithoutTimezone(new Date(res?.result?.expiry_date)))
            setSuccessMsg("Expiry date extended successfully.")
            setErrMsg("")
        }).catch((err) => {
            setErrMsg(err?.response?.data?.message)
            setSuccessMsg("")
        })
    }

    return (
        <BasicModal
            open={open}
            setOpen={setOpen}
            title="Expiry Date"
        >
            <Typography
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {expiryDate && expiryDate?.split("T")[0] + " " + expiryDate?.split("T")[1].split(".")[0]}
            </Typography>

            {/* date picker */}
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <TextField
                    id="datetime-local"
                    label="Expiry Date"
                    type="datetime-local"
                    placeholder="dd/mm/yyyy hh:mm:ss"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => setDate(e.target.value)}
                />
                <Button onClick={handleExtendDate}
                    aria-label='submit'
                >
                    {/* {translate("phase2.createRu.submit")} */}
                    {itranslate("submit")}
                </Button>
            </Box>

            {errMsg && (
                <Alert severity="error">{errMsg}</Alert>
            )}

            {successMsg && (
                <Alert severity="success">{successMsg}</Alert>
            )}
        </BasicModal>

    )
}

export default CompExpiryDate