import { useState } from "react";
import {
  Divider,
  IconButton,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from 'react-i18next';


function CertificateList(props) {
  const {
    certificates,
    upload,
    imageView,
    userInfo_Role,
    userInfo,
    userRoles,
  } = props;

  const { t : itranslate } = useTranslation();
  const [expanded, setExpanded] = useState(!userInfo_Role);

  if (!upload || !certificates) return null;
  const isButtonDisabled =
    (userInfo?.role === "EMSDGR" && //GR and current role name is not GR and not pending
      (upload?.status.includes("Pending E") ||
        upload?.status.includes("Pending SE") ||
        upload?.status.includes("Pending CE")) &&
      upload?.current_role_name !== "GR") ||
    userInfo?.role === "Inspector" || //Inspector
    (userInfo?.role !== "EMSDGR" && //Check roleId except GR
      !userRoles.includes(upload?.current_role_id));

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box spacing={2} direction="column" sx={{ "&:first-of-type": { mt: 1 }, width: "60vw", marginBottom: "30px" }}>
      <Typography variant="h6" sx={{ fontFamily: "Frutiger_bold" }}>
        {/* Parameters Check and Amendment */}
        {itranslate("Parameters Check and Amendment")}
      </Typography>

      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: "lightgray" }}
          onClick={handleToggle}
        >
          <Typography>
            {/* Toggle Content */}
            {itranslate("Toggle Content")}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Divider orientation="horizontal" flexItem />

          {/* ---------------------------------------------------------------------ISO CERTS -----------------------------------------------------------------------*/}
          <Typography sx={{ fontWeight: "bold", marginTop: "10px" }}>
            {/* ISO Certificate */}
            {itranslate("ISO Certificate")}
          </Typography>

          <TableContainer sx={{ width: "800px" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                    {/* ManufacturerCode */}
                    {itranslate("ManufacturerCode")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                    {/* Manufacturer Name */}
                    {itranslate("Manufacturer Name")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                    {/* ISO Certificate No. */}
                    {itranslate("ISO Certificate No.")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                    {/* Show */}
                    {itranslate("Show")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {certificates?.length > 0 &&
                  certificates
                    .filter((cert) => cert.type === "ISO" && !cert.historical)
                    .map((certificate) => {
                      const correspondingManu = upload?.manufacturer.find(
                        (manu) =>
                          manu.manufacturer_code === certificate?.reference
                      );
                      return (
                        <TableRow key={certificate.id}>
                          <TableCell>
                            {correspondingManu?.manufacturer_code}
                          </TableCell>
                          <TableCell>
                            {correspondingManu?.provided_name}
                          </TableCell>
                          <TableCell>
                            {certificate?.cert_corrections?.iso_cert_number ||
                              certificate?.cert_type_metadata?.iso_cert_number}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ p: 0 }}
                              disabled={isButtonDisabled}
                              onClick={() => imageView(certificate)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>

            </Table>
          </TableContainer>

          {/* ---------------------------------------------------------------------LE CERTS -----------------------------------------------------------------------*/}
          <Typography sx={{ fontWeight: "bold",  marginTop: "15px" }}>
            {/* Lift/Escalator Model Type Test Certificate / Full Quality Certificate */}
            {itranslate("Lift/Escalator Model Type Test Certificate / Full Quality Certificate")}
          </Typography>

          <TableContainer sx={{ width: "800px" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                    {/* Model Code */}
                    {itranslate("Model Code")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                    {/* Model Name */}
                    {itranslate("Model Name")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                    {/* Type Test Certificate No. */}
                    {itranslate("Type Test Certificate No.")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                    {/* Show */}
                    {itranslate("Show")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {certificates?.length > 0 &&
                  certificates
                    .filter(
                      (cert) =>
                        ["FullQualityCert", "ModelTypeCert"].includes(cert.type) && !cert.historical
                    )
                    .map((certificate) => {
                      const correspondingModel = upload.model.find((model) => model.model_code === certificate?.reference);

                      return (
                        <TableRow key={certificate.id}>
                          <TableCell>
                            {correspondingModel?.model_code}
                          </TableCell>
                          <TableCell>
                            {correspondingModel?.provided_model_name}
                          </TableCell>
                          <TableCell>
                            {certificate?.cert_corrections?.tec_certificate_number || certificate?.cert_type_metadata?.tec_certificate_number}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ p: 0 }}
                              disabled={isButtonDisabled}
                              onClick={() => imageView(certificate)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* ---------------------------------------------------------------------COMP CERTS -----------------------------------------------------------------------*/}
          <Typography sx={{ fontWeight: "bold",  marginTop: "15px"  }}>
            {/* Component Type Test Certificate */}
            {itranslate("Component Type Test Certificate")}
          </Typography>

          <TableContainer sx={{ width: "800px" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                    {/* Model Code */}
                    {itranslate("Model Code")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                    {/* Model Name */}
                    {itranslate("Model Name")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                    {/* Type Test Certificate No. */}
                    {itranslate("Type Test Certificate No.")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                    {/* Show */}
                    {itranslate("Show")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {certificates?.length > 0 &&
                  certificates
                    .filter(
                      (cert) =>
                        cert.type === "ComponentTypeCert" && !cert.historical
                    )
                    .map((certificate) => {
                      function getComponent() {
                        for (const compList of Object.values(upload.app_type.components)) {
                          const matchComp = compList.find((comp) => comp.component_code === certificate?.reference);
                          if (matchComp) return matchComp;
                        }
                      }
                      const correspondingComponent = getComponent();
                      return (
                        <TableRow key={certificate.id}>
                          <TableCell>
                            {correspondingComponent?.component_code}
                          </TableCell>
                          <TableCell>
                            {correspondingComponent?.provided_comp_name}
                          </TableCell>
                          <TableCell>
                            {certificate?.cert_corrections?.tec_certificate_number || certificate?.cert_type_metadata?.tec_certificate_number}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ p: 0 }}
                              disabled={isButtonDisabled}
                              onClick={() => imageView(certificate)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* ---------------------------------------------------------------------COMPAT CERTS -----------------------------------------------------------------------*/}
          <Typography sx={{ fontWeight: "bold",  marginTop: "15px"  }}>
            {/* Compatibility Certificate */}
            {itranslate("Compatibility Certificate")}
          </Typography>

          <TableContainer sx={{ width: "800px" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                    {/* Model Code */}
                    {itranslate("Model Code")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                    {/* Model Name */}
                    {itranslate("Model Name")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "35%" }}>
                    {/* Safety Component Name */}
                    {itranslate("Safety Component Name")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                    {/* Show */}
                    {itranslate("Show")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {certificates?.length > 0 &&
                  certificates
                    .filter((cert) => cert.type === "Compatibility" && !cert.historical)
                    .map((certificate) => {
                      const correspondingModel = upload.model.find((model) => model.model_code === certificate?.reference);

                      return (
                        <TableRow key={certificate.id}>
                          <TableCell>
                            {correspondingModel?.model_code}
                          </TableCell>
                          <TableCell>
                            {correspondingModel?.provided_model_name}
                          </TableCell>
                          <TableCell>
                            { certificate?.cert_type_metadata?.ccl_safety_component_name }
                          </TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ p: 0 }}
                              disabled={isButtonDisabled}
                              onClick={() => imageView(certificate)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default CertificateList;
