import {
  useEffect,
  useState,
  Suspense,
  createRef,
  useMemo,
  useRef,
} from "react";
import { Document, Page } from "react-pdf";
import getToken from "../../../utils/getAuthToken";

import { toPng } from "html-to-image";
// import QRCode from "qrcode";
import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from 'react-i18next';


import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Box,
  CircularProgress,
  Backdrop,
  Tooltip,
} from "@mui/material";
import { useGetList } from "react-admin";
import DerivedAppendixModal from "./DerivedAppendixModal";

import PdfModal from "./DaSavedVersionModal/pdfModal";

// hooks
import useDeAppendix from "../../../useHooks/useDeAppendix";
import ShowPDF from "./ShowPDF";


import i18n from "../../../../i18n";



const DerivedAppendix = ({ upload }) => {
  const { t : itranslate } = useTranslation();

  const { data: certificates, isLoading: isLoading } = useGetList(
    "certificates",
    {
      filter: {
        application: upload.id,
        manufacturers: upload.manufacturer.map((manufacturer) => manufacturer._id),
        models: upload.model.map((model) => model.model_code),
        components: Object.values(upload.app_type.components)
          .flat()
          .map((comp) => comp.component_code),
      },
      pagination: { page: null, perPage: null },
    }
  );
  

  const {
    data: deAppendices,
    isLoading: isLoadingDeAppendices,
    saveDeAppendix,
    deleteDeAppendix,
    updateDeAppendixPDF,
  } = useDeAppendix(upload.id);

  const [openModal, setOpenModal] = useState(false);
  const [openSavedDaModal, setOpenSavedDaModal] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [daData, setDaData] = useState(null);
  const [corrections, setCorrections] = useState(certificates);
  const [openQrPreDownloadModal, setOpenQrPreDownloadModal] = useState(false);

  useEffect(() => {
    if (!isLoading && certificates && certificates.length > 0) {
      setCorrections(certificates);
    }
  }, [isLoading, certificates]);

  const Spinner = () => (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openQrPreDownloadModal}
    >
      <CircularProgress color="inherit" size={40} />
    </Backdrop>
  );

  const simpleDisplay = (certificateId, field) => {
    if (certificates && corrections && corrections.length > 0) {
      const correction = corrections.filter((correction) => {
        return correction?.id === certificateId;
      })[0];

      const certificate = certificates.filter((certificate) => {
        return certificate?.id === certificateId;
      })[0];

      if (
        correction?.cert_corrections?.[field] ||
        correction?.cert_corrections?.[field] === ""
      )
        return correction?.cert_corrections[field];
      else if (correction?.cert_type_metadata?.[field])
        return correction?.cert_type_metadata[field];
      else return certificate?.cert_type_metadata?.[field];
    }
  };

  // refs for different das
  const refsByIndex = useMemo(() => {
    const refs = {};
    if (deAppendices && deAppendices.length > 0) {
      deAppendices.forEach((da) => {
        if (da && typeof da === "object" && da.da_no !== undefined) {
          refs[da.da_no] = createRef(null);
        }
      });
      return refs;
    } else {
      return null;
    }
  }, [deAppendices]);

  const handleQrCodeDownload = (da_no) => {
    if (Object.keys(refsByIndex).length === 0) {
      return;
    }

    toPng(refsByIndex[da_no].current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `qr_code_${da_no}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setOpenQrPreDownloadModal(false);
      });
  };

  const handleClick = () => setOpenModal(true);

  const handleDelDa = (da_id) => {
    deleteDeAppendix(da_id).then((res) => {});
  };

  const downloadFile = async (id) =>
    window.open(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${id}?token=${getToken()}`
  );


    // const handleButtonClick = (v) => {
    //   i18n.changeLanguage("ch");
    //   window.open(v.url);
    // };

  if (isLoadingDeAppendices)
    return <div>
      {itranslate("Loading different versions of the Derived Appendices")}
    </div>;
  return (
    <Card>
      <CardHeader title={itranslate("Derived Appendix")} />
      <Button
        sx={{ marginLeft: "10px" }}
        variant="text"
        onClick={() => handleClick()}
        aria-label={itranslate("Create Derived Appendix")}
      >
        {itranslate("Create Derived Appendix")}
      </Button>

      {openModal && (
        <DerivedAppendixModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={upload?.appendix}
          saveDeAppendix={saveDeAppendix}
          updateDeAppendixPDF={updateDeAppendixPDF}
          application={upload}
          isLoadingCerts={isLoading}
          certificates={certificates}
          simpleDisplay={simpleDisplay}
        />
      )}

      {deAppendices && deAppendices.length > 0 && (
        <>
          <CardContent>
            {deAppendices.map((v, i) =>
              typeof v === "object" &&
              v.da_no !== undefined &&
              !v.originalAppendix ? (
                <Box
                  sx={{
                    margin: ".5em 1em",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  key={v._id}
                  aria-label={
                    v.beingUsedInRu
                      ? `${v.da_no} is included in restricted url`
                      : ""
                  }
                >
                  {/* If using react-pdf */}
                  {/* <Button
                    sx={{ marginLeft: "1em" }}
                    variant="outlined"
                    onClick={() => {
                      setDaData(v);
                      setOpenSavedDaModal(true);
                    }}
                    aria-label={`open pdf (${v.da_no})`}
                  >
                    {v.da_no}
                  </Button> */}

                  <Button
                    sx={{ marginLeft: "1em" }}
                    variant="outlined"
                    onClick={() => {
                      setOpenPDF(true);
                      setPdfFile(() =>
                        setPdfFile(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/${v.pdfFile}?token=${getToken()}`)
                      );
                    }}
                    aria-label={`${itranslate("open pdf file")}(${v.da_no})`}
                  >
                    {v.da_no}
                  </Button>

                  <Button
                    sx={{ marginLeft: "1em" }}
                    variant="outlined"
                    onClick={() => downloadFile(v.pdfFile) }
                    aria-label={`${itranslate("download pdf")}(${v.da_no})`}
                  >
                    {/* {`Download PDF: ${v.da_no}`} */}
                    {itranslate("Download PDF")}
                  </Button>

                  {/* <Tooltip placement="top" title={v.beingUsedInRu ? `It is included in restricted url, are you sure you want to delete?` : ""}> */}
                  <Button
                    sx={{ marginLeft: "1em" }}
                    variant="outlined"
                    color="error"
                    onClick={() => handleDelDa(v._id)}
                    aria-label={`delete ${v.da_no})`}
                  >
                    {itranslate("Delete")}
                  </Button>
                  {/* </Tooltip> */}

                  <Button
                    sx={{ marginLeft: "1em" }}
                    variant="outlined"
                    onClick={() => window.open(v.url, "_blank")}
                  >
                    {itranslate("Open Link")}
                  </Button>

                  {/* <Button onClick={() => handleButtonClick(v)}>Open Link2</Button> */}

                  <Tooltip
                    placement="top"
                    title={`${itranslate("click and download qr code")}(${v.da_no})`}
                  >
                    <Button
                      ref={refsByIndex[v.da_no]}
                      aria-label={`${itranslate("click and download qr code")}(${v.da_no})`}
                      disableFocusRipple={true}
                      disableRipple={true}
                      className="Mui-focusVisible"
                      onClick={() => setOpenQrPreDownloadModal(true)}
                    >
                      <Suspense>
                        <QRCodeSVG
                          value={v.url}
                          size={120}
                          includeMargin={true}
                          onClick={() => handleQrCodeDownload(v.da_no)}
                          alt={`qr code(${v.da_no})`}
                        />
                      </Suspense>
                    </Button>
                  </Tooltip>
                </Box>
              ) : null
            )}
          </CardContent>
        </>
      )}

      {openSavedDaModal && (
        <PdfModal
          openModal={openSavedDaModal}
          setOpenModal={setOpenSavedDaModal}
          data={daData}
          setData={setDaData}
          application={upload}
          isLoadingCerts={isLoading}
          certificates={certificates}
          simpleDisplay={simpleDisplay}
        />
      )}

      {openPDF && (
        <ShowPDF pdfFile={pdfFile} openPDF={openPDF} setOpenPDF={setOpenPDF} />
      )}

      {openQrPreDownloadModal && <Spinner />}
    </Card>
  );
};

export default DerivedAppendix;
