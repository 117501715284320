import { createSlice } from "@reduxjs/toolkit";

export const ruPageDataSlice = createSlice({
  name: "ruPageData",
  initialState: {
    value: {},
  },
  reducers: {
    setRuPageData: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRuPageData } = ruPageDataSlice.actions;

export default ruPageDataSlice.reducer;
