import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import keyToField from "../../utils/keyToField.js";

const componentsExporter = (components) => {
  const componentsForExport = components.map((component) => {
    const componentForExport = {
      component_code: component.component_code,
      component_name: component.provided_comp_name,
      type: keyToField[component.type],
      LE_type: keyToField[component.modelsPopulated?.[0]?.type],
      LE_model: component.modelsPopulated?.[0]?.provided_model_name,
      manufacturer: component.manufacturersPopulated[0].provided_name,
    };
    if (window.location.pathname === "/lift_emsd")
      componentForExport.rc = component.rcsPopulated[0].name;
    return componentForExport;
  });

  const emsdExport = [
    "component_code",
    "component_name",
    "type",
    "LE_type",
    "LE_model",
    "rc",
    "manufacturer",
  ];

  const rcExport = [
    "component_code",
    "component_name",
    "type",
    "LE_type",
    "LE_model",
    "manufacturer",
  ];

  jsonExport(
    componentsForExport,
    {
      headers: window.location.pathname === "/lift_rc" ? rcExport : emsdExport, // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "components"); // download as 'manufacturers.csv` file
    }
  );
};

export default componentsExporter;
