import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  InputLabel,
  Pagination,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Button
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { changeManufacturersPage, changeManufacturersItemsPerPage } from '../../redux/revoke/revokeSlice';
import keyToField from "../../utils/keyToField";
import { useTranslation } from 'react-i18next';

const fields = {
  "Both Lift/Escalator System Model and Safety Components": "type",
};


const SelectManufacturer = ({ handleSelection, setUpload, upload }) => {
  const { t : itranslate } = useTranslation();

  const { manufacturers, currentPage, itemsPerPage, totalItems } = useSelector((state) => state.revoke.manufacturers);

  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [searchQueryResult, setSearchQueryResult] = useState('');

  const [selectedManufacturer, setSelectedManufacturer] = useState(upload[0]?.manufacturer || upload?.manufacturer[0] || '');

  const totalManufacturers = useRef(totalItems);
  const [totalPages, setTotalPages] = useState(Math.ceil(totalManufacturers.current / itemsPerPage));

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage; 

  // if search is not empty, then use searchQueryResult, else use (default) all Manufacturers
  let allManufacturers = searchQueryResult !== "" ? searchQueryResult : manufacturers;
  let sortedManufacturers = allManufacturers && [...allManufacturers].sort((a, b) => a.manufacturer_code.localeCompare(b.manufacturer_code));  // sort by manufacturer_code
  const paginatedManufacturers = sortedManufacturers?.slice(startIndex, endIndex);


  useEffect(() => {
    setTotalPages(Math.ceil(totalManufacturers.current / itemsPerPage));
  }, [totalManufacturers, itemsPerPage]);


  useEffect(() => {
    setSelectedManufacturer(upload.manufacturer);
  }, [upload.manufacturer]);


  useEffect(() => {
    dispatch(changeManufacturersPage(1));
  
    if (searchQueryResult.length === 0) {
      totalManufacturers.current = totalItems;
    } else if (searchQueryResult.length > 0 && searchQueryResult !== "") {
      totalManufacturers.current = searchQueryResult.length;
    } else {
      totalManufacturers.current = 0;
    }

    setTotalPages(Math.ceil(totalManufacturers.current / itemsPerPage)); // Update totalPages
  }, [searchQueryResult, totalItems, itemsPerPage]);


  const handleChange = useCallback((code) => {
    setUpload((prevState) => ({
      ...prevState,
      manufacturer: code,
    }));
    setSelectedManufacturer(code);
    handleSelection(code);
  }, [setUpload, handleSelection]);


  const handlePageChange = (e, page) => {
    dispatch(changeManufacturersPage(page));
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    dispatch(changeManufacturersItemsPerPage(newItemsPerPage));
  };



  const handleSearchChange = () => {
    sortedManufacturers = manufacturers;  // reset to all models when user press "enter" to search
  
    if (searchValue.trim() === '') {
      setSearchQueryResult(''); // Set the search query result to an empty string
    } else {
      let filteredManufacturers = sortedManufacturers.filter((manufacturer) => {
        const { manufacturer_code, provided_name, type, certsPopulated } = manufacturer;
        // const manufacturerName = manufacturer_files.manufacturer_name;
        const manufacturerAddress = certsPopulated[0]?.cert_corrections?.iso_manufacturer_address || 
                                    certsPopulated[0]?.cert_type_metadata?.iso_manufacturer_address || '';
  
        // filter the matched fields based on the search value. 
        // then using map() to extract the corresponding values into matchedValues
        const matchedValues = Object.entries(fields)
          .filter(([key, value]) => {
            return key.toLowerCase().includes(searchValue.toLowerCase().trim());
          })
          .map(([key, value]) => value);
  
        return (
          (manufacturer_code && manufacturer_code.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (provided_name && provided_name.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (type && type.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          (manufacturerAddress && manufacturerAddress.toLowerCase().includes(searchValue.toLowerCase().trim())) ||
          // matchedValues.some() to check if any of the matched values are included in the "type" field.
          (matchedValues && matchedValues.some((matchedValue) => type.toLowerCase().includes(matchedValue.toLowerCase().trim())))
        );
      });
  
      setSearchQueryResult(filteredManufacturers);
    }
  };


  const handleClearSearch = () => { 
    setSearchValue(''); // Clear the search field value
  };


  return (
    <>
      <InputLabel sx={{ mt: 4 }} id="model_type">
        {/* {"Select Manufacturer"} */}
        {itranslate("Select Manufacturer")}
      </InputLabel>

      <TextField
        sx={{ flex: 1, width: "40%" }}
        variant="filled"
        className="searchBox"
        // label="Search"
        label={itranslate("Search")}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                sx={{
                  cursor: "pointer",
                  color: "#051B44",
                  fontSize: ".7em",
                  textTransform: "none",
                }}
                onClick={handleClearSearch} 
              >
                {/* Clear */}
                {itranslate("Clear")}
              </Button>
              <Button
                onClick={handleSearchChange}
                aria-label='search'
                variant="contained"
                sx={{
                  backgroundColor: "#2A598F",
                  textTransform: "none",
                  '&:hover': {
                    backgroundColor: "#203C5B",
                  },
                  fontSize: ".7em",
                }}
                
              >
                {/* Search */}
                {itranslate("Search")}
              </Button>
            </InputAdornment>
          ),
        }}
        // onKeyDown={handleSearchChange} 
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // console.log(e.target.value);
            handleSearchChange()
          }
        }}
      />

      {/* <TablePagination data={searchQueryResult || manufacturers} currentPage={currentPage} itemsPerPage={itemsPerPage} /> */}
      <>
        {paginatedManufacturers.length > 0 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{width: "12%"}}>{itranslate("Manufacturer Code")} </TableCell>
                  <TableCell sx={{width: "30%"}}>{itranslate("Manufacturer Name")} </TableCell>
                  <TableCell sx={{width: "30%"}}>{itranslate("Scope of Manufacturer")}</TableCell>
                  <TableCell sx={{width: "20%"}}>{itranslate("Manufacturer Address")} </TableCell>
                  <TableCell sx={{width: "20%"}}>{itranslate("RC")} </TableCell>
                  <TableCell sx={{width: "8%"}} align="right">{itranslate("Select")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedManufacturers.map((manufacturer, index) => (
                  <TableRow key={index}>
                    <TableCell>{manufacturer?.manufacturer_code}</TableCell>
                    <TableCell>{manufacturer?.provided_name}</TableCell>
                    <TableCell>
                      {/* {keyToField[manufacturer.type]} */}
                      {itranslate(keyToField[manufacturer.type])}
                    </TableCell>
                    <TableCell>{manufacturer?.certsPopulated?.[0]?.cert_corrections?.iso_manufacturer_address || 
                      manufacturer.certsPopulated?.[0]?.cert_type_metadata?.iso_manufacturer_address || ""}</TableCell>
                    <TableCell>{manufacturer?.rcPopulated[0]?.name + " / " + manufacturer?.rcPopulated[0]?.address?.company }</TableCell>
                    <TableCell align="right">
                      <Checkbox
                        checked={selectedManufacturer === manufacturer.manufacturer_code}
                        onChange={() => handleChange(manufacturer.manufacturer_code)}
                        inputProps={{
                          'aria-label': manufacturer.provided_name,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <p style={{ marginRight: '10px' }}>{itranslate("Rows per page")}:</p>
          <Select
            variant='outlined'
            id="select-items-per-page"
            value={itemsPerPage}
            onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
            sx={{ minWidth: '40px', height: '30px'}}
          >
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="20">20</MenuItem>              
          </Select>
        </div>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />

      </div>
    </>
  );
};

export default SelectManufacturer;