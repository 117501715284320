import axios from 'axios'

const fetchWithToken = (url, token) => axios({
    method: 'GET',
    url,
    headers:{
        authorization: token
    }
}).then(res =>{
    return res.data
})

export default fetchWithToken