import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

// Dropdown
// const Dropdown = ({ options, defaultOption, onSelect }) => {
//   const [selectedOption, setSelectedOption] = useState(defaultOption);

//   const handleOptionSelect = (option) => {
//     setSelectedOption(option);
//     onSelect(option);
//   };

//   return (
//     <select
//       style={{
//         padding: "8px",
//         borderRadius: "8px",
//         width: "100%",
//         fontSize: "16px",
//         height: "40px",
//         backgroundColor: "#2a598f", // Add background color
//         color: "#ffffff", // Text color
//       }}
//       id="dropdown"
//       value={selectedOption}
//       onChange={(e) => handleOptionSelect(e.target.value)}
//     >
//       {options.map((option) => (
//         <option
//           key={option}
//           value={option}
//           style={{
//             padding: "8px",
//             fontSize: "16px",
//           }}
//         >
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// };


const Dropdown = ({ options, defaultOption, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption.value);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option); // Send the original value
  };

  return (
    <select
      style={{
        padding: "8px",
        borderRadius: "8px",
        width: "100%",
        fontSize: "16px",
        height: "40px",
        backgroundColor: "#2a598f",
        color: "#ffffff",
      }}
      value={selectedOption}
      onChange={(e) => handleOptionSelect(e.target.value)}
    >
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};



const MetricsBox = ({ metricsValue }) => {
  const { t : itranslate } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(null);

  let differentApplicationsOptionsLabels, differentApplicationOptions;
  if (metricsValue.label === "APPLICATIONS BEING PROCESSED - BY TYPE" && Object.keys(metricsValue.value).length !== 0) {
    differentApplicationsOptionsLabels = Object.keys(metricsValue.value);
    differentApplicationOptions = metricsValue.value;

    if (!selectedValue) {
      const values = Object.values(differentApplicationOptions);
      setSelectedValue(values[0]);
    }
  }

  const handleOptionSelect = (selectedOption) => {
    setSelectedValue(differentApplicationOptions[selectedOption]);
  };


  const options = differentApplicationsOptionsLabels?.map(label => ({
    value: label, // Original value
    label: itranslate(`${label}`), // Translated label
  }));


  // Function to format the value with Days translation
  const formatDays = (value) => {
    if (metricsValue.label === "AVERAGE PROCESSING TIME" || metricsValue.label === "AVERAGE HANDLING TIME") {
      // Use regex to extract the numeric part
      const numericValue = value.match(/\d+/); // Matches the first sequence of digits
      const dayText = itranslate("Day(s)"); // Get the translated term for "days"
      return numericValue ? `${numericValue[0]} ${dayText}` : value;
    }
    return value; // Return the value as-is if no translation is needed
  };


  return typeof metricsValue.value !== "object" ? (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "15px", // Adjusted padding to 15px
        borderRadius: "8px",
        fontSize: "16px",
        // height: "70px",
        height: "110px",
        width: "250px",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)", // Adjusted shadow
        backgroundColor: "#ffffff", // Set background color to white
        color: "#2a598f", // Text color
        transition: "background-color 0.3s ease, color 0.3s ease", // Transition for smooth color change
      }}
      // Add onMouseEnter and onMouseLeave events to change background color and font color on hover
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "#2a598f";
        e.currentTarget.style.color = "#ffffff";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "#ffffff";
        e.currentTarget.style.color = "#2a598f";
      }}
    >
      <div
        style={{
          marginBottom: "8px",
        }}
      >
        {itranslate(`${metricsValue.label}`)}
      </div>
      <div>
        {formatDays(metricsValue.value)}
      </div>
    </div>
  ) : (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "15px", // Adjusted padding to 15px
        borderRadius: "8px",
        fontSize: "16px",
        // height: "120px",
        height: "150px",
        width: "250px",
        marginBottom: "20px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)", // Adjusted shadow
        backgroundColor: "#ffffff", // Set background color to white
        color: "#2a598f", // Text color
        transition: "background-color 0.3s ease, color 0.3s ease", // Transition for smooth color change
      }}
      // Add onMouseEnter and onMouseLeave events to change background color and font color on hover
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "#2a598f";
        e.currentTarget.style.color = "#ffffff";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "#ffffff";
        e.currentTarget.style.color = "#2a598f";
      }}
    >
      <div
        style={{
          marginBottom: "8px",
        }}
      >
        {itranslate(`${metricsValue.label}`)}
      </div>

      {/* {(metricsValue.label === "APPLICATIONS BEING PROCESSED - BY TYPE" && Object.keys(metricsValue.value).length !== 0) && 
        <div style={{ marginBottom: "10px" }}>
          <Dropdown
            options={differentApplicationsOptionsLabels}
            defaultOption={differentApplicationsOptionsLabels[0]}
            onSelect={handleOptionSelect}
          />
        </div>
      } */}


        <div style={{ marginBottom: "10px" }}>
          {metricsValue.label === "APPLICATIONS BEING PROCESSED - BY TYPE" && 
          Object.keys(metricsValue.value).length !== 0 && (
            <Dropdown
              options={options}
              defaultOption={options[0]} // Pass the entire object
              onSelect={handleOptionSelect}
            />
          )}
        </div>
                

      <div>{selectedValue}</div>
    </div>
  );
};

export default MetricsBox;
