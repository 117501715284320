import emsdLogo from "./emsd.png";
import tapas_prod from "./tapas_prod.png"
import tapas_uat from "./tapas_uat.png"
import './App.css'

function Mobile() {
	return (
		<div className="mobile__container">
			<div>
				<img src={emsdLogo} className="mobile__logo" alt="EMSD Logo" />
			</div>
			<div>
				<h1>TAPAS Mobile App</h1>
				<h3>Version 1.0.6</h3>
				<div className="mobile__card">
					<div className="mobile__qrcode">
						<img src={process.env.REACT_APP_UAT ? tapas_uat : tapas_prod} className="mobile__logo" alt="Mobile QRcode" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Mobile;