function msToDays(totalMilliseconds) {
  if (!totalMilliseconds || typeof totalMilliseconds !== "number") return null;
  // const days = Math.floor(totalMilliseconds / 86400000); // 1 day = 24 * 60 * 60 * 1000 milliseconds
  const days = Math.ceil(totalMilliseconds / 86400000); // 1 day = 24 * 60 * 60 * 1000 milliseconds

  const remainingMilliseconds = totalMilliseconds % 86400000;

  // Convert remaining milliseconds to hours
  const hours = Math.floor(remainingMilliseconds / 3600000); // 1 hour = 60 * 60 * 1000 milliseconds
  const remainingMillisecondsAfterHours = remainingMilliseconds % 3600000;

  const minutes = Math.floor(remainingMillisecondsAfterHours / 60000); // 1 minute = 60 * 1000 milliseconds

  // return `${days} days, ${hours} hours ${minutes} minutes`;
  return `${days} days`;

}

export default msToDays;
