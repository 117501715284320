import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  flexDirection: "column",
  overflowY: "scroll",
};

export default function BasicModalLarge({open, setOpen, setOpen2, children, title}) {
  const handleClose = () => {
    if(setOpen){
      setOpen(false)
    }
    
    if(setOpen2){
      setOpen2(false)
    }

  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {title}
          </Typography>
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            {children}
          </div>
        </Box>
      </Modal>
    </div>
  );
}