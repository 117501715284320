import { createSlice } from "@reduxjs/toolkit";
import getToken from "../utils/getAuthToken";
import jwtDecode from "jwt-decode";

const token = getToken();
const decodedUserInfo = token ? jwtDecode(token) : null;

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: {
    value: decodedUserInfo,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.value = action.payload;
    },
    clearUserInfo: (state) => {
      state.value = null;
    },
  },
});

export const { setUserInfo, clearUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;
