import {
  Box,
  Popper,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { FormatBold, FormatUnderlined } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';


const StyleTagPopper = (props) => {
  const { anchorEl_styleDisplay } = props;
  const { t : itranslate } = useTranslation();
  const openPopper = Boolean(anchorEl_styleDisplay);
  const id = openPopper ? "simple-popper" : undefined;

  return (
    <Popper
      placement={"bottom-end"}
      id={id}
      open={openPopper}
      anchorEl={anchorEl_styleDisplay}
      sx={{
        position: "relative",
        zIndex: 999,
      }}
      disablePortal
    >
      <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
        <TableContainer component={Paper}>
          <Table fullWidth>
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: "none" }}> {itranslate('Icon')} </TableCell>
                <TableCell sx={{ borderBottom: "none" }}> {itranslate('Code')} </TableCell>
                <TableCell sx={{ borderBottom: "none" }}> {itranslate('Style')} </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell sx={{ display: "flex", alignItems: "center" }}>
                  Ultra
                  <FormatBold />
                </TableCell>
                <TableCell>**Ultra Bold**</TableCell>
                <TableCell sx={{ fontFamily: "Frutiger_Ultra_bold" }}>
                  Ultra Bold
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormatBold />
                </TableCell>
                <TableCell>**~Bold~**</TableCell>
                <TableCell
                  sx={{ fontFamily: "Frutiger_light", fontWeight: "bold" }}
                >
                  Bold
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ display: "flex", alignItems: "center" }}>
                  <FormatBold />+<FormatUnderlined />
                </TableCell>
                <TableCell>**__UnderlinedBold__**</TableCell>
                <TableCell
                  sx={{
                    fontFamily: "Frutiger_light",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  UnderlinedBold
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormatUnderlined />
                </TableCell>
                <TableCell>__Underlined__</TableCell>
                <TableCell
                  sx={{
                    fontFamily: "Frutiger_light",
                    textDecoration: "underline",
                  }}
                >
                  Underlined
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormatBold />
                </TableCell>
                <TableCell>**_Italic_**</TableCell>
                <TableCell
                  sx={{ fontFamily: "Frutiger_italic" }}
                >
                  Italic
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ display: "flex", alignItems: "center" }}>
                  Symbols
                </TableCell>
                <TableCell>{"`Ƨ`"}</TableCell>
                <TableCell>Ƨ</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ display: "flex", alignItems: "center" }}>
                  traditional Chinese
                </TableCell>
                <TableCell>{"##中心##"}</TableCell>
                <TableCell sx={{ fontFamily: "mYuenLight" }}>中心</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ display: "flex", alignItems: "center" }}>
                  traditional Chinese Bold
                </TableCell>
                <TableCell>{"###中心###"}</TableCell>
                <TableCell sx={{ mHeiBold: "" }}>中心</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Popper>
  );
};

export default StyleTagPopper;
