import axios from "axios"
import getToken from "../../utils/getAuthToken"
import useSWR from "swr"

import fetchWithToken from "../../services/fetchWithToken"

let token

export default function useRestrictedUrl(filter, range, sort) {
    token = getToken()
    const { data, error, isLoading, mutate } = useSWR(
        [`${process.env.REACT_APP_ENV_ENDPOINT}/api2/restrictedUrls/getAll?filter=${encodeURIComponent(filter)}&range=${range}&sort=${sort}`, token], ([url, token]) => fetchWithToken(url, token), {
        shouldRetryOnError: false,
        revalidateOnFocus: true
    })

    const delRuInfo = (ru_idsArr) => (new Promise((resolve, reject) => {
        axios({
            method: "DELETE",
            url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/restrictedUrls/deleteOneOrMany`,
            headers: {
                authorization: getToken()
            },
            data: {ru_idsArr}
        }).then(res => {
            mutate()
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    }))

    const updateExpiryDate = (ru_id, new_expiry_date) => ( new Promise((resolve, reject) => {
        axios({
            method: "PATCH",
            url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/restrictedUrls/updateExpiryDate`,
            headers: {
                authorization: getToken()
            },
            data: {ru_id, new_expiry_date}
        }).then(res => {
            mutate()
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    }))

    return {
        data,
        error,
        isLoading,
        delRuInfo,
        updateExpiryDate
    }

}

export const generateRestrictedURL = (clientUser, token, remark, ruPageData) => (new Promise((resolve, reject) => {
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/restrictedUrls/createOne`,
        headers: {
            authorization: getToken()
        },
        data: {clientUser, token, remark, ruPageData}
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))

export const getRuInfo = (ru_id) => (new Promise((resolve, reject) => {
    axios({
        method: "GET",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/restrictedUrls/getOne/${ru_id}`,
        headers: {
            authorization: getToken()
        }
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
})) 




// get ru pwd, don't need token
export const getRuRecordWithPwd = (encrypted_ruNo, pwd) => ( new Promise((resolve, reject) => {  
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_ENV_ENDPOINT}/api2/restrictedUrls/getRecordWithPwd`,
        data: {
            encrypted_ruNo,
            pwd
        }
    }).then(res => {
        resolve(res.data)
    }).catch(err => {
        reject(err)
    })
}))

