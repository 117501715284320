// components
import CompSummary from './CompSummary';
import CompNavigation from './CompNavigation';
import {Divider} from '@mui/material';

const CompConfirmOptions = ({
    model_manu_sc_id_arr, sc_id_arr, certsChosen, certsChosenForScPerspective,
    setModel_manu_sc_id_arr, setSc_id_arr, setCertsChosen, setCertsChosenForScPerspective
}) => {

    return (
        <>
            <CompNavigation
                model_manu_sc_id_arr={model_manu_sc_id_arr}
                sc_id_arr={sc_id_arr}
                setModel_manu_sc_id_arr={setModel_manu_sc_id_arr}
                setSc_id_arr={setSc_id_arr}
                setCertsChosen={setCertsChosen}
                setCertsChosenForScPerspective={setCertsChosenForScPerspective}
            />

            <Divider sx={{
                my:4
            }}/>
            <CompSummary 
                model_manu_sc_id_arr={model_manu_sc_id_arr}
                sc_id_arr={sc_id_arr}
                certsChosen={certsChosen}
                certsChosenForScPerspective={certsChosenForScPerspective}
            />
        </>
    )
}

export default CompConfirmOptions