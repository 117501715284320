import React from "react";
import {
  Box,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  Typography,
} from "@mui/material";
import keyToField from "../../utils/keyToField";
import { Link } from "react-router-dom";
import { fixInvalidDates } from "../../utils/misc/fixInvalidDates";
import { useTranslation } from 'react-i18next';


function ComponentExtraDetail(props) {
  const { t : itranslate } = useTranslation();

  const { component } = props;
  const displayInRc = [
    "tec_certificate_number",
    "tec_standard",
    "tec_issue_date",
  ];

  const simpleDisplay = (cert, field) => {
    return (
      cert?.cert_corrections?.[field] || cert?.cert_type_metadata?.[field] || ""
    );
  };

  return (
    <Box>
      {window.location.pathname === "/lift_emsd" && (
        <TableContainer sx={{ width: "800px" }}>
          <Typography sx={{ fontWeight: "bold", mt: 3 }}>
            {/* Registered Contractor */}
            {itranslate('Registered Contractor')}
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {/* RC number */}
                  {itranslate('RC number')}
                </TableCell>
                <TableCell>
                  {/* RC company name */}
                  {itranslate('RC company name')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Link to={`/rcs/${component?.rcPopulated?._id}/show`}>
                    {component?.rcPopulated?.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {component?.rcPopulated?.address?.company}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* only show it in standalone comp detail page */}
      {!component?.model_id && (
        <TableContainer sx={{ width: "800px" }}>
          <Typography sx={{ fontWeight: "bold", mt: 3 }}>
            {/* Type Test Certificates */}
            {itranslate('Type Test Certificates')}
          </Typography>
          {component?.certsPopulated?.map((cert) => {
            return (
              <React.Fragment key={cert._id}>
                <Typography sx={{ fontFamily: "Frutiger_bold", mt: 3, ml: 2 }}>
                  {simpleDisplay(cert, "tec_certificate_number")}
                </Typography>

                <Table size="small">
                  <TableBody>
                    {Array.from(
                      new Set([
                        ...Object.keys(cert.cert_type_metadata || {}),
                        ...Object.keys(cert.cert_corrections || {}),
                      ])
                    )
                      .filter((field) => window.location.pathname === "/lift_rc" ? displayInRc.includes(field) : field)
                      .map((field) => {
                        return (
                          <TableRow key={field}>
                            <TableCell
                              sx={{ fontWeight: "bold" }}
                              variant="head"
                            >
                              {/* {keyToField[field] ?? field} */}
                              {itranslate(keyToField[field] ?? itranslate(field))}:
                            </TableCell>
                            <TableCell>
                              {field === "tec_issue_date" ||
                              field === "tec_expiry_date" ||
                              field === "tec_first_issue_date" ||
                              field === "tec_expiry_date"
                                ? fixInvalidDates( (simpleDisplay(cert, field)?.split("T")[0] || "").replace(/–/g, "-") )
                                : itranslate(simpleDisplay(cert, field))}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </React.Fragment>
            );
          })}
        </TableContainer>
      )}

      <TableContainer sx={{ width: "800px" }}>
        <Typography sx={{ fontWeight: "bold", mt: 3 }}>
          {/* Related Applications */}
          {itranslate('Related Applications')}
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {/* Application Number */}
                {itranslate('Application Number')}
              </TableCell>
              <TableCell>
                {/* Approval Date */}
                {itranslate('Approval Date')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {component?.applicationsPopulated?.map((app) => {
              return (
                <TableRow key={app._id}>
                  <TableCell>
                    <Link
                      to={`${
                        window.location.pathname === "/lift_emsd"
                          ? "/v2/applications"
                          : "/applications"
                      }/${app._id}/show`}
                    >
                      {app?.application_number}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {app?.approval_date?.split("T")[0] &&
                      fixInvalidDates(
                        (app?.approval_date?.split("T")[0] || "").replace(
                          /–/g,
                          "-"
                        )
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ComponentExtraDetail;
