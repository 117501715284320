import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import getToken from "../../utils/getAuthToken";
import { useTranslation } from 'react-i18next';


const DownloadPublicHolidays = () => {
  const { t : itranslate } = useTranslation();

  const downloadFile = async (filename) => {
    try {
      window.open(`${process.env.REACT_APP_ENV_ENDPOINT}/lift/files/downloadPublicHolidays/${filename}?token=${getToken()}`);
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <Box>
      <Typography sx={{ textAlign: "center" }} variant="h5" marginBottom="20px" >
        {/* Download Public Holidays File */}
        {itranslate('publicHolidays.Download Public Holidays File')}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center"}}>
        <Button variant='contained' onClick={() => downloadFile('publicHolidays.xlsx')}> 
          {/* Download */}
          {itranslate('publicHolidays.Download')}
        </Button>
      </Box>
    </Box>
  )
}

export default DownloadPublicHolidays