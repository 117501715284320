import React from "react";
import { Text } from "@react-pdf/renderer";
import reactStringReplace from "react-string-replace";

function renderSymbol(input) {
  return reactStringReplace(input, /([βƧ≤≦<>≧≥])/g, (match, i) => (
    <Text
      key={i}
      style={{
        fontFamily: "DejaVu Sans",
      }}
    >
      {match}
    </Text>
  ));
}

export default renderSymbol;
