import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import renderSymbol from "../../utils/pdf/renderSymbol";
import { fixInvalidDates } from "../../utils/misc/fixInvalidDates";

const styles = StyleSheet.create({
  tableContainer: {
    padding: "10px 0px",
    marginRight: "-15px",
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "none",
    fontFamily: "Frutiger_bold",
    fontSize: "11px",
    marginRight: "-45px",
    marginBottom: "15px",
  },
  partBTableHead: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "Futura",
    justifyContent: "space-between",
    padding: "0px 3px",
  },
});

const PartCModelTable = ({
  appendix,
  application,
  model,
  manu_code,
  romanize,
  index,
  simpleDisplay,
  certificates,
  partB,
}) => {

  if (!certificates || !appendix || !application) return null;

  const certs = certificates.filter(
    (tec) =>
      tec?.reference === model.model_code &&
      ["FullQualityCert", "ModelTypeCert"].includes(tec.type) &&
      !appendix.hideCerts?.includes(tec.id) &&
      !appendix.hideComponentCodes?.includes(tec.reference) &&
      !appendix.hideIndividualCerts?.includes(tec.id)
  );

  const checkLiftStandard = [
    "HydraulicLift",
    "ElectricTractionLiftMachineRoom",
    "ElectricTractionLiftRoomless",
  ].includes(application.app_type?.type);

  const checkEscalatorStandard = ["Escalator", "PassengerConveyor"].includes(
    application.app_type?.type
  );

  return (
    <View style={partB ? {} : styles.tableContainer}>
      {!partB && (
        <Text
          style={{
            textDecoration: "underline",
            fontFamily: "Frutiger_bold",
            fontSize: "11px",
          }}
        >{`${romanize(index + 1)}. ${
          model.LE_type === "E" ? "Escalator" : "Lift"
        } Model - ${model.provided_model_name}`}</Text>
      )}

      <View style={partB ? styles.partBTableHead : styles.tableHead}>
        <Text
          style={{
            width: partB ? "40.3333%" : "33.3333%",
            ...(!partB && { paddingLeft: "6px" }),
          }}
        >
          {appendix?.tecHeader || "Type Exam. Certificate"}
        </Text>

        <Text style={{ width: partB ? "26.3333%" : "33.3333%" }}>
          Manufacturer Code
        </Text>

        <Text
          style={{ width: "33.3333%", ...(partB && { textAlign: "right" }) }}
        >
          EMSD Approval Ref.
        </Text>
        
      </View>

      {certs.map((cert, certIndex) => {
        return (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              ...(partB && { fontFamily: "Futura", padding: "0px 3px" }),
              ...(!cert.historical && {
                fontFamily: partB ? "Futura_bold" : "Frutiger_italic",
              }),
            }}
            key={certIndex}
          >
            <Text
              style={{ width: "45%", ...(!partB && { paddingLeft: "6px" }) }}
            >
              {simpleDisplay(cert.id, "tec_certificate_number")} dated{" "}
              {fixInvalidDates(simpleDisplay(cert.id, "tec_issue_date"))}
            </Text>
            <Text style={{ width: "10%" }}>
              |{"     "}
              {simpleDisplay(cert.id, "manufacturer_code") || manu_code}
              {"     "}|
            </Text>
            <Text style={{ width: "45%", textAlign: "right" }}>
              {renderSymbol(simpleDisplay(cert.id, "Approval Reference"))}
              {checkLiftStandard && cert.comply_standard?.lift && (
                <>
                  {" "}
                  [<Text style={{ fontFamily: "DejaVu Sans" }}>Ƨ</Text>]
                </>
              )}
              {checkEscalatorStandard &&
                cert.comply_standard?.escalator &&
                `[#]`}
            </Text>
          </View>
        );
      })}

      {!partB && <Text style={{ marginTop: "10px" }}>{appendix.refer}</Text>}

    </View>
  );
};

export default PartCModelTable;
