import React, { useEffect, useState } from 'react';
import licenceText from './licence.txt'; 
import { Box } from '@mui/material';



// const ViewTextFile = () => {
//   const [content, setContent] = useState('');

//   useEffect(() => {
//     fetch(`${process.env.PUBLIC_URL}/licence/licence.txt`)
//       .then((response) => response.text())
//       .then((text) => setContent(text))
//       .catch((error) => console.error('Error fetching the text file:', error));
//   }, []);

//   return (
    // <div style={{ display: 'flex', justifyContent: 'center' }} div>
    //   <pre>{content}</pre>
    // </div>
//   );
// };



const ViewTextFile = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(licenceText)
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((error) => console.error('Error fetching the text file:', error));
  }, []);

  return (
    <Box sx={{
      marginLeft: '100px',
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', }} div>
        <pre>{content}</pre>
      </Box>
    </Box>
  );
};

export default ViewTextFile;