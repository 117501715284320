import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Pagination,
  CardContent,
  Card,
  CardHeader,
  Box,
} from "@mui/material";
import { useGetList } from "react-admin";
import { useEffect, useState } from "react";

function AutogenSelect(props) {
  const { upload, update, setUpdate, id } = props;
  const updatedComponents = Object.values(update?.app_type?.components || {})
    .flat()
    .filter((comp) => comp.type_test_certificate_id?.some((cert) => cert.id))
    .map((comp) => comp.provided_comp_name);
  const { data: components_same_name } = useGetList(
    "components/autogen/same_name",
    {
      filter: {
        id: id,
        component_names: updatedComponents,
      },
    },
    { enabled: Boolean(update) }
  );
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * 10;
  const endIndex = Math.min(startIndex + 10, components_same_name?.length);

  const handleManuf = (value) => {
    const checkedManuf = update.autogen?.chosen || [];
    const currentIndex = checkedManuf.indexOf(value);
    const newChecked = [...checkedManuf];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setUpdate({
      ...update,
      autogen: {
        apps: [],
        chosen: newChecked,
      },
    });
  };

  useEffect(() => {
    //In case user go to previous step and change stuff, just nuke the autogen list
    if (update.autogen) {
      setUpdate({
        ...update,
        autogen: {
          chosen: [],
          apps: [],
        },
      });
    }
  }, []);

  if (!components_same_name) return null;
  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader title="Auto-Update" />
      <CardContent>
        <List>
          <Typography sx={{ fontWeight: "bold" }}>
            Choose manufacturer of update component for auto-update
          </Typography>
          {components_same_name?.slice(startIndex, endIndex).map((comp) => {
            return (
              <ListItem
                dense
                key={comp.id}
                onClick={() => handleManuf(comp.component_code)}
              >
                <Checkbox
                  edge="start"
                  checked={
                    (update?.autogen?.chosen || []).indexOf(
                      comp.component_code
                    ) !== -1
                  }
                />
                <ListItemText
                  primary={`${comp.manufacturersPopulated?.[0]?.provided_name} - ${comp.provided_comp_name} | ${comp.component_code}`}
                />
              </ListItem>
            );
          })}
          <Pagination
            count={Math.ceil(components_same_name.length / 10)}
            onChange={(e, value) => setCurrentPage(value)}
          />
        </List>
        <AppList
          upload={upload}
          update={update}
          setUpdate={setUpdate}
          id={id}
        />
      </CardContent>
    </Card>
  );
}

function AppList(props) {
  const { upload, update, setUpdate, id } = props;
  const { data: appsAffectedByUpdatedComps } = useGetList(
    "apps/update/autogen",
    {
      filter: {
        id: id,
        //If components are updated, some of the type certs will no longer be ObjectId, but an object containing id, hence indicated it is updated
        model_codes: upload?.model?.map((m) => m.model_code),
        component_codes: update?.autogen?.chosen || [],
      },
    },
    { enabled: Boolean(update) }
  );
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * 10;
  const endIndex = Math.min(
    startIndex + 10,
    appsAffectedByUpdatedComps?.length
  );

  const handleToggle = (value) => () => {
    const checkedApps = update.autogen?.apps || [];
    const currentIndex = checkedApps.indexOf(value);
    const newChecked = [...checkedApps];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setUpdate({
      ...update,
      autogen: {
        ...update.autogen,
        apps: newChecked,
      },
    });
  };

  if (!appsAffectedByUpdatedComps) return <Box sx={{ height: 350 }} />; //to prevent the page jumping up and down when rerender

  if (appsAffectedByUpdatedComps.length === 0)
    return (
      <Typography sx={{ fontWeight: "bold" }}>
        No auto-update application found
      </Typography>
    );
  return (
    <List>
      <Typography sx={{ fontWeight: "bold" }}>
        Would you like to auto-update the following application (s)?
      </Typography>
      {appsAffectedByUpdatedComps
        .slice(startIndex, endIndex)
        .map((app, index) => {
          return (
            <ListItem key={app.id} dense onClick={handleToggle(app.id)}>
              <Checkbox
                edge="start"
                checked={(update?.autogen?.apps || []).indexOf(app.id) !== -1}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText
                primary={`${app.application_number} |
      ${app.manufacturers?.join(", ") || ""} - ${
                  app.provided_model_name || ""
                }`}
                secondary={
                  (app.components || [])
                    .map(
                      (c) =>
                        c.manufacturer +
                        " - " +
                        c.provided_comp_name +
                        " | " +
                        c.component_code
                    )
                    .join(", ") || ""
                }
              />
            </ListItem>
          );
        })}
      <Pagination
        count={Math.ceil(appsAffectedByUpdatedComps.length / 10)}
        onChange={(e, value) => setCurrentPage(value)}
      />
    </List>
  );
}

export default AutogenSelect;
