
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Tooltip from '@mui/material/Tooltip';


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// components
import EnhancedTableHead from "./ModelListTable/EnhancedTableHead.jsx";
import InputSearchBar from "./ModelListTable/InputSearchBar"
import CompPopper from '../../../../../../_hoc/CompPopper/index.jsx';

// custom hooks
import {getModelListForRuSummary} from "../../../../../../admin/useHooks/useModels/index.jsx";

import {headCells} from '../../../../CreateStage2/constants/headCells.js'
import { ShowBtn } from '../../../../../../_hoc/Buttons/ShowBtn.jsx';
import BasicModal from '../../../../../../_hoc/modals/BasicModalLarge.jsx';
import ShowModalScs from './show.jsx';
import { useTranslation } from 'react-i18next';
// import ModelPerspectiveShow from './show.jsx';

// import { useTranslate } from 'react-admin';

export default function SummaryModelPerspective({model_manu_sc_id_arr, certsChosen}) {
    // const translate = useTranslate();
    const { t : itranslate } = useTranslation();

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('client_user');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [isLoading, setIsLoading] = useState(true);
    const [openLoadingModal, setOpenLoadingModal] = useState(true)

    const [totalCount, setTotalCount] = useState(0)
    const [range, setRange] = useState(`[0, 4]`)
    const [filter, setFilter] = useState(`{}`)
    const [sort, setSort] = useState(`["model_code", "DESC"]`)

    const [dataModel, setDataModel] = useState([])
    const [openModelScModal, setOpenModelScModal] = useState(false)
    const [oneModel, setOneModel] = useState(null)

    // setRange when page changes
    useEffect(() => {
        setRange(`[${page * rowsPerPage},${page * rowsPerPage + rowsPerPage - 1}]`)
    }, [page, rowsPerPage])

    // setSort
    const handleRequestSort = (event, property) => {
        event.preventDefault()
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort(`["${property}","${isAsc ? "DESC" : "ASC"}"]`)
    };

    function fixedSortingIssue(property){
        let arr = headCells.models.map(v => v.id)
        if(arr.includes(property[0])){
            setSort(`["${property[0]}","${property[1]}"]`)
        } else {
            setSort(`["model_code","DESC"]`)
        }
    }

    // handle query value update
    useEffect(() => {
        // if no value in the filter field, the function will set sort state to default value, 
        // it can prevents the page from returning nothing when user pass no filter field and sort[0] is "".
        fixedSortingIssue(JSON.parse(sort))

        getModelListForRuSummary(filter, range, sort, {model_manu_sc_id_arr, certsChosenForModelPerspective: certsChosen}).then((res) => {
            setDataModel(res?.data)
            setTotalCount( + res?.headers["x-total-count"] || 999999)
        }).catch((err) => {
        }).finally(() => {
            setIsLoading(false)
        })
    }, [filter, range, sort])

    useEffect(() => {
        if(isLoading === false) {
            setOpenLoadingModal(false)
            // collect all the model ids in the dataModel
            
        } else {
            setOpenLoadingModal(true)
        }

    }, [dataModel, isLoading])

    // setPage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // set rowsPerPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, rowsPerPage - dataModel?.length) : 0;


    const handelView = (row) => {
        setOneModel(row)
        setOpenModelScModal(true)
    }

    return (
        <Container component="main" maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoadingModal}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }

            {
                <Box sx={{ 
                        width: '100%',
                        boxShadow: "0 0 0 2rem rgba(246,248,249)",
                    }}
                >
                    <Paper sx={{ width: '100%', mb: 2, overflowX: "auto" }}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size='medium'
                            >
                                {
                                    !isLoading && dataModel?.length > 0 &&
                                    (
                                        <>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={handleRequestSort}
                                                tableHeadCells={headCells?.models}
                                            />
                                            <TableBody>
                                                {dataModel?.length > 0 && dataModel.map((row, index) => {
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    let rowTypeWithSpace = !row.type.includes(" ") ? row.type.split(/(?=[A-Z])/).join(" ") : row.type
                                                    // let rowTypeWithSpace = !row.type.includes(" ") ? row.type.split(/(?=[A-Z])/) : row.type

                                                    let model_manu_sc = model_manu_sc_id_arr.filter(v => row._id === v.model)
                                                    let manuIds = model_manu_sc.flatMap(v => v.relatedManufacturersChosen)

                                                    return (
                                                        <TableRow
                                                            key={row._id}
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                            >
                                                                {row.model_code}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ 
                                                                    cursor: 'pointer', 
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "8em"
                                                                }}
                                                            >{row.provided_model_name}</TableCell>

                                                            <TableCell
                                                                sx={{ 
                                                                    cursor: 'pointer', 
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "8em"
                                                                }}
                                                            >
                                                                <Tooltip title={itranslate(rowTypeWithSpace)}
                                                                    placement="bottom"
                                                                    arrow
                                                                    followCursor
                                                                >
                                                                    <Box 
                                                                        key={index} 
                                                                        sx={{ 
                                                                            cursor: 'pointer', 
                                                                            whiteSpace: "nowrap",
                                                                            textOverflow: "ellipsis",
                                                                            overflow: "hidden",
                                                                            maxWidth: "8em"
                                                                        }}
                                                                    >
                                                                        {itranslate(rowTypeWithSpace)}
                                                                    </Box>
                                                                </Tooltip>
                                                            </TableCell>

                                                            <TableCell
                                                                
                                                            >
                                                                {
                                                                    row.manufacturersPopulated.map((manu, index) => manuIds.includes(manu._id) && (
                                                                        <Tooltip title={manu.provided_name}
                                                                            key={index}
                                                                            placement="bottom"
                                                                            arrow
                                                                            followCursor
                                                                        >
                                                                            <Box 
                                                                                key={index} 
                                                                                sx={{ 
                                                                                    cursor: 'pointer', 
                                                                                    whiteSpace: "nowrap",
                                                                                    textOverflow: "ellipsis",
                                                                                    overflow: "hidden",
                                                                                    maxWidth: "8em"
                                                                                }}
                                                                            >
                                                                                {manu.provided_name}
                                                                            </Box>
                                                                        </Tooltip>
                                                                    ))
                                                                }
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{ 
                                                                    cursor: 'pointer', 
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "8em"
                                                                }}
                                                            >
                                                                {row?.uniqueModelTecIds?.length}
                                                            </TableCell>

                                                            <TableCell
                                                                sx={{ 
                                                                    cursor: 'pointer', 
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    maxWidth: "8em"
                                                                }}
                                                            >
                                                                { 
                                                                    row?.manufacturersPopulated?.filter((manu, index) => {
                                                                        return certsChosen?.iso?.includes(manu?.iso_certificate_id)
                                                                    })?.length
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                size="small"
                                                            >
                                                                <ShowBtn
                                                                    handleView={() => handelView(row)}
                                                                    row={row}
                                                                    code={row.model_code}
                                                                    size='small'
                                                                >
                                                                    {/* {translate("phase2.createRu.Components")} */}
                                                                    {itranslate("Components")}
                                                                </ShowBtn>

                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}

                                                {/* fill up the empty dataModel for last page's items of the list */}
                                                {emptyRows > 0 && (
                                                    <TableRow>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                )}

                                            </TableBody>
                                        </>
                                    )
                                }
                            </Table>
                        </TableContainer>

                        {!isLoading && dataModel?.length === 0 && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div>
                                    <h1 className='my-60 text-3xl'>
                                        {/* {translate("phase2.createRu.NoModel")} */}
                                        {itranslate("NoModel")}
                                    </h1>
                                </div>
                            </Box>
                        )}

                        {
                            !isLoading && dataModel?.length > 0 && (
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage={itranslate("Rows per page")}
                                />
                            )
                        }
                    </Paper>
                </Box>
            }
        
            <BasicModal open={openModelScModal} setOpen={setOpenModelScModal}>
                <ShowModalScs oneModel={oneModel}/>
            </BasicModal> 
        </Container>
        
    );
}