// mui 
import Button from '@mui/material/Button';
// redux
import { useSelector, useDispatch } from "react-redux";
import { setStage } from "../../../../admin/redux/stageSlice.js";
import { setOption } from '../../../../admin/redux/optionSlice.js';
import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/system';
import {
    Grid, 
    Paper,
    Stack
} from '@mui/material';
import {grey, blue } from "@mui/material/colors"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from "react-router-dom";
import BasicModal from '../../../../_hoc/modals/BasicModal.jsx';
import { useState } from 'react';
// import { useTranslate } from 'react-admin';
import { useTranslation } from 'react-i18next';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: "#EFF5FA",
    '&:hover': {
        backgroundColor: "#E4EDF7",
    },
}));


export default function CompNavigation({model_manu_sc_id_arr, sc_id_arr}) {
    // const translate = useTranslate();
    const { t : itranslate } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openModalBackToModel, setOpenModalBackToModel] = useState(false);
    const [openModalBackToComponent, setOpenModalBackToComponent] = useState(false);
    return (
        <Container
            maxWidth="xl"
            className="mt-10"
        >
            <div className='w-full'>
                <Button
                    onClick={() => {
                        navigate(`/restrictedUrls`)
                    }}
                    variant='outlined'
                >
                    {/* <ArrowBackIcon />{translate("phase2.createRu.backToRuListing")} */}
                    <ArrowBackIcon />{itranslate("backToRuListing")}
                </Button>

{/* final step btn */}
                {
                    (model_manu_sc_id_arr.length > 0 || sc_id_arr.length > 0) && (
                        <Button
                            onClick={() => {
                                dispatch(setStage("3"))
                            }}
                            variant="contained"
                            sx={{
                            }}
                            className='float-right'
                        >
                            {/* {translate("phase2.createRu.gotoFinalStep")} */}
                            {itranslate("gotoFinalStep")}
                        </Button>
                    )
                }

{/* components btn */}
                {
                    sc_id_arr.length === 0 && (
                        <ColorButton
                            onClick={() => { 
                                dispatch(setOption("components"))
                                dispatch(setStage("2_1"))
                            }}
                            variant="contained"
                            className='float-right'
                            sx={{
                                mr: 2
                            }}         
                        >
                            {/* {translate("phase2.createRu.selectComponents")} */}
                            {itranslate("selectComponents")}
                        </ColorButton>
                    )
                }

                {
                    sc_id_arr.length > 0 && (
                        <ColorButton
                            onClick={() => { 
                                setOpenModalBackToComponent(true)
                            }}
                            variant="contained"
                            className='float-right'
                            sx={{
                                mr: 2
                            }}         
                        >
                            {/* {translate("phase2.createRu.backToComponentPerspective")} */}
                            {itranslate("backToComponentPerspective")}
                        </ColorButton>
                    )
                }

{/* models btn */}
                {
                    model_manu_sc_id_arr.length === 0 && (
                        <ColorButton
                            onClick={() => { 
                                dispatch(setOption("models"))
                                dispatch(setStage("2_1"))
                            }}
                            variant="contained"
                            className='float-right'
                            sx={{
                                mr: 2
                            }}                            
                        >
                            {/* {translate("phase2.createRu.selectModels")} */}
                             {itranslate("selectModels")}
                        </ColorButton>
                    )
                }
                {
                    model_manu_sc_id_arr.length > 0 && (
                        <ColorButton
                            onClick={() => { 
                                setOpenModalBackToModel(true)
                            }}
                            variant="contained"
                            className='float-right'
                            sx={{
                                mr: 2
                            }}
                        >
                            {/* {translate("phase2.createRu.backToModelPerspective")} */}
                            {itranslate("backToModelPerspective")}
                        </ColorButton>
                    )
                }
            </div>

            {/* for back to models */}
            <BasicModal setOpen={setOpenModalBackToModel} open={(openModalBackToModel)}>
                <p>
                    {/* {translate("phase2.createRu.discardAllModels")} */}
                    {itranslate("discardAllModels")}
                </p>
                <Button 
                    onClick={() => {
                        dispatch(setOption("models"))
                        dispatch(setStage("2_1"))
                    }}>
                        {/* {translate("phase2.createRu.confirm")} */}
                        {itranslate("confirm")}
                </Button>
            </BasicModal>

            {/* for back to models */}
            <BasicModal setOpen={setOpenModalBackToComponent} open={(openModalBackToComponent)}>
                <p>
                    {/* {translate("phase2.createRu.discardAllComps")} */}
                    {itranslate("discardAllComps")}
                </p>
                <Button 
                    onClick={() => {
                        dispatch(setOption("components"))
                        dispatch(setStage("2_1"))
                    }}>
                        {/* {translate("phase2.createRu.confirm")} */}
                        {itranslate("confirm")}
                </Button>
            </BasicModal>
        </Container>
    )
}