import React, { useState } from "react";
import { fixInvalidDates } from "../misc/fixInvalidDates";

const DateStringInput = (props) => {
  const { value, isDisabled, ...others } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <input
      disabled={isDisabled}
      type="text"
      value={isFocused ? value : value ? fixInvalidDates(value) : ""}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      {...others}
    />
  );
};

export default DateStringInput;
