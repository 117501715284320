import React, { useEffect, useState } from "react";
import {
    Popper,
    IconButton,
    Paper,
    Grid,
    CircularProgress,
    Typography,
    Divider,
    ClickAwayListener,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
} from "@mui/material";
import { useGetOne } from "react-admin";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import keyToField from "../../utils/keyToField";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";

const PreviewSc = (props) => {
    const { record } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    //enabled to ensure it does not call when the popper is not open

    const [isLoading, setIsLoading] = useState(true);

    const handleToggle = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (record) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [record]);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Box>
                <IconButton
                    size="small"
                    onClick={handleToggle}
                    aria-describedby={anchorEl ? "mouse-enter-popper" : undefined}
                >
                    <HelpOutlineIcon />
                </IconButton>
                <Popper
                    sx={{ zIndex: 2 }}
                    id="mouse-enter-popper"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    placement="left-start"
                    disablePortal
                >
                    {({ TransitionProps }) => (
                        <Paper
                            sx={{ p: 3, maxHeight: 800, maxWidth: "70vw", overflow: "auto" }}
                        >
                            {isLoading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    {
                                        record.rcsFromAppPopulated.map((rc) => {
                                            let rlc = rc.rc_number_lift;
                                            let rec = rc.rc_number_escalator;
                                            let company = rc.address.company;
                                            let rc_id = rc._id;
                                            return (
                                                <div key={uuid()}>
                                                    <Link
                                                        className="no-underline text-[#2a598f]"
                                                        to={`/rcs/${rc_id}/show`}
                                                    >
                                                        {`${rlc ? rlc + " / " : ""} ${rec ? rec + " / " : ""} ${company}`}
                                                    </Link>

                                                </div>
                                            );

                                        })
                                    }
                                </>
                            )
                            }
                        </Paper>
                    )}
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

export default PreviewSc;
