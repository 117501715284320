import * as React from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  List,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "react-admin";
import { useEffect, useState } from "react";
import getToken from "../utils/getAuthToken";
import axios from "axios";
import { useDispatch } from "react-redux";
import { saveData } from "../redux/uploadSlice";
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";


const AiValidating = (props) => {

  //So I tried to refactor with useGetList, but failed, because in dataProvider there is a mappingFunction that will cause issue for app_type
  const [validatedList, setValidatedList] = useState([]);
  // console.log("🚀 ~ AiValidating ~ validatedList:", validatedList)

  const [updatedValidedList, setUpdatedValidedList] = useState([]);
  // console.log("🚀 ~ AiValidating ~ updatedValidedList:", updatedValidedList)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permissions } = usePermissions();


  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || i18n.language);
  const { t: itranslate } = useTranslation();


  // check to see if the list has been validated and translate some of the words in the title on each application, if it exists
  useEffect(() => {
    if(validatedList.length > 0) {

      const translatedList = validatedList.map(item => {

        if (item?.title === undefined) {
          return item;
        }

        else {
          const title = item.title;
          const words = title.split(", ");
      
          if (words[0].startsWith("Validated")) {
              const validationKey = words[0].split(" ")[0]; // "Validated"
              const componentKey = words[1] ? words[1].split(" ")[0] : ""; // Extract component name if present
      
              const translatedTitle = `${itranslate(validationKey)}${words[0].slice(9)}, ${itranslate(componentKey)}${words[1] ? words[1].slice(componentKey.length) : ""}`;
              
              return {
                  ...item,
                  title: translatedTitle
              };
          } 

          return item; // Return unchanged if not "Validated"
        }
        
    });

    setUpdatedValidedList(translatedList);

    }
  }, [validatedList]);





  useEffect(() => {
    if (permissions === false) {
      navigate("/login");
    }
  });

  const fetchValidatedList = () => {
    axios
      .get(
        `${process.env.REACT_APP_ENV_ENDPOINT}/lift/app_preSubmit/resubmit_required`,
        {
          headers: { authorization: getToken() },
        }
      )
      .then((res) => setValidatedList(res.data))
      .catch((err) => console.log(err));
  };

  // Here we want to get draft that has validation status equals validated/AIerror, and also the clarifying applications
  useEffect(() => {
    fetchValidatedList();
  }, []);

  const handleRetry = () => {
    fetchValidatedList();
  };
  

  return (
    <div>
      {permissions && (
        <Card sx={{ mt: 2.5 }}>
          <CardHeader title={itranslate('Applications (Validating)')}/>

          <CardContent>
            <List>
              {validatedList &&
                validatedList.length > 0 &&
                // validatedList.map((app) => {
                  (language === "en" ? validatedList : updatedValidedList).map((app) => {
                  return (
                    <ListItemButton
                      key={app._id}
                      divider={true}
                      disabled={
                        app.validationStatus === "validating" ||
                        app.status === "Pending AI"
                      }
                      onClick={() => {
                        if (app.__t === "ApprovedApplicationUpdateDraft") {

                          //update application AI pre checking
                          dispatch(saveData(app));

                          const query = app.changedManufacturer ? `?manufacturer=${app.changedManufacturer}` : "";

                          navigate(`/applications_${app.update_type}/${app.update_upon}${query}`);
                        } else if (
                          app.validationStatus === "validated" ||
                          app.validationStatus === "AIerror"
                        ) {
                          navigate(`/applications_new/${app._id}`);
                        } else if (
                          app.status === "Clarifying" ||
                          app.status === "AIerror"
                        ) {
                          navigate(`/applications/${app._id}`);
                        }
                      }}
                    >

                      <ListItemAvatar>
                        <Avatar>
                          <PostAddIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        primary={app.title ? app.title : app.application_number}
                        // secondary={
                        //   app.validationStatus === "validated"
                        //     ? "Application is validated, please continue by clicking here"
                        //     : app.validationStatus === "validating"
                        //     ? "Validating, please wait for the AI"
                        //     : app.validationStatus === "AIerror" ||
                        //       app.status === "AIerror"
                        //     ? "Some error occurred and AI was unable to get certificate data, please validate again"
                        //     : app.status === "Clarifying"
                        //     ? "Clarifying is required, please continue by clicking here"
                        //     : app.status === "Pending AI"
                        //     ? "Pending AI, please wait for the AI"
                        //     : ""
                        // }
                        secondary={
                          app.validationStatus === "validated"
                            ? itranslate('Application is validated, please continue by clicking here')
                            : app.validationStatus === "validating"
                            ? itranslate('Validating, please wait for the AI')
                            : app.validationStatus === "AIerror" ||
                              app.status === "AIerror"
                            ? itranslate('Some error occurred and AI was unable to get certificate data, please validate again')
                            : app.status === "Clarifying"
                            ? itranslate('Clarifying is required, please continue by clicking here')
                            : app.status === "Pending AI"
                            ? itranslate('Pending AI, please wait for the AI')
                            : ""
                        }
                      />
                    </ListItemButton>
                  );
                })}

              {validatedList && validatedList.length === 0 && (
                <div>
                  <Typography>
                    {itranslate("No record found")}
                  </Typography>
                  <IconButton sx={{ px: 0 }} onClick={handleRetry}>
                    <RefreshIcon />
                    <Typography>
                      {itranslate("Retry")}
                    </Typography>
                  </IconButton>
                </div>
              )}

            </List>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default AiValidating;
